import { useState, useRef, useEffect } from 'react'
import ReactCalendar from "react-calendar";
import { useTranslation } from "react-i18next";

import useUser from 'hooks/useUser'
import parseDate from 'lib/parseDate'

const App = props => {
  const user = useUser()
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const $dropdown = useRef(null)

  useEffect(() => {
    const show = () => setShow(true)
    const hide = () => setShow(false)
    if ($dropdown.current) {
      $dropdown.current.addEventListener('show.bs.dropdown', show)
      $dropdown.current.addEventListener('hidden.bs.dropdown', hide)
    }
    return () => {
      if ($dropdown.current) {
        $dropdown.current.removeEventListener('show.bs.dropdown', show)
        $dropdown.current.removeEventListener('hidden.bs.dropdown', hide)
      }
    }
  }, [$dropdown.current])

  return (
    <div className="dropdown" ref={$dropdown}>
      <button disabled={props.disabled} className={`btn ${props.className || ''} ${props.inline ? 'd-flex gap-2' : ''} text-truncate`} data-bs-toggle="dropdown" data-tip={props.tip}>
        <div className="text-start text-secondary fw-light text-first-up">{props.label}</div>
        <div className="text-primary">{props.value?.label}</div>
      </button>
      <div className="dropdown-menu p-2" style={{ maxWidth: '280px', minWidth: '280px' }}>
        {show && (
          <>
            <ReactCalendar
              locale={user.state?.language}
              next2Label={null}
              prev2Label={null}
              calendarType="ISO 8601"
              defaultValue={new Date()}
              onClickDay={date => props.onChange(date)}
              value={parseDate(props.value.value) || null}
              minDetail="year"
              showFixedNumberOfWeeks
              formatDay={(locale, date) => date.getDate().toString()}
            />
            <div className="mt-2 text-end">
              <button
                className="btn btn-sm bg-light text-danger"
                onClick={() => props.onChange()}
              >
                {t('no_date', 'Sin fecha')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default App
