import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../config/i18n";

function VitalSigns(props) {
  const { t } = useTranslation();

  const [selectedVital, setSelectedVital] = useState(false);

  function renderVitalSigns(obj) {
    let returnArray = [];
    let date;
    let tas;
    let tad;

    const units = {
      height: "cm",
      weight: "kg",
      fio2: "%",
      abdperim: "cm",
      sat: "%",
    };

    if (obj.startsAt) date = obj.startsAt;

    if (date) {
      returnArray.push(
        <span className="me-4">
          <small className="fst-italic text-muted">
            {formatDate(date, "dd-MMM-yyyy")}
          </small>
        </span>
      );
    }

    if (obj.tas) tas = obj.tas;
    if (obj.tad) tad = obj.tad;

    if (tas && tad) {
      returnArray.push(
        <span className="me-2">
          <span className="mx-1">ta</span>
          {tas}/{tad}
        </span>
      );
    }

    if (obj) {
      for (const key in obj) {
        if (
          key === "fc" ||
          key === "fr" ||
          key === "sat" ||
          key === "fio2" ||
          key === "height" ||
          key === "weight" ||
          key === "abdperim"
        ) {
          returnArray.push(
            <span className="me-2">
              <span className="mx-1">{key}</span>
              {obj[key]} {units[key]}
            </span>
          );
        }
      }
    }
    return returnArray;
  }

  const Dropdown = (data) => {
    return (
      <div className="btn-group dropup ">
        <button
          type="button"
          className="btn btn-sm btn-link text-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <small>
            {!selectedVital
              ? t("select")
              : formatDate(selectedVital.startsAt, "dd-MMM-yyyy")}
          </small>
        </button>
        <ul className="dropdown-menu dropdownHeightLimit">
          {DropdownList(data)}
        </ul>
      </div>
    );
  };

  const DropdownList = (data) => {
    return data.map((element) => (
      <li>
        <a
          className="dropdown-item"
          onClick={() => {
            setSelectedVital(element);
            props.setVitalsMode(false);
          }}
        >
          {formatDate(element.startsAt, "dd-MMM-yyyy")}
        </a>
      </li>
    ));
  };

  return (
    <>
      {props.vitalSigns.filter((element) => element.id).length > 0 && (
        <div className="d-flex align-items-center bg-light px-4 py-2 mb-2">
          <h5 className="h4 fw-bold mb-0 py-2">{t("ehr:vitalSigns")}</h5>

          {props.showBtns && (
            <button
              className={
                props.vitalsMode === "all"
                  ? "btn btn-sm btn-link text-success"
                  : "btn btn-sm btn-link text-secondary"
              }
              onClick={() => {
                props.setVitalsMode("all");
              }}
            >
              <small>{t("ehr:all")}</small>
            </button>
          )}

          {props.showBtns && (
            <button
              className={
                props.vitalsMode === "last"
                  ? "btn btn-sm btn-link text-success"
                  : "btn btn-sm btn-link text-secondary"
              }
              onClick={() => {
                props.setVitalsMode("last");
              }}
            >
              <small>{t("ehr:last")}</small>
            </button>
          )}

          {props.showBtns && Dropdown(props.vitalSigns)}

          {props.showBtns && (
            <button
              className="btn btn-link btn-sm text-danger"
              onClick={() => {
                props.setPrintStatus({
                  ...props.printStatus,
                  vitalSigns: false,
                });
              }}
            >
              <small>{t("ehr:hide")}</small>
            </button>
          )}
        </div>
      )}

      {props.printStatus.vitalSigns &&
      !props.vitalsMode &&
      selectedVital &&
      selectedVital.id ? (
        <div className="px-4 mt-2">{renderVitalSigns(selectedVital)}</div>
      ) : null}

      {props.printStatus.vitalSigns &&
      props.vitalsMode === "last" &&
      props.vitalSigns[0] &&
      props.vitalSigns[0].id ? (
        <div className="px-4 yt-2">{renderVitalSigns(props.vitalSigns[0])}</div>
      ) : null}

      {props.printStatus.vitalSigns &&
      props.vitalsMode === "all" &&
      props.vitalSigns
        ? props.vitalSigns.map((vital) => {
            if (vital.id) {
              return <div className="px-4 my-2">{renderVitalSigns(vital)}</div>;
            }
          })
        : null}
    </>
  );
}

export default VitalSigns;
