import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Typeahead } from "react-bootstrap-typeahead";

import geo from "./geo";

function Address(props) {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [searching, setSearching] = useState(false);
  const [provider, setProvider] = useState(props.provider || "google");

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      if (debounceTimer) clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const fetchOptions = async (query) => {
    setOptions([]);
    setSearching(true);

    const options =
      (await geo
        .autocomplete(provider, query, props.autocomplete)
        .catch(console.log)) || [];

    setOptions(options);
    setSearching(false);
  };

  const onInputChange = useCallback(
    debounce((query) => {
      if (query.length >= 2) {
        fetchOptions(query);
      }
    }, 500),
    [provider, props.autocomplete]
  );

  useEffect(() => {
    const fetchProvider = async () => {
      return fetch("https://app.drapp.la/api/maps-provider").then(res => res.json()).then(rows => setProvider(rows.at(0)?.provider)
      ).catch(() => null)
    };
    fetchProvider();
  }, []);

  const onChange = (value) => {
    if (props.onChange) props.onChange({ ...value });
  };

  const onSelect = async (array) => {
    const value = array[0] || {};
    onChange({ ...value });
    value.place_id = value.place_id || value.id || value.reference;
    if (value && value.place_id) {
      let details;
      if (provider === "opencage") {
        details = await geo.deatilsOpenCage(
          options.find((o) => o.place_id === value.place_id),
          provider
        );
      } else {
        details = await geo.details(provider, value.place_id).catch(console.log);
      }
      const payload = { ...value, ...details };
      if (details) onChange(payload);
    }
  };

  return (
    <Typeahead
      isLoading={!!searching}
      inputProps={{
        required: true,
        id: props.id || "address",
        autoComplete: "off",
        disabled: !!props.disabled,
      }}
      minLength={2}
      id={props.id || "address"}
      labelKey="description"
      onChange={onSelect}
      options={options}
      filterBy={() => true}
      onInputChange={onInputChange}
      selected={[props.value].filter((p) => p && p.description)}
      placeholder={
        props.placeholder || t(`address_placeholder`, { context: "address" })
      }
      newSelectionPrefix={null}
      emptyLabel={searching ? t(`searching`) : t(`no_matches`)}
      paginationText={t(`display_more_results`, { context: "address" })}
      promptText={t(`type_to_search`, { context: "address" })}
      searchText={t(`searching`, { context: "address" })}
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
        <div className="form-floating">
          <input
            {...inputProps}
            autoFocus={props.autoFocus}
            type="text"
            className={`form-control${props.borderless ? " border-0 rounded-0" : ""
              }${props.transparent ? " bg-transparent" : ""}`}
            id={`floatingInput${props.id}`}
            placeholder={
              props.placeholder ||
              t(`address_placeholder`, { context: "address" })
            }
            ref={(input) => {
              inputRef(input);
              referenceElementRef(input);
            }}
          />
          <label htmlFor={`floatingInput${props.id}`}>{props.label}</label>
        </div>
      )}
    />
  );
}

export default React.memo(Address);
