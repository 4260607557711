import states from '../../config/ar_states'
const {
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_OPENCAGEDATA_API_KEY,
  REACT_APP_GOOGLE_URL,
  REACT_APP_OPENCAGE_URL
} = process.env;

let service;
let sessionToken;
const geo = {};

const waitForGoogleMaps = async () => {
  const step = 200;
  const loaded = () =>
    window.google &&
    window.google.maps &&
    window.google.maps.places &&
    window.google.maps.places.AutocompleteService;

  for (let time = 0; time < 4000 && !loaded(); time += step) {
    await new Promise((resolve) => setTimeout(resolve, step));
  }

  if (!loaded()) {
    try {
      window.notify({
        message: "Google Maps Error",
        type: "danger",
        title: "Google Maps",
      });
    } catch { }
    throw Error("google_maps_error");
  } else {
    service = service || new window.google.maps.places.AutocompleteService();
    sessionToken =
      sessionToken || new window.google.maps.places.AutocompleteSessionToken();
  }
};

const waitForOpenCageData = async () => {
  const step = 200;
  const loaded = () => true; // no hace falta esto para openCage

  for (let time = 0; time < 4000 && !loaded(); time += step) {
    await new Promise((resolve) => setTimeout(resolve, step));
  }

  if (!loaded()) {
    try {
      window.notify({
        message: "OpenCageData Error",
        type: "danger",
        title: "OpenCageData",
      });
    } catch { }
    throw Error("opencagedata_error");
  }
};

const parseGoogleAddressComponent = (hash, component) => {
  const key = component.types.filter((type) => type !== "political")[0];
  if (!key) return hash;

  let long = component.long_name;
  let short = component.short_name;

  if (
    key === "sublocality_level_1" &&
    (/^palermo/i.test(long) || /^palermo/i.test(short))
  ) {
    long = "Palermo";
    short = "Palermo";
  } else if (
    key === "administrative_area_level_1" &&
    /buenos aires/i.test(short)
  ) {
    short = "Buenos Aires";
    long = "Buenos Aires";
  } else if (key === "administrative_area_level_1" && /^caba$/i.test(short)) {
    short = "CABA";
    long = "Capital Federal";
  }

  const payload = { long, short };
  return { ...hash, [key]: payload };
};
const parseOpenCageAddressComponent = (components, formatted = undefined) => {
  const hash = {};
  try {
    // Si el país es Argentina, se busca el estado en la lista de estados de Argentina
    if (components.country_code === 'ar') {
      let isoCode;
      let isoCodes = components['ISO_3166-2'];
      if (!isoCodes && components.state_code) {
        isoCode = `ar-${components.state_code.toLowerCase()}`;
      } else {
        if (!isoCodes) isoCodes = ['ar-b']
        isoCode = isoCodes[0];
      }
      const state = states.find(state => state.iso === isoCode.toLowerCase());

      components.state = state ? state.label : components.state;
      components.county = state ? state.label : components.county;

      hash.administrative_area_level_1 = {
        long: components.state,
        short: components.state
      };

      hash.administrative_area_level_2 = {
        long: components.county,
        short: components.county
      };

      hash.country = {
        long: components.country,
        short: components.country_code
      };

      hash.locality = {
        long: components.state || components.city || components.town || components.village,
        short: components.state || components.city || components.town || components.village
      };

      hash.main = formatted || components.city || components.town || components.village;
      hash.secondary = `${components.state}, ${components.country}`;
    } else {
      hash.administrative_area_level_1 = {
        long: components.state,
        short: components.state
      };

      hash.administrative_area_level_2 = {
        long: components.county,
        short: components.county
      };

      hash.country = {
        long: components.country,
        short: components.country_code
      };

      hash.locality = {
        long: components.state || components.city || components.town || components.village,
        short: components.state || components.city || components.town || components.village
      };

      hash.main = formatted || components.city || components.town || components.village;
      hash.secondary = `${components.state}, ${components.country}`;
    }

    // Postal code
    if (components.postcode) {
      const [postalCode, postalCodeSuffix] = components.postcode.split(' ');
      hash.postal_code = {
        long: postalCode,
        short: postalCode
      };
      if (postalCodeSuffix) {
        hash.postal_code_suffix = {
          long: postalCodeSuffix,
          short: postalCodeSuffix
        };
      }
    }

    // Route (Street name)
    if (components.road) {
      hash.route = {
        long: components.road,
        short: components.road 
      };
    }

    // Street number
    if (components.house_number) {
      hash.street_number = {
        long: components.house_number,
        short: components.house_number
      };
    }

    // Sublocality (Neighborhood)
    if (components.neighbourhood || components.suburb) {
      hash.sublocality = {
        long: components.neighbourhood || components.suburb,
        short: components.neighbourhood || components.suburb
      };
    }

  } catch (error) {
    console.log('parseOpenCageAddressComponent', error);
  }
  return hash;
};


const getNeighborhood = (hash) => {
  if (hash.neighborhood) return hash.neighborhood;
  if (
    hash.sublocality_level_1 &&
    hash.administrative_area_level_1 &&
    hash.administrative_area_level_1.short === "CABA"
  ) {
    return { ...hash.sublocality_level_1 };
  } else if (
    hash.sublocality &&
    hash.administrative_area_level_1 &&
    hash.administrative_area_level_1.short === "CABA"
  ) {
    return { ...hash.sublocality };
  }
};

const getHash = (payload, useOpenCage) => {
  const hash = useOpenCage ? parseOpenCageAddressComponent(payload.components, payload.formatted) : payload.address_components.reduce(parseGoogleAddressComponent, {});
  hash.neighborhood = getNeighborhood(hash);
  hash.geo = {
    location:payload.geometry
  };

  if (useOpenCage) {
    hash.place_id = payload.annotations.geohash;
    hash.geo.bounds = payload.bounds;
  } else {
    hash.place_id = payload.place_id;
  }

  return hash;
};

geo.autocomplete = async (provider, input, options) => {
  if (provider === "opencage") {
    try {
      await waitForOpenCageData();
    } catch {
      return [];
    }

    if (!input || !input.trim()) return [];

    const params = new URLSearchParams({
      key: REACT_APP_OPENCAGEDATA_API_KEY,
      q: input,
      ...options,
    });

    return fetch(`${REACT_APP_OPENCAGE_URL}?${params.toString()}`)
      .then((res) => res.json())
      .then((data) =>
        data.results.map((result) => ({
          ...result,
          place_id: result.formatted,
          description: result.formatted,
          main: result.components.city || result.components.town || result.components.village,
          secondary: result.components.state || result.components.country,
        }))
      )
      .catch((e) => {
        console.error('geo.autocomplete', e);
        return [];
      });
  } else {
    try {
      await waitForGoogleMaps();
    } catch {
      return [];
    }

    if (!input || !input.trim() || !service) return [];

    return new Promise((resolve, reject) => {
      const params = { input, sessionToken, ...options };
      service.getPlacePredictions(params, (results, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK)
          return reject(status);
        const items = results.map((item) => ({
          place_id: item.place_id,
          description: item.description,
          main: ((item || {}).structured_formatting || {}).main_text,
          secondary: ((item || {}).structured_formatting || {}).secondary_text,
        }));
        resolve(items);
      });
    });
  }
};
geo.deatilsOpenCage = async (result, provider) => {
  const shouldUseOpenCage = provider === "opencage";

  try {
    return getHash(result, shouldUseOpenCage)
  } catch {
    return {};
  }
}
geo.details = async (provider, place_id, options) => {
  const shouldUseOpenCage = provider === "opencage";
  const params = new URLSearchParams({
    key: shouldUseOpenCage ? REACT_APP_OPENCAGEDATA_API_KEY : REACT_APP_GOOGLE_API_KEY,
    ...(shouldUseOpenCage ? { q: place_id } : { place_id }),
    ...options,
    ...(shouldUseOpenCage ? { language: 'es', roadinfo: 1, no_annotations:1, pretty: 1} : {})
  });

  if (!params.get("key") || (!params.get("q") && !params.get("place_id"))) return {};

  const url = shouldUseOpenCage ? REACT_APP_OPENCAGE_URL : `${REACT_APP_GOOGLE_URL}/geocode/json`;
  try {
    const response = await fetch(`${url}?${params.toString()}`);
    const result = await response.json();
    if (!shouldUseOpenCage) {
      return getHash(result.results[0], shouldUseOpenCage);
    }
    return getHash(result.results.filter(res => res.formatted == place_id)[0] || result.results[0] || {}, shouldUseOpenCage);

  } catch (e) {
    console.error('geo.details', e);
    return {};
  }
};
export default geo;
