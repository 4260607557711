import create from "zustand";
import { persist } from "zustand/middleware"

let date = new Date().toISOString().substr(0, 10)

try {
  date = window.location.pathname?.match(/\d{4}-\d{2}-\d{2}/)?.[0] || new Date().toLocaleDateString('fr-ca')
} catch {
  date = new Date().toISOString().substr(0, 10)
}

const team = window.location.pathname.match(/\/teams\/[a-z0-9]+/i)?.[0]?.replace('/teams/', '')

const callback = (set, get) => ({
  free: false,
  noshow: true,
  cancelled: true,
  reschedule: false,
  date,
  update: payload => set(() => ({ ...payload })),
  setEvent: event => set(() => ({ event })),
  setLocation: (location) => set((state) => ({ location })),
  setResource: (resource) => set((state) => ({ resource })),
  setService: (service) => set((state) => ({ service })),
  setFinancier: (financier) => set((state) => ({ financier })),
  clear: () => set(state => ({
    clearAt: Date.now(),
    resource: null,
    location: null,
    service: null,
    financier: null,
    free: false,
    noshow: true,
    cancelled: true
  })),
  toggle: (key) => set((state) => ({ [key]: !state[key] })),
  refetch: refetchAt => set(() => ({ refetchAt })),
  reset: () => set(() => ({
    event: undefined,
    reschedule: undefined,
    refetchAt: undefined
  }))
})

let useStore

if (team) {
  const options = {
    name: `${team}-events-storage`,
    partialize: state => ({ ...state, date: undefined, updatedAt: undefined, clearAt: undefined })
  }
  useStore = create(persist(callback, options))
} else {
  useStore = create(callback)
}


export default useStore;
