function ClearanceCalc(equation, sex, weight, age, creatinine, unit) {
  let result;

  if (creatinine === false || creatinine === "" || !creatinine) {
    return "cannot_calculate";
  }

  //check unit
  let unitCheck = checkUnits(creatinine, unit);

  if (unitCheck !== true) {
    creatinine = unitCheck;
  }

  //check age
  let ageIsInt = Number.isInteger(age);
  if (!ageIsInt) {
    return "cannot_calculate";
  }

  if (equation === "cogau") {
    let one = 140 - +age;
    let two = +one * +weight;
    let three = 72 * creatinine;
    let four = +two / +three;
    if (sex === "female") {
      let five = +four * 0.85;
      result = five;
      return result;
    } else {
      result = four;
      return result;
    }
  } else if (equation === "mdrd") {
    let one = Math.pow(creatinine, -1.154);
    let two = Math.pow(age, -0.203);
    let three = 175 * +one * +two;
    if (sex === "female") {
      let four = +three * 0.742;
      result = four;
    } else {
      result = three;
    }
  } else if (equation === "ckd") {
    if (sex === "male") {
      if (creatinine > 0.9) {
        let one = creatinine / 0.9;
        let two = Math.pow(one, -1.209);
        let three = Math.pow(0.993, age);
        let four = 141 * two * three;
        result = four;
      } else if (creatinine <= 0.9) {
        let one = creatinine / 0.9;
        let two = Math.pow(one, -0.411);
        let three = Math.pow(0.993, age);
        let four = 141 * two * three;
        result = four;
      }
    } else if (sex === "female") {
      if (creatinine > 0.7) {
        let one = creatinine / 0.7;
        let two = Math.pow(one, -1.209);
        let three = Math.pow(0.993, age);
        let four = 144 * two * three;
        result = four;
      } else if (creatinine <= 0.7) {
        let one = creatinine / 0.7;
        let two = Math.pow(one, -0.329);
        let three = Math.pow(0.993, age);
        let four = 144 * two * three;
        result = four;
      }
    }
  }
  if (!result?.toFixed) return ''
  return result.toFixed(0) + " mil/min";
}

function checkUnits(inputValue, inputUnit) {
  let relation = 88.4;
  let result;
  if (inputUnit === "µmol/L") {
    result = inputValue / relation;
    return result;
  } else {
    return true;
  }
}

export default ClearanceCalc;
