import { Suspense } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import getAccessToken from "./hooks/getAccessToken";
import Auth0Error from "./Components/Auth0Error/Auth0Error";

function Loader() {
  return (
    <div className="vh-100 p-5 d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status"></div>
    </div>
  );
}

const Protected = withAuthenticationRequired(
  ({ children }) => {
    const accessToken = getAccessToken({});

    if (!accessToken.value) return <Loader />;

    return children;
  },
  { onRedirecting: () => <Loader /> }
);

const Component = ({ children }) => {
  const auth0 = useAuth0();

  return auth0.error ? (
    <Suspense fallback={<Loader />}>
      <Auth0Error />
    </Suspense>
  ) : (
    <Protected children={children} />
  );
};

export default Component;
