import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import useUpload from 'hooks/useUpload'
import useMutation from 'hooks/useMutation'

function Table(props) {
  let { t } = useTranslation();
  let [config, setConfig] = useState({
    firstLineHeader: /nombre|apellido/gi.test(props.table.csv?.data?.at(0)?.join(' ')),
    identificationDuplicate: 'ignore'
  })
  let upload = useUpload()
  let [status, setStatus] = useState('ready')

  let [mutate, mutation] = useMutation(window._l(`/consumers/migrate`).substr(1), {
    onMutate: (data) => {

    },
    onSuccess: (data) => {

    },
    onError: (error) => {
      window.notify({
        message: error.message,
        type: "danger",
        title: "Error",
      });
    },
  });

  let remove = () => {
    props.remove(props.table.key)
  }

  let load = async () => {
    alert(t('consumers_csv_process_message', 'Se va a iniciar el proceso para importar pacientes. Esto puede demorar de unos pocos minutos hasta 24hs'))
    let { table } = props
    let file = await upload(table.file).then(links => links.at(0)).catch(() => null);
    if (!file) return
    let payload = {
      file,
      key: table.key,
      name: table.name,
      firstLineHeader: config.firstLineHeader,
      identificationDuplicate: config.identificationDuplicate,
    }
    props.ready(props.table.key)
    setStatus('ready')
    mutate(payload);
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div>
      <div className="d-flex gap-3 align-items-center px-1 mb-2">
        <div className="d-flex align-items-center gap-3">
          <div className="dropdown bg-white">
            <button
              className="btn btn-sm btn-dark"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
            >
              <div>{t('actions', 'Acciones')}</div>
            </button>
            <div className="dropdown-menu shadow-lg border-0 p-0" style={{ minWidth: 300 }}>
              <div className="p-3 d-flex align-items-center border-bottom">
                <input
                  className="form-check-input me-3"
                  type="checkbox"
                  checked={!!config.firstLineHeader}
                  id={`ignoreFirstLine${props.table.key}`}
                  onChange={() => setConfig({ ...config, firstLineHeader: !config.firstLineHeader })}
                />
                <label className="form-check-label" htmlFor={`ignoreFirstLine${props.table.key}`}>
                  {t('ignore_first_line', 'Ignorar la primera linea')}
                </label>
              </div>
              <div className="border-bottom p-3">
                <small className="text-secondary">Si ya exsite el DNI</small>
              </div>
              <div className="p-3 d-flex align-items-center border-bottom">
                <input
                  className="form-check-input me-3"
                  type="radio"
                  checked={config.identificationDuplicate === 'ignore'}
                  id={`identificationDuplicateIgnore${props.table.key}`}
                  onChange={() => setConfig({ ...config, identificationDuplicate: 'ignore' })}
                />
                <label className="form-check-label" htmlFor={`identificationDuplicateIgnore${props.table.key}`}>
                  {t('consumers_csv_identification_ignore', 'Ignorar')}
                </label>
              </div>
              <div className="p-3 d-flex align-items-center border-bottom">
                <input
                  className="form-check-input me-3"
                  type="radio"
                  checked={config.identificationDuplicate === 'create'}
                  id={`identificationDuplicateCreate${props.table.key}`}
                  onChange={() => setConfig({ ...config, identificationDuplicate: 'create' })}
                />
                <label className="form-check-label" htmlFor={`identificationDuplicateCreate${props.table.key}`}>
                  {t('consumers_csv_identification_create', 'Duplicar')}
                </label>
              </div>
              <div className="p-3 d-flex align-items-center border-bottom">
                <input
                  className="form-check-input me-3"
                  type="radio"
                  checked={config.identificationDuplicate === 'replace'}
                  id={`identificationDuplicateReplace${props.table.key}`}
                  onChange={() => setConfig({ ...config, identificationDuplicate: 'replace' })}
                />
                <label className="form-check-label" htmlFor={`identificationDuplicateReplace${props.table.key}`}>
                  {t('consumers_csv_identification_replace', 'Reemplazar')}
                </label>
              </div>
              <div className="d-flex align-items-center">
                {props.remove && (
                  <div className="border-end flex-fill">
                    <button className="btn py-3 border-0 w-100" onClick={remove}>
                      {t('consumers_csv_remove', 'Descartar')}
                    </button>
                  </div>
                )}
                <div className="flex-fill">
                  <button className="btn py-3 border-0  w-100" onClick={load}>
                    {mutation.isLoading && (
                      <div className="spinner-border spinner-border-sm text-primary" role="status"></div>
                    )} {t('consumers_csv_import', 'Procesar')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <pre className="m-0 text-secondary">{props.table.name} {Array.isArray(props.table.csv?.data) && (
            <sup className="text-secondary">({props.table.csv.data.length - !!config.firstLineHeader} {t('consumers_csv_records_length', 'registros')})</sup>
          )}</pre>
        </div>
      </div>
      {Array.isArray(props.table.csv?.data) && (
        <div className="shadow-sm rounded">
          <div className="table-responsive">
            <table className="table bg-white table-bordered mb-0">
              <thead>
                <tr>
                  <th className="text-nowrap text-first-up">{t('first_name', 'nombre')}</th>
                  <th className="text-nowrap text-first-up">{t('last_name', 'apellido')}</th>
                  <th className="text-nowrap text-first-up">{t('identification', 'DNI', { context: 'consumer_migrate' })}</th>
                  <th className="text-nowrap text-first-up">{t('dob', 'fecha nacimiento', { context: 'consumer_migrate' })}</th>
                  <th className="text-nowrap text-first-up">{t('gender', 'genero', { context: 'consumer_migrate' })}</th>
                  <th className="text-nowrap text-first-up">{t('email', 'email')}</th>
                  <th className="text-nowrap text-first-up">{t('phone', 'telefono')}</th>
                  <th className="text-nowrap text-first-up">{t('financier_name', 'cobertura', { context: 'consumer_migrate' })}</th>
                  <th className="text-nowrap text-first-up">{t('financier_plan', 'plan', { context: 'consumer_migrate' })}</th>
                  <th className="text-nowrap text-first-up">{t('financier_identification', 'nro. afiliado', { context: 'consumer_migrate' })}</th>
                  <th className="text-nowrap text-first-up">{t('occupation', 'Ocupación', { context: "consumers" })}</th>
                  <th className="text-nowrap text-first-up">{t('notes')}</th>
                  <th className="text-nowrap text-first-up">{t('country', 'pais')}</th>
                  <th className="text-nowrap text-first-up">{t('address', 'direccion')}</th>
                  <th className="text-nowrap text-first-up">{t('reference')}</th>
                </tr>
              </thead>
              <tbody>
                {props.table.csv.data.map((row, index1) => config.firstLineHeader && index1 === 0 ? (
                  null
                ) :(
                  <tr key={index1}>
                    {row.map((cell, index2) => (
                      <td className="text-nowrap" key={index2}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      )}
    </div>
  );
}

export default Table;
