import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typeahead } from "react-bootstrap-typeahead";

import Form from '../../../Consumers/Form/Form'
import getConsumer from '../../../Consumers/onChangeConsumer'
import useFilters from "hooks/useFilters";
import getConsumerIdentification from '../getConsumerIdentification'
import validateConsumer from 'App/Consumers/validateConsumer';

let clone = window.structuredClone || JSON.clone

let Consumer = props => {
  let { t } = useTranslation()
  let [consumer, setConsumer] = useState(clone(props.consumer))
  let [financier, setFinancier] = useState(clone(props.financier))
  let [financiers, setFinanciers] = useState()
  let [warnings, setWarnings] = useState({})

  let onChangeFinancier = ({ target: { value } }) => {
    let financier = clone(
      consumer.financiers.find(f => f.id === value)
    )
    setFinancier(financier)
    if (props.onChangeFinancier) props.onChangeFinancier(financier)
  }

  useEffect(() => {
    if (!props.consumer?.id) {
      setConsumer()
    } else {
      setConsumer(clone(props.consumer))
    }
  }, [props.consumer])

  useEffect(() => {
    if (consumer?.id) try {
      let payload = { financiers: {} }

      let financiers = clone(consumer.financiers) || []
      for (let financier of financiers) {
        let id = financier.id
        if (!id) continue
        payload.financiers[id] ||= {
          id,
          label: financier.label,
          identification: financier.identification,
          plan: financier.plan,
        }
      }

      setFinanciers(Object.values(payload.financiers))
    } catch {} else {
      setFinanciers()
    }
  }, [consumer])

  useEffect(() => {
    if (Array.isArray(financiers) && !financier?.id) {
      let financier = financiers.find(f => f.identification)
      financier ||= financiers.find(f => /private/i.test(f.id))
      financier ||= financiers[0]
      financier = clone(financier)
      setFinancier(financier)
      if (props.onChangeFinancier) props.onChangeFinancier(financier)
    }
  }, [financiers, financier])

  useEffect(() => {
    if (Array.isArray(financiers)){
      let financier = financiers.find(f => !warnings.financiers[f.id].flag && f.identification)
      financier ||= financiers.find(f => /private/i.test(f.id))
      financier ||= financiers[0];
      setFinancier(financier);
      if(props.onChangeFinancier) props.onChangeFinancier(financier);
    }
  }, [warnings])

  useEffect(() => {
    if (consumer && consumer.id) try {
      const fetchWarnings = async (consumer) => {
        const warnings = validateConsumer(consumer);
        setWarnings(warnings);
        if(props.onWarnings) props.onWarnings(warnings);
      }
      fetchWarnings(consumer);
    } catch (e) {
      console.error(e)
    }
  }, [consumer])

  if (!financiers) return null

  if (!consumer?.id) return null

  let identification = getConsumerIdentification(consumer.identification) || t('ehr:prescription_form_consumer_no_identification', 'Sin documento')

  return (
    <div className="d-flex justify-content-between align-items-center bg-light rounded border" style={{ padding: '7px 10px' }}>
      <div>
        <h6 className="m-0">{consumer.label}</h6>
        <div className="lh-1 d-flex align-items-center gap-2">
          <div>
            <small>{t('ehr:prescription_form_consumer_identification', 'DNI')}: <span className="text-secondary">{identification}</span>
            </small>
          </div>
          <div>
            <small>{t('ehr:prescription_financier', 'Cobertura')}: </small>
            {financiers.length <= 0 ? (
              <small className="text-secondary">{t('ehr:prescription_form_default_to_private_financier', 'Particular')}</small>
            ) : financiers.length === 1 && financier?.id ? (
              <small className="text-secondary">{t(financier.id, financier.label)}{financier.identification ? ` #${financier.identification}` : ''}</small>
            ) : (
              <select name="financier" value={financier?.id || ''} onChange={onChangeFinancier} className="p-0 text-xs border-0 bg-transparent text-primary">
                {financiers.map(financier => {
                  return (
                    <option
                      key={financier.id}
                      value={financier.id}>{t(financier.id, financier.label)}{financier.identification ? ` #${financier.identification}` : ''}</option>
                  )
                })}
              </select>
            )}
          </div>
        </div>
      </div>
      <div>
        <button
          className="btn btn-sm btn-link"
          onClick={props.onClickEdit}>{t('edit', 'Editar')}</button>
      </div>
    </div>
  )
}

let Selector = props => {
  let { t } = useTranslation()

  const consumers = useFilters(
    { key: "consumers", sort: { key: "label" } }, "skipLocation"
  );

  let onChangeConsumer = items => {
    let consumer = getConsumer(items, false)
    props.onChange(clone(consumer))
  }

  return (
    <Typeahead
      selected={[]}
      isLoading={!!consumers.isLoading}
      shouldSelect
      filterBy={() => true}
      inputProps={{ id: "consumer" }}
      id="consumer"
      allowNew={() => !consumers.isLoading}
      onChange={onChangeConsumer}
      onInputChange={(value) => consumers.onChange("q", value)}
      options={consumers.data.flatMap(data => data)}
      placeholder={t(`consumer_placeholder_prescriptions`, 'Buscar paciente por apellido o DNI')}
      newSelectionPrefix={`${t("new_consumer", {
        context: "prescriptions",
      })}: `}
      emptyLabel={
        consumers.isLoading ? t(`searching`) : t(`no_matches`)
      }
      paginationText={t(`display_more_results`)}
      promptText={t(`type_to_search`)}
      searchText={t(`searching`)}
      renderMenuItemChildren={(option, props, index) => {
        const items = [
          option.identification,
          ...option.emails.map((e) => e.value),
        ].filter(Boolean);
        return (
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div className="flex-fill w-100">{option.label}</div>
              <div className="overflow-hidden text-truncate">
                {option.financiers.length > 0 && (
                  <div className="text-secondary ms-2 me-n3">
                    {option.financiers.map((item) => (
                      <small
                        className="me-2"
                        key={`${option.id}${item.id}${item.identification}`}
                      >
                        {[t(item.id, item.label), item.identification].filter(Boolean).join(" #")}
                      </small>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {items.length > 0 && (
              <div className="text-secondary mt-n1 text-truncate overflow-hidden">
                {items.map((item) => (
                  <small className="me-2" key={`${option.id}${item}`}>
                    {item}
                  </small>
                ))}
              </div>
            )}
          </div>
        );
      }}
    />
  )
}

Consumer.Form = Form
Consumer.Selector = Selector
export default Consumer
