const hepatogramaModel = () => {
  return {
    tgo: {
      value: "",
      unit: "",
    },
    tgp: {
      value: "",
      unit: "",
    },
    fal: {
      value: "",
      unit: "",
    },
    bt: {
      value: "",
      unit: "",
    },
    bd: {
      value: "",
      unit: "",
    },
    bi: {
      value: "",
      unit: "",
    },
    ggt: {
      value: "",
      unit: "",
    },
    n5n: {
      value: "",
      unit: "",
    },
    alb: {
      value: "",
      unit: "",
    },
    prottot: {
      value: "",
      unit: "",
    },
  };
};

const um = () => {
  return {
    bt: ["mg/dL", "mcmol/L"],
    bd: ["mg/dL", "mcmol/L"],
    bi: ["mg/dL", "mcmol/L"],
    fal: ["U/dL", "mckat/L"],
    tgo: ["U/L", "pkat/L"],
    tgp: ["U/L", "pkat/L"],
    ggt: ["U/L"],
    n5n: ["U/L"],
    alb: ["g/dL", "g/L"],
    prottot: ["mg/dL", "g/L"],
  };
};

export { hepatogramaModel, um };
