let getConsumerIdentification = _value => {
  let value = _value?.toString()?.replace(/\s\s+/g, ' ').trim()
  try {
    if (!value) return ''
    if (/[a-z.]/i.test(value)) return value
    value = value.replace(/\D/g, '')
    return parseInt(value).toLocaleString('es-AR') || value
  } catch {}

  return value
}

module.exports = getConsumerIdentification
