import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import { v4 as uuid } from "uuid";

import "./styles.scss";

const LANGS = {
  pt: "pt_BR",
  es: "es",
  en: "en",
};

const App = (props) => {
  const [ready, setReady] = useState();
  const { t, i18n } = useTranslation();

  const onEditorChange = (content, editor) => {
    const div = document.createElement("div");
    div.innerHTML = content;
    const text = div.textContent.trim();
    if (!text) return props.onEditorChange("", "");
    props.onEditorChange(content, text);
  };

  if (props.disabled) return (
    <div className="mce-content-body readonly" dangerouslySetInnerHTML={{
      __html: props.value
    }} />
  )

  return (
    <>
      {!ready && (
        <div className="spinner-border spinner-border-sm text-primary ms-2"></div>
      )}
      <Editor
        id={props.id || uuid()}
        value={props.value || ""}
        tinymceScriptSrc={[process.env.PUBLIC_URL,'/tinymce/tinymce.min.js'].filter(Boolean).join('')}
        disabled={props.disabled}
        init={{
          setup: (editor) => {
            editor.on("init", () => {
              setReady(true);
            });
          },
          // height: 50,
          body_class: props.className,
          autoresize_bottom_margin: 104,
          plugins: ["quickbars", "autoresize"],
          toolbar: false,
          menubar: false,
          quickbars_insert_toolbar: false,
          quickbars_image_toolbar: false,
          quickbars_selection_toolbar: "bold backcolor forecolor italic | quicklink",
          inline: true,
          language: LANGS[i18n.language.substr(0, 2)] || LANGS.es,
          statusbar: false,
          placeholder: props.placeholder || t('write_here', 'Escriba aqui...'),
        }}
        onEditorChange={onEditorChange}
      />
    </>
  );
};

export default App;
