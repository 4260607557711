import create from "zustand";
import { persist } from "zustand/middleware"

let useStore = create(
  persist(
    (set, get) => ({
      copies: 1,
      size: 'A4',
      email: {},
      setEmail: email => set(() => ({ email: { ...get().email, ...email } })),
      setSize: size => set(() => ({ size })),
      setCopies: copies => set(() => ({ copies }))
    }), 
    {
      name: `ehr-prescriptions-storage`,
      partialize: state => ({
        copies: state.copies || 1,
        size: state.size || 'A4',
        email: { cc1: state.email.cc1, cc2: state.email.cc2 }
      })
    }
  )
)

export default useStore;
