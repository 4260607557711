import { useEffect, useState } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { ReactComponent as IconChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg'

import { formatDate } from "config/i18n";
import WaMe from "Components/WaMe/WaMe";
import Form from "App/Consumers/Form/Form";
import useStore from "./useStore";
import getAge from "lib/getAge";
import useQuery from "hooks/useQuery";
import styles from "./styles.module.scss";

const _getAge = (dob, t) => {
  try {
    const { years, months, days } = getAge(dob);

    let age = [];

    if (years) {
      age.push(years, t("time_distance_years"));
    }

    if (months) {
      age.push(months, t("time_distance_months"));
    }

    if (days) {
      age.push(days, t("time_distance_days"));
    }

    return age.join(" ");
  } catch {
    return "";
  }
};

const App = (props) => {
  const { t } = useTranslation();
  const store = useStore();
  const [edit, setEdit] = useState()

  const consumer = useQuery(
    window._l(`/consumers/${props.consumerID}`).substr(1),
    { enabled: !!props.consumerID && !props.consumer?.id, initialData: props.consumer || {} }
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    if (consumer.data.id) {
      store.update({ consumer: JSON.clone(consumer.data) });
    }
  }, [consumer.data]);

  const { data } = consumer;
  if (!data?.id) return null;
  const age = _getAge(data.dob, t);
  const dob = formatDate(data.dob, "dd-MMM-yyyy");
  const isBirth =
    data.dob?.slice(5) === new Date().toLocaleDateString("fr-ca").slice(5);

  return (
    <div
      className={`${styles.container}`}
    >
      <div>
        <Link
          className="fs-5 lh-1"
          to={window._l(`/${data.id}`)}
          onClick={e => {
            e.preventDefault()
            setEdit(!edit)
          }}
        >
          {data.label ||
            [data.lastName, data.firstName].filter(Boolean).join(", ")}
        </Link>
        {age ? (
          <div
            className="small lh-1 text-secondary d-print-none"
            data-tip={(dob || data.dob) && (dob || data.dob)}
          >{age}</div>
        ) : (
          <div className="small lh-1 text-warning d-print-none">{t('fill_dob', 'Cargar fecha de nacimiento')}</div>
        )}
        {isBirth && (
          <div className="d-print-none small lh-1 text-secondary text-truncate">
            {t("today_birth_message", "Hoy cumple 🎉")}
          </div>
        )}
        {props.consumerID && edit ? (
          <Form
            className="mt-3"
            callback={() => setEdit(false)}
            ehr={false}
            remove={false}
            consumerID={props.consumerID}
            initialState={consumer.data}
          />
        ) : (
          <div className="mb-1 small">
            {!!Number(data.identification) && (
              <div className="d-flex align-items-center justify-content-between border-bottom py-1">
                <div className="text-truncate fw-bold">{t("identification", { context: "consumers" })}</div>
                <div className="user-select-all text-truncate">
                  {Number(data.identification)
                    ? Number(data.identification).toLocaleString("es-AR")
                    : data.identification}
                </div>
              </div>
            )}

            {!!data.financiers?.length && data.financiers?.map((financier, index) => (
              <div
                key={`${data.id}${financier.id}`}
                className={"d-flex justify-content-between border-bottom py-1"}
              >
                <div className="fw-bold text-truncate">{t(financier.id, financier.label)}</div>
                {financier.identification && (
                  <div className="text-secondary text-truncate">
                    {" "}#
                    <span className="user-select-all">
                      {financier.identification}
                    </span>
                  </div>
                )}
              </div>
            ))}

            {data.phones?.length > 0 && (
              <div className="d-md-flex align-items-md-center text-nowrap border-bottom py-1 gap-2">
                {data.phones.map((phone, index) => (
                  <WaMe
                    className={"text-truncate"}
                    key={`${consumer.id}${phone.label}`}
                    phone={phone}
                  />
                ))}
              </div>
            )}

            {data.emails?.length > 0 && (
              <div className="d-md-flex align-items-md-center text-nowrap gap-2 border-bottom py-1">
                {data.emails.map((email, index) => (
                  <a
                    href={`mailto:${email.value}`}
                    target="_blank"
                    key={`${data.id}${email.value}`}
                    className="text-truncate"
                  >
                    {email.value}
                  </a>
                ))}
              </div>
            )}

            {data.notes && (
              <div className="py-1 mb-n3">{data.notes}</div>
            )}
          </div>
        )}


      </div>
    </div>
  );
};

export default App;
