import { useTranslation } from "react-i18next";
import cx from "classnames";

const Simple = ({ event }) => {
  const { t } = useTranslation();

  let identification;

  if (!isNaN(parseInt(event.consumer?.identification)))
    try {
      identification = parseInt(
        event.consumer.identification.toString().replace(/\D/g, "")
      )?.toLocaleString("es-AR");
    } catch {}

  let phones = event.consumer?.phones?.split("/") || [];
  phones = phones.map(p => p.label)?.filter(p => p?.trim()).join(', ')
  const message = [event.comments, event.instructions].filter(Boolean).join(' ')
  const [specialty, practice] = t(event.service.key, event.service.label)?.split('/')?.filter(p => p?.trim()) || []
  let due = event.pricing?.total

  if (event.mercadopago?.payment?.status === 'approved') {
    due = event.pricing?.total - (event.mercadopago?.payment?.transaction_amount || 0)
  }

  return (
    <>
      <tr>
        <td className="text-center">
          <div
            className={cx({
              "text-warning": event.status === "arrived",
              "text-primary": event.status === "active",
              "text-success": event.status === "fulfilled",
              "text-danger": ["cancelled", "noshow"].includes(event.status),
              "text-dark": event.status === "booked",
            })}
          >
            {t(`status_${event.status}`, { context: "events" })}
          </div>
          <div className="fw-bold small">
            {event.time || event.range?.split('-')?.join(' ') || t('no_time_setup', 'Turno sin horario')}
          </div>
          {event.overbooking && <div className="text-danger small opacity-75">{t('overbooking', 'Sobreturno')}</div>}
        </td>
        <td>
          {event.consumer.label}
          {identification && <div className="text-secondary small">{identification}</div>}
        </td>
        <td>
          {!!phones && (
            <>
              <strong className="small">{t('phone_abbr', 'TEL')}:</strong>{' '}{phones}
            </>
          )}
        </td>
        <td>
          <div>{t(event.financier.key, event.financier.label, { excludeSuffix: true })}</div>
          {event.financier.identification && (
            <div className="text-secondary small">{event.financier.identification}</div>
          )}
          {event.pricing && (
            !due ? (
              <div className="small text-success">
                ${event.pricing?.total} - <span className="text-capitalize">{t('paid', 'Pagado')}</span>
              </div>
            ) : (
              <div className="small text-danger">
                <span className="text-capitalize">{t('collect_pending_payment', 'Cobrar')}</span> ${due}
              </div>
            )
          )}
        </td>
        <td>
          <div>{practice}</div>
          <div className="text-secondary small">{specialty}</div>
        </td>
        <td>
          <div>{event.resource.label}</div>
          {event.remote ? (
            <div className="text-secondary small">{t('telemedicine', 'Telemedicina', { context: 'agenda' })}</div>
          ) : (
            <div className="text-secondary small">{event.location.label}</div>
          )}
        </td>
      </tr>
      <tr>
        <td colSpan="7">
          <div className={message ? 'border-start ps-2 border-3 border-primary': ''}>{message}&nbsp;</div>
        </td>
      </tr>
    </>
  )
}

export default Simple
