import { useTranslation } from "react-i18next";
import Select from "react-select";

import countries from "./countries";

const Country = (props) => {
  const { t } = useTranslation();
  const TOP = [
    "ec",
    "co",
    "ar",
    "br",
    "cl",
    "uy",
    "pe",
    "py",
    "bo",
    "ve",
  ].sort();

  const options = countries
    .sort((a, b) => {
      if (TOP.includes(a) && !TOP.includes(b)) return -1;
      if (!TOP.includes(a) && TOP.includes(b)) return 1;
      return 0;
    })
    .map((value) => ({ value, label: t(`country_${value}`) }));

  return (
    <Select
      isClearable
      value={options.find((o) => o.value === props.value)}
      onChange={(country) => props.onChange(country?.value)}
      options={options}
      placeholder={props.placeholder || t(`country_placeholder`)}
    />
  );
};

export default Country;
