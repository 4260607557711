const states = [
  { label: "CABA", abbr: "caba", iso: "ar-c" },
  { label: "Buenos Aires", abbr: "ba", iso: "ar-b" },
  { label: "Catamarca", abbr: "ca", iso: "ar-k" },
  { label: "Chaco", abbr: "ch", iso: "ar-h" },
  { label: "Chubut", abbr: "ct", iso: "ar-u" },
  { label: "Córdoba", abbr: "cb", iso: "ar-x" },
  { label: "Corrientes", abbr: "cr", iso: "ar-w" },
  { label: "Entre Ríos", abbr: "er", iso: "ar-e" },
  { label: "Formosa", abbr: "fo", iso: "ar-p" },
  { label: "Jujuy", abbr: "jy", iso: "ar-y" },
  { label: "La Pampa", abbr: "lp", iso: "ar-l" },
  { label: "La Rioja", abbr: "lr", iso: "ar-f" },
  { label: "Mendoza", abbr: "mz", iso: "ar-m" },
  { label: "Misiones", abbr: "mi", iso: "ar-n" },
  { label: "Neuquén", abbr: "nq", iso: "ar-q" },
  { label: "Río Negro", abbr: "rn", iso: "ar-r" },
  { label: "Salta", abbr: "sa", iso: "ar-a" },
  { label: "San Juan", abbr: "sj", iso: "ar-j" },
  { label: "San Luis", abbr: "sl", iso: "ar-d" },
  { label: "Santa Cruz", abbr: "sc", iso: "ar-z" },
  { label: "Santa Fe", abbr: "sf", iso: "ar-s" },
  { label: "Santiago del Estero", abbr: "se", iso: "ar-g" },
  {
    label: "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
    abbr: "tf",
    iso: "ar-v",
  },
  { label: "Tucumán", abbr: "tu", iso: "ar-t" },
];

export default states;
