import { useTranslation } from "react-i18next";

const Obsolete = () => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex flex-column justify-content-center p-xl-5 p-lg-4 p-md-3 p-sm-2 p-1 mx-auto vh-100"
      style={{ maxWidth: "640px" }}
    >
      <h2>{t("old_browser_title", 'Actualización')}</h2>
      <div className="mb-1">{t("old_browser_message", "Para usar DrApp es necesario tener un navegador actualizado")}</div>
      <a href="https://www.google.com/chrome/" target="_chrome" rel="nofollow noreferrer noopener">Descargar Google Chrome</a>
    </div>
  );
};

export default Obsolete;
