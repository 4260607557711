import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { useQuery, useQueryClient } from "react-query";

import useUser from "../../../../hooks/useUser";
import useQueryLocal from "../../../../hooks/useQuery";
import Lab from "./Lab";
import BibliographyCard from "../Bibliography/BibliographyCard";

import { hemogramaModel } from "./Values/Hemograma";
import { ionogramaModel } from "./Values/Ionograma";
import { eabrenalModel } from "./Values/Eabrenal";
import { hepatogramaModel } from "./Values/Hepatograma";
import { perfiltiroideoModel } from "./Values/PerfilTiroideo";
import { perfillipidicoModel } from "./Values/PerfilLipidico";
import { perfilferricoModel } from "./Values/PerfilFerrico";
import { diabetesModel } from "./Values/Diabetes";

const sort = (a, b) => {
  if (!a.id && b.id) return -1;
  if (a.id && !b.id) return 1;
  return b.createdAt - a.createdAt;
};

function Labs({ consumer, uid, className, resource }) {
  const { t } = useTranslation();
  const $container = useRef(null);
  const queryClient = useQueryClient();
  const user = useUser();
  const [records, setRecords] = useState([]);
  const [stats, setStats] = useState({});
  const [status, setStatus] = useState([]);

  const query = useQueryLocal(window._l(`/${consumer.id}/labs`).substr(1), {
    initialData: [],
  });

  let hemograma = hemogramaModel();
  let ionograma = ionogramaModel();
  let eabrenal = eabrenalModel();
  let hepatograma = hepatogramaModel();
  let perfiltiroideo = perfiltiroideoModel();
  let perfillipidico = perfillipidicoModel();
  let perfilferrico = perfilferricoModel();
  let diabetes = diabetesModel();

  let licenses
  try {
    licenses =
      resource &&
      resource.licenses &&
      resource.licenses
        .filter((element) => element.type === "license")
        .map((license) => {
          if (license.jurisdiction === "AR") {
            return `M.N. ${license.value}`;
          } else {
            return `M.P. ${license.value}`;
          }
        })
        .join("-");
  } catch {}


  const add = () => {
    const date = new Date();
    update({
      consumer: { id: consumer.id },
      createdBy: user.state.uid,
      createdByName: resource?.label,
      createdByLicense: licenses,
      createdAt: Date.now(),
      type: "labs",
      startsAt: new Date().getTime(),
      dob: consumer.dob,
      gender: consumer.gender,
      hemograma,
      ionograma,
      eabrenal,
      hepatograma,
      perfiltiroideo,
      perfillipidico,
      perfilferrico,
      diabetes,
    });
  };

  const update = (payload, clear) => {
    const id = payload.id || payload.createdAt;
    const keys = queryClient
      .getQueryCache()
      .findAll()
      .filter((query) => query.queryHash.includes("/labs"))
      .map((query) => query.queryKey);

    for (const key of keys) {
      queryClient.setQueryData(key, (records) => {
        const array = JSON.clone(records);
        const index = array.findIndex(
          (record) =>
            (record.id || record.createdAt) === id &&
            record.consumer?.id === payload.consumer?.id
        );
        if (index < 0)
          return [...array, payload].filter((record) => !clear || record.id);
        array[index] = payload;
        return array;
      });
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    const array = [];
    const stats = {};
    const now = Date.now();

    for (const record of query.data) {
      if (uid && record.createdBy !== uid) {
        continue;
      }

      if (record.deleted) {
        stats.deleted = stats.deleted || 0;
        stats.deleted += 1;
      }

      if (record.deleted && !status.includes("deleted")) {
        continue;
      }

      if (record.status && !record.deleted) {
        stats[record.status] = stats[record.status] || 0;
        stats[record.status] += 1;
      }

      array.push(record);
    }

    if (query.data.length === 0) {
      add();
    }

    setRecords(array);
    setStats(stats);
  }, [query.data, status, uid]);

  const toggle = (value) => {
    if (status.includes(value)) {
      setStatus(status.filter((s) => s !== value));
    } else {
      setStatus([...status, value]);
    }
  };

  if (!query.isFetched) {
    return (
      <div className={className || ""}>
        <div className="text-center">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (query.isLoadingError || query.isError) {
    return (
      <div className={className || ""}>
        <div className="text-danger">{t("error")}</div>
      </div>
    );
  }

  const filters = Object.keys(stats || {}).sort((a, b) => {
    if (a === "deleted" && b !== "deleted") return 1;
    if (a !== "deleted" && b === "deleted") return -1;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  return (
    <div>
      <div className={className || ""}>
        <div className="d-flex justify-content-start">
          <button className="btn btn-sm btn link-success ms-n2" onClick={add}>
            {t("new", { label: t("lab") })}
          </button>

          {filters.length > 0 && (
            <>
              <div className="flex-fill"></div>
              {filters.map((key) => (
                <button
                  key={`status-${key}`}
                  onClick={() => toggle(key)}
                  className={cx("btn btn-sm btn-link", {
                    "link-primary": status.includes(key),
                    "link-secondary": !status.includes(key),
                  })}
                  data-tip={t(`${key}_tooltip`)}
                  data-event="mouseenter"
                  data-event-off="mouseleave"
                  data-effect="solid"
                  data-place="top"
                >
                  {t(`ehr:${key}`, { count: stats[key] })}
                  <sup>{stats[key]}</sup>
                </button>
              ))}
            </>
          )}
        </div>
        <div ref={$container}>
          <div
            className="accordion accordion-flush"
            id="accordionFlushExample"
          ></div>
          {records.sort(sort).map((record) => (
            <Lab
              key={record.id || record.createdAt}
              record={record}
              update={update}
              consumer={consumer}
            />
          ))}
        </div>
      </div>
      <div className="container-xxl my-3 p-0 d-flex">
        <BibliographyCard text="labs_bibliography"></BibliographyCard>
      </div>
    </div>
  );
}

export default Labs;
