import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

let clone = window.structuredClone || JSON.clone

let Resource = props => {
  let { t } = useTranslation()

  let [resource, setResource] = useState(clone(props.resource))

  let [license, setLicense] = useState(clone(props.license))
  let [licenses, setLicenses] = useState()

  let [locations, setLocations] = useState(clone(props.location))
  let [location, setLocation] = useState()

  let [specialties, setSpecialties] = useState()
  let [specialty, setSpecialty] = useState()

  let [warnings, setWarnings] = useState({})

  let onChangeLicense = ({ target: { name, value } }) => {
    let license = clone(
      licenses.find(l => l.value === value && !l.deleted)
    )
    setLicense(license)
    if (props.onChangeLicense) props.onChangeLicense(license)
  }

  let onChangeLocation  = ({ target: { name, value } }) => {
    let location = locations.find(l => l.id === value)
    location ||= locations.find(l => l.place_id === value)
    location ||= locations.find(l => l.placeId === value)
    location ||= locations.find(l => l.placeID === value)
    location = clone(location)
    setLocation(location)
    if (props.onChangeLocation) props.onChangeLocation(location)
  }

  let onChangeSpecialty  = ({ target: { name, value } }) => {
    let specialty = clone(specialties.find(l => l.id === value))
    setSpecialty(specialty)
    if (props.onChangeSpecialty) props.onChangeSpecialty(specialty)
  }

  useEffect(() => {
    if (!props.resource?.id) {
      setResource()
    } else {
      setResource(clone(props.resource))
    }
  }, [props.resource])

  useEffect(() => {
    if (resource?.id) try {
      let payload = { locations: {}, licenses: {} }

      let specialties = clone(resource.specialties) || []

      let locations = clone(resource.locations) || []
      for (let location of locations) {
        let id = location.id || location.place_id || location.placeId || location.placeID
        if (!id) continue
        payload.locations[id] ||= {
          id,
          name: location.name,
          address: location.address || location.label,
          emails: location.emails || [],
          phones: location.phone || location.phones || [],
          coords: location.coords || {}
        }
      }

      let licenses = clone(resource.licenses) || []
      for (let { value, deleted, jurisdiction, specialty, specialties, kind } of licenses) {
        if (deleted || !value) continue
        kind ||= (/caba/i.test(jurisdiction) ? 'M.N.' : 'M.P.')
        let label = [kind?.replaceAll('.', ''), value].filter(Boolean).join(' ')
        payload.licenses[value] ||= {
          label,
          value,
          jurisdiction: jurisdiction?.replace('-', '/')?.split('/')?.filter(Boolean)?.reverse()?.at(0),
          state: jurisdiction,
          specialty,
          specialties,
          kind
        }
      }
      setLocations(Object.values(payload.locations))
      setLicenses(Object.values(payload.licenses))
      setSpecialties(specialties)
    } catch {} else {
      setLocations()
    }
  }, [resource])

  useEffect(() => {
    if (Array.isArray(locations) && !location?.id) {
      let payload = clone(locations[0])
      setLocation(payload)
      if (props.onChangeLocation) props.onChangeLocation(payload)
      if (!payload?.id) {
        setWarnings(warnings => ({ ...warnings, flag: true, location: true }))
      }
    }
  }, [locations, location])

  useEffect(() => {
    if (Array.isArray(licenses) && !license?.value) {
      let payload = clone(licenses[0])
      setLicense(payload)
      if (props.onChangeLicense) props.onChangeLicense(payload)
      if (!payload?.value) {
        setWarnings(warnings => ({ ...warnings, flag: true, license: true }))
      }
    }
  }, [licenses, license])

  useEffect(() => {
    if (Array.isArray(specialties) && !specialty?.id) {
      let payload = clone(specialties[0])
      setSpecialty(payload)
      if (props.onChangeSpecialty) props.onChangeSpecialty(payload)
      if (!payload?.id) {
        setWarnings(warnings => ({ ...warnings, flag: true, specialty: true }))
      }
    }
  }, [specialties, specialty])

  useEffect(() => {
    if (props.onWarnings) props.onWarnings(warnings)
  }, [warnings, props.onWarnings])


  if (!resource?.id) return null

  if (!locations) return null
  if (!licenses) return null
  if (!specialties) return null

  if (!warnings.flag && !location?.id) return null
  if (!warnings.flag && !license?.value) return null
  if (!warnings.flag && !specialty?.id) return null

  return (
    <div className="bg-light rounded border" style={{ padding: '7px 10px' }}>
      <h6 className="m-0">{resource.label}</h6>
      <div className="lh-1 d-flex align-items-center justify-content-between gap-2">
        <div className="d-flex flex-column">
          <small>{t('ehr:prescription_form_specialty', 'Especialidad')}:</small>
          {specialties.length <= 0 ? (
            <small className="text-secondary">{t('ehr:prescription_form_no_specialty', 'Sin especialidad')}</small>
          ) : specialties.length === 1 && specialty?.id ? (
            <small className="text-secondary">{t(specialty.id, specialty.label)}</small>
          ) : (
            <select value={specialty?.id || ''} onChange={onChangeSpecialty} className="p-0 text-xs border-0 bg-transparent text-primary">
              {specialties.map(specialty => (
                <option
                  key={specialty.id}
                  value={specialty.id}
                >{t(specialty.id, specialty.label)}</option>
              ))}
            </select>
          )}
        </div>

        <div className="d-flex flex-column">
          <small>{t('ehr:prescription_form_license', 'Licencia')}:</small>
          {licenses.length <= 0 ? (
            <small className="text-secondary">{t('ehr:prescription_form_no_license', 'Sin licencia para recetar')}</small>
          ) : licenses.length === 1 ? (
            <small className="text-secondary">{[parseInt(license?.value || '')?.toLocaleString('es-AR'), license.kind, license?.jurisdiction].join(' ') || ''}</small>
          ) : (
            <select value={license?.value || ''} onChange={onChangeLicense} className="p-0 text-xs border-0 bg-transparent text-primary">
              {licenses.map(license => (
                <option
                  key={license.value}
                  value={license.value}
                >{[license.label || license.value, license.jurisdiction].filter(Boolean).join(' - ')}</option>
              ))}
            </select>
          )}
        </div>

        <div className="d-flex flex-column">
          <small>{t('ehr:prescription_form_address', 'Direccion')}:</small>
          {locations.length <= 0 ? (
            <small className="text-secondary">{t('ehr:prescription_form_no_address', 'Sin licencia para recetar')}</small>
          ) : locations.length === 1 && location ? (
            <small className="text-secondary">{location.address}</small>
          ) : (
            <select value={location?.id || ''} onChange={onChangeLocation} className="p-0 text-xs border-0 bg-transparent text-primary">
              {locations.map(location => (
                <option key={location.id} value={location.id}>{location.name || location.address}</option>
              ))}
            </select>
          )}
        </div>
      </div>
    </div>
  )
}

export default Resource
