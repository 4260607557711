function LabRow(props) {
  let missingDivs = [];

  if (props.array.length < 4) {
    let missing = 4 - props.array.length;

    for (let i = 0; i < missing; i++) {
      missingDivs.push(<div className="col-sm px-0"></div>);
    }
  }

  return (
    <div className="container">
      <div className="row">
        {props.array.map((element, index) => (
          <div key={index} className="col-3 px-0 py-2">{element}</div>
        ))}
      </div>
    </div>
  );
}

export default LabRow;
