import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg'
import { ReactComponent as IconPlusSquareFill } from 'bootstrap-icons/icons/plus-square-fill.svg'
import { ReactComponent as IconSearch } from 'bootstrap-icons/icons/search.svg'
import { v4 as uuid } from "uuid";
import { Collapse } from 'bootstrap'

import useUser from "hooks/useUser";
import useStore from '../useStore'
import styles from './styles.module.scss'
import Select from './Select'
import Treatment from './Treatment'

const Treatments = ({ className }) => {
  const { t } = useTranslation();

  const user = useUser();
  const [state, setState] = useState({ all: [], current: [] })

  const $collapse = useRef(null)

  const store = useStore(state => ({
    consumer: state.consumer,
    resource: state.resource,
    treatments: state.treatments,
    update: state.update,
  }))

  const licenses = store.resource?.licenses?.filter(e => e.type === "license")?.map(l =>
    l.jurisdiction === "AR" ? `M.N. ${l.value}` : `M.P. ${l.value}`
  ).join("-");

  const add = treatment => {
    const payload = {
      type: "records",
      consumer: { id: store.consumer?.id, label: store.consumer?.label },
      createdAt: Date.now(),
      createdBy: user.state.uid,
      createdByName: store.resource?.label,
      createdByResourceID: store.resource?.id,
      _id: uuid(),

      label: [treatment.drug, treatment.product, treatment.presentation].filter(Boolean).join(' - ') || treatment.label,
      company: treatment.company,
      drug: treatment.drug,
      drug_code: treatment.drug_code,
      drugs: treatment.drugs || [],
      presentation: treatment.presentation,
      product: treatment.product,
      product_code: treatment.product_code,
      status: "active",
      startsAt: new Date().toLocaleDateString('fr-ca'),

      type: "treatments",
      content: "",
      text: "",
      dose: "",
      frecuency: "",
      notes: "",
    }

    const current = store.treatments || []
    const treatments = [payload, ...current]

    store.update({ treatments })
  }

  const onChange = record => {
    const treatments = [...store.treatments]
    const index = treatments.findIndex(i => (
      record._id && record._id === i._id
    ) || (
      record.id && record.id === i.id
    ))
    if (index < 0) return
    treatments[index] = { ...record }
    store.update({ treatments })
  }

  useEffect(() => {
    try {
      const state = store.treatments.reduce((hash, record) => {
        if (record._id) {
          return { ...hash, current: [...hash.current, record] }
        } else {
          return { ...hash, all: [...hash.all, record] }
        }
      }, { current: [], all: [] })

      setState(state)
    } catch {}
  }, [JSON.stringify(store.treatments)])

  return (
    <div className="bg-white rounded">
      <div className="d-flex gap-2 align-items-center p-2" style={{ height: '56px' }}>
        <div className="flex-fill">
          <Select
            path={`vademecums/${user.state?.country}`}
            onChange={add}
            placeholder={<div><IconSearch width="15" height="15" className="text-secondary opacity-50 me-1 mt-n1" /> {t('search_treatments_placeholder', 'Agregue tratamientos')}</div>}
            getOptionValue={record => record.product_code}
            getOptionLabel={record => [record.drug, record.product, record.presentation].filter(Boolean).join(' - ') || record.label}
            enabled={!!user.state?.country}
          />
        </div>
        {state.all.length > 0 ? (
          <button
            data-bs-toggle="collapse"
            data-bs-target="#collapseTreatments"
            data-tip={t('show_all_treatments_tip', `Mostrar los ${state.all.length} tratamientos cargados anteriormente`, { count: state.all.length })}
            className="btn bg-white link-primary"
          >
            <IconChevronExpand width="16" height="16" /> {state.all.length}
          </button>
        ) : (
          <span className="text-secondary opacity-75 me-3">0</span>
        )}
      </div>

      {state.current.length > 0 && (
        <div className="d-flex flex-column">
          {state.current.map(record => (
            <Treatment state={record} key={record._id} onChange={onChange} />
          ))}
        </div>
      )}

      <div className="collapse" id="collapseTreatments" ref={$collapse}>
        <div className="px-3 text-center bg-light border-top">
          <small className="font-small-caps fw-bold">{t('prev_treatments', 'Tratamientos anteriores')}</small>
        </div>
        <div className="d-flex flex-column">
          {state.all.map(record => (
            <Treatment key={record.id} state={record} onChange={onChange} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Treatments;
