import { Suspense } from "react";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const Component = ({ children }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<span className="visually-hidden">loading</span>}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

export default Component;
