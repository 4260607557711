import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";

import Component from "./Component";
import useStore from "../useStore";

const Services = props => {
  const { t } = useTranslation();
  const [state, setState] = useState({ data: [] })
  const store = useStore((state) => ({
    setService: state.setService,
    facets: state?.facets?.services || {},
    location: state.location,
    resource: state.resource,
    service: state.service,
    financier: state.financier,
    clearAt: state.clearAt
  }));

  const onChange = value => {
    if (props.onChange) return props.onChange(value)
    setState(state => ({ ...state, active: value, dirty: true }))
    store.setService(value)
  }

  useEffect(() => {
    if (store.clearAt) setState(s => ({ data: [], force: s.force }))
  }, [store.clearAt])

  useEffect(() => {
    const entries = Object.entries(props.facets ?? store.facets)

    const disabled = []
    const enabled = []
    const data = []

    for (const [key, entry] of entries) {
      if (entry.active) {
        enabled.push({ key, ...entry, label: t(key, entry.label) })
      } else {
        disabled.push({ key, ...entry, label: t(key, entry.label) })
      }
    }

    if (enabled.length) {
      const options = enabled.sort((a, b) => {
        if (a.key < b.key) return -1
        if (a.key > b.key) return 1
        return 0
      })
      data.push({ options, label: `${t('filters_services_enable_plural', 'Habilitados')}` })
    }

    if (disabled.length) {
      const options = disabled.sort((a, b) => {
        if (a.key < b.key) return -1
        if (a.key > b.key) return 1
        return 0
      })
      data.push({ options, label: `${t('filters_services_disable_plural', 'Deshabilitados')}` })
    }

    const state = { data }

    if (enabled.length === 1) {
      state.active = { ...enabled[0] }
    }

    state.force = entries.length === 1 && !!state.active

    setState(s => ({ ...state, dirty: s.dirty }))
  }, [props.facets, store.facets])

  useEffect(() => {
    const clearAt = store.clearAt || 0
    const diff = Date.now() - clearAt
    if (props.store !== 'manual' && (diff > 1000 || state.force) && !state.dirty && state.active?.key && store.service?.key !== state.active.key) {
      store.setService({ ...state.active })
    }
  }, [store.clearAt, state.force, state.active, state.dirty, store.service, props.store])

  return (
    <Component
      show={props.show ?? true}
      isLoading={props.isLoading}
      onChange={onChange}
      highlight={props.highlight}
      data={state.data}
      valueKey="key"
      value={props.value ?? store.service}
      placeholder={t("service_plural", { context: "sidebar" })}
    />
  );
};

export default Services;
