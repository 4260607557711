import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../config/i18n";

function Treatments(props) {
  const { t } = useTranslation();

  return (
    <>
      {props.activeTreatments.filter((element) => element.id).length > 0 && (
        <div className="d-flex align-items-center bg-light px-4 py-2 mb-2">
          <h5 className="h4 fw-bold mb-0 py-2">{t("active_treatments")}</h5>
          {props.showBtns && (
            <button
              className="btn btn-link btn-sm text-danger"
              onClick={() => {
                props.setPrintStatus({
                  ...props.printStatus,
                  treatmentsActive: false,
                });
              }}
            >
              <small>{t("ehr:hide")}</small>
            </button>
          )}
        </div>
      )}
      {props.activeTreatments &&
        props.activeTreatments.map((element, index) => {
          if (element.id) {
            return (
              <div
                className={
                  index + 1 === props.activeTreatments.length
                    ? "py-3 px-4"
                    : "py-3 px-4 border-bottom"
                }
              >
                <p className="mb-1">
                  <span className="">{element.drug || element.label} </span>
                  {element.dose} {element.frecuency}
                  {element.startsAt && (
                    <small className="text-muted ms-4">
                      {" "}
                      {formatDate(element.startsAt, "dd-MMM-yyyy")}
                    </small>
                  )}
                  <small className="text-muted">
                    {" "}
                    {t("ehr:by")}{" "}
                    {element.createdByName &&
                      element.createdByName.replace(",", " ")}
                    <small className="ms-1">{element.createdByLicense}</small>
                  </small>
                </p>

                {props.printStatus.treatmentsNotes && (
                  <small>{element.notes}</small>
                )}
              </div>
            );
          }
        })}
    </>
  );
}

export default Treatments;
