import { useEffect, useState } from "react";
import { formatDistanceToNowStrict, formatDistanceStrict } from "date-fns";

import { getLocale } from "../../../../config/i18n";

function useTicker({ status, states } = {}) {
  const [distance, setDistance] = useState();

  useEffect(() => {
    const update = () => {
      if (!Array.isArray(states)) return;
      if (!status) return;
      let distance;

      try {
        const createdAt = new Date(
          states
            .filter((s) => s.status === status)
            .sort((a, b) => b.createdAt - a.createdAt)[0].createdAt
        );
        if (status === "fulfilled") {
          const activeAt = new Date(
            states
              .filter((s) => s.status === "active")
              .sort((a, b) => b.createdAt - a.createdAt)[0].createdAt
          );
          distance = formatDistanceStrict(activeAt, createdAt, {
            locale: getLocale(),
            addSuffix: false,
          });
        } else {
          distance = formatDistanceToNowStrict(createdAt, {
            locale: getLocale(),
            addSuffix: true,
          });
        }

        setDistance(distance);
      } catch {}
    };
    update();
    const ticker = setTimeout(update, 1000);
    return () => clearTimeout(ticker);
  });

  return distance;
}

export default useTicker;
