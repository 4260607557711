import { useTranslation } from "react-i18next";

import Editor from "../../../Editor/Editor";

const App = (props) => {
  const { t } = useTranslation();

  const onChange = (content, text) => {
    props.onChange({ content, text });
  };

  return props.editable ? (
    <Editor value={props.value} onEditorChange={onChange} />
  ) : (
    <div className="p-2" dangerouslySetInnerHTML={{ __html: props.value }} />
  );
};

export default App;
