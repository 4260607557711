import { useRef, useEffect } from "react";

import io from "../lib/io";
import getAccessToken from "./getAccessToken";

const Hook = () => {
  const accessToken = getAccessToken();
  const socket = useRef()

  useEffect(() => {
    if (!socket.current && accessToken.value) {
      socket.current = io(accessToken.value)
    }
  }, [accessToken.value, socket.current])

  return socket.current
};

export default Hook;
