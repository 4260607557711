import { useEffect, useState, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { ReactComponent as IconChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg'
import { ReactComponent as IconPlus } from 'bootstrap-icons/icons/plus.svg'
import { v4 as uuid } from "uuid";

import useUpload from "hooks/useUpload";
import useUser from "hooks/useUser";
import useStore from "../useStore";
import Archive from './Archive'

const Files = props => {
  const user = useUser()
  const [status, setStatus] = useState('')
  const $files = useRef(null)
  const $collapse = useRef(null)

  const [state, setState] = useState({ all: [], current: [] })

  const store = useStore(state => ({
    files: state.files || [],
    update: state.update,
    consumer: state.consumer
  }))

  const { t } = useTranslation();
  const upload = useUpload()
  
  const add = async (e) => {
    if (status === 'uploading') return

    const array = Array.from(e.target.files);
    $files.current.value = ""
    if (!array?.length) return

    setStatus('loading')

    const links = await upload(array);
    if (!links?.length) return setStatus('');
    const files = links.map((link, index) => ({
      _id: uuid(),
      consumer: { id: store.consumer?.id, label: store.consumer?.label },
      createdAt: Date.now(),
      createdBy: user.state.uid,
      createdByName: store.resource?.label,
      createdByResourceID: store.resource?.id,
      link,
      name: array[index].name,
      type: 'files',
      date: new Date().toLocaleDateString('fr-ca'),
    }))

    const current = store.files || []
    const _array = [...files, ...current]

    store.update({ files: _array })

    setStatus('')
  }

  const onChange = record => {
    const files = [...store.files]
    const index = files.findIndex(i => (
      record._id && record._id === i._id
    ) || (
      record.id && record.id === i.id
    ))
    if (index < 0) return
    files[index] = { ...record }
    store.update({ files })
  }

  useEffect(() => {
    const state = store.files.reduce((hash, record) => {
      if (record._id) {
        return { ...hash, current: [...hash.current, record] }
      } else {
        return { ...hash, all: [...hash.all, record] }
      }
    }, { current: [], all: [] })

    setState(state)
  }, [store.files])

  return (
    <div className="bg-white rounded">
      <div className="d-flex gap-2 align-items-center p-2 position-relative" style={{ height: '56px' }}>
        {status === 'loading' && <div className="loading loading-bottom loading-sm" />}
        <div className="flex-fill">
          <button className="btn text-secondary opacity-75" onClick={() => $files.current.click()}>
            <IconPlus width="16" height="16" /> {t('files', 'Archivos')}
          </button>
        </div>
        {state.all.length > 0 ? (
          <button
            data-bs-toggle="collapse"
            data-bs-target="#collapseFiles"
            data-tip={t('show_all_files_tip', `Mostrar los ${state.all.length} archivos cargados anteriormente`, { count: state.all.length })}
            className="btn bg-white link-primary"
          >
            <IconChevronExpand width="16" height="16" /> {state.all.length}
          </button>
        ) : (
          <span className="text-secondary opacity-75 me-3">0</span>
        )}
      </div>

      {state.current.length > 0 && (
        <div className="d-flex flex-column">
          {state.current.map(record => (
            <Archive key={record._id} state={record} onChange={onChange} />
          ))}
        </div>
      )}

      <div className="collapse" id="collapseFiles" ref={$collapse}>
        <div className="px-3 text-center bg-light border-top">
          <small className="font-small-caps fw-bold">{t('prev_files', 'Archivs cargados anteriores')}</small>
        </div>
        <div className="d-flex flex-column">
          {state.all.map(record => (
            <Archive key={record.id} state={record} onChange={onChange} />
          ))}
        </div>
      </div>

      <input
        ref={$files}
        type="file"
        className="form-control d-none"
        id="files"
        name="files[]"
        multiple
        onChange={add}
      />
    </div>
  )
}

export default Files
