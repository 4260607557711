import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import useUpload from "hooks/useUpload";

const App = (props) => {
  const $file = useRef(null);
  const { t } = useTranslation();
  const upload = useUpload();
  const [uploading, setUploading] = useState();
  const [files, setFiles] = useState([]);

  const onChange = async (e) => {
    if (uploading) return;
    setUploading(true);
    const links = await upload(e.target.files);
    props.onChange({ files: [...files, ...links] });
    setUploading(false);
  };

  const remove = (url) => {
    const array = files.filter((file) => file !== url);
    props.onChange({ files: array });
  };

  useEffect(() => {
    if (Array.isArray(props.value)) {
      setFiles(JSON.clone(props.value));
    }
  }, [props.value]);

  return (
    <div className="d-flex align-items-center text-nowrap">
      {props.editable && (
        <input
          ref={$file}
          type="file"
          multiple
          onChange={onChange}
          className={`${styles.input} form-control rounded-0 border-0 d-none`}
        />
      )}
      {uploading ? (
        <div className="flex-fill text-center">
          <div
            className="spinner-border text-secondary spinner-border-sm ms-2"
            role="status"
          >
            <span className="visually-hidden">{t("uploading")}</span>
          </div>
        </div>
      ) : files.length === 0 && props.editable ? (
        <button
          disabled={!props.editable}
          className="btn btn-sm bg-light text-success w-100 m-1 border"
          onClick={() => $file.current.click()}
          disabled={uploading}
        >
          {t("upload", "Subir")}
        </button>
      ) : (
        files.length > 0 && (
          <>
            <button
              className="btn btn-sm"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
            >
              {t("form_field_type_files_label", "Archivos")}{" "}
              <small className="text-secondary">({files.length})</small>
            </button>
            <div className="dropdown-menu p-0" style={{ maxWidth: "350px" }}>
              {props.editable && (
                <button
                  className="btn btn-sm bg-light text-success w-100 border-bottom rounded-0 p-2"
                  onClick={() => $file.current.click()}
                  disabled={uploading}
                >
                  {t("upload_new_files", "Seleccionar mas archivos")}
                </button>
              )}
              {files.map((file) => (
                <div
                  key={file}
                  className="d-flex align-items-center overflow-hidden"
                >
                  {props.editable && (
                    <button className="btn btn-sm" onClick={() => remove(file)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path
                          fillRule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                    </button>
                  )}
                  <a href={file} target="_blank" className="p-2">
                    {file.replace("https://", "")}
                  </a>
                </div>
              ))}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default App;
