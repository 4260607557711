function Cell(props) {
  return (
    <td
      style={props.allNumbers ? { width: '70px', minWidth: '70px', maxWidth: '70px' } : undefined}
      className={`text-xs ${props.cell.class || ''} ${props.allNumbers ? 'font-monospace text-center align-middle' : 'text-truncate'}`}
      data-tip={props.cell.message}
      dangerouslySetInnerHTML={{
        __html: props.cell.value || '',
      }}
    />
  )
}

export default Cell
