(() => {
  try {
    const defaultStorage = window.localStorage.getItem('default-events-storage') || window.localStorage.getItem('default-ehr-storage')
    if (defaultStorage) {
      window.localStorage.clear()
      window.location.reload()
    }
  } catch {}
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  window.callbacks = {};
  function setDocHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  window.addEventListener("resize", setDocHeight);
  window.addEventListener("orientationchange", setDocHeight);

  if (window.Modernizr.inputtypes.date) {
    document.body.classList.remove("no-inputtype-date");
    document.body.classList.add("inputtype-date");
  } else {
    document.body.classList.remove("inputtype-date");
    document.body.classList.add("no-inputtype-date");
  }

  if (window.Modernizr.inputtypes.time) {
    document.body.classList.remove("no-inputtype-time");
    document.body.classList.add("inputtype-time");
  } else {
    document.body.classList.remove("inputtype-time");
    document.body.classList.add("no-inputtype-time");
  }

  setDocHeight();

  JSON.clone = (payload) => {
    try {
      return JSON.parse(JSON.stringify(payload), (key, value) =>
        key === "__typename" ? undefined : value
      );
    } catch {
      return payload;
    }
  };

  window._l = (path) => {
    try {
      const parts = window.location.pathname.substr(1).split("/");
      if (parts[0] === "teams") {
        return `/teams/${parts[1]}${path || ''}`;
      }
      return path;
    } catch {
      return path;
    }
  };
})();
