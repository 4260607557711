import { useEffect, useState } from 'react';

const Timer = props => {
  let [time, setTime] = useState('00:00');
  
  useEffect(() => {
    let tick = setTimeout(() => {
      let hi = props.hi || Date.now();
      let low = props.low || Date.now()

      let ms = hi - low

      let s = Math.floor((ms / 1000) % 60);
      let m = Math.floor((ms / 1000 / 60) % 60);
      let h = Math.floor((ms  / 1000 / 3600 ) % 24)

      let hh = h.toFixed(0).padStart(2, '0');
      let mm = m.toFixed(0).padStart(2, '0');
      let ss = s.toFixed(0).padStart(2, '0');

      let time = `${mm}:${ss}`
      if (hh !== '00') {
        time = `${hh}:${mm}:${ss}`
      }

      setTime(time)
    }, 1000);

    return () => clearTimeout(tick)
  }, [time, props.hi, props.low]);
  
  return time || ''
}

export default Timer;
