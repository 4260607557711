import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../config/i18n";

function Labs(props) {
  const { t } = useTranslation();

  const [selectedLab, setSelectedLab] = useState(false);

  const Dropdown = (data) => {
    return (
      <div className="btn-group dropup">
        <button
          type="button"
          className="btn btn-sm btn-link text-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <small>
            {!selectedLab
              ? t("select")
              : formatDate(selectedLab.startsAt, "dd-MMM-yyyy")}
          </small>
        </button>
        <ul className="dropdown-menu dropdownHeightLimit">
          {DropdownList(data)}
        </ul>
      </div>
    );
  };

  const DropdownList = (data) => {
    return data.map((element) => (
      <li>
        <a
          className="dropdown-item"
          onClick={() => {
            setSelectedLab(element);
            props.setLabMode(false);
          }}
        >
          {formatDate(element.startsAt, "dd-MMM-yyyy")}
        </a>
      </li>
    ));
  };

  function renderLabs(lab) {
    let array = [];
    for (const key in lab) {
      if (
        key === "hemograma" ||
        key === "eabrenal" ||
        key === "ionograma" ||
        key === "hepatograma"
      ) {
        for (const labKey in lab[key]) {
          if (lab[key][labKey].value) {
            array.push(
              <p className="me-3 mb-0">
                {labKey}: {lab[key][labKey].value} {lab[key][labKey].unit}
              </p>
            );
          }
        }
      } else if (key === "startsAt") {
        array.push(
          <p className="me-3 mb-2 text-muted">
            {formatDate(lab[key], "dd-MMM-yyyy")}
          </p>
        );
      }
    }
    return array;
  }

  return (
    <>
      {props.printStatus.orderedLabs.filter((element) => element.id).length >
        0 && (
        <div className="d-flex align-items-center bg-light px-4 py-2 mb-2">
          <h5 className="h4 fw-bold mb-0 py-2">{t("ehr:labs")}</h5>
          {props.showBtns && (
            <button
              className={
                props.labMode === "all"
                  ? "btn btn-sm btn-link text-success"
                  : "btn btn-sm btn-link text-secondary"
              }
              onClick={() => {
                props.setLabMode("all");
                setSelectedLab(false);
              }}
            >
              <small>{t("ehr:all")}</small>
            </button>
          )}
          {props.showBtns && (
            <button
              className={
                props.labMode === "last"
                  ? "btn btn-sm btn-link text-success"
                  : "btn btn-sm btn-link text-secondary"
              }
              onClick={() => {
                props.setLabMode("last");
                setSelectedLab(false);
              }}
            >
              <small>{t("ehr:last")}</small>
            </button>
          )}

          {props.showBtns && Dropdown(props.printStatus.orderedLabs)}

          {props.showBtns && (
            <button
              className="btn btn-link btn-sm text-danger"
              onClick={() => {
                props.setPrintStatus({
                  ...props.printStatus,
                  lastLab: false,
                });
              }}
            >
              <small>{t("ehr:hide")}</small>
            </button>
          )}
        </div>
      )}

      {props.printStatus.lastLab && !props.labMode && selectedLab && (
        <div className="d-flex flex-column px-4 mt-2">
          {renderLabs(selectedLab)}
        </div>
      )}

      {props.printStatus.lastLab &&
        props.printStatus.orderedLabs &&
        props.printStatus.orderedLabs[0] &&
        props.printStatus.orderedLabs[0].id &&
        props.labMode === "last" && (
          <div className="d-flex flex-column px-4 my-3">
            {renderLabs(props.printStatus.orderedLabs[0], "last")}
          </div>
        )}

      {props.printStatus.lastLab && props.labMode === "all" && (
        <div className="px-4">
          {props.printStatus.orderedLabs.map((lab) => (
            <div className="d-flex d-inline-flex flex-column my-3">
              {lab.id && renderLabs(lab)}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Labs;
