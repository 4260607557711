import { useEffect, useState, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconSearch } from 'bootstrap-icons/icons/search.svg'
import { ReactComponent as IconBoxArrowUpRight } from 'bootstrap-icons/icons/box-arrow-up-right.svg'
import { ReactComponent as IconCardText } from 'bootstrap-icons/icons/card-text.svg'
import { ReactComponent as IconLock } from 'bootstrap-icons/icons/lock.svg'
import { ReactComponent as IconTrash } from 'bootstrap-icons/icons/trash.svg'
import { ReactComponent as IconArrowCounterclockwise } from 'bootstrap-icons/icons/arrow-counterclockwise.svg'
import Fuse from 'fuse.js'
import deburr from 'lodash/deburr'
import ReactTooltip from 'react-tooltip'

import useMutation from 'hooks/useMutation'
import { formatDate } from 'config/i18n'
import getDate from 'lib/getDate'
import styles from './styles.module.scss'
import Form from './Form/Form'
import sanitize from './sanitize'
import { Prescriptions } from './Prescriptions/Prescriptions'

let MAX_TIME = 48 * 60 * 60 * 1000

function Archive(props) {
  const [file, setFile] = useState({ ...props.file })
  const [mutate, mutation] = useMutation(
    window._l(`/records`).substr(1),
    {
      onMutate: payload => setFile({ ...payload })
    }
  );
  const { t } = useTranslation()

  const onToggleFile = file => e => {
    e.preventDefault()
    if (!file.id) return
    if (!file.deleted && window.confirm(t('ehr:file_remove_confirmation', 'Confirmas eliminar el archivo?'))) {
      mutate({ ...file, deleted: true })
    } else if (file.deleted) {
      mutate({ ...file, deleted: false })
    }
    return false
  }

  return (
    <div
      className={props.className}
      style={{ opacity: file.deleted ? '0.5' : '' }}
    >
      <a
        href={file.link}
        rel="noopener noreferrer"
        target="_blank"
        className="text-sm d-flex align-items-center gap-1"
        data-place="right"
        data-tip={file.notes}
      >
        <IconBoxArrowUpRight width="12" height="12" className="text-secondary" />
        <div className="flex-fill">
          <span className={!!file.notes ? 'border-bottom border-2 border-info' : ''}>{file.name}</span>
        </div>
        {props.user.uid === file.createdBy && file.id && file.createdAt >= (Date.now() - 48 * 60 * 60 * 1000) && (
          <div>
            <button className="btn btn-sm p-0 lh-1 mt-n1" onClick={onToggleFile(file)}>
              {file.deleted ? (
                <IconArrowCounterclockwise width="14" height="14" className="text-success" />
              ) : (
                <IconTrash width="13" height="13" className="text-danger" />
              )}
            </button>
          </div>
        )}
      </a>
    </div>
  )
}

function Consultation(props) {
  const { t } = useTranslation()
  const [edit, setEdit] = useState({})

  if (!props.consultation) return null
  if (!props.user) return null

  return (
    <div className="bg-white shadow-sm">
      <div className="p-3 d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-2 mb-2 text-xs">
          <div className="text-secondary">{formatDate(props.consultation.date, "dd-MMM-yy").toUpperCase()}</div>
          <div className="text-secondary">{props.consultation.createdBy}</div>
        </div>
        {props.consultation.evolutions.length > 0 && (
          <div>
            <h6 className="font-small-caps text-secondary mb-0">{t('ehr:evolutions', 'Evoluciones')}</h6>
            {props.consultation.evolutions.map(item => (
              <div key={item.id} className="mb-2">
                <div className="d-flex gap-2 align-items-center">
                  <small className="text-secondary">
                    {new Date(item.updatedAt).toLocaleTimeString()}
                  </small>
                  {item.createdBy === props.user.uid && item.createdAt + MAX_TIME >= Date.now() ? (
                    <>
                      {!edit[item.id] && (
                        <button onClick={() => setEdit({ ...edit, [item.id]: true })} className="btn btn-sm btn-link p-0">{t('ehr:edit_evolution', 'Editar')}</button>
                      )}
                    </>
                  ) : (
                    <IconLock className="text-secondary ms-n1" width="12" />
                  )}

                  {!!item.createdByName && (
                    <small className="d-none d-print-block">{item.createdByName}</small>
                  )}
                  {!!item.createdBy && (
                    <small className="text-primary d-none d-print-block">{item.createdBy}</small>
                  )}
                  {!!item.createdByLicense && (
                    <small className="text-secondary d-none d-print-block">{item.createdByLicense}</small>
                  )}
                </div>
                {!!edit[item.id] ? (
                  <div className="mx-n3"><Form tiny record={{ ...item }} /></div>
                ) : (
                  <div
                    className={`${styles.reset}`}
                    dangerouslySetInnerHTML={{ __html:  sanitize(item.content) }}
                  />
                )}
              </div>
            ))}
          </div>
        )}

        {props.consultation.diagnostics.length > 0 && (
          <div>
            <h6 className="font-small-caps text-secondary mb-0">{t('ehr:diagnostics', 'Diagnosticos')}</h6>
            <ul className="mb-0">
              {props.consultation.diagnostics.map(item => (
                <li key={item.id}>
                  <span dangerouslySetInnerHTML={{ __html: item.label }}/> {item.cie10Code && (<sup className="text-secondary">{item.cie10Code.toUpperCase()}</sup>)}
                </li>
              ))}
            </ul>

          </div>
        )}
        {props.consultation.treatments.length > 0 && (
          <div>
            <h6 className="font-small-caps text-secondary mb-0">{t('ehr:treatments', 'Tratamientos')}</h6>
            <ul className="mb-0">
              {props.consultation.treatments.map(item => (
                <li key={item.id} dangerouslySetInnerHTML={{ __html: item.product }} />
              ))}
            </ul>

          </div>
        )}

        {props.consultation.files.length > 0 && (
          <div className="d-print-none">
            <h6 className="font-small-caps text-secondary mb-0">{t('ehr:files', 'Archivos')}</h6>
            <ul className="mb-0">
              {props.consultation.files.map(item => (
                <li key={item.id}>
                  <a href={item.link} target="_blank" data-place="right" data-tip={item.notes} className={!!item.notes ? 'border-bottom border-2 border-info' : ''} rel="noopener noreferrer">{item.name || item.link}</a>
                </li>
              ))}
            </ul>
          </div>
        )}

        {(props.consultation.vitalSigns > 0 || props.consultation.labs > 0 || props.consultation.forms > 0) && (
          <div className="d-flex align-items-center justify-content-between border-top mx-n3 mb-n3 px-3 py-2 bg-light d-print-none">
            <small>{t('ehr:vitalSigns', 'Signos Vitales')} <span className={props.consultation.vitalSigns > 0 ? 'fw-bold' : 'text-secondary'}>{props.consultation.vitalSigns}</span></small>
            <small>{t('ehr:labs', 'Laboratorios')} <span className={props.consultation.labs > 0 ? 'fw-bold' : 'text-secondary'}>{props.consultation.labs}</span></small>
            <small>{t('ehr:forms', 'Formularios')} <span className={props.consultation.forms > 0 ? 'fw-bold' : 'text-secondary'}>{props.consultation.forms}</span></small>
          </div>
        )}
      </div>
    </div>
  )
}

function Consultations(props) {
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const [showAllFiles, setShowAllFiles] = useState(false)

  const [groups, setGroups] = useState()
  const fuse = useRef(null)

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    if (Array.isArray(props.consultations.groups)) {
      const groups = { all: [] }
      for (const group of props.consultations.groups) {
        groups.all.push({
          group,
          text: props.consultations[group].evolutions.map(e => e.text).join(" \n ")
        })
      }

      groups.results = groups.all

      setGroups(groups)

      fuse.current = new Fuse(groups.all, {
        keys: ['text'],
        threshold: 0.3,
        ignoreLocation: true
      })
    }
  }, [props.consultations.groups])

  const search = ({ target: { value } }) => {
    const query = deburr(value?.trim() || '')
    setQuery(value)
    let payload = { ...groups }

    if (!query) {
      payload.results = [...groups.all]
    } else {
      payload.results = fuse.current.search(query).flatMap(i => i.item) || []
    }

    setGroups(JSON.clone(payload))
  }

  if (!groups || !Array.isArray(groups.results) || !fuse.current) return null

  return (
    <>
      {props.filters.records && (
        <div className="bg-white d-flex align-items-center shadow-sm mb-2 rounded-md position-relative d-print-none">
          <div className="position-absolute start-0 ms-3 top-0 mt-1">
            <IconSearch className="text-secondary" />
          </div>
          <input
            type="search"
            className="form-control border-0"
            style={{ paddingLeft: '43px' }}
            placeholder={t('ehr:finder_records', 'Buscador de evoluciones...')}
            value={query}
            onChange={search}
          />
        </div>
      )}
      {props.prescriptions?._form && (
        <div className={`overflow-auto d-flex flex-column gap-3 ${styles.printAutoHW}`} style={{ height: `calc(100% - 45px)` }}>
          <Prescriptions prescriptions={props.prescriptions.docs || []}/>
        </div>
      )}

      <div className={`overflow-auto d-flex flex-column gap-3 ${styles.printAutoHW}`} style={{ height: `calc(100% - 45px)` }}>
        {props.filters.files && Array.isArray(props.files) && props.files.length > 0 && (
          <div className="py-2 px-3 bg-white shadow-sm d-print-none">
            <h6>{t('files')}</h6>
            {props.files.map((file, index) => (
              <Archive
                key={file.id}
                file={file}
                className={showAllFiles || index <= 2 ? '' : 'd-none'}
                user={props.user}
              />
            ))}
            {!showAllFiles && props.files.length > 3 && (
              <div className="border-top pt-2 mt-2">
                <button
                  className="btn btn-sm link-primary bg-light border"
                  onClick={() => setShowAllFiles(true)}
                >{t('ehr:show_all_files', 'Mostrar todos los archivos')} <sup className="text-secondary">{props.files.length}</sup></button>
              </div>
            )}
          </div>
        )}

        {props.filters.records && groups.results.map(result => (
          <Consultation
            user={props.user}
            key={result.group}
            resource={props.resource}
            consultation={props.consultations[result.group]}
          />
        ))}
      </div>
    </>
  )
}

export default Consultations;
