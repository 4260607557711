import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useQuery from "../../../../hooks/useQuery";
import { formatDate, getLocale } from "../../../../config/i18n";
import { differenceInCalendarDays } from "date-fns";
import CalculateBMI from "../VitalSigns/calculateBMI";
import calcClCr from "../../../../hooks/useClearanceCalc";
import ReactTooltip from "react-tooltip";
import { formatDistanceStrict } from "date-fns";
import getAge from "../../../../lib/getAge";

function Summary({ consumerID, consumer }) {
  const { t } = useTranslation();

  const [diagnostics, setDiagnostics] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [vitalSigns, setVitalsigns] = useState([]);
  const [labs, setLabs] = useState([]);

  const [goBack, setGoBack] = useState(false);

  function _calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const recordsQuery = useQuery(
    window._l(`/consumers/${consumerID}/records`).substr(1),
    {
      initialData: [],
    }
  );

  const diagnosticsQuery = useQuery(
    window._l(`/consumers/${consumerID}/diagnostics`).substr(1),
    {
      initialData: [],
    }
  );

  const treatmentsQuery = useQuery(
    window._l(`/consumers/${consumerID}/treatments`).substr(1),
    {
      initialData: [],
    }
  );

  const vitalSignsQuery = useQuery(
    window._l(`/consumers/${consumerID}/vitalSigns`).substr(1),
    {
      initialData: [],
    }
  );

  const labsQuery = useQuery(
    window._l(`/consumers/${consumerID}/labs`).substr(1),
    {
      initialData: [],
    }
  );

  useEffect(() => {
    setDiagnostics(diagnosticsQuery.data);
    setTreatments(treatmentsQuery.data);
    setVitalsigns(vitalSignsQuery.data);
    setLabs(labsQuery.data);
  }, [
    diagnosticsQuery.data,
    treatmentsQuery.data,
    vitalSignsQuery.data,
    labsQuery.data,
    recordsQuery.data,
  ]);

  let diagnosticsArray = {
    chronic: [],
    intercurrence: [],
    pending: [],
  };

  if (diagnostics) {
    diagnostics.map((diagnostic) => {
      if (diagnostic.status === "chronic" && !diagnostic.deleted)
        diagnosticsArray.chronic.push(diagnostic);
      if (diagnostic.status === "intercurrence" && !diagnostic.deleted)
        diagnosticsArray.intercurrence.push(diagnostic);
      if (diagnostic.status === "pending" && !diagnostic.deleted)
        diagnosticsArray.pending.push(diagnostic);
    });
  }

  let treatmentsArray = {
    active: [],
    recent: [],
    recentDefinitionInDays: 30,
  };

  if (treatments) {
    treatments.map((treatment) => {
      if (!treatment.deleted) treatmentsArray.active.push(treatment);
      if (
        !treatment.deleted &&
        treatment.endsAt &&
        differenceInCalendarDays(new Date(), new Date(treatment.endsAt)) >= 0 &&
        differenceInCalendarDays(new Date(), new Date(treatment.endsAt)) <=
          treatmentsArray.recentDefinitionInDays
      ) {
        treatmentsArray.recent.push(treatment);
      }
    });
  }

  let bmi;
  let latestVitalSign;

  function checkVitalsForData(vital) {
    let value = [];
    for (const key in vital) {
      if (
        vital.tas ||
        vital.tad ||
        vital.fc ||
        vital.fr ||
        vital.sat ||
        vital.height ||
        vital.weight
      ) {
        if (vital[key] && vital[key] !== "") {
          value.push(true);
        }
      }
    }

    if (value.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  if (vitalSigns && vitalSigns.length > 0) {
    let orderedVitalSigns = vitalSigns
      .filter((element) => !element.deleted)
      .sort((a, b) => b.startsAt - a.startsAt);

    if (checkVitalsForData(orderedVitalSigns[0])) {
      latestVitalSign = orderedVitalSigns[0];
      bmi = CalculateBMI(latestVitalSign.height, latestVitalSign.weight);
    }
  }

  let vitalSignAgeDiff;
  if (latestVitalSign && latestVitalSign.startsAt) {
    vitalSignAgeDiff = formatDistanceStrict(
      new Date(latestVitalSign.startsAt),
      new Date(Date.now()),
      {
        addSuffix: latestVitalSign.startsAt,
        locale: getLocale(),
      }
    );
  }

  let clcr = {
    value: false,
    date: false,
  };

  let latestLab;
  if (labs && labs.length > 0) {
    let orderedLabs = labs
      .filter((lab) => {
        if (lab.eabrenal && lab.eabrenal.cr) {
          return lab.eabrenal.cr.value && lab.eabrenal.cr.unit && !lab.deleted;
        }
      })
      .sort((a, b) => b.startsAt - a.startsAt);
    latestLab = orderedLabs[0];
    if (latestLab) {
      clcr.value = calcClCr(
        "ckd",
        consumer.gender,
        false,
        _calculateAge(new Date(consumer.dob)),
        latestLab.eabrenal.cr.value,
        latestLab.eabrenal.cr.unit
      );
      clcr.date = latestLab.startsAt;
    } else {
      clcr.value = "cannot_calculate";
      clcr.date = "";
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    setGoBack(window.history.length);
  }, []);

  let age = [];
  let { years, months, days } = getAge(consumer.dob);

  if (years) {
    age.push(years, t("time_distance_years"));
  }

  if (months) {
    age.push(months, t("time_distance_months"));
  }

  if (days) {
    age.push(days, t("time_distance_days"));
  }

  age = age.join(" ");

  return (
    <div>
      <div className="p-3">
        <button
          className="btn btn-link text-primary p-0"
          type="button"
          data-consumer={JSON.stringify({ id: consumer.id })}
          data-bs-toggle="modal"
          data-bs-target="#consumersModal"
        >
          {consumer.label ||
            [consumer.lastName, consumer.firstName].filter(Boolean).join(", ")}
        </button>

        {age && consumer.dob && (
          <p className=" mb-0">
            <small className="mb-0 text-muted"> {age}</small>
          </p>
        )}

        {
          <p className="mb-0">
            <small className="mb-0 text-muted">
              {" "}
              {formatDate(consumer.dob, "dd-MMM-yyyy")}
            </small>
          </p>
        }

        {consumer.identification && (
          <p className="text-muted m-0 p-0 ">
            <small>{t("identification", { context: "consumers" })}: </small>
            <small className="text-muted m-0 p-0">
              {consumer.identification}
            </small>
          </p>
        )}

        {consumer.financiers.map((financier) => (
          <p
            className="text-muted p-0 m-0 "
            key={`${consumer.id}${financier.id}`}
          >
            <small>
              {[t(financier.id, financier.label), financier.identification]
                .filter(Boolean)
                .join(" #")}
            </small>
          </p>
        ))}

        {(consumer.phones.length > 0 || consumer.emails.length > 0) && (
          <div className="text-muted  mb-0">
            {consumer.phones.map((phone) => (
              <a
                key={`${consumer.id}${phone.label}`}
                className="mb-0"
                target="_blank"
                data-tip={t("ehr:send_whatsapp")}
                data-event="mouseenter"
                data-event-off="mouseleave"
                data-effect="solid"
                href={`https://wa.me/${phone.label
                  .substring(1)
                  .replace(/ /g, "")}`}
              >
                <div className="d-flex text-success">
                  <svg
                    className="text-success me-1 align-self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                  </svg>
                  <small className="text-success align-self-center">
                    {phone.label}
                  </small>
                </div>
              </a>
            ))}
            <span className="m-0 p-0">
              {consumer.emails.map((email) => (
                <div key={`${consumer.id}${email.value}`} className="me-2 mb-0">
                  <small>{email.value}</small>
                </div>
              ))}
            </span>
          </div>
        )}
      </div>

      <div>
        {diagnosticsArray.chronic.filter((element) => element.dx).length ===
          0 &&
        diagnosticsArray.intercurrence.filter((element) => element.dx)
          .length === 0 &&
        diagnosticsArray.pending.filter((element) => element.dx).length ===
          0 ? null : (
          <div className="bg-light p-3 fw-bold text-uppercase">
            {t("diagnostic_plural")}
          </div>
        )}

        {diagnosticsArray.chronic
          .filter((element) => element.dx)
          .map((dx, i) => (
            <div
              className="d-flex align-items-center justify-content-between flex-row border-bottom p-3"
              key={dx && dx.id ? dx.id.replace("/", "") : null}
            >
              <small
                data-tip={`<div class="text-start"  style="max-width: 380px;"><small>
           <span class="fw-bold">${dx.dx}</span>
           </br>
           ${dx.notes}</small></div>`}
                className="text-truncate"
                data-event="mouseenter"
                data-event-off="mouseleave"
                data-effect="solid"
                data-place="right"
              >
                {dx.dx}
              </small>

              <small className="text-primary">{t(dx.status)}</small>
            </div>
          ))}

        {diagnosticsArray.intercurrence.map((dx) => (
          <div
            className="d-flex align-items-center justify-content-between flex-row border-bottom p-3"
            key={dx && dx.id ? dx.id.replace("/", "") : null}
          >
            <small
              data-tip={`<div class="text-start"  style="max-width: 380px;"><small>
        <span class="fw-bold">${dx.dx}</span>
        </br>
        ${dx.notes}</small></div>`}
              className="text-truncate"
              data-event="mouseenter"
              data-event-off="mouseleave"
              data-effect="solid"
              data-place="right"
            >
              {dx.dx}
            </small>

            <small className="text-success">{t(dx.status)}</small>
          </div>
        ))}

        {diagnosticsArray.pending.map((dx, index) => (
          <div
            className={
              diagnosticsArray.pending.length === index + 1
                ? "d-flex align-items-center justify-content-between flex-row p-3"
                : "d-flex align-items-center justify-content-between flex-row border-bottom p-3"
            }
            key={dx && dx.id ? dx.id.replace("/", "") : null}
          >
            <small
              data-tip={`<div class="text-start"  style="max-width: 380px;"><small>
        <span class="fw-bold">${dx.dx}</span>
        </br>
        ${dx.notes}</small></div>`}
              className="text-truncate"
              data-event="mouseenter"
              data-event-off="mouseleave"
              data-effect="solid"
              data-place="right"
            >
              {dx.dx}
            </small>

            <small className="text-warning">{t(dx.status)}</small>
          </div>
        ))}

        {treatmentsArray.active.filter((element) => element.drug).length ===
        0 ? null : (
          <div className="bg-light p-3 fw-bold text-uppercase">
            {t("treatment_plural")}
          </div>
        )}

        {treatmentsArray.active
          .filter((element) => element.drug)
          .filter((element) => element.status === "active")
          .map((treatment) => (
            <div
              className="d-flex align-items-center justify-content-between flex-row border-bottom p-3"
              key={
                treatment && treatment.id ? treatment.id.replace("/", "") : null
              }
            >
              <small className="text-truncate">{treatment.drug}</small>
              <small className="text-primary">{t(treatment.status)}</small>
            </div>
          ))}

        {treatmentsArray.active
          .filter((element) => element.status === "pending")
          .map((treatment) => (
            <div
              className="d-flex align-items-center justify-content-between flex-row border-bottom p-3"
              key={
                treatment && treatment.id ? treatment.id.replace("/", "") : null
              }
            >
              <small className="text-truncate">{treatment.drug}</small>
              <small className="text-warning">{t(treatment.status)}</small>
            </div>
          ))}

        {treatmentsArray.active
          .filter((element) => element.status === "done")
          .map((treatment) => (
            <div
              className="d-flex align-items-center justify-content-between flex-row border-bottom p-3"
              key={
                treatment && treatment.id ? treatment.id.replace("/", "") : null
              }
            >
              <small className="text-truncate">{treatment.drug}</small>
              <small className="text-success">{t(treatment.status)}</small>
            </div>
          ))}

        {treatmentsArray.recent.length === 0 ? null : (
          <div className="p-3 fw-bold text-uppercase">
            {t("finished_plural")}{" "}
            <small className="text-muted fw-normal text-lowercase">
              {t("since")} {treatmentsArray.recentDefinitionInDays}{" "}
              {t("day_plural")}{" "}
            </small>
          </div>
        )}

        {treatmentsArray.recent.map((treatment, index) => (
          <div
            className="d-flex align-items-center justify-content-between flex-row p-3"
            key={
              treatment && treatment.id ? treatment.id.replace("/", "") : null
            }
          >
            <small
              className={
                treatmentsArray.recent.length === index + 1
                  ? "text-truncate"
                  : "text-truncate border-bottom"
              }
            >
              {treatment.drug}
            </small>
          </div>
        ))}

        {latestVitalSign && (
          <div className="bg-light p-3 fw-bold text-uppercase">
            {t("vitalSign_plural")}
            {latestVitalSign && (
              <small className="text-muted fw-normal text-lowercase ms-1">
                {vitalSignAgeDiff}
              </small>
            )}
          </div>
        )}

        {latestVitalSign && (
          <div className="p-3">
            <div className="mb-2 pb-2 d-flex justify-content-left border-bottom">
              <div className="d-flex">
                <small>
                  <sup className="text-muted me-2">TA</sup>
                  {latestVitalSign.tas}/{latestVitalSign.tad}
                </small>
                <small>
                  <sup className="text-muted mx-2">FC</sup>
                  {latestVitalSign.fc}
                </small>
                <small>
                  <sup className="text-muted mx-2">FR</sup>
                  {latestVitalSign.fr}
                </small>
                <small>
                  <sup className="text-muted mx-2">T</sup>
                  {latestVitalSign.temp} <span className="text-muted">°C</span>
                </small>
              </div>
            </div>

            <div className="mb-2 pb-2 d-flex justify-content-left border-bottom">
              <div className="d-flex">
                <small>
                  <sup className="text-muted me-2">SAT/fiO2</sup>
                  {latestVitalSign.sat} <span className="text-muted">% / </span>
                  {latestVitalSign.fio2} <span className="text-muted">%</span>
                </small>
              </div>
            </div>

            <div className="mb-2 pb-2 d-flex justify-content-left border-bottom">
              <div className="d-flex">
                <small>
                  <sup className="text-muted me-2">altura / peso</sup>
                  {latestVitalSign.height}
                </small>
                <small>
                  <span className="text-muted"> cm /</span>{" "}
                  {latestVitalSign.weight}{" "}
                  <span className="text-muted">kg</span>
                </small>
              </div>
            </div>
            <div className="mb-1">
              {bmi.value ? (
                <small
                  className="p-0"
                  data-tip={t("bmi_tooltip")}
                  data-place="right"
                  data-effect="solid"
                >
                  <sup className="text-muted me-2">{t("bmi")} </sup>
                  {bmi.value.toFixed(0)} {bmi.warning}
                </small>
              ) : null}
            </div>
          </div>
        )}

        {clcr.value && clcr.value !== "cannot_calculate" && (
          <>
            <div className="bg-light p-3 fw-bold text-uppercase">
              {t("latest_clcr")}
              {latestVitalSign && (
                <small className="text-muted fw-normal text-lowercase">
                  {" "}
                  {formatDate(clcr.date, "dd-MMM-yyyy")}
                </small>
              )}
            </div>
            <div className="p-3">
              <div
                className="d-inline"
                data-tip={t("clcr_tooltip")}
                data-effect="solid"
              >
                {t(clcr.value)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Summary;
