/* eslint-disable */
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "@reach/router";

import getAccessToken from "./getAccessToken";

const { REACT_APP_API_URL } = process.env;

const useUpload = () => {
  const accessToken = getAccessToken();

  const upload = async (files) => {
    try {
      const array = Array.from(files);
      const body = new FormData();
      for (const file of array) {
        body.append("files", file);
      }
      const response = await fetch(`${REACT_APP_API_URL}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
        },
        body,
      }).then((res) => res.json());
      return response;
    } catch {
      return null;
    }
  };

  return upload;
};

export default useUpload;

/* eslint-enable */
