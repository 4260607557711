import { useState } from "react";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";
import kebabCase from "lodash/kebabCase";
import { useQuery } from "react-query";

import useUser from "hooks/useUser";
import styles from "./styles.module.scss";

const { REACT_APP_API_URL } = process.env;

const request = (country, query) =>
  fetch(`${REACT_APP_API_URL}/vademecums/${country}?q=${query || ""}`)
    .then((res) => res.json())
    .catch(() => []);

const getOptionLabel = (i) =>
  i.label ||
  `${[i.product, i.presentation, i.company].filter(Boolean).join(" - ")} (${
    i.drug
  })`;

const App = (props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState();
  const user = useUser();
  const catalog = useQuery(
    ["vademecums", user.state.country, query].filter(Boolean).join(":"),
    () => request(user.state.country, query),
    { initialData: [], enabled: !!user.state.country }
  );

  const onChange = (value) => {
    let array = [value];
    if (Array.isArray(value)) {
      array = [...value];
    }
    array = array.filter((item) => item?.product_code);
    props.onChange({ treatments: array });
  };

  return props.editable ? (
    <Creatable
      styles={{
        container: (styles) => ({
          ...styles,
          width: "100%",
        }),
        control: (styles) => ({
          ...styles,
          border: "none",
          borderRadius: 0,
          boxShadow: "none",
        }),
      }}
      isMulti={!!props.field.isMulti}
      isDisabled={props.editable === false}
      isClearable
      tabSelectsValue
      isLoading={catalog.isLoading}
      backspaceRemovesValue
      defaultMenuIsOpen={false}
      getNewOptionData={(value, label) => ({
        label,
        product_code: kebabCase(value),
      })}
      onInputChange={setQuery}
      loadingMessage={() => t("loading")}
      value={props.value || []}
      options={catalog.data}
      getOptionValue={(item) => item.product_code}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      formatCreateLabel={(value) => value}
      placeholder={t("form_field_type_treatments_placeholder", "")}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: null,
        ClearIndicator: null,
      }}
    />
  ) : (
    <div className="p-2">
      {props.value?.map(getOptionLabel)?.filter(Boolean)?.join(", ")}
    </div>
  );
};

export default App;
