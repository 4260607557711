import { Modal } from "bootstrap";

import i18n from "../config/i18n";

const { REACT_APP_DEFAULT_TITLE } = process.env;

const updateTitle = () => {
  try {
    if (!i18n.isInitialized) return i18n.on("initialized", updateTitle);
    document.title = window.location.pathname
      .split("/")
      .filter(Boolean)
      .map((p) => i18n.t(p.replace(/s$/, "_plural")))
      .join(" | ");
    document.title = document.title || REACT_APP_DEFAULT_TITLE || "";
  } catch {}
};

i18n.on("languageChanged", updateTitle);

/* ACTIONS */
const actions = {};

actions.onChangeLocation = () => {
  updateTitle();
  window.modals.close();
};

window.modals = window.modals || {};

window.modals.close = () => {
  for (const modal of document.querySelectorAll(".modal")) {
    try {
      Modal.getInstance(modal).hide();
    } catch {}
  }
};

export default actions;
