/* eslint-disable */
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const { REACT_APP_AUTH0_AUDIENCE } = process.env;

const getAccessToken = ({
  audience = REACT_APP_AUTH0_AUDIENCE,
  scope,
} = {}) => {
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isLoading,
  } = useAuth0();
  const [state, setState] = useState({});

  const get = async ({ audience, scope }) => {
    let accessToken;
    try {
      accessToken = await getAccessTokenSilently({ audience, scope });
    } catch (error) {
      if (error.error === "consent_required") {
        accessToken = await getAccessTokenWithPopup({ audience, scope });
      } else {
        throw error;
      }
    }
    return accessToken;
  };

  useEffect(() => {
    (async () => {
      if (isLoading) return;
      try {
        const accessToken = await get({ audience, scope });
        setState({
          value: accessToken || null,
          error: undefined,
        });
      } catch (error) {
        setState({
          ...state,
          value: state.value || null,
          error,
        });
      }
    })();
  }, [isLoading]);

  return state;
};

export default getAccessToken;
/* eslint-enable */
