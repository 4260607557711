const perfiltiroideoModel = () => {
  return {
    tsh: {
      value: "",
      unit: "",
    },
    t4: {
      value: "",
      unit: "",
    },
    t4l: {
      value: "",
      unit: "",
    },
    t3: {
      value: "",
      unit: "",
    },
  };
};

const um = () => {
  return {
    tsh: ["mUI/L"],
    t4: ["nmol/L"],
    t4l: ["pmol/L"],
    t3: ["nmol/L"],
  };
};

export { perfiltiroideoModel, um };
