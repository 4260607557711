import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";

import Component from "./Component";
import useStore from "../useStore";

const Resources = props => {
  const { t } = useTranslation();
  const [state, setState] = useState({ data: [] })
  const store = useStore((state) => ({
    setResource: state.setResource,
    facets: state?.facets?.resources || {},
    location: state.location,
    resource: state.resource,
    service: state.service,
    financier: state.financier,
    clearAt: state.clearAt
  }));

  const onChange = value => {
    if (props.onChange) return props.onChange(value)
    setState(state => ({ ...state, active: value, dirty: true }))
    store.setResource(value)
  }

  useEffect(() => {
    if (store.clearAt) setState(s => ({ data: [], force: s.force }))
  }, [store.clearAt])

  useEffect(() => {
    const entries = Object.entries(props.facets ?? store.facets)

    const disabled = []
    const enabled = []
    const data = []

    for (const [id, entry] of entries) {
      if (entry.active) {
        enabled.push({ id, ...entry })
      } else {
        disabled.push({ id, ...entry })
      }
    }

    if (enabled.length) {
      const options = enabled.sort((a, b) => {
        if (a.label?.toLowerCase() < b.label?.toLowerCase()) return -1
        if (a.label?.toLowerCase() > b.label?.toLowerCase()) return 1
        return 0
      })
      data.push({ options, label: `${t('filters_resources_enable_plural', 'Habilitados')}` })
    }

    if (disabled.length) {
      const options = disabled.sort((a, b) => {
        if (a.label?.toLowerCase() < b.label?.toLowerCase()) return -1
        if (a.label?.toLowerCase() > b.label?.toLowerCase()) return 1
        return 0
      })
      data.push({ options, label: `${t('filters_resources_disable_plural', 'Deshabilitados')}` })
    }

    const state = { data }

    if (enabled.length === 1) {
      state.active = { ...enabled[0] }
    }

    state.force = entries.length === 1 && !!state.active

    setState(s => ({ ...state, dirty: s.dirty }))
  }, [props.facets, store.facets])

  useEffect(() => {
    const clearAt = store.clearAt || 0
    const diff = Date.now() - clearAt
    if (props.store !== 'manual' && (diff > 1000 || state.force) && !state.dirty && state.active?.id && store.resource?.id !== state.active.id) {
      store.setResource({ ...state.active })
    }
  }, [store.clearAt, state.force, state.active, state.dirty, store.resource, props.store])

  return (
    <Component
      show={props.show ?? true}
      isLoading={props.isLoading}
      onChange={onChange}
      highlight={props.highlight}
      data={state.data}
      valueKey="id"
      value={props.value ?? store.resource}
      placeholder={t("resource_plural", { context: "sidebar" })}
    />
  );
};

export default Resources;
