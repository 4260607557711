import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { useQuery, useQueryClient } from "react-query";

import useUser from "../../../../hooks/useUser";
import useQueryLocal from "../../../../hooks/useQuery";

import VitalSign from "./VitalSign";
import BibliographyCard from "../Bibliography/BibliographyCard";

const sort = (a, b) => {
  if (!a.id && b.id) return -1;
  if (a.id && !b.id) return 1;
  return b.createdAt - a.createdAt;
};

function VitalSigns({ consumer, uid, className, resource }) {
  const { t } = useTranslation();
  const $container = useRef(null);
  const queryClient = useQueryClient();
  const user = useUser();
  const [records, setRecords] = useState([]);
  const [stats, setStats] = useState({});
  const [status, setStatus] = useState([]);

  const query = useQueryLocal(
    window._l(`/${consumer.id}/vitalSigns`).substr(1),
    {
      initialData: [],
    }
  );

  const update = (payload, clear) => {
    const id = payload.id || payload.createdAt;
    const keys = queryClient
      .getQueryCache()
      .findAll()
      .filter((query) => query.queryHash.includes("/vitalSigns"))
      .map((query) => query.queryKey);

    for (const key of keys) {
      queryClient.setQueryData(key, (records) => {
        const array = JSON.clone(records);
        const index = array.findIndex(
          (record) =>
            (record.id || record.createdAt) === id &&
            record.consumer?.id === payload.consumer?.id
        );
        if (index < 0)
          return [...array, payload].filter((record) => !clear || record.id);
        array[index] = payload;
        return array;
      });
    }
  };

  const licenses =
    resource &&
    resource.licenses &&
    resource.licenses
      .filter((element) => element.type === "license")
      .map((license) => {
        if (license.jurisdiction === "AR") {
          return `M.N. ${license.value}`;
        } else {
          return `M.P. ${license.value}`;
        }
      })
      .join("-");

  const add = () => {
    update({
      consumer: { id: consumer.id },
      createdBy: user.state.uid,
      createdByName: resource?.label,
      createdByLicense: licenses,
      createdAt: Date.now(),
      startsAt: new Date().getTime(),
      type: "vitalSigns",

      tas: "",
      tad: "",
      fc: "",
      fr: "",
      temp: "",
      sat: "",
      fio2: "",
      height: "",
      weight: "",
      abdperim: "",
    });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    const array = [];
    const stats = {};

    for (const record of query.data) {
      if (uid && record.createdBy !== uid) {
        continue;
      }

      if (record.deleted) {
        stats.deleted = stats.deleted || 0;
        stats.deleted += 1;
      }

      if (record.deleted && !status.includes("deleted")) {
        continue;
      }

      if (record.status && !record.deleted) {
        stats[record.status] = stats[record.status] || 0;
        stats[record.status] += 1;
      }

      array.push(record);
    }

    if (query.data.length === 0) {
      add();
    }

    setRecords(array);
    setStats(stats);
  }, [query.data, status, uid]);

  const toggle = (value) => {
    if (status.includes(value)) {
      setStatus(status.filter((s) => s !== value));
    } else {
      setStatus([...status, value]);
    }
  };

  if (!query.isFetched) {
    return (
      <div className={className || ""}>
        <div className="text-center">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (query.isLoadingError || query.isError) {
    return (
      <div className={className || ""}>
        <div className="text-danger">{t("error")}</div>
      </div>
    );
  }

  const filters = Object.keys(stats || {}).sort((a, b) => {
    if (a === "deleted" && b !== "deleted") return 1;
    if (a !== "deleted" && b === "deleted") return -1;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  return (
    <div>
      <div className={className || ""}>
        <div className="d-flex justify-content-between">
          <button className="btn btn-sm btn link-success ms-n2" onClick={add}>
            {t("new", { label: t("vitalSign") })}
          </button>

          {filters.length > 0 && (
            <>
              <div className="flex-fill"></div>
              {filters.map((key) => (
                <button
                  key={`status-${key}`}
                  onClick={() => toggle(key)}
                  className={cx("btn btn-sm btn-link", {
                    "link-primary": status.includes(key),
                    "link-secondary": !status.includes(key),
                  })}
                  data-tip={t(`${key}_tooltip`)}
                  data-event="mouseenter"
                  data-event-off="mouseleave"
                  data-effect="solid"
                  data-place="top"
                >
                  {t(`ehr:${key}`, { count: stats[key] })}
                  <sup>{stats[key]}</sup>
                </button>
              ))}
            </>
          )}
        </div>
        <div ref={$container}>
          {records.sort(sort).map((record) => (
            <VitalSign
              key={record.id || record.createdAt}
              record={record}
              update={update}
            />
          ))}
        </div>
      </div>
      <div className="container-xxl my-3 p-0 d-flex">
        <BibliographyCard text="vitalSigns_bibliography"></BibliographyCard>
      </div>
    </div>
  );
}

export default VitalSigns;
