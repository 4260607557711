function sanitize(html) {
  try {
    if (html.includes('cdn.drapp.la') || html.includes('cdn.migration.drapp.la')) return html
  } catch {}
  try {
    // return html
    return html.replaceAll('background-color', 'backgroundColor').replace(/<(\w+)(\s+[^>]*)?>/g, (match, tagName, attributes) => {
        let styleMatch = attributes ? attributes.match(/\sstyle="[^"]*"/i) : null;
        let styleAttribute = styleMatch ? styleMatch[0].replace(/(\b(?!color\b|backgroundColor\b)[a-z-]+)\s*:\s*[^;#]/gi, '') : '';
        return `<${tagName}${styleAttribute}>`;
    }).replaceAll('backgroundColor', 'background-color');
  } catch {}
  return ''
}

export default sanitize
