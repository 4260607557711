import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Link } from "@reach/router";
import { ReactComponent as IconChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg'
import { ReactComponent as IconArrowLeft } from 'bootstrap-icons/icons/arrow-left.svg'
import cx from "classnames";
import set from 'lodash/set'
import get from 'lodash/get'

import useMutation from "hooks/useMutation";
import useUser from "hooks/useUser";
import useTicker from "App/Events/Agenda/Event/useTicker";

import useStorePrescription from "App/Prescription/useStore";

import Consumer from "App/EHR/Consumer/Consumer";
import Filters from "./Filters";
import useStore from '../useStore'
import Consultations from './Consultations'

const Sidebar = props => {
  const { t } = useTranslation();
  const prescription = useStorePrescription();
  const navigate = useNavigate();
  const user = useUser()
  const location = useLocation()

  const store = useStore(state => ({
    event: state.event,
    consumer: state.consumer,
  }))

  const [search, setSearch] = useState('')

  const ticker = useTicker(store.event);

  const [mutate] = useMutation(window._l(`/${store.event?.id}`).substr(1), {
    onError: (error) => {
      window.notify({
        message: error.message,
        type: "danger",
        title: "Error",
      });
    },
  });

  const fulfilled = () => {
    if (!store.event?.id) return;
    if (store.event.status !== "active") return;
    const payload = JSON.clone(store.event);
    payload.states = payload.states || [];
    payload.states.push({ createdAt: Date.now(), status: "fulfilled" });
    payload.status = "fulfilled";
    mutate(payload);
    navigate(window._l("/events/agenda"));
  };

  return (
    <div>
      {(store.event?.id && store.event.status === "active") && (
        <div className="mb-3">
          <button
            onClick={fulfilled}
            className="btn btn-primary w-100
            "
          >
            {t("end_consultation", "Finalizar consulta", { context: "ehr" })}
            {ticker && <div className="small">{ticker}</div>}
          </button>
        </div>
      )}

      <div className="d-flex gap-3 mb-3 rounded">
        <Filters />
        <Link to="print" className="btn flex-fill bg-white text-primary">{t('print_ehr', 'Imprimir HCE')}</Link>
        {user.state.country === 'ar' && (
          <button
            onClick={() => prescription.setConsumerID(store.consumer?.id)}
            className="btn bg-white text-primary flex-fill"
          >
            {t('ehr:prescription', 'Receta')}
          </button>
        )}
        {!/\/ehr$/.test(location.pathname) && (
          <Link
            to="../ehr"
            className="btn text-secondary bg-white">
            <IconArrowLeft width="15" height="15" />
          </Link>
        )}
      </div>

      <div className="rounded p-3 bg-white">
        <Consumer
          consumer={store.consumer}
          consumerID={store.consumer?.id?.replace('consumers/', '')}
        />
      </div>

      <div className="mt-3 rounded">
        <Consultations />
      </div>

    </div>
  );
}

export default Sidebar;
