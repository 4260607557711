import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconPrescription } from 'bootstrap-icons/icons/prescription.svg'

import { useQuery } from "react-query";
import { formatDate } from 'config/i18n'
import styles from './styles.module.scss'

let {
  REACT_APP_API_URL,
  REACT_APP_PRESCRIPTION_FRONT_URL
} = process.env

let clone = window.structuredClone || JSON.clone

let get = path => () => fetch(`${REACT_APP_API_URL}/${path}`).then(res => res.json())

function Prescription({ prescription }) {
  let [data, setData] = useState()
  let { t } = useTranslation();

  let query = useQuery(
    `prescriptions/${prescription.id}`,
    get(`prescriptions/${prescription.id}/${prescription.token}`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: data => setData(clone(data))
    }
  )

  if (!data?.payload || !data.payload.resource?.label) return null

  let { payload, integrations, pdf } = data
  let treatments = payload.treatments || []
  let diagnostic = payload.diagnostic
  let indications = payload.indications
  let content = payload.content

  return (
    <div className="bg-white shadow-sm mb-3 p-3">
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-2 mb-2 text-xs">
          <div className="text-secondary">
            <IconPrescription /> {formatDate(payload.date, "dd-MMM-yy").toUpperCase()}
          </div>
          <div className="text-secondary">{payload.resource.label}</div>
          <div className="flex-fill text-end">
            <a
              href={pdf}
              target="_blank"
              rel="noopener noreferrer"
            >{t('ehr:open_prescription_pdf', 'PDF')}</a>
            {REACT_APP_PRESCRIPTION_FRONT_URL && (
              <a
                href={`${REACT_APP_PRESCRIPTION_FRONT_URL}/${prescription.id}/${prescription.token}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ms-3"
              >{t('ehr:open_prescription_link', 'Link')}</a>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column gap-3">
        {diagnostic && (
          <div>
            <h6 className="font-small-caps text-secondary mb-0">{t('ehr:prescription_diagnostic', 'Diagnostico')}</h6>
            <div className="lh-1">
              <small dangerouslySetInnerHTML={{ __html: diagnostic }} />
            </div>
          </div>
        )}
        {indications && (
          <div>
            <h6 className="font-small-caps text-secondary mb-0">{t('ehr:prescription_indications', 'Indicaciones')}</h6>
            <div className="lh-1">
              <small dangerouslySetInnerHTML={{ __html: indications }} />
            </div>
          </div>
        )}
        {content && (
          <div>
            <h6 className="font-small-caps text-secondary mb-0">{t('ehr:prescription_order_study', 'Orden')}</h6>
            <div className="lh-1">
              <small dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        )}
        {treatments.length > 0 && (
          <div>
            <h6 className="font-small-caps text-secondary mb-0">{t('ehr:prescription_treatments', 'Tratamientos')}</h6>
            <ul className="mb-0">
              {treatments.map(item => (
                <li key={item.product_code} dangerouslySetInnerHTML={{ __html: item.label || item.product }} />
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export function Prescriptions({ prescriptions }) {
  let { t } = useTranslation();

  if (!Array.isArray(prescriptions) || prescriptions.length === 0) return null

  return (
    <div>
      <h6 className="font-small-caps text-secondary mb-0">{t('ehr:prescriptions', 'Recetas')}</h6>
      {prescriptions.map(prescription => (
        <Prescription
          prescription={prescription}
          key={prescription.id}
        />
      ))}
    </div>
  );
}
