import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "@reach/router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Offcanvas } from "bootstrap";
import { ReactComponent as IconPlusCircleFill } from 'bootstrap-icons/icons/plus-circle-fill.svg'
import { ReactComponent as IconLayoutSidebarInset } from 'bootstrap-icons/icons/layout-sidebar-inset.svg'
import { ReactComponent as IconLayoutSidebar } from 'bootstrap-icons/icons/layout-sidebar.svg'

import useUser from "hooks/useUser";
import useQuery from "hooks/useQuery";
import useDownload from "hooks/useDownload";
import useTeamID from "hooks/useTeamID";
import useStorePrescription from "App/Prescription/useStore";
import useStoreGlobal from "../useStore";

import styles from "./styles.module.scss";
import useStoreRooms from "../Room/useStore";
import useStoreEvents from "../Events/useStore";
import EventForm from "../Events/Form/Form";

const { REACT_APP_MARKETPLACE_ROOT_URL } = process.env;

export default function () {
  const $offcanvas = useRef(null)
  const today = new Date();
  const storeGlobal = useStoreGlobal()
  const { t, i18n } = useTranslation();
  const user = useUser();
  const location = useLocation();
  const openRoom = useStoreRooms((state) => state.open);
  const date = useStoreEvents(state => state.date)
  const [event, setEvent] = useState();
  const downloadEvents = useDownload("events");
  const downloadConsumers = useDownload("consumers");
  const currentTeamID = useTeamID();
  const team = useQuery(currentTeamID);
  const prescription = useStorePrescription();
  const [range, setRange] = useState({
    startsAt: new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    ).toLocaleDateString("fr-ca"),
    endsAt: new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
      23,
      59,
      59,
      999
    ).toLocaleDateString("fr-ca"),
  });

  let backup = useQuery(window._l(`/backup`).substr(1), {
    enabled: false
  })

  let onClickBackup = () => {
    backup.refetch()
    window.alert(t(`backup_message`, `En los proximos minutos te vamos a enviar un email a ${user.state.email} para que bajes el backup.`, { email: user.state.email }))
  }

  const changeTeam = ({ target: { value } }) => {
    if (value === "new") {
      window.location.href = "/teams/new";
    } else {
      window.location.href = location.pathname.replace(currentTeamID, value);
    }
  };

  useEffect(() => {
    window.document.addEventListener("hidden.bs.offcanvas", () => {
      setEvent();
    });
    return () => window.document.removeEventListener("hidden.bs.offcanvas", () => {
      setEvent();
    });
  }, []);

  if (!user.state.teams) return null;
  const slug = team.data?.slug || currentTeamID.replace("teams/", "");
  const marketplaceUrl = `${REACT_APP_MARKETPLACE_ROOT_URL.replace(
    "://",
    `://${slug}.`
  )}`;

  // const enableSidebarBtn = /\/events\/calendar\/|\/events\/agenda\/|\/consumers\/[a-zA-Z0-9]+\/ehr\//i.test(location.pathname)
  const enableSidebarBtn = /\/events\/calendar\/|\/events\/agenda\//i.test(location.pathname)

  return (
    <>
      <nav className="sticky-top navbar navbar-expand-lg navbar-light bg-white border-bottom d-print-none">
        <div className="container-fluid">
          <div className="dropdown">
            <button className="btn text-primary" data-bs-toggle="dropdown">
              <IconPlusCircleFill width="25" height="25" />
            </button>
            <div className={`${styles["plus-container"]} dropdown-menu p-0 shadow-lg border-0 m-2 `}>
              <button
                className="btn btn-link link-dark btn-sm p-3 w-100 text-start border-bottom text-truncate"
                type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEvent"
                onClick={() => setEvent({})}
              >
                {t("new", { label: t("event") })}
              </button>

              <button
                className="btn btn-link link-dark btn-sm p-3 w-100 text-start border-bottom text-truncate"
                data-bs-dismiss="modal"
                onClick={() => openRoom()}
              >
                {t("new", { label: t("remote_link"), context: "female" })}
              </button>

              <button
                className="btn btn-link link-dark btn-sm p-3 w-100 text-start border-bottom text-truncate"
                data-bs-toggle="modal"
                data-bs-target="#consumersModal"
              >
                {t("new", { label: t("consumer") })}
              </button>

              <button
                className="btn btn-link link-dark btn-sm p-3 w-100 text-start text-truncate"
                data-bs-toggle="modal"
                data-bs-target="#messageModal"
              >
                {t("new", { label: t("message") })}
              </button>

              {(user.state.isStaff ||
                (user.actions.has("ehr") &&
                  user.state.country === "ar" &&
                  user.team?.resourceID)) && (
                <button
                  className="btn btn-link link-dark btn-sm p-3 w-100 text-start border-top text-truncate"
                  onClick={prescription.toggle}
                >
                  {t("new", { label: t("prescription") })}
                </button>
              )}
            </div>
          </div>
          <button
            disabled={!enableSidebarBtn}
            className={`btn ${!enableSidebarBtn ? 'opacity-25' : ''}`}
            onClick={() => storeGlobal.update({ sidebar: !storeGlobal.sidebar })}
          >
            <IconLayoutSidebarInset width="25" height="25" className={storeGlobal.sidebar ? 'text-dark' : 'text-secondary opacity-75'} />
          </button>
          <button className="navbar-toggler border-0 text-dark p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <div className="offcanvas offcanvas-end" id="offcanvasNavbar">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">DrApp</h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body align-items-center">
              <ul className="navbar-nav me-auto gap-lg-3">
                <li className="nav-item">
                  <Link
                    to={window._l(`/events/calendar/${date || today.toLocaleDateString("fr-ca")}`)}
                    className={`nav-link ${location.pathname.includes("calendar") ? "active" : ""}`}
                  >
                    {t("calendar", "Calendario")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={window._l(`/events/agenda/${date || today.toLocaleDateString("fr-ca")}`)}
                    className={`nav-link ${location.pathname.includes("agenda") ? "active" : ""}`}
                  >
                    {t("agenda")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={window._l("/consumers")}
                    className={`nav-link ${location.pathname.includes("consumers") ? "active" : ""}`}
                  >
                    {t("consumer_plural")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={window._l("/setup/resources")}
                    className={`nav-link ${location.pathname.includes("resources") ? "active" : ""}`}
                  >
                    {t("resource_plural")}
                  </Link>
                </li>
                {user.actions.has('agreements') && (
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {t("report_plural", "Reportes")}
                    </a>
                    <div className="dropdown-menu p-0" style={{ maxWidth: "320px" }}>
                      <button
                        onClick={downloadConsumers.start}
                        disabled={downloadConsumers.isLoading}
                        className="btn border-bottom btn-link d-block w-100 text-start px-3 py-2"
                      >
                        {downloadConsumers.isLoading && (
                          <div
                            className="spinner-border spinner-border-sm text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">{t("loading")}</span>
                          </div>
                        )}{" "}
                        {t("consumer_plural")}
                      </button>
                      <div>
                        <h6 className="dropdown-header">{t("event_plural")}</h6>
                        <div className="px-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <div
                              data-tip={t(
                                "event_report_startsAt_tip",
                                "Ingrese la fecha desde cuando quiere incluir turnos. Por defecto es principio de mes"
                              )}
                              style={{ maxWidth: "136px" }}
                            >
                              <label>{t("events_report_startsAt", "Desde")}</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                value={range.startsAt || ""}
                                onChange={(e) =>
                                  setRange({ ...range, startsAt: e.target.value })
                                }
                              />
                            </div>
                            <div
                              data-tip={t(
                                "event_report_endsAt_tip",
                                "Ingrese la fecha hasta cuando quiere incluir turnos. Por defecto es fin de mes"
                              )}
                              style={{ maxWidth: "136px" }}
                            >
                              <label>{t("events_report_endsAt", "Hasta")}</label>
                              <input
                                type="date"
                                className="form-control form-control-sm"
                                value={range.endsAt || ""}
                                onChange={(e) =>
                                  setRange({ ...range, endsAt: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <button
                            onClick={() => downloadEvents.start(range)}
                            disabled={downloadEvents.isLoading}
                            className="btn btn-link px-0"
                          >
                            {downloadEvents.isLoading && (
                              <div
                                className="spinner-border spinner-border-sm text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">{t("loading")}</span>
                              </div>
                            )}{" "}
                            {t("download_events_reports", "Descargar")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>

              <div className="dropdown">
                <button
                  className="btn w-100 w-lg-auto px-0 px-lg-3 text-start d-flex gap-2"
                  data-bs-toggle="dropdown"
                >
                  <div className="d-none d-lg-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="32"
                      width="32"
                      className="text-secondary"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div>
                    <div className="small">{user.state.email}</div>
                    <div className="text-muted small lh-1">
                      {team?.data?.label || currentTeamID.replace("teams/", "")}
                    </div>
                  </div>
                </button>
                <div className={`${styles["account-container"]} dropdown-menu-lg-end dropdown-menu p-0 shadow-lg border-0 mt-2 `}>
                  <div>
                    <div className="d-flex align-items-center">
                      <Link
                        to={window._l("/setup/account")}
                        className="flex-fill p-3 btn btn-link w-100 text-start"
                      >
                        {t("configuration", "Configuracion")}
                      </Link>
                      <select
                        id="teamSwitcher"
                        className="form-select form-select-sm bg-light border-0"
                        onChange={changeTeam}
                        style={{ width: "130px" }}
                        value={currentTeamID}
                      >
                        {!user.state.teams.find(
                          (team) => team.id === currentTeamID
                        ) && (
                          <option value={currentTeamID}>
                            {currentTeamID.replace("teams/", "")}
                          </option>
                        )}
                        {user.state.teams.map((team) => (
                          <option key={`sidebar-team-${team.id}`} value={team.id}>
                            {team.label
                              ? `${team.label} (${team.id.replace("teams/", "")})`
                              : team.id.replace("teams/", "")}
                          </option>
                        ))}
                        <option disabled>------------------</option>
                        <option value="new">{t("create_new_team")}</option>
                      </select>
                    </div>
                    {!!team.data?.subscription?.status && false && (
                      <a
                        href="https://www.mercadopago.com.ar/subscriptions"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-link p-3 w-100 text-start"
                      >
                        {t("subscriptions", "Suscripciones")}
                      </a>
                    )}
                    {user.state.isStaff && (
                      <Link to={window._l('/monitor')} className="p-3 btn btn-link w-100 text-start">{t('monitor_mode', 'Monitor')}</Link>
                    )}
                    {!!user.actions.has('teamConfig') && (
                      <button className="p-3 btn btn-link w-100 text-start" onClick={onClickBackup}>
                        {t('backup', 'Backup')}
                      </button>
                    )}
                    <div className="d-flex justify-content-between">
                      <a
                        href={marketplaceUrl}
                        target="_blank"
                        className="flex-fill p-3 btn btn-sm btn-link w-100 text-start text-truncate flex-fill"
                      >
                        {marketplaceUrl.replace(/^http:\/\/|^https:\/\//, "")}
                      </a>
                      <CopyToClipboard text={marketplaceUrl} onCopy={() => null}>
                        <button className="btn btn-sm btn-link link-secondary">
                          {t("copy")}
                        </button>
                      </CopyToClipboard>
                    </div>
                    <div className="d-flex align-items-center justify-content-between bg-light gap-3 px-3">
                      <button
                        className="py-3 px-0 btn btn-link link-danger text-start text-nowrap"
                        onClick={user.actions.logout}
                      >
                        {t("logout")}
                      </button>
                      <div className="py-3 ms-auto text-secondary text-truncate">{user.state.email}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div ref={$offcanvas} className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasEvent" aria-labelledby="offcanvasEventForm">
        <div className="offcanvas-body">
          {event && (
            <EventForm
              time={false}
              day={false}
              reschedule
              onClose={() => {
                try {
                  Offcanvas.getOrCreateInstance($offcanvas.current).hide()
                } catch {}
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}
