async function calculateChecksum(file) {
  try {
    if (!file) return

    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex
  } catch {
    return [file.name, Date.now()].filter(Boolean).join('-')
  }
}

export default calculateChecksum;
