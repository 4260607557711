import { useTranslation } from "react-i18next";
import { Link } from '@reach/router'
import { ReactComponent as IconInfoCircleFill } from 'bootstrap-icons/icons/info-circle-fill.svg'

import WaMe from "Components/WaMe/WaMe";
import getAge from "lib/getAge";
import { formatDate } from 'config/i18n'
import styles from './styles.module.scss'
import getConsumerIdentification from './getConsumerIdentification'

const _getAge = (dob, t) => {
  try {
    const { years, months, days } = getAge(dob);

    let age = [];

    if (years) {
      age.push(years, t("time_distance_years"));
    }

    if (months) {
      age.push(months, t("time_distance_months"));
    }

    if (days) {
      age.push(days, t("time_distance_days"));
    }

    return age.join(" ");
  } catch {
    return "";
  }
};

function Consumer(props) {
  const { t } = useTranslation()

  let identification = getConsumerIdentification(props.consumer?.identification)

  let age = ''
  let dob = ''
  let isBirth = false
  try {
    age = _getAge(props.consumer.dob, t);
    dob = formatDate(props.consumer.dob, "dd-MMM-yyyy")?.toUpperCase();
    isBirth = props.consumer?.dob.slice(5) === new Date().toLocaleDateString("fr-ca").slice(5);
  } catch {}

  let financiers = []
  try {
    financiers = props.consumer.financiers
      .filter(f => f.id)
      .map(f => ({
        id: f.id,
        label:  t(f.id, f.label),
        identification: f.identification?.trim() ? `# ${f.identification}` : ''
      }))
  } catch {}

  let phones = []
  try {
    phones = props.consumer.phones.filter(phone => phone.label)
  } catch {}

  let emails = []
  try {
    emails = props.consumer.emails.filter(email => email.label || email.value)
  } catch {}

  return (
    <div>


    <div className={`${styles['consumer-info']} bg-white shadow-sm d-flex justify-content-between flex-nowrap py-3 px-2 gap-3`}>
      <div data-tip={props.consumer.notes}>
        <div className="fw-bold">
          {t('name', 'Nombre')}
        </div>
        <Link
          data-consumer={JSON.stringify({ id: props.consumer.id })}
          data-bs-toggle="modal"
          data-bs-target="#consumersModal"
          to={props.consumer.id.replace("consumers/", "")}
          className={props.consumer.deleted ? "link-danger" : ""}
        >{!!props.consumer.notes && (
            <IconInfoCircleFill className="text-pink me-1 mt-n1" />
          )} {props.consumer.label}</Link>
      </div>
      {identification && <div><div className="fw-bold">{t('identification', 'DNI')}</div> <div>{identification}</div></div>}
      {age ? (
        <div>
          <div className="fw-bold">
            {isBirth ? (
              t("today_birth_message", "🎉Hoy cumple🎉")
            ) : (
              t('age', 'Edad')
            )}<span className="fw-light ms-1 text-xs">{dob?.replace(/-/g, ' ')}</span>
          </div>
          <div>{age}</div>
        </div>
      ) : (
        <div>
          <div className="fw-bold">
            {t('age', 'Edad')}
          </div>
          <div className="text-first-up text-secondary">{t("not_definied", 'Sin definir')}</div>
        </div>
      )}
      {financiers.map(financier => (
        <div key={`${financier.id}${financier.identification}`}>
          <div className="fw-bold">{financier.label}</div> <div> <span className="text-truncate">{financier.identification}</span></div>
        </div>
      ))}

      {phones.map(phone => (
        <div key={phone.label}>
          <div className="fw-bold">{t('phone', 'Telefono')}</div>
          <WaMe
            className={"text-truncate"}
            key={phone.label}
            phone={phone}
          />
        </div>
      ))}

      {emails.map(email => (
        <div key={email.label || email.value}>
          <div className="fw-bold text-first-up">{t('email', 'email')}</div>
          <div>
            <a href={`mailto:${email.label || email.value}`} target="_blank" rel="noopener noreferrer">{email.label || email.value}</a>
          </div>
        </div>
      ))}

    </div>

    </div>
  )
}


export default Consumer;
