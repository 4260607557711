import create from "zustand";
import { persist } from "zustand/middleware"

const team = window.location.pathname.match(/\/teams\/[a-z0-9]+/i)?.[0]?.replace('/teams/', '')

const callback = (set, get) => ({
  sort: 'date:desc',
  filters: {
    showDeletedRecords: false,
    uids: {},
    treatments: { status: { active: true, pending: true, done: false } },
    diagnoses: { status: { chronic: true, pending: true, intercurrence: true } },
  },
  update: payload => set(() => ({ ...payload })),
})

let useStore

if (team) {
  const options = {
    name: `${team}-ehr-storage`,
    partialize: state => ({
      sort: state.sort,
      filters: state.filters,
    })
  }
  useStore = create(persist(callback, options))
} else {
  useStore = create(callback)
}

export default useStore;
