import { useState, useEffect } from "react";
import { useLocation } from "@reach/router";

const Hook = () => {
  const [state, setState] = useState("");
  const location = useLocation();

  useEffect(() => {
    try {
      const teamID = Array.from(
        location.pathname.match(/^\/teams\/[a-zA-Z0-9]*/)
      )[0].replace("/teams/", "teams/");
      setState(teamID);
    } catch {}
  }, [location.pathname]);

  return state;
};

export default Hook;
