import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import getTimeSlots from "../../../lib/get-time-slots";

const Dropdown = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="dropdown">
        <button className="btn p-0" type="button" data-bs-toggle="dropdown">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-dark"
            width="16"
            height="16"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className="dropdown-menu" style={{ width: "250px" }}>
          <div className="p-3">
            {props.options.agenda && (
              <div className="mb-3">
                <label className="form-label" htmlFor="emailAgenda">
                  {t("agenda_label", { context: "email" })}
                </label>
                <select
                  name="agenda"
                  id="emailAgenda"
                  className="form-select"
                  value={props.value.agenda}
                  onChange={props.onChange}
                >
                  {props.value.agenda === undefined && (
                    <option>{t("agenda_placeholder")}</option>
                  )}
                  <option value="disabled">{t("disabled")}</option>
                  {getTimeSlots(15).map((slot) => (
                    <option key={`emailAgenda${slot}`} value={slot}>
                      {slot}
                    </option>
                  ))}
                </select>
                <div
                  className="form-text"
                  dangerouslySetInnerHTML={{
                    __html: t(`agenda_hint`, { context: "email" }),
                  }}
                />
              </div>
            )}

            {props.options.summary && (
              <div className="mb-3">
                <label className="form-label" htmlFor="emailSummary">
                  {t("summary_label", { context: "email" })}
                </label>
                <select
                  name="summary"
                  id="emailSummary"
                  className="form-select"
                  value={props.value.summary || ""}
                  onChange={props.onChange}
                >
                  {props.value.summary === undefined && (
                    <option>{t("summary_placeholder")}</option>
                  )}
                  <option value="disabled">{t("disabled")}</option>
                  {getTimeSlots(15).map((slot) => (
                    <option key={`emailSummary${slot}`} value={slot}>
                      {slot}
                    </option>
                  ))}
                </select>
                <div
                  className="form-text"
                  dangerouslySetInnerHTML={{
                    __html: t(`summary_hint`, { context: "email" }),
                  }}
                />
              </div>
            )}

            {props.options.event && (
              <div>
                <label className="form-label" htmlFor="emailEveryEvent">
                  {t("event_label", { context: "email" })}
                </label>
                <select
                  name="event"
                  id="emailEveryEvent"
                  className="form-select"
                  value={props.value.event || ""}
                  onChange={props.onChange}
                >
                  {props.value.event === undefined && (
                    <option>{t("event_placeholder")}</option>
                  )}
                  <option value="disabled">{t("disabled")}</option>
                  <option value="public">
                    {t("public", { context: "email" })}
                  </option>
                  <option value="all">{t("all", { context: "email" })}</option>
                </select>
                <div
                  className="form-text"
                  dangerouslySetInnerHTML={{
                    __html: t(`event_hint`, { context: "email" }),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
