import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const App = (props) => {
  const { t } = useTranslation();

  return (
    <input
      type="text"
      value={props.value}
      className={`${styles.input} form-control rounded-0 border-0`}
    />
  );
};

export default App;
