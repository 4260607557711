const ionogramaModel = () => {
  return {
    na: {
      value: "",
      unit: "",
    },
    k: {
      value: "",
      unit: "",
    },
    cl: {
      value: "",
      unit: "",
    },
    mg: {
      value: "",
      unit: "",
    },
    ca: {
      value: "",
      unit: "",
    },
    cai: {
      value: "",
      unit: "",
    },
    p: {
      value: "",
      unit: "",
    },
  };
};

const um = () => {
  return {
    na: ["mEq/L", "mmol/L"],
    k: ["mEq/L", "mmol/L"],
    cl: ["mEq/L", "mmol/L"],
    mg: ["mg/dL", "mmol/L"],
    ca: ["mg/dL", "mmol/L"],
    cai: ["mg/dL", "mmol/L"],
    p: ["mg/dL", "mmol/L"],
  };
};

export { ionogramaModel, um };
