import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Diagnostics from "./Diagnostics";
import Records from "./Records";
import Treatments from "./Treatments";
import VitalSigns from "./VitalSigns";
import Labs from "./Labs";
import "./print.css";
import logo from "./drapplogo.png";

function PrintModal(props) {
  const { t } = useTranslation();

  function _calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const [labMode, setLabMode] = useState("last");
  const [vitalsMode, setVitalsMode] = useState("last");
  const [showBtns, setShowBtns] = useState(true);

  const licenses =
    props.resource &&
    props.resource.licenses &&
    props.resource.licenses
      .map((license) => {
        if (license.jurisdiction === "AR") {
          return `M.N. ${license.value}`;
        } else {
          return `M.P. ${license.value}`;
        }
      })
      .join("-");

  const recordsFiltered = props.records
    .filter((element) => !element.deleted)
    .map((element) => ({ ...element, display: true }));

  const diagnosticsChronicFiltered = props.diagnostics
    .filter((element) => !element.deleted)
    .filter((element) => element.status === "chronic")
    .map((element) => ({ ...element, display: true }));

  const diagnosticsIntercurrenceFiltered = props.diagnostics
    .filter((element) => !element.deleted)
    .filter((element) => element.status === "intercurrence")
    .map((element) => ({ ...element, display: true }));

  const diagnosticsPendingFiltered = props.diagnostics
    .filter((element) => !element.deleted)
    .filter((element) => element.status === "pending")
    .map((element) => ({ ...element, display: true }));

  const activeTreatments = props.treatments
    .filter((element) => !element.deleted)
    .filter((element) => element.status === "active");

  const orderedLabs = props.labs
    .filter((element) => !element.deleted)
    .sort((a, b) => b.startsAt - a.startsAt);

  const vitalSigns = props.vitalSigns
    .filter((element) => !element.deleted)
    .sort((a, b) => b.startsAt - a.startsAt);

  const [printStatus, setPrintStatus] = useState({
    diagnosticsChronic: true,
    diagnosticsIntercurrence: true,
    diagnosticsPending: true,
    diagnosticsNotes: true,
    treatmentsActive: true,
    treatmentsNotes: true,
    records: true,
    individualRecords: [],
    individualDiagnosticsChronic: [],
    individualDiagnosticsIntercurrence: [],
    individualDiagnosticsPending: [],
    individualTreatmentsActive: [],
    lastLab: true,
    orderedLabs: [],
    vitalSigns: true,
    individualVitalSigns: [],
  });

  const download = () => {
    setShowBtns(false);
    let date = new Date();
    window
      .html2pdf()
      .from(document.getElementById("download"))
      .toPdf()
      .save(
        `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}-${
          props.consumer.data.lastName
        }${props.consumer.data.firstName}.pdf`
      );

    setTimeout(() => {
      setShowBtns(true);
    }, 1000);
  };

  useEffect(() => {
    setPrintStatus({
      ...printStatus,
      individualRecords: recordsFiltered,
      individualDiagnosticsChronic: diagnosticsChronicFiltered,
      individualDiagnosticsIntercurrence: diagnosticsIntercurrenceFiltered,
      individualDiagnosticsPending: diagnosticsPendingFiltered,
      individualTreatmentsActive: activeTreatments,
      orderedLabs: orderedLabs,
      individualVitalSigns: vitalSigns,
    });
  }, [
    props.records,
    props.diagnostics,
    props.treatments,
    props.labs,
    props.vitals,
  ]);

  return (
    <div>
      <div className="d-flex align-items-center noPrint py-2">
        <button
          className="btn btn-sm btn-outline-success mx-2"
          onClick={() => {
            window.print();
          }}
        >
          {t("ehr:print")}
        </button>
        <button
          className="btn btn-sm btn-outline-success mx-2"
          onClick={download}
        >
          {t("ehr:download")}
        </button>
        <button
          className="btn btn-sm btn-outline-primary mx-2"
          onClick={() => {
            setPrintStatus({
              diagnosticsChronic: true,
              diagnosticsIntercurrence: true,
              diagnosticsPending: true,
              diagnosticsNotes: true,
              treatmentsActive: true,
              treatmentsNotes: true,
              records: true,
              individualRecords: recordsFiltered,
              individualDiagnosticsChronic: diagnosticsChronicFiltered,
              individualDiagnosticsIntercurrence: diagnosticsIntercurrenceFiltered,
              individualDiagnosticsPending: diagnosticsPendingFiltered,
              individualTreatmentsActive: activeTreatments,
              lastLab: true,
              orderedLabs: orderedLabs,
              vitalSigns: true,
              individualVitalSigns: vitalSigns,
            });
          }}
        >
          {t("ehr:show_all")}
        </button>

        <small className="ms-4 noPrint">{t("notes")}:</small>

        <button
          className={
            printStatus.diagnosticsNotes
              ? "btn btn-link btn-sm text-danger"
              : "btn btn-link btn-sm text-success"
          }
          onClick={() => {
            setPrintStatus({
              ...printStatus,
              diagnosticsNotes: !printStatus.diagnosticsNotes,
            });
          }}
        >
          {printStatus.diagnosticsNotes
            ? t("ehr:hide_in_diagnostics")
            : t("ehr:show_in_diagnostics")}
        </button>

        <button
          className={
            printStatus.treatmentsNotes
              ? "btn btn-link btn-sm text-danger"
              : "btn btn-link btn-sm text-success"
          }
          onClick={() => {
            setPrintStatus({
              ...printStatus,
              treatmentsNotes: !printStatus.treatmentsNotes,
            });
          }}
        >
          {printStatus.treatmentsNotes
            ? t("ehr:hide_in_treatments")
            : t("ehr:show_in_treatments")}
        </button>
      </div>

      <div className={props.className || ""}>
        <div className="modal-body print" id="download">
          <div className="d-flex flex-column mb-4">
            <h2 className="h2 fw-bold mx-4 mt-2">
              {t("ehr")}
              <img src={logo} width="130px" className="mx-4 align-middle"></img>
            </h2>

            <div className="d-flex px-4 justify-content-between mt-3">
              <div className="d-flex flex-column">
                <span className="fw-bold">
                  {props.consumer.data.lastName} {props.consumer.data.firstName}
                </span>
                <small className="fst-italic">
                  DNI {props.consumer.data.identification}
                </small>
                <small>
                  {_calculateAge(new Date(props.consumer.data.dob))} años{" "}
                  {t(props.consumer.data.gender)}
                </small>
                {props.consumer.data.financiers.map((financier) => {
                  return (
                    <span>
                      {financier.label} {financier.identification}
                    </span>
                  );
                })}
              </div>

              <div className="d-flex flex-column">
                <span className="fw-bold">
                  {props.resource && props.resource.label}
                </span>
                <small className="fst-italic">
                  {licenses && licenses.replace(/-/g, " ")}
                </small>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="">
              {printStatus.diagnosticsChronic &&
                printStatus.individualDiagnosticsChronic &&
                printStatus.individualDiagnosticsChronic.length > 0 && (
                  <Diagnostics
                    type="chronic"
                    showBtns={showBtns}
                    array={printStatus.individualDiagnosticsChronic}
                    printStatus={printStatus}
                    setPrintStatus={setPrintStatus}
                  ></Diagnostics>
                )}

              {printStatus.diagnosticsIntercurrence &&
                printStatus.individualDiagnosticsIntercurrence &&
                printStatus.individualDiagnosticsIntercurrence.length > 0 && (
                  <Diagnostics
                    type="intercurrence"
                    showBtns={showBtns}
                    array={printStatus.individualDiagnosticsIntercurrence}
                    printStatus={printStatus}
                    setPrintStatus={setPrintStatus}
                  ></Diagnostics>
                )}

              {printStatus.diagnosticsPending &&
                printStatus.individualDiagnosticsPending &&
                printStatus.individualDiagnosticsPending.length > 0 && (
                  <Diagnostics
                    type="pending"
                    showBtns={showBtns}
                    array={printStatus.individualDiagnosticsPending}
                    printStatus={printStatus}
                    setPrintStatus={setPrintStatus}
                  ></Diagnostics>
                )}
            </div>
          </div>

          <div className="mt-3">
            <div className="p-1">
              {printStatus.treatmentsActive &&
                activeTreatments &&
                activeTreatments.length > 0 && (
                  <Treatments
                    activeTreatments={activeTreatments}
                    showBtns={showBtns}
                    printStatus={printStatus}
                    setPrintStatus={setPrintStatus}
                  ></Treatments>
                )}
            </div>
          </div>

          <div className="mt-3">
            <div className="p-1">
              {printStatus.records &&
                recordsFiltered &&
                recordsFiltered.length > 0 && (
                  <Records
                    showBtns={showBtns}
                    printStatus={printStatus}
                    setPrintStatus={setPrintStatus}
                  ></Records>
                )}
            </div>
          </div>

          <div className="mt-3">
            <div className="p-1">
              {printStatus.vitalSigns && vitalSigns.length > 0 && (
                <VitalSigns
                  vitalsMode={vitalsMode}
                  setVitalsMode={setVitalsMode}
                  setPrintStatus={setPrintStatus}
                  printStatus={printStatus}
                  showBtns={showBtns}
                  vitalSigns={vitalSigns}
                ></VitalSigns>
              )}
            </div>
          </div>

          <div className="mt-3">
            <div className="p-1">
              {printStatus.lastLab && orderedLabs.length > 0 && (
                <Labs
                  labMode={labMode}
                  setLabMode={setLabMode}
                  setPrintStatus={setPrintStatus}
                  printStatus={printStatus}
                  showBtns={showBtns}
                ></Labs>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintModal;
