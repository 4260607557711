import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function VitalSignsBiblio({ text }) {
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    if (!showAlert) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  };

  return (
    <div>
      <button
        className="btn btn-sm btn-link text-secondary mb-3"
        onClick={handleClick}
      >
        {t("bibliography")}
      </button>

      {showAlert && (
        <div>
          {" "}
          <div
            className="form-text"
            dangerouslySetInnerHTML={{
              __html: t(text),
            }}
          />
        </div>
      )}
    </div>
  );
}

export default VitalSignsBiblio;
