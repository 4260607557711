import { useQuery, useQueryClient } from "react-query";

import getAccessToken from "./getAccessToken";

const { REACT_APP_API_URL } = process.env;

const request = (key, accessToken, query, body) => {
  const options = {
    headers: {},
  };
  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }
  let qs = "";
  if (body && body.constructor === Object) {
    options.method = "POST";
    options.headers["Content-Type"] = "application/json";
    options.body = JSON.stringify(body);
  }
  if (query && query.constructor === String) {
    qs = "?" + new URLSearchParams({ q: query }).toString();
  }
  const url = [REACT_APP_API_URL, `${key}${qs}`.replace(/\/\/+/g, "/")].join(
    "/"
  );
  return fetch(url, options).then((res) => res.json());
};

const Hook = (endpoint, options = { enabled: true }) => {
  const accessToken = getAccessToken();
  const queryClient = useQueryClient();

  if (options.enabled === undefined) {
    options.enabled = true;
  }

  const query = useQuery(
    [endpoint, options.query, options.body].filter(Boolean),
    () => request(endpoint, accessToken.value, options.query, options.body),
    {
      ...options,
      refetchOnWindowFocus: false,
      enabled: !!(options.enabled && accessToken.value && endpoint),
      query: undefined,
      body: undefined,
    }
  );

  query.refresh = data => {
    queryClient.cancelQueries([endpoint, options.query, options.body].filter(Boolean));
    queryClient.setQueryData(
      [endpoint, options.query, options.body].filter(Boolean),
      JSON.clone(data)
    );
  };

  return query;
};

export default Hook;
