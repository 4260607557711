import { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from 'react-tooltip'
import { ReactComponent as IconArrowRepeat } from 'bootstrap-icons/icons/arrow-repeat.svg'

import { formatDate } from 'config/i18n'
import Treatment from './Form/Treatment'
import Diagnostic from './Form/Diagnostic'
import VitalSigns from './Form/VitalSigns'
import Row from './Row'

const clone = window.structuredClone || JSON.clone

function Table(props) {
  const { t } = useTranslation()

  const [record, setRecord] = useState()
  const [editable, setEditable] = useState()
  const [data, setData] = useState()
  const [values, setValues] = useState()
  const [orientation, setOrientation] = useState(props.data.setup.orientation || 'vertical')

  let onClick = (recordID, editable) => {
    let record = clone(props.data.records[recordID])
    if (!record || !['treatments', 'diagnostics', 'vitalSigns'].includes(record.type)) return
    setEditable(!!editable)
    setRecord(record)
  }

  let onSave = record => {
    if (props.onSave) props.onSave(record)
  }

  useEffect(() => {
    setData(clone(props.data))
  }, [props.data])

  useEffect(() => {
    if (data && data.values) {
      const values = Object.values(data.values)
      setValues(values)
    }
  }, [data])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!data || !values) return null

  const allNumbers = !!data.setup.allNumbers
  const borderless = !!data.setup.borderless

  return (
    <>
      {!!record?.id && (
          <div className="position-fixed top-0 bottom-0 start-0 end-0" style={{ zIndex: 1000, background: 'rgba(0, 0, 0, 0.85)' }}>
            <div
              className="overflow-hidden d-flex flex-column justify-content-center align-items-center"

            >
              <div className="p-3 pb-2 mt-3 rounded bg-white">
                {record.type === 'treatments' ? (
                  <Treatment
                    value={{ ...record }}
                    editable={editable}
                    onClose={() => setRecord()}
                    onSave={onSave}
                  />
                ) : record.type === 'diagnostics'  ? (
                  <Diagnostic
                    value={{ ...record }}
                    editable={editable}
                    onClose={() => setRecord()}
                    onSave={onSave}
                  />
                ) : record.type === 'vitalSigns'  ? (
                  <VitalSigns
                    value={{ ...record }}
                    editable={editable}
                    onClose={() => setRecord()}
                    onSave={onSave}
                  />
                ) : null}
              </div>
            </div>

          </div>
        )}

      <div>
        <div className={`d-flex align-items-center gap-1 bg-white ${borderless ? '' : 'p-2 border-top border-start border-end'}`}>
          <button disabled={data.ids.length <= 0} data-tip={t('ehr:rotate_table', 'Rotar tabla')} className="btn btn-sm py-0 px-1 bg-light btn-link" onClick={() => setOrientation(orientation === 'vertical' ? 'horizontal' : 'vertical')}>
            <IconArrowRepeat />
          </button>
          <div className="text-black fw-semibold text-first-up">{props.label}</div>
        </div>
        {data.ids.length > 0 && (
          <>
            {orientation === 'horizontal' ? (
              <table className={`table bg-white table-hover mb-0 ${borderless ? 'table-borderless' : 'table-bordered'}`}>
                <tbody>
                  <tr>
                    <td className="text-secondary text-xs">{t(`date`, 'Fecha')}</td>
                    {values.map(cells => (
                      <td key={cells.id} className="text-secondary text-xs">
                        {t(`ehr:${cells.id}`, cells.label || cells.id)}
                      </td>
                    ))}
                  </tr>
                  {data.ids.map((id, index) => (
                    <Fragment key={id}>
                      <tr className="align-middle">
                        <td
                          key={data.headers.date.items[index].id}
                          style={{ width: '85px', minWidth: '85px', maxWidth: '85px' }}
                          className="text-center p-0"
                        >
                          <button
                            className="w-100 h-100 border-0 btn btn-sm text-truncate font-monospace"
                            data-tip={formatDate(data.headers.date.items[index].value, "dd-MMM-yyyy")}
                            onClick={() => onClick(id, data.headers.date.items[index].editable)}
                          >
                            <span className="border-bottom">
                              {formatDate(data.headers.date.items[index].value, "dd-MMM").toUpperCase()}
                            </span>
                          </button>
                          <small className="d-none d-print-block">
                            {formatDate(data.headers.date.items[index].value, "dd-MMM").toUpperCase()}
                          </small>
                        </td>
                        {values.map(cells => (
                          <td
                            style={allNumbers ? { width: '85px', minWidth: '85px', maxWidth: '85px' } : undefined}
                            className={`text-xs ${cells.items[index]?.class || ''} ${allNumbers ? 'font-monospace text-center' : 'text-truncate'}`}
                            data-tip={cells.items[index]?.message}
                            key={`${cells.id}-${index}`}

                            dangerouslySetInnerHTML={{
                              __html: cells.items[index]?.value || '',
                            }}
                          />
                        ))}
                      </tr>
                      <tr className="d-none d-print-table-row">
                        <td colSpan={values.length + 1}>
                          <div className="d-flex gap-2 align-items-center">
                            {!!data.records?.[id]?.createdByName && (
                              <div>{data.records[id].createdByName}</div>
                            )}
                            {!!data.records?.[id]?.createdByLicense && (
                              <div className="text-secondary">{data.records[id].createdByLicense}</div>
                            )}
                            {!!data.records?.[id]?.createdBy && (
                              <div className="text-primary">{data.records[id].createdBy}</div>
                            )}
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className={`table table-hover mb-0 ${borderless ? 'table-borderless' : 'table-bordered'}`}>
                <tbody>
                  <tr className="bg-white">
                    <td></td>
                    {Array.isArray(data.headers.date?.items) && data.headers.date.items.map(date => (
                      <td
                        key={date.id}
                        style={allNumbers ? { width: '85px', minWidth: '85px', maxWidth: '85px' } : undefined}
                        className="text-center p-0"
                      >
                        <button
                          className="w-100 h-100 border-0 btn btn-sm text-truncate font-monospace"
                          data-tip={formatDate(date.value, "dd-MMM-yyyy")}
                          onClick={() => onClick(date.id, date.editable)}
                        >
                          <span className="border-bottom">
                            {formatDate(date.value, "dd-MMM").toUpperCase()}
                          </span>
                        </button>
                        <small className="d-none d-print-block">
                          {formatDate(date.value, "dd-MMM").toUpperCase()}
                        </small>
                      </td>
                    ))}
                    {allNumbers && <td></td>}
                  </tr>
                  {values.map(cells => (
                    <Row
                      key={cells.id}
                      allNumbers={allNumbers}
                      cells={cells}
                      setupID={data.setup?.id}
                    />
                  ))}
                  <tr className="d-none d-print-table-row">
                    <td></td>
                    {data.ids.map(id => (
                      <td key={id}>
                        <div className="d-flex flex-column gap-1 lh-1 text-nowrap">
                          {!!data.records?.[id]?.createdByName && (
                            <small>{data.records[id].createdByName}</small>
                          )}
                          {!!data.records?.[id]?.createdByLicense && (
                            <small className="text-secondary">{data.records[id].createdByLicense}</small>
                          )}
                          {!!data.records?.[id]?.createdBy && (
                            <small className="text-primary">{data.records[id].createdBy}</small>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Table;
