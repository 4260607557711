import { useState, useRef, useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Dropdown } from "bootstrap";
import ReactTooltip from "react-tooltip";

import styles from "../styles.module.scss";
import useStore from "../../useStore";
import { formatDate } from "../../../../config/i18n";
import Form from "../../Form/Form";
import Info from "../../Info";

const today = new Date()

const EventSlot = ({ event }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const $dropdown = useRef(null);
  const store = useStore(state => ({
    reschedule: state.reschedule,
    event: state.event,
    free: state.free,
    cancelled: state.cancelled,
    noshow: state.noshow,
    slot: state.slot,
    update: state.update
  }))

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const hideDropdown = () => {
    if (show) try {
      Dropdown.getInstance($dropdown.current).hide();
    } catch {
      new Dropdown($dropdown.current).hide();
    }
  }

  const toggle = () => {
    if (store.reschedule && store.event?.id) {
      const date = formatDate(`${event.day}T${event.time}:00.000`, `HH:mm - EEEE d - yyyy`)
      if (window.confirm(t('reschedule_event_confirmation', `Vas a reprogramar el turno ${store.event.consumer.label} a ${date}`))) {
        store.reschedule({ day: event.day, time: event.time })
      }
      if (store.event.id !== event.id || event.status === "cancelled") return
    }

    hideDropdown()

    setShow(!show);
  };

  if (store.free && event.id) return null;
  if (event.status === "cancelled" && !store.cancelled) return null;
  if (event.status === "noshow" && !store.noshow) return null;

  return (
    <div
      key={`slot-event-${event.id || event.updatedAt}`}
      className={cx(`${styles.slot} link position-relative dropdown`, {
        [styles.past]: today.getTime() >= event.datetime,
        [styles.current]: event.id === store.event?.id,
        [styles.reschedule]: store.reschedule && store.event?.id !== event.id
      })}
    >
      <button
        onClick={toggle}
        data-bs-toggle={store.reschedule && event.id !== store.event?.id ? undefined : 'dropdown'}
        data-bs-auto-close={store.reschedule && event.id !== store.event?.id ? undefined : 'false'}
        ref={$dropdown}
        className={`btn btn-sm d-block w-100 rounded-0 p-1 overflow-hidden h-100 d-flex shadow-0 text-start ${
          event.status === "cancelled" ? "opacity-50" : ""
        }`}
      >
        <div className="m-0 mx-2">
          <div
            className={cx(styles.time, {
              "link-secondary": !event.overbooking,
              "link-danger": !!event.overbooking,
            })}
          >
            {event.time || event.range?.split('-')?.map((range, index) => (
              <div key={range} className={index > 0 ? 'mt-n1' : ''}>{range}</div>
            ))}
          </div>

          <div className="d-flex justify-content-center align-items-center gap-1 mt-n1">
            <div>
              <Info event={event} className="text-secondary opacity-75" />
            </div>
            {event.remote && (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  className="bi text-secondary bi-camera-video-fill ms-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
                  />
                </svg>
              </div>
            )}
            {event.source && event.source !== "drmanager" && (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="bi ms-1"
                  data-tip={t(`source_${event.source}`)}
                  height="16"
                  width="16"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-secondary"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>

        {store.reschedule && store.event?.id !== event.id && (
          <div className={`${styles.candidate} text-dark align-self-center flex-fill ms-n3 text-center`}>
            <span className="badge bg-dark">{t(`reschedule_here`, 'Reprogramar aca')}</span>
          </div>
        )}

        <div className={`overflow-hidden flex-fill ${store.reschedule && store.event?.id !== event.id ? styles.hideme : ''}`}>
          {event.type === "lock" ? (
            <div>
              <div
                className={cx({
                  "text-secondary text-decoration-line-through":
                    event.status === "cancelled",
                  "text-danger": event.status !== "cancelled",
                })}
              >
                {t("lock")}
              </div>
              <div className="text-secondary m-0 text-truncate small">
                {event.time} -{" "}
                {event.untilDay === event.day
                  ? event.untilTime
                  : formatDate(
                      `${event.untilDay}T${event.untilTime}`,
                      "EEE d MMM - HH:mm"
                    )}
              </div>
            </div>
          ) : (
            <>
              <div
                className={cx("text-truncate", {
                  "text-decoration-line-through text-secondary":
                    event.status === "cancelled",
                  "text-primary": event.status !== "cancelled",
                })}
              >
                {event.consumer.label}
              </div>
              {store.reschedule && store.event?.id === event.id ? (
                <div className="badge bg-warning text-dark">
                  {t('reschedule_inprogress', 'Reprogramando')}
                </div>
              ) : (event.duration || event.service.duration) && event.compute ? (
              <div className="text-secondary small text-truncate">
                <span>{event.duration || event.service.duration}'</span> -{" "}
                {t(event.service.key, event.service.label)}
              </div>
            ) : (
              <div className="text-secondary small text-truncate">
                {t(event.service.key, event.service.label)}
              </div>
            )}

            </>
          )}
        </div>

        {event.type === "lock" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="opacity-75 text-danger"
          >
            <path
              fillRule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </button>
      <div className={`dropdown-menu border-0 p-0 m-0 ${styles.form}`}>
        {show && (
          <div className={`shadow-lg bg-white rounded p-3`}>
            <Form
              eventID={event.id} onClose={toggle}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Slot = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const $dropdown = useRef(null);
  const store = useStore(state => ({
    reschedule: state.reschedule,
    event: state.event,
    free: state.free,
    cancelled: state.cancelled,
    noshow: state.noshow,
    slot: state.slot
  }))

  const toggle = () => {
    if (store.reschedule && store.event?.id) {
      const date = formatDate(`${props.day}T${props.time}:00.000`, `HH:mm - EEEE d - yyyy`)
      if (window.confirm(t('reschedule_event_confirmation', `Vas a reprogramar el turno ${store.event.consumer.label} a ${date}`))) {
        store.reschedule({ day: props.day, time: props.time })
      }
      return
    }
    if (show) {
      try {
        Dropdown.getInstance($dropdown.current).hide();
      } catch {
        new Dropdown($dropdown.current).hide();
      }
    }
    setShow(!show);
  };

  const childs = props.events.map((event) => (
    <EventSlot key={`slot-event-${event.id}`} event={event} />
  ));

  const isPast = Date.now() >= (new Date(`${props.day}T${props.time}`).getTime() + today.getTimezoneOffset() * 60 * 1000);
  const capacity = props.options?.capacity || 0

  const datetime = Date.parse(`${props.day}T${props.time}`)
  const occupation = props.occupation.reduce((sum, e) => {
    if (e[0] <= datetime && datetime < e[1]) return sum + 1
    return sum
  }, 0)
  if (capacity - occupation > 0) {
    const isCurrent = props.day === store.slot?.day && props.time === store.slot?.time;
    childs.push(
      <div
        key={`slot-${props.day}T${props.time}`}
        className={cx(`${styles.slot} position-relative dropdown ps-2`, {
          [styles.past]: isPast,
          [styles.current]: isCurrent,
          [styles.reschedule]: store.reschedule && store.event?.id
        })}
      >
        <button
          onClick={toggle}
          data-bs-toggle={store.reschedule ? undefined : 'dropdown'}
          data-bs-auto-close={store.reschedule ? undefined : 'false'}
          ref={$dropdown}
          className="btn btn-sm link-secondary d-block w-100 rounded-0 p-1 overflow-hidden h-100 d-flex shadow-0"
        >
          <div className={`${styles.time} opacity-75`}>{props.time}</div>
          {store.reschedule && (
            <div className={`${styles.candidate} text-dark align-self-center flex-fill ms-n3`}>
              <span className="badge bg-dark">{t(`reschedule_here`, 'Reprogramar aca')}</span>
            </div>
          )}
        </button>
        <div
          className={`dropdown-menu border-0 p-0 shadow-lg bg-white rounded p-3 ${styles.form}`}
        >
          {show && (
            <Form
              isSlot
              slot={{ day: props.day, time: props.time }}
              onClose={toggle}
            />
          )}
        </div>
      </div>
    );
  }

  return childs;
};

export default Slot;
