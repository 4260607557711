import { useEffect, useState, useMemo, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { ReactComponent as IconChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg'
import { ReactComponent as IconTrash } from 'bootstrap-icons/icons/trash.svg'
import { ReactComponent as IconArrowClockwise } from 'bootstrap-icons/icons/arrow-clockwise.svg'
import { ReactComponent as IconInfoCircle } from 'bootstrap-icons/icons/info-circle.svg'
import { ReactComponent as IconJournalText } from 'bootstrap-icons/icons/journal-text.svg'
import ReactTooltip from 'react-tooltip'
import debounce from 'lodash.debounce'

import getAccessToken from "hooks/getAccessToken";
import parseDate from 'lib/parseDate'
import useMutation from "hooks/useMutation";
import Status from './Status'
import Calendar from './Calendar'

const App = props => {
  const { t } = useTranslation()
  const [state, setState] = useState({ ...props.state })
  const [loading, setLoading] = useState()
  const [mutate, mutation] = useMutation(window._l(`/records`).substr(1));
  const $collapse = useRef(null)
  /* WORKAROUND ya que cuando creas un nuevo registro hace el POST sin el access token */
  const token = getAccessToken()

  const onChange = payload => {
    if (!state) return
    const record = { ...state, ...payload }
    if (payload.status && payload.status === 'done' && (!record.endsAt || parseDate(record.endsAt).getTime() >= Date.now())) {
      record.endsAt = new Date().toLocaleDateString('fr-ca')
    } else if (payload.status && payload.status !== 'done' && record.endsAt && parseDate(record.endsAt).toLocaleDateString('fr-ca') <= new Date().toLocaleDateString('fr-ca')) {
      record.endsAt = null
    }
    setState(record)
    setLoading(true)
    debouncedChangeHandler(record)
  }

  const save = record => {
    setLoading(false)
    mutate(record, {
      onSuccess: data => props.onChange({ ...data, ...record })
    })
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(save, 2000)
    , [token.value]
  );

  const toggle = () => {
    const deleted = !(state.deleted ?? false)
    if (state.id && deleted && !window.confirm(t('ehr:remove_record', 'Confirmas que queres borrar el registro', { context: state.type }))) {
      return
    }
    onChange({ deleted })
  }

  useEffect(() => {
    setState({ ...props.state })
  }, [props.state])

  useEffect(() => {
    setState({ ...props.state })
    if (!props.state?.id && token.value) {
      setLoading(true)
      save(props.state)
    }
  }, [props.state, token.value])

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const options = [
    {
      value: 'active',
      label: t('active', 'En curso', { context: 'treatments' }),
      description: t('active_description_treatments', 'Trataminento en curso'),
      color: 'primary'
    },
    {
      value: 'pending',
      label: t('pending', 'Sin iniciar', { context: 'treatments' }),
      description: t('pending_description_treatments', 'El tratamiento no todavia no fue iniciado'),
      color: 'warning'
    },
    {
      value: 'done',
      label: t('done', 'Finalizado', { context: 'treatments' }),
      description: t('done_description_treatments', 'El tratamiento fue finalizado'),
      color: 'success'
    },
  ]

  const label = state.label || [state.drug, state.product, state.presentation].filter(Boolean).join(' - ')
  let tip = `<dt>${t('status', 'Estado')}</dt><dd>${t(state.status || 'active')}</dd>`
  if (state.notes) {
    tip += `<dt>${t('notes', 'Notas')}</dt><dd>${state.notes}</dd>`
  }
  if (state.frecuency) {
    tip += `<dt>${t('frecuency', 'Frecuencia')}</dt><dd>${state.frecuency}</dd>`
  }
  if (state.dose) {
    tip += `<dt>${t('dose', 'Dosis')}</dt><dd>${state.dose}</dd>`
  }
  if (state.startsAt) {
    tip += `<dt>${t('startsAt', 'Inicio')}</dt><dd>${parseDate(state.startsAt).toLocaleDateString()}</dd>`
  }
  if (state.endsAt) {
    tip += `<dt>${t('endsAt', 'Fin')}</dt><dd>${parseDate(state.endsAt).toLocaleDateString()}</dd>`
  }
  if (state.date || state.createdAt) {
    tip += `<dt>${t('date', 'Cargado')}</dt><dd>${parseDate(state.date || state.createdAt).toLocaleDateString()}</dd>`
  }
  tip = `<dl>${tip}</dl>`

  return (
    <div className="position-relative border-top">
      {loading && <div className="loading loading-top loading-sm" />}
      <div className="d-flex justify-content-between align-items-center gap-2 p-3 bg-white">
        <button className={`btn d-flex align-items-center gap-2 p-0 ${state.deleted ? 'link-danger' : 'link-dark'}`} data-bs-target={`#collapse${state.id?.replace('/', '-')}`} data-bs-toggle="collapse">
          {loading && (
            <span className="spinner-border spinner-border-sm text-primary me-1" />
          )}
          <IconChevronExpand width="14" height="14" className="link-primary" />
          {tip && (
            <IconInfoCircle width="14" height="14" className="text-teal" data-tip={tip} />
          )}
          {state.notes && (
            <IconJournalText width="14" height="14" className="text-teal" data-tip={state.notes}/>
          )}
          {label}
        </button>
        <div className="text-secondary small">
          {t(state.status || 'active', { context: 'treatments' })}
        </div>

      </div>
      <div className="collapse" ref={$collapse} id={`collapse${state.id?.replace('/', '-')}`}>
        <div className="p-3 d-flex flex-column border-top gap-3 shadow-sm bg-light mb-4 rounded-bottom">
          <div className="d-flex flex-column flex-xxl-row gap-2 align-items-xxl-center">
            <div className="flex-fill form-floating">
              <input
                disabled={loading && !state.id}
                value={state.notes || ''}
                onChange={e => onChange({ notes: e.target.value })}
                type="text"
                className="form-control focus-bg-light"
                id="notes"
                name="notes"
                placeholder={t("notes_placeholder_short", "Notas")}
              />
              <label>{t("notes_placeholder_short", "Notas")}</label>
            </div>
            <div className="flex-shrink-1 form-floating">
              <input
                disabled={loading && !state.id}
                value={state.frecuency || ''}
                onChange={e => onChange({ frecuency: e.target.value })}
                type="text"
                className="form-control focus-bg-light"
                id="frecuency"
                name="frecuency"
                placeholder={t("frecuency_placeholder", "Frecuencia")}
              />
              <label>{t("frecuency_placeholder", "Frecuencia")}</label>
            </div>
            <div className="flex-shrink-1 form-floating">
              <input
                disabled={loading && !state.id}
                value={state.dose || ''}
                onChange={e => onChange({ dose: e.target.value })}
                type="text"
                className="form-control focus-bg-light"
                id="dose"
                name="dose"
                placeholder={t("dose_placeholder", "Dosis")}
              />
              <label>{t("dose_placeholder", "Dosis")}</label>
            </div>

            <div className="d-flex justify-content-between flex-fill">
              <Status
                disabled={loading && !state.id}
                label={t('status', 'Estado')}
                value={{
                  value: state.status ,
                  label: t(state.status || 'active')
                }}
                onChange={status => onChange({ status })}
                options={options}
              />

              <Calendar
                disabled={loading && !state.id}
                label={t('date', 'Fecha')}
                tip={t('treatment_date_tip', 'Fecha de carga')}
                value={{
                  value: state.date || state.createdAt,
                  label: parseDate(state.date || state.createdAt)?.toLocaleDateString()
                }}
                onChange={date => onChange({ date: date?.toLocaleDateString('fr-ca') || "" })}
              />

              <Calendar
                disabled={loading && !state.id}
                label={t('startsAt', 'inicio')}
                tip={t('treatment_startsAt_tip', 'Fecha de inicio del tratamiento')}
                value={{
                  value: state.startsAt,
                  label: parseDate(state.startsAt)?.toLocaleDateString() || t('undefined', 'Indefinida', { context: 'female' })
                }}
                onChange={date => onChange({ startsAt: date?.toLocaleDateString('fr-ca') || "" })}
              />

              <Calendar
                disabled={loading && !state.id}
                label={t('endsAt', 'inicio')}
                tip={t('treatment_endsAt_tip', 'Fecha de finalizacion del tratamiento')}
                value={{
                  value: state.endsAt,
                  label: parseDate(state.endsAt)?.toLocaleDateString() || t('undefined', 'Indefinido', { context: 'male' })
                }}
                onChange={date => onChange({ endsAt: date?.toLocaleDateString('fr-ca') || "" })}
              />
            </div>
            <div>
              <button
                disabled={loading && !state.id}
                data-tip={state.deleted ? t('restore', 'Restaurar') : undefined}
                className={state.deleted ? 'btn link-success' : 'btn link-danger'}
                onClick={toggle}
              >
                {state.deleted ? (
                  <>
                    <IconArrowClockwise className="opacity-75" width="16" height="16" />
                  </>
                ) : (
                  <>
                    <IconTrash className="opacity-75" width="16" height="16" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
