import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "@reach/router";

import useMutation from "hooks/useMutation";
import useUser from "hooks/useUser";
import useStoreGlobal from "../../../useStore";
import WaMe from "Components/WaMe/WaMe";
import useTicker from "./useTicker";
import Form from "../../Form/Form";
import Info from "../../Info";
import useStoreRooms from "App/Room/useStore";
import { formatDate } from "config/i18n";

const {
  REACT_APP_MEET_URL,
  REACT_APP_API_URL
} = process.env

const waMessage = (event, t) => {
  try {
    const today = new Date().toLocaleDateString("fr-ca");
    const tomorrow = new Date(
      Date.now() + 24 * 60 * 60 * 1000
    ).toLocaleDateString("fr-ca");
    let date
    if (today === event.day) {
      date = `${t('wa_reminder_helper_today', 'dia de hoy a las')} ${event.time}`
    } else if (tomorrow === event.day) {
      date = `${t('wa_reminder_helper_tomorrow', 'dia de mañana a las')} ${event.time}`
    } else  {
      date = formatDate(new Date(`${event.day}T${event.time}`), "EEEE d MMMM yyyy HH:mm")
    }
    const [lastName, firstName] = event.consumer.label.split(',').map(p => p.trim());
    const consumer = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    const phone = event.consumer.phones?.split('/')[0].trim();
    const service = event.service.label.replace('/', '-');
    const resource = event.resource.label.replace(/,/g, '')
    const location = event.location?.address || ''
    const link = [REACT_APP_MEET_URL, event.id.replace('events/', ''), event.token].filter(Boolean).join('/')
    const payload = {
      icon1: '',
      icon2: '',
      consumer,
      date,
      resource,
      service,
      location,
      link,
      time: event.time,
      day: event.day,
    }

    const prefix = event.remote ? 'remote' : ''
    const key = ['wa', prefix, 'reminder', 'mobile'].filter(Boolean).join('_')
    return t(key, payload)
  } catch {}
}

function Event({ event, style }) {
  const { t } = useTranslation();
  const location = useLocation();
  const user = useUser();
  const distance = useTicker(event);
  const navigate = useNavigate();
  const openRoom = useStoreRooms((state) => state.open);
  const [form, setForm] = useState(false);
  const storeGlobal = useStoreGlobal()

  const [mutate] = useMutation(window._l(`/${event.id}`).substr(1), {
    onError: (error) => {
      window.notify({
        message: error.message,
        type: "danger",
        title: "Error",
      });
    },
  });

  const hasEhr = user.actions.has("ehr");
  let due = event.pricing?.total

  if (event.mercadopago?.payment?.status === 'approved') {
    due = event.pricing?.total - (event.mercadopago?.payment?.transaction_amount || 0)
  }

  const changeStatus = (status) => {
    if (event.status === status) return;
    const payload = JSON.clone(event);
    payload.states = payload.states || [];
    payload.states.push({ createdAt: Date.now(), status });
    payload.status = status;
    mutate(payload);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  let identification;

  if (!isNaN(parseInt(event.consumer?.identification)))
    try {
      identification = parseInt(
        event.consumer.identification.toString().replace(/\D/g, "")
      )?.toLocaleString("es-AR");
    } catch {}

  const phones = event.consumer?.phones?.split("/")?.filter(p => p?.trim()) || [];

  const today = new Date().toLocaleDateString("fr-ca");

  const waText = waMessage(event, t)

  return (
    <div
      className={cx(`d-flex align-items-center gap-4 px-1 py-2`, {
        "bg-warning bg-gradient bg-opacity-25 shadow-sm": event.status === "arrived",
        "bg-primary bg-gradient bg-opacity-25 shadow-sm": event.status === "active",
        "bg-success bg-gradient bg-opacity-25 shadow-sm": event.status === "fulfilled",
        "bg-danger bg-gradient bg-opacity-25 shadow-sm": ["cancelled", "noshow"].includes(event.status),
        "bg-white border-bottom": ["booked", "pending"].includes(event.status),
      }) }
        style={{ width: `calc(100% - ${storeGlobal.sidebar ? '280px' : '0px' })`, height: 100 }}>
      <div style={{ width: "125px" }} className="py-2 text-center">
        <div className="d-flex align-items-center justify-content-center gap-1 mb-1">
          <div className="mt-n1">
            <Info event={event} />
          </div>
          {(event.instructions || event.comments) && (
            <div>
              <svg
                data-tip={[event.comments, event.instructions]
                  .filter(Boolean)
                  .join("<hr />")}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                className="text-secondary bi bi-chat-left-text-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"
                />
              </svg>
            </div>
          )}
          {event.source !== "drmanager" && (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="bi"
                data-tip={t(`source_${event.source}`)}
                height="16"
                width="16"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
          <button
            className={cx("btn text-truncate p-0", {
              "link-danger": event.overbooking,
              "link-primary": !event.overbooking,
            })}
            onClick={() => setForm(!form)}
          >
            {event.time || event.range?.split('-')?.join(' ') || t('no_time_setup', 'Turno sin horario')}
          </button>
        </div>
        {form && (
          <div
            style={{ width: "390px", zIndex: 1000 }}
            className={`p-3 mt-2 border shadow-lg rounded bg-white position-absolute text-start`}
          >
            <Form
              eventID={event.id}
              onClose={() => setForm(false)}
              onChange={(newEvent) => {
                setForm(false);
              }}
            />
          </div>
        )}
        {distance && (
          <div className="text-secondary text-xs lh-1 text-truncate">{distance}</div>
        )}
        <div className="text-secondary text-truncate rounded mt-1 lh-1 text-xs">
          {t(`status_${event.status}`, { context: "events" })}
        </div>
      </div>
      <div style={{ width: "320px" }} className="py-2 text-truncate">
        <Link
          className="link-dark"
          type="button"
          data-consumer={JSON.stringify({ id: event.consumer.id })}
          data-bs-toggle="modal"
          data-bs-target="#consumersModal"
          to={window._l(`/${event.consumer.id}`)}
        >
          {event.consumer.label}
        </Link>
        <div className="text-truncate d-flex gap-3 align-items-center small opacity-75">
          {phones.map((phone, index) => (
            <WaMe
              key={`${index}`}
              text={waText}
              phone={phone}
            />
          ))}
          {identification && <span className="text-secondary">{identification}</span>}
        </div>
      </div>
      <div style={{ width: "170px" }} className="d-none d-lg-block py-2">
        <div className="text-truncate small">
          {t(event.financier.key, event.financier.label, { excludeSuffix: true })}
        </div>
        {event.financier.identification && (
          <div className="text-truncate">
            <small className="form-text">{event.financier.identification}</small>
          </div>
        )}
        {event.pricing && (
          !due ? (
            <div className="small text-success">
              ${event.pricing?.total} - <span className="text-capitalize">{t('paid', 'Pagado')}</span>
            </div>
          ) : (
            <a target="_blank" rel="noopener noreferrer" data-tip={t('event_open_payment_link', "Abrir link de pago en una nueva pestaña")} href={`${REACT_APP_API_URL}/${event.id}/pay`} className="small text-danger">
              <span className="text-capitalize">{t('collect_pending_payment', 'Cobrar')}</span> ${due}
            </a>
          )
        )}
      </div>
      <div className="d-none d-lg-block py-2 overflow-hidden flex-fill">
        <div className="text-truncate">
          {t(event.service.key, event.service.label)
            .split("/")
            .reverse()
            .map((key, index) => (
              <span
                key={key}
                className={index !== 0 ? "small text-secondary" : "me-2"}
              >
                {key}
              </span>
            ))}
        </div>
        <div>
          <small>{event.resource.label}</small>
          {!event.remote && (
            <small className="text-secondary ms-2">{event.location.label}</small>
          )}
        </div>
      </div>
      <div className="d-flex flex-column ms-auto">
        {event.remote && (
          <button
            className="btn btn-sm bg-light link-primary mb-1 d-flex justify-content-between align-items-center"
            onClick={() => openRoom(`${event.id}/${event.token}`)}
          >
            <div>{t("open_remote", "Videconsulta")}</div>
            <div>&rarr;</div>
          </button>
        )}
        {today === event.day && (
          <>
            {hasEhr && ["booked", "arrived"].includes(event.status) ? (
              <button
                className="btn btn-sm bg-light link-primary mb-1 d-flex justify-content-between align-items-center"
                onClick={() => {
                  changeStatus("active");
                  navigate(
                    window._l(
                      `/${event.consumer.id}/ehr?eventID=${
                        event.id.split("/")[1]
                      }`
                    )
                  );
                }}
              >
                <div>{t("start", { context: "agenda" })}</div>
                <div>&rarr;</div>
              </button>
            ) : (
              hasEhr &&
              event.status === "active" && (
                <Link
                  className="btn btn-sm bg-light link-primary mb-1 d-flex justify-content-between align-items-center"
                  to={window._l(
                    `/${event.consumer.id}/ehr?eventID=${
                      event.id.split("/")[1]
                    }`
                  )}
                >
                  <div>{t("ehr", { context: "agenda" })}</div>
                  <div>&rarr;</div>
                </Link>
              )
            )}
            <div className="dropdown d-flex align-items-center">
              {event.status === "booked" ? (
                <button
                  className="flex-fill btn btn-sm bg-light link-primary text-truncate text-start d-flex align-items-center justify-content-between"
                  onClick={() => changeStatus("arrived")}
                  type="button"
                >
                  <div>
                    {t(`status_${event.status}`, { context: "events" })}
                  </div>
                  <div>&rarr;</div>
                </button>
              ) : event.status === "arrived" ? (
                <button
                  className="flex-fill btn btn-sm bg-light link-primary text-truncate text-start d-flex align-items-center justify-content-between"
                  onClick={() => changeStatus("active")}
                  type="button"
                >
                  <div>
                    {t(`status_${event.status}`, { context: "events" })}
                  </div>
                  <div>&rarr;</div>
                </button>
              ) : event.status === "active" ? (
                <button
                  className="flex-fill btn btn-sm bg-light link-success text-truncate text-start d-flex align-items-center justify-content-between"
                  onClick={() => changeStatus("fulfilled")}
                  type="button"
                >
                  <div>
                    {t(`status_${event.status}`, { context: "events" })}
                  </div>
                  <div>&rarr;</div>
                </button>
              ) : event.status === "fulfilled" ? (
                <button
                  className="flex-fill btn btn-sm bg-light link-success d-flex text-truncate text-start d-flex align-items-center justify-content-between"
                  type="button"
                >
                  {t(`status_${event.status}`, { context: "events" })}
                </button>
              ) : (
                <button
                  className="flex-fill btn btn-sm bg-light link-success text-truncate text-start d-flex align-items-center justify-content-between"
                  type="button"
                >
                  {t(`status_${event.status}`)}
                </button>
              )}
              <button
                type="button"
                className="btn btn-sm bg-light link-dark dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">
                  {t("toggle_dropdown")}
                </span>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <h6 className="dropdown-header">
                    {t("change_event_status")}
                  </h6>
                </li>
                <li>
                  <button
                    className={cx("btn btn-sm dropdown-item", {
                      "fw-bold": event.status === "booked",
                    })}
                    onClick={() => changeStatus("booked")}
                  >
                    {t("status_booked", { context: "events" })}
                  </button>
                </li>
                <li>
                  <button
                    className={cx("btn btn-sm dropdown-item", {
                      "fw-bold": event.status === "arrived",
                    })}
                    onClick={() => changeStatus("arrived")}
                  >
                    {t("status_arrived", { context: "events" })}
                  </button>
                </li>
                <li>
                  <button
                    className={cx("btn btn-sm dropdown-item", {
                      "fw-bold": event.status === "active",
                    })}
                    onClick={() => changeStatus("active")}
                  >
                    {t("status_active", { context: "events" })}
                  </button>
                </li>
                <li>
                  <button
                    className={cx("btn btn-sm dropdown-item", {
                      "fw-bold": event.status === "fulfilled",
                    })}
                    onClick={() => changeStatus("fulfilled")}
                  >
                    {t("status_fulfilled", { context: "events" })}
                  </button>
                </li>
                <li>
                  <button
                    className={cx("btn btn-sm dropdown-item", {
                      "fw-bold": event.status === "cancelled",
                    })}
                    onClick={() => changeStatus("cancelled")}
                  >
                    {t("status_cancelled", { context: "events" })}
                  </button>
                </li>
                <li>
                  <button
                    className={cx("btn btn-sm dropdown-item", {
                      "fw-bold": event.status === "noshow",
                    })}
                    onClick={() => changeStatus("noshow")}
                  >
                    {t("status_noshow", { context: "events" })}
                  </button>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Event;
