function getTimeSlots(step = 15) {
  const slots = [];
  for (let h = 0; h < 24; h += 1) {
    for (let m = 0; m < 60; m += step) {
      const slot = `${h.toString().padStart(2, "0")}:${m
        .toString()
        .padStart(2, "0")}`;
      slots.push(slot);
    }
  }
  return slots;
}

export default getTimeSlots;
