const diabetesModel = () => {
  return {
    hemGlic: {
      value: "",
      unit: "",
    },
    ptog: {
      value: "",
      unit: "",
    },
    fruct: {
      value: "",
      unit: "",
    },
    pepc: {
      value: "",
      unit: "",
    },
  };
};

const um = () => {
  return {
    hemGlic: ["%"],
    ptog: ["mg/dL", "mmol/L"],
    fruct: ["mcmol/L", "mmol/L"],
    pepc: ["nmol/L", "ng/mL"],
  };
};

export { um, diabetesModel };
