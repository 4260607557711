import { useTranslation } from "react-i18next";
import set from 'lodash/set'
import get from 'lodash/get'
import { ReactComponent as IconList } from 'bootstrap-icons/icons/list.svg'

import useUser from 'hooks/useUser'
import useStore from '../useStore'

import { useLocation, Link } from "@reach/router";

const Filters = props => {
  const { t } = useTranslation();
  const location = useLocation()

  const store = useStore(state => ({
    sort: state.sort,
    update: state.update,
    uids: state.uids,
    filters: state.filters,
    diagnostics: state.diagnostics,
    treatments: state.treatments,
    files: state.files,
    vitalSigns: state.vitalSigns,
    labs: state.labs,
    forms: state.forms
  }))

  const user = useUser()  

  const toggle = (path, value = true) => {
    const filters = JSON.clone(store.filters)
    const current = get(filters, path)

    set(filters, path, (current ?? current === value) ? undefined : value)
    store.update({ filters })
  }

  const toggleUids = uid => {
    const filters = JSON.clone(store.filters)
    if (filters.uids[uid] ?? true) {
      filters.uids[uid] = false
    } else {
      filters.uids[uid] = true
    }
    store.update({ filters })
  }

  return (
    <div className="dropdown">
      <button className="btn bg-white text-primary" data-bs-toggle="dropdown" data-bs-auto-close="false">
        <IconList width="16" height="16" />
      </button>
      <div className="dropdown-menu p-1 pb-3 shadow border-0 overflow-auto" style={{ height: "calc((var(--vh, 1vh) * 100) - 66px - 39px)" }}>
        <h6 className="dropdown-header">{t('sections', 'Secciones')}</h6>
        <div className="d-flex flex-column gap-2 ms-3">
          <Link to="" className={`text-truncate ${/ehr$/.test(location.pathname) ? 'link-secondary' : 'link-primary'}`}>
            {t("home", 'inicio')}
          </Link>
          <Link to="diagnostics" className={`text-truncate ${location.pathname.includes('/diagnostics') ? 'link-secondary' : 'link-primary'}`}>
            {t("diagnostic_plural")} <sup className="mx-1">{store.diagnostics?.length || 0}</sup>
          </Link>
          <Link to="treatments" className={`text-truncate ${location.pathname.includes('/treatments') ? 'link-secondary' : 'link-primary'}`}>
            {t("treatment_plural")} <sup className="mx-1">{store.treatments?.length || 0}</sup>
          </Link>
          <Link to="vitalSigns" className={`text-truncate ${location.pathname.includes('/vitalSigns') ? 'link-secondary' : 'link-primary'}`}>
            {t("vitalSign_plural")} <sup className="mx-1">{store.vitalSigns?.length || 0}</sup>
          </Link>
          <Link to="labs" className={`text-truncate ${location.pathname.includes('/labs') ? 'link-secondary' : 'link-primary'}`}>
            {t("lab_plural")} <sup className="mx-1">{store.labs?.length || 0}</sup>
          </Link>
          <Link to="files" className={`text-truncate ${location.pathname.includes('/files') ? 'link-secondary' : 'link-primary'}`}>
            {t("file_plural")} <sup className="mx-1">{store.files?.length || 0}</sup>
          </Link>
          <Link to="forms" className={`text-truncate ${location.pathname.includes('/forms') ? 'link-secondary' : 'link-primary'}`}>
            {t(`form_plural`)}
          </Link>
          <Link to="print" className={`text-truncate ${location.pathname.includes('/print') ? 'link-secondary' : 'link-primary'}`}>
            {t("print")}
          </Link>
        </div>
        <h6 className="dropdown-header mt-3">{t('sort', 'Orden')}</h6>
        <div className="form-check ms-3">
          <input
            className="form-check-input"
            checked={store.sort === 'date:asc'}
            onChange={() => store.update({ sort: 'date:asc' })}
            type="radio"
            value="date:asc"
            name="sort"
            id="sortByDateAsc"
          />
          <label className="form-check-label" htmlFor="sortByDateAsc">
            {t('sortByDateAsc', 'Fecha de carga: mas viejos primeros')}
          </label>
        </div>

        <div className="form-check ms-3">
          <input
            className="form-check-input"
            checked={store.sort === 'date:desc'}
            onChange={() => store.update({ sort: 'date:desc' })}
            type="radio"
            value="date:desc"
            name="sort"
            id="sortByDateDesc"
          />
          <label className="form-check-label" htmlFor="sortByDateDesc">
            {t('sortByDateDesc', 'Fecha de carga: mas nuevos primeros')}
          </label>
        </div>

        <div className="form-check ms-3">
          <input
            className="form-check-input"
            checked={store.sort === 'createdAt:asc'}
            onChange={() => store.update({ sort: 'createdAt:asc' })}
            type="radio"
            value="createdAt:asc"
            name="sort"
            id="sortByCreatedAtAsc"
          />
          <label className="form-check-label" htmlFor="sortByCreatedAtAsc">
            {t('sortByCreatedAtAsc', 'Fecha de creacion: mas viejos primeros')}
          </label>
        </div>

        <div className="form-check ms-3">
          <input
            className="form-check-input"
            checked={store.sort === 'createdAt:desc'}
            onChange={() => store.update({ sort: 'createdAt:desc' })}
            type="radio"
            value="createdAt:desc"
            name="sort"
            id="sortByCreatedAtDesc"
          />
          <label className="form-check-label" htmlFor="sortByCreatedAtDesc">
            {t('sortByCreatedAtDesc', 'Fecha de creacion: mas nuevos primeros')}
          </label>
        </div>

        <h6 className="dropdown-header mt-3">{t('filters_common', 'Filtros generales')}</h6>
        <div className="form-check ms-3">
          <input
            onChange={() => toggle('uids.me', user.state.uid)}
            checked={store.filters.uids.me === user.state.uid || !!store.filters.uids.me}
            className="form-check-input"
            type="checkbox"
            value=""
            id="showOnlyMyRecords"
          />
          <label className="form-check-label" htmlFor="showOnlyMyRecords">
            {t('showOnlyMyRecords', 'Mostrar solos mis registros')}
          </label>
        </div>

        <div className="form-check ms-3">
          <input
            onChange={() => toggle('showDeletedRecords')}
            checked={!!store.filters.showDeletedRecords}
            className="form-check-input"
            type="checkbox"
            value=""
            id="showDeletedRecords"
          />
          <label className="form-check-label" htmlFor="showDeletedRecords">
            {t('showDeletedRecords', 'Mostrar registros eliminados')}
          </label>
        </div>

        <h6 className="dropdown-header mt-3">{t('filters_by_user', 'Filtros de usuario')}</h6>
        {store.uids?.map(uid => (
          <div className="form-check ms-3" key={uid}>
            <input
              disabled={!!store.filters.uids.me}
              onChange={() => toggleUids(uid)}
              checked={!!(store.filters.uids[uid] ?? true)}
              className="form-check-input"
              type="checkbox"
              value=""
              id={`flagUid${uid}`}
            />
            <label className="form-check-label" htmlFor={`flagUid${uid}`}>
              {uid}
            </label>
          </div>
        ))}

        <h6 className="dropdown-header mt-3">{t('filters_diagnoses', 'Diagnosticos')}</h6>
        {[
          {
            value: 'chronic',
            label: t('ehr:chronic', 'Cronico', { context: 'diagnoses' }),
            description: t('chronic_description_diagnosis', 'Diagnostico confirmado y en curso'),
            color: 'primary'
          },
          {
            value: 'pending',
            label: t('ehr:pending', 'Sin confirmar', { context: 'diagnoses' }),
            description: t('pending_description_diagnosis', 'Diagnostico por confirmarse'),
            color: 'warning'
          },
          {
            value: 'intercurrence',
            label: t('ehr:intercurrence', 'Intercurrencia', { context: 'diagnoses' }),
            description: t('ehr:intercurrence_description_diagnosis', 'El diagnostico es una Intercurrencia'),
            color: 'success'
          },
        ].map(option => (
          <div key={option.value} className="form-check ms-3">
            <input
              onChange={() => toggle(`diagnoses.status.${option.value}`)}
              checked={!!store.filters.diagnoses.status[option.value]}
              className="form-check-input"
              type="checkbox"
              value=""
              id={`diagnosesStatus${option.value}`}
            />
            <label className="form-check-label" htmlFor={`diagnosesStatus${option.value}`}>
              {option.label}
            </label>
          </div>
        ))}

        <h6 className="dropdown-header mt-3">{t('filters_treatments', 'Tratamientos')}</h6>

        {[
          {
            value: 'active',
            label: t('in_progress', 'En curso', { context: 'treatments' }),
            description: t('active_description_treatments', 'Trataminento en curso'),
            color: 'primary'
          },
          {
            value: 'pending',
            label: t('pending', 'Sin iniciar', { context: 'treatments' }),
            description: t('pending_description_treatments', 'El tratamiento no todavia no fue iniciado'),
            color: 'warning'
          },
          {
            value: 'done',
            label: t('done', 'Finalizado', { context: 'treatments' }),
            description: t('done_description_treatments', 'El tratamiento fue finalizado'),
            color: 'success'
          },
        ].map(option => (
          <div key={option.value} className="form-check ms-3">
            <input
              onChange={() => toggle(`treatments.status.${option.value}`)}
              checked={!!store.filters.treatments.status[option.value]}
              className="form-check-input"
              type="checkbox"
              value=""
              id={`treatmentsStatus${option.value}`}
            />
            <label className="form-check-label" htmlFor={`treatmentsStatus${option.value}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  )  
}

export default Filters
