import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { formatDistanceStrict } from "date-fns";

import useMutation from "../../../../hooks/useMutation";
import { formatDate, getLocale } from "../../../../config/i18n";
import CalculateBMI from "./calculateBMI";
import { findByDisplayValue } from "@testing-library/dom";

let ticker;

function VitalSign(props) {
  const [countdown, setCountdown] = useState(null);
  const { t } = useTranslation();

  const [record, setRecord] = useState({});
  const [mode, setMode] = useState("read");
  const [bmi, setBmi] = useState(false);

  const [mutate] = useMutation(
    window._l(`/${record.id || "records"}`).substr(1),
    {
      onMutate: (payload) => {
        update(payload);
      },
      onSuccess: (data) => {
        update(data, "clear");
      },
      onError: (error) => {
        window.notify({
          message: error.message,
          type: "danger",
          title: "Error",
        });
      },
    }
  );

  const warningValue = {
    tas: {
      max: 140,
      min: 90,
    },
    tad: {
      max: 90,
      min: 40,
    },
    fc: {
      max: 100,
      min: 60,
    },
    fr: {
      max: 25,
      min: 16,
    },
    temp: {
      max: 38,
      min: 36,
    },
    sat: {
      max: 99,
      min: 90,
    },
  };

  const update = (payload, clear) => {
    setMode("read");
    props.update(payload, clear);
  };

  const onChange = ({ target: { name, value, type } }) => {
    if (type === "date") {
      let date;
      if (!value) {
        date = new Date(
          new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000
        );
      } else {
        date = new Date(
          new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000
        );
      }
      record[name] = date.getTime();
    } else {
      record[name] = value;
    }
    setRecord({ ...record });
  };

  const restore = () => {
    mutate({
      ...record,
      status: undefined,
      deleted: false,
    });
  };

  const remove = (mode) => {
    setCountdown(null);

    if (!record.id) {
      return update({ ...record, deleted: true, content: "", text: "" });
    }

    if (mode === "confirm") {
      return mutate({
        ...record,
        status: undefined,
        deleted: true,
      });
    }

    if (mode === "discard") return;

    setCountdown(true);
  };

  const save = async () => {
    mutate({ ...record });
    setMode("read");
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    let bmi = CalculateBMI(record.height, record.weight);
    setBmi(bmi);
  }, [record.height, record.weight]);

  useEffect(() => {
    setRecord({ ...props.record });
  }, [props.record]);

  useEffect(() => {
    setMode(!record.id ? "edit" : "read");
  }, [record.id]);

  let age;
  if (record.startsAt) {
    age = formatDistanceStrict(new Date(record.startsAt), Date.now(), {
      addSuffix: record.status !== "done",
      locale: getLocale(),
    });
  }

  return mode === "edit" ? (
    <div className="bg-light p-3 my-3 rounded">
      <div className="d-flex align-items-center mb-3">
        <div className="form-floating me-2">
          <input
            value={
              record.startsAt
                ? new Date(record.startsAt).toISOString().substr(0, 10)
                : new Date().toISOString().substr(0, 10)
            }
            onChange={onChange}
            type="date"
            className="form-control"
            id="startsAt"
            name="startsAt"
            placeholder={t("date")}
          />
          <label htmlFor="date">{t("date")}</label>
        </div>
      </div>
      <div className="d-flex align-items-center mb-3">
        <div className="form-floating flex-fill me-2">
          <input
            value={record.tas}
            onChange={onChange}
            type="text"
            className="form-control"
            id="tas"
            name="tas"
            placeholder={t("tas_placeholder")}
          />
          <label htmlFor="tas">{t("tas")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.tad}
            onChange={onChange}
            type="text"
            className="form-control"
            id="tad"
            name="tad"
            placeholder={t("tad_placeholder")}
          />
          <label htmlFor="tad">{t("tad")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.fc}
            onChange={onChange}
            type="text"
            className="form-control"
            id="fc"
            name="fc"
            placeholder={t("fc_placeholder")}
          />
          <label htmlFor="fc">{t("fc")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.fr}
            onChange={onChange}
            type="text"
            className="form-control"
            id="fr"
            name="fr"
            placeholder={t("fr_placeholder")}
          />
          <label htmlFor="fr">{t("fr")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.temp}
            onChange={onChange}
            type="text"
            className="form-control"
            id="temp"
            name="temp"
            placeholder={t("temp_placeholder")}
          />
          <label htmlFor="temp">{t("temp")}</label>
        </div>
      </div>

      <div className="d-flex align-items-center mb-3">
        <div className="form-floating flex-fill me-2">
          <input
            value={record.sat}
            onChange={onChange}
            type="text"
            className="form-control"
            id="sat"
            name="sat"
            placeholder={t("sat_placeholder")}
          />
          <label htmlFor="sat">{t("sat")}</label>
        </div>
        <div className="form-floating flex-fill me-2">
          <input
            value={record.fio2}
            onChange={onChange}
            type="text"
            className="form-control"
            id="fio2"
            name="fio2"
            placeholder={t("fio2")}
          />
          <label htmlFor="fio2">{t("fio2")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.height}
            onChange={onChange}
            type="text"
            className="form-control"
            id="height"
            name="height"
            placeholder={t("height_placeholder")}
          />
          <label htmlFor="height">{t("height")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.weight}
            onChange={onChange}
            type="text"
            className="form-control"
            id="weight"
            name="weight"
            placeholder={t("weight_placeholder")}
          />
          <label htmlFor="weight">{t("weight")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.abdperim}
            onChange={onChange}
            type="text"
            className="form-control"
            id="abdperim"
            name="abdperim"
            placeholder={t("abdperim_placeholder")}
          />
          <label htmlFor="abdperim">{t("abdperim")}</label>
        </div>
      </div>

      {record.deleted ? (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={restore}
          >
            {t("restore", { label: t("vitalsign") })}
          </button>
          <button
            className="btn btn-sm btn-link link-secondary"
            onClick={() => setMode("read")}
          >
            {t("close")}
          </button>
        </div>
      ) : countdown !== null ? (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-link link-success me-2"
            onClick={() => remove("discard")}
          >
            {t("discard")}
          </button>
          <button
            className="btn btn-sm btn-link link-danger"
            onClick={() => remove("confirm")}
          >
            {t("confirm")}
          </button>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-success me-2"
            onClick={save}
          >
            {t("save", { label: t("vitalsign") })}
          </button>
          <button
            className="btn btn-sm btn-link link-secondary"
            onClick={() => {
              if (!record.updatedAt) {
                setMode("discard");
                mutate({
                  ...record,
                  deleted: true,
                });
              } else {
                setMode("read");
              }
            }}
          >
            {t("close")}
          </button>
          <button className="btn btn-sm btn-link link-danger" onClick={remove}>
            {t("ehr:delete", { label: t("vitalsign") })}
          </button>
        </div>
      )}
    </div>
  ) : mode === "discard" ? null : (
    <div>
      <div className="mt-3 pt-3 border-top d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column"></div>
        <div>
          {record.deleted && (
            <small className="badge bg-danger fw-normal">
              {t("ehr:deleted")}
            </small>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <div>
            <span
              className={
                parseInt(record.tas) > warningValue.tas.max ||
                parseInt(record.tas) < warningValue.tas.min
                  ? "me-3 text-danger"
                  : "me-3"
              }
            >
              <sup className="text-muted">TA</sup>{" "}
              {[record.tas, record.tad].filter(Boolean).join("/")}
            </span>

            <span
              className={
                parseInt(record.fc) > warningValue.fc.max ||
                parseInt(record.fc) < warningValue.fc.min
                  ? "mx-3 text-danger"
                  : "mx-3"
              }
            >
              <sup className="text-muted me-1">FC</sup> {[record.fc]}
            </span>

            <span
              className={
                parseInt(record.fr) > warningValue.fr.max ||
                parseInt(record.fr) < warningValue.fr.min
                  ? "me-3 text-danger"
                  : "mx-3"
              }
            >
              <sup className="text-muted me-1">FR</sup> {[record.fr]}
            </span>

            <span
              className={
                parseInt(record.temp) >= warningValue.temp.max
                  ? "me-3 text-danger"
                  : "mx-3"
              }
            >
              <sup className="text-muted me-1">T°</sup> {[record.temp]}
            </span>

            <span
              className={
                parseInt(record.sat) < warningValue.sat.min
                  ? "me-3 text-danger"
                  : "mx-3"
              }
            >
              <sup className="text-muted me-1">Sat</sup> {[record.sat]}
            </span>

            <span className="mx-3">
              <sup className="text-muted me-1">fiO2%</sup> {[record.fio2]}
              {record.fio2 !== "" ? "%" : ""}
            </span>

            <span className="mx-3">
              <sup className="text-muted me-1">{t("height")}</sup>{" "}
              {[record.height]}
            </span>

            <span className="mx-3">
              <sup className="text-muted me-1">{t("weight")}</sup>{" "}
              {[record.weight]}
            </span>

            <span className="mx-3">
              <sup className="text-muted me-1">{t("abdperim")}</sup>{" "}
              {[record.abdperim]}
            </span>

            {record.height && record.weight && (
              <span
                data-tip={t("bmi_tooltip")}
                data-effect="solid"
                className={
                  bmi.warning === "obese"
                    ? "text-danger mx-3"
                    : bmi.warning === "overweight" ||
                      bmi.warning === "underweight"
                    ? "text-warning mx-3"
                    : "mx-3"
                }
              >
                <sup className="text-muted me-1">{t("bmi")}</sup>{" "}
                {bmi.value.toFixed(0)} {t(bmi.warning)}
              </span>
            )}
          </div>
          <div className="mt-2">
            {record.startsAt && (
              <small className="text-uppercase">
                {formatDate(new Date(record.startsAt), "dd-MMM-yyyy")}
              </small>
            )}
            {age ? <small className="text-muted mx-2">{age}</small> : null}
            <small className="text-muted">
              {record.createdByName}{" "}
              <small>
                {record.createdByLicense &&
                  record.createdByLicense.replace(/-/g, " ")}
              </small>
            </small>
          </div>
        </div>
        <div>
          <button
            className="btn btn-sm btn-link p-0 align-baseline"
            onClick={() => setMode("edit")}
          >
            {t("edit")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default VitalSign;
