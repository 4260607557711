import { useEffect, useState, useMemo, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { ReactComponent as IconChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg'
import { ReactComponent as IconTrash } from 'bootstrap-icons/icons/trash.svg'
import { ReactComponent as IconArrowClockwise } from 'bootstrap-icons/icons/arrow-clockwise.svg'
import { ReactComponent as IconInfoCircle } from 'bootstrap-icons/icons/info-circle.svg'
import { ReactComponent as IconJournalText } from 'bootstrap-icons/icons/journal-text.svg'
import ReactTooltip from 'react-tooltip'
import debounce from 'lodash.debounce'

import getAccessToken from "hooks/getAccessToken";
import parseDate from 'lib/parseDate'
import useMutation from "hooks/useMutation";
import Status from './Status'
import Calendar from './Calendar'

const Diagnosis = props => {
  const { t } = useTranslation()
  const [state, setState] = useState({ ...props.state })
  const [loading, setLoading] = useState()
  const [mutate, mutation] = useMutation(window._l(`/records`).substr(1));
  const $collapse = useRef(null)
  /* WORKAROUND ya que cuando creas un nuevo registro hace el POST sin el access token */
  const token = getAccessToken()

  const onChange = payload => {
    if (!state) return
    const record = { ...state, ...payload }
    setState(record)
    setLoading(true)
    debouncedChangeHandler(record)
  }

  const save = record => {
    setLoading(false)
    mutate(record, {
      onSuccess: data => props.onChange({ ...data, ...record })
    })
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(save, 2000)
    , [token.value]
  );

  const toggle = () => {
    const deleted = !(state.deleted ?? false)
    if (state.id && deleted && !window.confirm(t('ehr:remove_record', 'Confirmas que queres borrar el registro', { context: state.type }))) {
      return
    }
    onChange({ deleted })
  }

  useEffect(() => {
    setState({ ...props.state })
    if (!props.state?.id && token.value) {
      setLoading(true)
      save(props.state)
    }
  }, [props.state, token.value])

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const options = [
    {
      value: 'chronic',
      label: t('ehr:chronic', 'Cronico', { context: 'diagnoses' }),
      description: t('chronic_description_diagnosis', 'Diagnostico confirmado y en curso'),
      color: 'primary'
    },
    {
      value: 'pending',
      label: t('ehr:pending', 'Sin confirmar', { context: 'diagnoses' }),
      description: t('pending_description_diagnosis', 'Diagnostico por confirmarse'),
      color: 'warning'
    },
    {
      value: 'intercurrence',
      label: t('ehr:intercurrence', 'Intercurrencia', { context: 'diagnoses' }),
      description: t('ehr:intercurrence_description_diagnosis', 'El diagnostico es una Intercurrencia'),
      color: 'success'
    },
  ]

  const label = state.dx || state.label
  let tip = `<dt>${t('status', 'Estado')}</dt><dd>${t(state.status || 'chronic')}</dd>`
  if (state.notes) {
    tip += `<dt>${t('notes', 'Notas')}</dt><dd>${state.notes}</dd>`
  }
  if (state.startsAt) {
    tip += `<dt>${t('startsAt', 'Inicio')}</dt><dd>${parseDate(state.startsAt).toLocaleDateString()}</dd>`
  }
  if (state.endsAt) {
    tip += `<dt>${t('endsAt', 'Fin')}</dt><dd>${parseDate(state.endsAt).toLocaleDateString()}</dd>`
  }
  if (state.date || state.createdAt) {
    tip += `<dt>${t('date', 'Cargado')}</dt><dd>${parseDate(state.date || state.createdAt).toLocaleDateString()}</dd>`
  }
  if (state.cie10Code || state.cie10code) {
    tip += `<dt>${t('cie10', 'CIE10')}</dt><dd class="text-uppercase">${t(state.cie10Code || state.cie10code)}</dd>`
  }
  tip = `<dl>${tip}</dl>`

  return (
    <div className="position-relative border-top">
      {loading && <div className="loading loading-top loading-sm" />}
      <div className="d-flex justify-content-between align-items-center gap-2 p-3 bg-white">
        <button className={`btn d-flex align-items-center gap-2 p-0 ${state.deleted ? 'link-danger' : 'text-dark'}`} data-bs-target={`#collapse${state.id?.replace('/', '-')}`} data-bs-toggle="collapse">
          {loading && (
            <span className="spinner-border spinner-border-sm text-primary me-1" />
          )}
          <IconChevronExpand width="14" height="14" className="link-primary" />
          {tip && (
            <IconInfoCircle  width="14" height="14" className="text-teal" data-tip={tip} />
          )}
          {state.notes && (
            <IconJournalText width="14" height="14" className="text-teal" data-tip={state.notes}/>
          )}
          {label}
        </button>
        <div className="text-secondary small">
          {t(state.status || 'chronic', { context: 'diagnoses' })}
        </div>
      </div>
      <div className="collapse" ref={$collapse} id={`collapse${state.id?.replace('/', '-')}`}>
        <div className="p-3 d-flex flex-column border-top gap-3 shadow-sm bg-light rounded-bottom">
          <div className="d-flex flex-column flex-xxl-row gap-2 align-items-xxl-center">
            <div className="flex-fill form-floating">
              <input
                disabled={loading && !state.id}
                value={state.notes || ''}
                onChange={e => onChange({ notes: e.target.value })}
                type="text"
                className="form-control"
                id="notes"
                name="notes"
                placeholder={t("notes_placeholder_short", "Notas")}
              />
              <label>{t("notes_placeholder_short", "Notas")}</label>
            </div>

            <Status
              disabled={loading && !state.id}
              label={t('status', 'estado')}
              value={{
                value: state.status ,
                label: t(state.status || 'chronic')
              }}
              onChange={status => onChange({ status })}
              options={options}
            />

            <Calendar
              disabled={loading && !state.id}
              label={t('date', 'Fecha')}
              tip={t('diangosis_date_tip', 'Fecha de carga')}
              value={{
                value: state.date || state.createdAt,
                label: parseDate(state.date || state.createdAt)?.toLocaleDateString()
              }}
              onChange={date => onChange({ date: date?.toLocaleDateString('fr-ca') || "" })}
            />

            <Calendar
              disabled={loading && !state.id}
              label={t('startsAt', 'inicio')}
              tip={t('diangosis_startsAt_tip', 'Cuando fue diagnosticado')}
              value={{
                value: state.startsAt,
                label: parseDate(state.startsAt)?.toLocaleDateString() || t('undefined', 'Indefinida', { context: 'female' })
              }}
              onChange={date => onChange({ startsAt: date?.toLocaleDateString('fr-ca') || "" })}
            />

            <Calendar
              disabled={loading && !state.id}
              tip={t('diangosis_endsAt_tip', 'Fecha de finalización del diagnostico')}
              label={t('endsAt', 'fin')}
              value={{
                value: state.endsAt,
                label: parseDate(state.endsAt)?.toLocaleDateString() || t('undefined', 'Indefinida', { context: 'female' })
              }}
              onChange={date => onChange({ endsAt: date?.toLocaleDateString('fr-ca') || "" })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Diagnosis
