const getConsumerIdentification = _value => {
  let value = _value?.toString()?.replace(/\s\s+/g, ' ').trim()
  try {
    if (!value) return ''
    if (/[a-z.]/i.test(value)) return value
    value = value.replace(/\D/g, '')
    return parseInt(value).toLocaleString('es-AR') || value
  } catch { }

  return value
}

const verifyConsumer = (consumer) => {
  try {
    consumer ||= {};
    let errors = {}
    if (!consumer) return errors;

    let identification = getConsumerIdentification(consumer.identification);
    errors.identification = !identification || identification.length < 4;

    let firstName = consumer.firstName;
    let lastName = consumer.lastName;
    if (!firstName || !lastName) {
      [lastName, firstName] = consumer.label.split(", ");
    }
    errors.firstName = !consumer.firstName;
    errors.lastName = !consumer.lastName;

    consumer.financiers ||= []
    let financiers = consumer.financiers.filter(f => f.id && !f.deleted);

    errors.financiers = {};
    errors.financiers.validFinanciersCount = 0;

    for (let financier of financiers) {
      let financierKey = financier.id || financier.label;
      errors.financiers[financierKey] = {};
      let financierIdentification = financier.identification?.replace(/\s+/g, '');

      let slug = financier.id?.replace("pms_financiers:", "").split("/").filter(Boolean).at(0);
      if (!/wiri|private/i.test(slug) && !financierIdentification) {
        errors.financiers[financierKey].identification = true
      }
      else if ((slug === "osde" || slug === "osde/")
        && !/^\d{11}$/.test(financierIdentification)) {
        errors.financiers[financierKey].identification_format = true;
      }

      errors.financiers[financierKey].flag = errors.financiers[financierKey]?.financier || errors.financiers[financierKey]?.identification || errors.financiers[financierKey]?.identification_format;
      if (!errors.financiers[financierKey].flag) errors.financiers.validFinanciersCount++;
      errors.financiers.flag ||= errors.financiers[financierKey].flag;
    }

    errors.flag = errors.identification || errors.firstName || errors.lastName;
    return errors;
  } catch { }
};

export default verifyConsumer;