const eabrenalModel = () => {
  return {
    cr: {
      value: "",
      unit: "",
    },
    ur: {
      value: "",
      unit: "",
    },
    ph: {
      value: "",
      unit: "",
    },
    pco2: {
      value: "",
      unit: "",
    },
    po2: {
      value: "",
      unit: "",
    },
    hco3: {
      value: "",
      unit: "",
    },
    sat: {
      value: "",
      unit: "",
    },
  };
};

const um = () => {
  return {
    cr: ["mg/dL", "µmol/L"],
    ur: ["mg/dL", "mmol/L"],
    ph: [""],
    po2: ["mmHg"],
    pco2: ["mmHg"],
    hco3: ["mEq/L", "mmol/L"],
    sat: ["%"],
  };
};

export { eabrenalModel, um };
