import { useState, useEffect } from "react";
import { formatDate } from "../../../../config/i18n";
import { useTranslation } from "react-i18next";

function Records(props) {
  const { t } = useTranslation();

  const [recordArray, setRecordArray] = useState([]);
  const [hideNotSelected, setHideNotSelected] = useState(false);

  const addRecordToArray = (record) => {
    setRecordArray([...recordArray, record]);
  };

  const records = props.printStatus.individualRecords.filter(
    (element) => element.display
  );

  const Dropdown = (data) => {
    return (
      <div className="btn-group dropup">
        <button
          type="button"
          className="btn btn-sm btn-link text-secondary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <small>{t("add")}</small>
        </button>
        <ul className="dropdown-menu dropdownHeightLimit">
          {DropdownList(data)}
        </ul>
      </div>
    );
  };

  const DropdownList = (data) => {
    return data.map((element) => (
      <li>
        <a
          className="dropdown-item"
          onClick={() => {
            addRecordToArray(element);
          }}
        >
          {formatDate(element.createdAt, "dd-MMM-yyyy")}
          {
            <small className="small text-muted fst-italic ms-1">
              {element.createdByName}
            </small>
          }
        </a>
      </li>
    ));
  };

  function handleDeleteRecord(recordID) {
    let newPrintStatus = records.map((element) => {
      if (element.id === recordID) element.display = false;
      return element;
    });

    props.setPrintStatus({
      ...props.printStatus,
      individualRecords: newPrintStatus,
    });
  }

  return (
    <>
      {records.filter((element) => element.id).length > 0 && (
        <div className="d-flex align-items-center bg-light px-4 py-2 mb-2">
          <h5 className="h4 fw-bold mb-0 py-2">{t("ehr:records")}</h5>
          {props.showBtns && (
            <>
              <button
                className={
                  !hideNotSelected
                    ? "btn btn-link btn-sm text-success ms-1"
                    : "btn btn-link btn-sm text-secondary ms-1"
                }
                onClick={() => {
                  setHideNotSelected(false);
                }}
              >
                <small>{t("ehr:all")}</small>
              </button>
              <button
                className={
                  !hideNotSelected
                    ? "btn btn-link btn-sm text-secondary"
                    : "btn btn-link btn-sm text-primary"
                }
                onClick={() => {
                  setHideNotSelected(true);
                  setRecordArray([]);
                }}
              >
                <small>{t("ehr:specific_female_plural")}</small>
              </button>
            </>
          )}
          {props.showBtns && hideNotSelected && Dropdown(records)}
          {props.showBtns && (
            <button
              className="btn btn-link btn-sm text-danger"
              onClick={() => {
                props.setPrintStatus({
                  ...props.printStatus,
                  records: false,
                });
              }}
            >
              <small>{t("ehr:hide")}</small>
            </button>
          )}
        </div>
      )}

      {!hideNotSelected &&
        records.map((element, index) => {
          if (element.id) {
            return (
              <div
                className={
                  index + 1 === records.length
                    ? " px-4 py-3"
                    : " border-bottom px-4 py-3"
                }
              >
                <span className="">
                  {formatDate(element.date, "dd-MMM-yyyy")}{" "}
                  <small className="text-muted fw-normal">
                    {t("ehr:by")}{" "}
                    {element.createdByName &&
                      element.createdByName.replace(",", " ")}
                    <small className="ms-1">{element.createdByLicense}</small>
                  </small>{" "}
                  {props.showBtns && (
                    <button
                      className="btn btn-link btn-sm text-danger"
                      onClick={() => {
                        handleDeleteRecord(element.id);
                      }}
                    >
                      <small>{t("ehr:hide")}</small>
                    </button>
                  )}
                </span>
                <div>
                  <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{
                      __html: element.content,
                    }}
                  />
                </div>
              </div>
            );
          }
        })}

      {hideNotSelected &&
        recordArray.map((element, index) => {
          if (element.id) {
            return (
              <div
                className={
                  index + 1 === records.length
                    ? " px-4 py-2"
                    : " border-bottom px-4 py-2"
                }
              >
                <span className="fw-bold">
                  {formatDate(element.date, "dd-MMM-yyyy")}{" "}
                  <small className="text-muted fw-normal">
                    {element.createdByName && element.createdByName}
                  </small>{" "}
                </span>
                <div>
                  <div
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: element.content,
                    }}
                  />
                </div>
              </div>
            );
          }
        })}
    </>
  );
}

export default Records;
