import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";
import kebabCase from "lodash/kebabCase";
import cx from "classnames";
import { ReactComponent as IconGear } from "bootstrap-icons/icons/gear.svg";
import { ReactComponent as IconArrowBarRight } from "bootstrap-icons/icons/arrow-bar-right.svg";
import { ReactComponent as IconArrowBarLeft } from "bootstrap-icons/icons/arrow-bar-left.svg";
import { ReactComponent as IconArrowBarDown } from "bootstrap-icons/icons/arrow-bar-down.svg";
import { ReactComponent as IconArrowBarUp } from "bootstrap-icons/icons/arrow-bar-up.svg";
import ReactTooltip from "react-tooltip";

const App = (props) => {
  const { t } = useTranslation();
  const [field, setField] = useState(JSON.clone(props.field));
  const [flag, setFlag] = useState(false);
  const $dropdown = useRef(null);

  const onChange = ({ target: { name, value, type } }) => {
    const state = JSON.clone(field);
    if (value && type === "number") {
      state[name] = +value;
    } else if (type === "checkbox") {
      state[name] = !state[name];
    } else {
      state[name] = value;
    }
    setField(state);
  };

  const toggle = () => {
    props.onChange({ ...field, hide: !field.hide });
  };

  const save = () => {
    props.onChange({ ...field });
  };

  const move = (direction) => {
    props.onChange({ ...field, move: direction });
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if ($dropdown.current) {
      $dropdown.current.addEventListener("hide.bs.dropdown", () => {
        setFlag(false);
      });
      $dropdown.current.addEventListener("show.bs.dropdown", () => {
        setFlag(true);
      });
    }
  }, [$dropdown]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const label = props.field?.label
    ? props.field.label
    : props.field?.type === "number"
    ? t("form_field_type_number", "Numerica")
    : props.field?.type === "date"
    ? t("form_field_type_date", "Fecha")
    : props.field?.type === "checkbox"
    ? t("form_field_type_check", "Check")
    : props.field?.type === "items"
    ? t("options", "Opciones")
    : props.field?.type === "files"
    ? t("form_field_type_files", "Archivos")
    : props.field?.type === "diagnoses"
    ? t("form_field_type_diagnoses", "Diagnosticos")
    : props.field?.type === "treatments"
    ? t("form_field_type_treatments", "Tratamientos")
    : props.field?.type === "references"
    ? t("form_field_type_references", "Referencias")
    : t("form_field_name", "Nombre del campo");

  return (
    <th
      className="p-0 align-middle"
      style={{ ...props.field?.[props.form?.orientation] }}
    >
      <div className={`p-2 fw-normal d-none d-print-block`}>{label}</div>
      <div ref={$dropdown}>
        <button
          className="btn p-2 lh-1 text-nowrap text-black"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          data-tip={
            props.field?.hint ||
            t("ehr:form_field_tip", "Click para ver las opciones")
          }
        >
          <span className={field.hide ? 'text-warning' : ''}>{label}</span>
          {props.field?.label && props.field?.unit && (
            <sup className="text-secondary fw-normal"> {props.field.unit}</sup>
          )}
        </button>
        <div
          className={cx("dropdown-menu p-0", {
            "dropdown-menu-end": props.form.orientation === "horizontal",
          })}
          style={{ maxWidth: "350px" }}
        >
          {flag && (
            <form
              onSubmit={onSubmit}
              autoComplete="off"
              className="p-3 fw-normal rounded shadow-sm"
            >
              <div className="mb-3">
                <label htmlFor={`f-${props.form.id}-${props.field.id}-type`}>
                  {t("form_field_type_label", "Tipo de dato")}
                </label>
                <select
                  id={`f-${props.form.id}-${props.field.id}-type`}
                  name="type"
                  value={field.type || "text"}
                  onChange={onChange}
                  className="form-select"
                >
                  <option value="text">
                    {t("form_field_type_text", "Texto")}
                  </option>
                  <option value="number">
                    {t("form_field_type_number", "Numerica")}
                  </option>
                  <option value="date">
                    {t("form_field_type_date", "Fecha")}
                  </option>
                  <option value="checkbox">
                    {t("form_field_type_check", "Check")}
                  </option>
                  <option value="items">
                    {t("form_field_type_items", "Lista de opciones")}
                  </option>
                  <option value="files">
                    {t("form_field_type_files", "Archivos")}
                  </option>
                  <option value="diagnoses">
                    {t("form_field_type_diagnoses", "Diagnosticos")}
                  </option>
                  <option value="treatments">
                    {t("form_field_type_treatments", "Tratamientos")}
                  </option>
                  <option value="references" disabled>
                    {t("form_field_type_references", "Referencias (beta)")}
                  </option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor={`f-${props.form.id}-${props.field.id}-label`}>
                  {t("form_field_label_label", "Nombre")}
                </label>
                <input
                  id={`f-${props.form.id}-${props.field.id}-label`}
                  type="text"
                  name="label"
                  value={field.label || ""}
                  placeholder={label}
                  onChange={onChange}
                  className="form-control"
                />
              </div>

              <div
                className="mb-3"
                data-tip={t(
                  "form_field_hint_label_hint",
                  "Puede agregar algun texto que sirva de ayuda o referencia"
                )}
              >
                <label htmlFor={`f-${props.form.id}-${props.field.id}-hint`}>
                  {t("form_field_hint_label", "Ayuda")}
                </label>
                <input
                  type="text"
                  name="hint"
                  value={field.hint || ""}
                  placeholder={t(
                    "form_field_hint_placeholder",
                    "Algun texto de ayuda (opcional)"
                  )}
                  onChange={onChange}
                  className="form-control"
                />
              </div>
              {field.type === "items" ? (
                <div className="mb-3">
                  <label htmlFor={`f-${props.form.id}-${props.field.id}-items`}>
                    {t("form_field_items_label", "Opciones")}
                  </label>
                  <Creatable
                    id={`f-${props.form.id}-${props.field.id}-items`}
                    isMulti
                    isClearable
                    tabSelectsValue
                    backspaceRemovesValue
                    defaultMenuIsOpen={false}
                    getNewOptionData={(value, label) => ({
                      label,
                      value: kebabCase(value),
                    })}
                    loadingMessage={() => t("loading")}
                    value={field.options || []}
                    options={field.options || []}
                    onChange={(options) =>
                      onChange({ target: { name: "options", value: options } })
                    }
                    formatCreateLabel={(inputValue) => inputValue}
                    placeholder={t(
                      "form_field_type_items_placeholder",
                      "Escriba la lista de opciones"
                    )}
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: null,
                    }}
                  />
                </div>
              ) : field.type === "references" ? (
                <div className="mb-2"></div>
              ) : (
                field.type === "number" && (
                  <>
                    <div className="mb-3">
                      <label
                        htmlFor={`f-${props.form.id}-${props.field.id}-unit`}
                      >
                        {t("form_field_unit_label", "Unidad de medida")}
                      </label>
                      <input
                        id={`f-${props.form.id}-${props.field.id}-unit`}
                        type="text"
                        name="unit"
                        value={field.unit || ""}
                        placeholder={t(
                          "ehr:form_field_unit_placeholder",
                          "cm, kg, mm (opcional)"
                        )}
                        onChange={onChange}
                        className="form-control"
                      />
                    </div>
                  </>
                )
              )}
              {["diagnoses", "treatments", "items"].includes(field.type) && (
                <div
                  className="mb-3"
                  data-tip={t(
                    "form_field_unit_isMulti_tip",
                    "Marcar para poder seleccionar mas de una opcion del listado al momento de la carga de datos"
                  )}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      onChange={onChange}
                      name="isMulti"
                      type="checkbox"
                      id={`f-${props.form.id}-${props.field.id}-isMulti`}
                      checked={!!field.isMulti}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`f-${props.form.id}-${props.field.id}-isMulti`}
                    >
                      {t("form_field_unit_isMulti", "Multiple")}
                    </label>
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <button
                    className="btn btn-sm text-secondary p-0 me-3"
                    data-tip={t("ehr:form_field_move", "Mover campo")}
                    data-place="bottom"
                    onClick={() => move("prev")}
                  >
                    {props.form.orientation === "vertical" ? (
                      <IconArrowBarUp />
                    ) : (
                      <IconArrowBarLeft />
                    )}
                  </button>
                  <button
                    className="btn btn-sm text-secondary p-0"
                    data-tip={t("ehr:form_field_move", "Mover campo")}
                    data-place="bottom"
                    onClick={() => move("next")}
                  >
                    {props.form.orientation === "vertical" ? (
                      <IconArrowBarDown />
                    ) : (
                      <IconArrowBarRight />
                    )}
                  </button>
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-sm link-dark me-2"
                    onClick={toggle}
                  >
                    {field.hide ? t("restore", "Restaurar") : t("remove", "Borrar")}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary border-0"
                    onClick={save}
                  >
                    {t("apply", "Aplicar")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </th>
  );
};

export default App;
