const perfilferricoModel = () => {
  return {
    ferrit: {
      value: "",
      unit: "",
    },
    transfer: {
      value: "",
      unit: "",
    },
    satTransfer: {
      value: "",
      unit: "",
    },
    tibc: {
      value: "",
      unit: "",
    },
    fe: {
      value: "",
      unit: "",
    },
  };
};

const um = () => {
  return {
    ferrit: ["ng/mL"],
    transfer: ["mg/dL"],
    satTransfer: ["%"],
    tibc: ["mcg/dL", "mcmol/L"],
    fe: ["mcg/dL", "mcg/L"],
  };
};

export { perfilferricoModel, um };
