import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconTrash } from 'bootstrap-icons/icons/trash.svg'
import { ReactComponent as IconArrowClockwise } from 'bootstrap-icons/icons/arrow-clockwise.svg'
import { ReactComponent as IconLock } from 'bootstrap-icons/icons/file-earmark-lock2-fill.svg'
import { v4 as uuid } from "uuid";

import ReactTooltip from "react-tooltip";

import parseDate from 'lib/parseDate'
import useUser from "hooks/useUser";
import useMutation from "hooks/useMutation";
import Editor from "../../../EHR/Editor/Editor";
import Calendar from '../Home/Calendar'
import useStore from '../useStore'

function Record(props) {
  const user = useUser();
  const { t } = useTranslation();
  const [state, setState] = useState();
  const [editable, setEditable] = useState()
  const [loading, setLoading] = useState()
  const ticker = useRef(null)
  const store = useStore(state => ({
    resource: state.resource,
    consumer: state.consumer,
    records: state.records || [],
    update: state.update,
  }))
  const [mutate, mutation] = useMutation(window._l(`/records`).substr(1));

  const onChange = payload => {
    const record = { ...state, ...payload }
    setState(record)
    clearTimeout(ticker.current)
    setLoading(true)
    ticker.current = setTimeout(() => {
      setLoading(false)
      mutate(record, {
        onSuccess: data => {
          setState(state => {
            const payload = { ...data, ...state }
            let records = [...store.records]
            const index = records.findIndex(r => r.id === payload.id)
            if (index >= 0) {
              records[index] = payload
            } else {
              records = [...records, payload]
            }
            store.update({ records })

            return payload
          })
        }
      })
    }, 2000)
  }

  const toggle = () => {
    const deleted = !(state.deleted ?? false)
    if (state.id && deleted && !window.confirm(t('ehr:remove_record', 'Confirmas que queres borrar el registro', { context: state.type }))) {
      return
    }
    onChange({ deleted })
  };

  useEffect(() => {
    if (user.state?.uid && store.consumer?.id) {
      const payload = {
        date: new Date().toLocaleDateString('fr-ca'),
        type: "records",
        consumer: { id: store.consumer?.id, label: store.consumer?.label },
        createdAt: Date.now(),
        createdBy: user.state.uid,
        createdByName: store.resource?.label,
        createdByResourceID: store.resource?.id,
        ...props.state
      }
      if (!payload.id) {
        payload._id = uuid()
      }
      setState(payload);
    }
  }, [props.state, user.state.uid, store.consumer, store.resource]);

  useEffect(() => {
    const editable = state?.createdBy === user.state.uid && Date.now() - state?.createdAt <= 24 * 60 * 60 * 1000;
    setEditable(editable)
  }, [state?.createdBy, state?.createdAt, user.state.uid])

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!user.state.uid) return null;

  if (!state) return (
    <div className="mvh-100-1 d-flex align-items-center justify-content-center">
      <div className="spinner-border text-primary">
        <span className="visually-hidden">{t("loading")}</span>
      </div>
    </div>
  )

  return (
    <div className="rounded bg-white position-relative">
      {loading && <div className="loading loading-bottom loading-sm" />}

      <div>
        <div>
          <Editor
            disabled={!editable}
            id={state.id || state._id}
            value={state.content}
            placeholder={t("ehr:record_placeholder", "Escriba aqui la evolucion")}
            onEditorChange={content => onChange({ content })}
          />
        </div>

        <div className="d-flex align-items-center">
          {loading && (
            <div className="spinner-border-sm spinner-border ms-2 text-primary"></div>
          )}
          <Calendar
            inline
            disabled={!editable}
            label={t('date', 'fecha')}
            value={{
              value: state.date,
              label: parseDate(state.date)?.toLocaleDateString() || t('undefined', 'Indefinida', { context: 'female' })
            }}
            onChange={date => onChange({ date: date?.toLocaleDateString('fr-ca') || "" })}
          />
          {state.id ? (
            state.deleted && editable && !loading ? (
              <button disabled={loading && !state.id} data-tip={t('restore', 'Restaurar')} className="btn btn-sm p-0" onClick={toggle}>
                <IconArrowClockwise className="text-success" width="16" height="16" />
              </button>
            ) : editable && !loading ? (
              <button disabled={loading && !state.id} className="btn btn-sm p-0 text-danger" onClick={toggle}>
                <IconTrash className="text-danger" width="16" height="16" />
              </button>
            ) : !editable && (
              <small
                className="text-secondary lh-1"
                data-tip={t(
                  "ehr:editLockedTip",
                  "Los registros solo se pueden editar hasta 24hs despes de la creacion, y por el mismo que lo haya creado"
                )}
              >
                <IconLock width="16" height="16" /> {t("locked", "Bloqueado", { context: 'record' })}
              </small>
            )
          ) : props.onRemove && (
            <button className="btn btn-sm p-0 text-danger" onClick={() => props.onRemove(state._id)}>
              <IconTrash className="text-danger" width="16" height="16" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Record;
