import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import detector from "i18next-browser-languagedetector";
import { format } from "date-fns-tz";
import { formatDistance } from 'date-fns'
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";

import parseDate from 'lib/parseDate'

const { REACT_APP_API_URL } = process.env;

export const locales = {
  es,
  en,
  pt,
};

export const getLocale = () => {
  return locales[i18n.language.split("-")[0]] || locales.es;
};

const backend = {
  loadPath: `${REACT_APP_API_URL}/translates/i18n/{{ns}}/{{lng}}`,
  addPath: `${REACT_APP_API_URL}/translates/i18n/{{ns}}/{{lng}}`,
  withCredentials: true,
  crossDomain: true,
  overrideMimeType: true,
};

export const timeDistance = (date1, date2, options = {}) => {
  options.locale ||= getLocale()
  return formatDistance(date1, date2, options)
}

export const formatDate = (value, key) => {
  try {
    const locale = getLocale();
    let date = value
    if (!(date instanceof Date)) {
      date = parseDate(value)
    }
    return format(date, key, { locale, weekStartsOn: 1 });
  } catch {
    return "";
  }
};

i18n
  .use(HttpApi)
  .use(detector)
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
    },
    saveMissing: false,
    whitelist: ["es", "es-AR", "es-EC", "es-419", "en", "pt", "pt-BR"],
    backend,
    ns: [
      "generic",
      "pms",
      "ehr",
      "onboarding",
      "pms_financiers",
      "pms_practices",
      "pms_specialties",
    ],
    defaultNS: "pms",
    fallbackNS: "generic",
    fallbackLng: "es",
  });

window.i18n = i18n;
const t = i18n.t;
i18n.t = (_keys, _options, lastKey) => {
  let keys = _keys;
  let options = _options || {};
  if (i18n.language === "cimode") {
    delete options.defaultValue;
    if (Array.isArray(keys) && options.context) {
      keys = `${keys[0]}_${options.context}`;
    } else if (keys && keys.constructor === String && options.context) {
      keys = `${keys}_${options.context}`;
    }
  }
  let suffix;
  if (
    keys &&
    keys.constructor === String &&
    /^pms_financiers:/.test(keys) &&
    /\/$/.test(keys) &&
    !["pms_financiers:wiri", "pms_financiers:private"].includes(keys) &&
    i18n.exists(keys, options) && options?.excludeSuffix !== true
  ) {
    suffix = t.bind(i18n)("all_plans", {
      context: options?.context || "financiers",
    });
  }
  if (
    keys &&
    keys.constructor === String &&
    /^pms_specialties:+.+pms_practices:/.test(keys) &&
    keys.includes("/") &&
    keys.substr(-1) !== "/"
  ) {
    const parts = keys.split("/").filter(Boolean);
    const { defaultValue = "" } = options;
    const values = defaultValue
      .split("/")
      .map((part) => part.trim())
      .filter(Boolean);
    return parts
      .map((part, index) =>
        t.bind(i18n)(part, values[index] || part.split(":").reverse()[0])
      )
      .filter(Boolean)
      .join(" / ");
  }
  try {
    const value = t.bind(i18n)(keys, options, lastKey);
    if (suffix && value.includes(suffix)) return value;
    if (suffix && !value.includes(suffix)) return [value, suffix].join(" - ");
    return value;
  } catch {
    if (_options && _options.constructor === String) return _options
  }
  return ''
};

export default i18n;
