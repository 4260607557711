const getAge = (date1, date2) => {
  try {
    let now = new Date(date2 || `${new Date().toLocaleDateString('fr-ca').substr(0, 10)}T00:00`);
    let dob = new Date(`${date1}T00:00`);

    let days
    let months

    dob.year = dob.getFullYear();
    dob.month = dob.getMonth() + 1;
    dob.day = dob.getDate();

    now.year = now.getFullYear();
    now.month = now.getMonth() + 1;
    now.day = now.getDate();

    const daysInMonth = new Date(now.getFullYear(), now.month, 0).getDate()

    if (now.day < dob.day) {
      days = now.day - dob.day + daysInMonth;
      now.month = now.month - 1;
    } else {
      days = now.day - dob.day
    }

    if (now.month < dob.month) {
      months = now.month - dob.month + 12
      now.year = now.year - 1;
    } else {
      months = now.month - dob.month
    }

    let years = now.year - dob.year

    return { years, months, days }
  } catch {
    return {}
  }
};

export default getAge;
