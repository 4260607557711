import { useTranslation } from "react-i18next";

function Input({
  label,
  disabled,
  autoCapitalize,
  required,
  minLength,
  maxLength,
  pattern,
  type,
  name,
  id,
  placeholder,
  value,
  onChange,
  onBlur,
  hint,
  formats,
  autoFocus,
  autoComplete = "--disabled",
  margin = "mb-3",
  warning
}) {
  const { t } = useTranslation();
  return (
    <div className={`form-floating ${margin}`}>
      <input
        autoFocus={!!autoFocus}
        disabled={disabled}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder || label}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        className={`form-control ${warning ? 'is-invalid' : ''}`}
      />
      <label className={`form-label ${warning ? 'text-danger fw-bold' : ''}`} htmlFor={id}>
        {label}
      </label>
      {(hint || Array.isArray(formats)) && (
        <div className="form-text">
          {hint && <span dangerouslySetInnerHTML={{ __html: hint }} />}
          {Array.isArray(formats) &&
            formats.map((format) => (
              <span
                key={format}
                className={`${format} ms-1`}
                dangerouslySetInnerHTML={{ __html: t(format) }}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default Input;
