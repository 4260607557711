import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const App = (props) => {
  const { t } = useTranslation();

  const onChange = (e) => {
    props.onChange({ date: e.target.value });
  };

  return props.editable ? (
    <input
      disabled={!props.editable}
      style={{ maxWidth: "162px" }}
      minLength="10"
      maxLength="10"
      type="date"
      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
      value={props.value || new Date().toLocaleDateString("fr-ca")}
      onChange={onChange}
      className={`${styles.input} form-control rounded-0 border-0`}
    />
  ) : (
    <div className="p-2">{props.value}</div>
  );
};

export default App;
