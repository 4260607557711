import { useState } from "react";
import getAccessToken from "./getAccessToken";

const { REACT_APP_API_URL } = process.env;

export default function (key) {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = getAccessToken();

  const start = async (params) => {
    try {
      setIsLoading(true);
      const url = `${REACT_APP_API_URL}${window._l(
        `/${key}/download?${new URLSearchParams(params).toString()}`
      )}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
        },
      }).then((res) => res.text());
      window.open(response, "_blank");
    } catch {}
    setIsLoading(false);
  };

  return {
    isLoading,
    start,
  };
}
