import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { ReactComponent as IconPlusCircleFille } from "bootstrap-icons/icons/plus-circle-fill.svg";
import uniq from "lodash/uniq";
import { useQuery } from "react-query";

import useMutation from "hooks/useMutation";
import { formatDate } from "config/i18n";
import useUser from "hooks/useUser";
import styles from "./styles.module.scss";
import useStoreConsumer from "../../Consumer/useStore";
import useStore from "../../useStore";
import Field from "./Field/Field";
import Menu from "./Menu/Menu";
import Cell from "./Cell/Cell";
let ticker;

const { REACT_APP_INTERACTIONS_API_URL } = process.env;

const findInteractions = async (drugs) => {
  if (drugs.length <= 1) return [];
  return fetch(REACT_APP_INTERACTIONS_API_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ drugs }),
  })
    .then((res) => res.json())
    .then((res) => res.data || [])
    .catch(() => []);
};

const Table = (props) => {
  const { t } = useTranslation();
  const [records, setRecords] = useState(JSON.clone(props.records || []));
  const [form, setForm] = useState();
  const [drugs, setDrugs] = useState([]);
  const consumerID = useStoreConsumer(state => state.consumer?.id);
  const showDeletedFields = !!props.showDeletedFields
  const store = useStore();
  const user = useUser();
  const interactions = useQuery(
    ["interactions", drugs],
    () => findInteractions(drugs),
    { refetchOnWindowFocus: false, initialData: [] }
  );
  const [mutate] = useMutation(window._l("/records").substr(1), {
    onMutate: (payload) => {},
    onSuccess: (data) => {},
    onError: (error) => {
      window.notify({
        message: error.message,
        type: "danger",
        title: "Error",
      });
    },
  });

  const onChangeField = (payload) => {
    const index = form.fields.findIndex((f) => f.id === payload.id);
    if (index < 0) return;
    const visibleFields = form.fields.filter((f) => !f.hide);
    if (payload.move === "prev" && index > 0) {
      const newIndex = index - 1;
      [form.fields[newIndex], form.fields[index]] = [
        form.fields[index],
        form.fields[newIndex],
      ];
    } else if (payload.move === "next" && index + 1 < visibleFields.length) {
      const newIndex = index + 1;
      [form.fields[newIndex], form.fields[index]] = [
        form.fields[index],
        form.fields[newIndex],
      ];
    } else if (payload.move) {
      return;
    } else {
      form.fields[index] = {
        ...form.fields[index],
        ...payload,
        move: undefined,
      };
    }

    form.fields = form.fields.sort((a, b) => {
      if (a.hide && !b.hide) return 1;
      if (!a.hide && b.hide) return -1;
      return 0;
    });

    props.onChange(JSON.clone(form));
  };

  const onChangeRecord = (record) => {
    const index = records.findIndex((r) => r.id === record.id);
    if (index < 0) return;
    record.fields = record.fields || {};
    for (const field of form.fields) {
      if (field.type !== "date") continue;
      if (record.fields[field.id]?.date) continue;
      record.fields[field.id] = { ...field };
      record.fields[field.id].date = new Date().toLocaleDateString("fr-ca");
    }
    records[index] = record;
    clearTimeout(ticker);
    ticker = setTimeout(() => mutate(record), 750);
    setRecords(JSON.clone(records));
  };

  const addRecord = () => {
    const record = {
      id: `records/${uuid()}`,
      formID: form.id,
      consumerID,
      createdBy: user.state.uid,
      createdAt: Date.now(),
    };
    setRecords(JSON.clone([record, ...records]));
  };

  useEffect(() => {
    setForm(JSON.clone(props.form));
  }, [props.form]);

  useEffect(() => {
    const drugs = [];
    for (const record of records) {
      const isDone = record.fields?.status?.items?.some(
        (item) => item.value === "done"
      );

      const fields = Object.values(record.fields || {}).filter(
        (field) => Array.isArray(field.treatments) && !isDone
      );

      const array = fields.flatMap((field) =>
        field.treatments.flatMap((treatment) => treatment.drugs)
      );
      drugs.push(...array);
    }

    setDrugs(uniq(drugs.sort()));
  }, [records]);

  if (!consumerID) return null;
  if (!Array.isArray(form?.fields)) return null;

  const _fields = form?.fields?.reduce((hash, field) => {
    const payload = { ...hash }
    if (field.hide) {
      payload.hidden = [...payload.hidden, field]
    } else  {
      payload.visible = [...payload.visible, field]
    }
    return payload
  }, { hidden: [], visible: [] }) || [];
  _fields.hasFields = _fields.visible.length > 0 || (showDeletedFields && _fields.hidden.length > 0)

  const onlyMe = store.me ?? true;
  const onlyOthers = !onlyMe && store.me === false && (store.others ?? true);
  const all = (store.me ?? true) && (store.others ?? true);

  const _records = records
    .filter(
      (record) =>
        (!record.hide || props.showDeletedRecords) &&
        (all ||
          (onlyMe && record.createdBy === user.state.uid) ||
          (onlyOthers && record.createdBy !== user.state.uid))
    )
    .sort((a, b) => {
      if (!a.updatedAt || !b.updatedAt) return 0;
      if (!a.fields && b.fields) return -1;
      if (a.fields && !b.fields) return 1;
      if (!a.fields && !b.fields) return 0;
      for (const field of form.fields) {
        if (!field.sort && a.createdAt > b.createdAt) return -1;
        if (!field.sort && a.createdAt < b.createdAt) return 1;
        const cellA = a.fields[field.id]?.[field.type];
        const cellB = b.fields[field.id]?.[field.type];
        if (field.sort === "desc" && cellA > cellB) return -1;
        if (field.sort === "desc" && cellA < cellB) return 1;
        if (field.sort === "asc" && cellA < cellB) return -1;
        if (field.sort === "asc" && cellA > cellB) return 1;
        return 0;
      }
    });

  return _records.length === 0 ? (
    <div className="p-2">
      <button onClick={addRecord} className="btn btn-sm btn-outline-primary">
        {t("form_add_record_empty_state", "Agregar el primer registro")}
      </button>
    </div>
  ) : (
    <>
      {interactions.data.length > 0 && (
        <div
          className="alert alert-warning alert-dismissible fade show mb-0"
          role="alert"
        >
          <strong>{t("interactions_found", "Interacciones")}</strong>{" "}
          {t(
            "interactions_found_message",
            `Detectamos interacciones medicamentosas entre los tratamientos`,
            { count: interactions.data.length }
          )}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}
      <table className="table table-bordered mb-0">
        {form?.orientation === "vertical" ? (
          <tbody>
            <tr className="d-print-none">
              <td className="align-middle p-0">
                <button
                  onClick={addRecord}
                  className="d-flex align-items-center btn text-success text-start w-100 lh-1 p-2"
                  data-tip={t(
                    "ehr:add_new_record_tip",
                    "Agregar un nuevo registro"
                  )}
                >
                  <IconPlusCircleFille className="me-2" />
                  <div>{t("new")}</div>
                </button>
              </td>
              {_records.map((record) => (
                <td
                  className="p-0 bg-light text-end align-middle"
                  key={`a${form.id}-${record.id}`}
                  style={{
                    width: `${100 / _records.length}%`,
                  }}
                >
                  <Menu
                    interactions={interactions.data}
                    record={record}
                    onChange={onChangeRecord}
                  />
                </td>
              ))}
            </tr>
            {_fields.visible.map(field => (
              <tr key={`${form.id}-${field.id}`}>
                <Field field={field} form={form} onChange={onChangeField} />
                {_records.map(record => (
                  <Cell
                    style={{ width: `${100 / _records.length}%` }}
                    key={`${form.id}-${field.id}-${record.id}`}
                    record={record}
                    field={field}
                    form={form}
                    onChange={onChangeRecord}
                  />
                ))}
              </tr>
            ))}
            {showDeletedFields && _fields.hidden.map(field => (
              <tr key={`${form.id}-${field.id}`}>
                <Field field={field} form={form} onChange={onChangeField} />
                {_records.map(record => (
                  <Cell
                    style={{ width: `${100 / _records.length}%` }}
                    key={`${form.id}-${field.id}-${record.id}`}
                    record={record}
                    field={field}
                    form={form}
                    onChange={onChangeRecord}
                  />
                ))}
              </tr>
            ))}
            <tr className="d-print-table-row d-none">
              <th></th>
              {_records
                .filter((record) => record.createdAt)
                .map((record) => (
                  <td
                    key={`${form.id}-summary-${record.id}`}
                    className={`${styles.audit} p-1 text-secondary align-middle lh-1`}
                  >
                    <div>{record.createdBy?.replace("users/", "")}</div>
                    <div>
                      {formatDate(
                        record.createdAt,
                        "H:mm:ss dd-MMM-yyyy"
                      ).toUpperCase()}
                    </div>
                    {record.createdAt !== record.updatedAt && (
                      <div>
                        {formatDate(
                          record.updatedAt,
                          "H:mm:ss dd-MMM-yyyy"
                        ).toUpperCase()}
                      </div>
                    )}
                  </td>
                ))}
            </tr>
          </tbody>
        ) : (
          <tbody>
            {_fields.hasFields > 0 && (
              <tr>
                <td
                  className="d-print-none align-middle text-center p-0"
                  style={{ width: "42px" }}
                >
                  <button
                    onClick={addRecord}
                    className="btn text-success lh-1 p-2"
                    data-tip={t(
                      "ehr:add_new_record_tip",
                      "Agregar un nuevo registro"
                    )}
                  >
                    <IconPlusCircleFille />
                  </button>
                </td>
                {_fields.visible.map(field => (
                  <Field
                    field={field}
                    form={form}
                    onChange={onChangeField}
                    key={`${form.id}-${field.id}`}
                  />
                ))}
                {showDeletedFields && _fields.hidden.map(field => (
                  <Field
                    field={field}
                    form={form}
                    onChange={onChangeField}
                    key={`${form.id}-${field.id}`}
                  />
                ))}
              </tr>
            )}
            {_records.map((record) => (
              <Fragment key={`${form.id}-${record.id}`}>
                <tr>
                  <td className="p-0 d-print-none" style={{ width: "42px" }}>
                    <Menu
                      interactions={interactions.data}
                      record={record}
                      onChange={onChangeRecord}
                    />
                  </td>
                  {_fields.visible.map(field => (
                    <Cell
                      key={`${form.id}-${field.id}-${record.id}`}
                      record={record}
                      field={field}
                      form={form}
                      onChange={onChangeRecord}
                    />
                  ))}
                  {showDeletedFields && _fields.hidden.map(field => (
                    <Cell
                      key={`${form.id}-${field.id}-${record.id}`}
                      record={record}
                      field={field}
                      form={form}
                      onChange={onChangeRecord}
                    />
                  ))}
                </tr>
                {record.createdAt && (
                  <tr className="d-print-table-row d-none">
                    <td
                      colSpan={_fields.visible.length + (showDeletedFields ? _fields.hidden.length : 0)  + 1}
                      className={`${styles.audit} p-1 text-secondary align-middle lh-1`}
                    >
                      {[
                        record.createdBy?.replace("users/", ""),
                        formatDate(
                          record.createdAt,
                          "H:mm:ss dd-MMM-yyyy"
                        ).toUpperCase(),
                        record.createdAt !== record.updatedAt &&
                          formatDate(
                            record.updatedAt,
                            "H:mm:ss dd-MMM-yyyy"
                          ).toUpperCase(),
                      ]
                        .filter(Boolean)
                        .join(" ")}
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        )}
      </table>
    </>
  );
};

export default Table;
