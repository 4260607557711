import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg'
import { ReactComponent as IconPlusSquareFill } from 'bootstrap-icons/icons/plus-square-fill.svg'
import { ReactComponent as IconSearch } from 'bootstrap-icons/icons/search.svg'
import { v4 as uuid } from "uuid";
import { Collapse } from 'bootstrap'

import useUser from "hooks/useUser";
import useStore from '../useStore'
import styles from './styles.module.scss'
import Select from './Select'
import Diagnosis from './Diagnosis'

const Diagnoses = ({ className }) => {
  const { t } = useTranslation();

  const user = useUser();
  const [state, setState] = useState({ all: [], current: [] })

  const $collapse = useRef(null)
  
  const store = useStore(state => ({
    consumer: state.consumer,
    resource: state.resource,
    diagnostics: state.diagnostics,
    update: state.update,
  }))

  const licenses = store.resource?.licenses?.filter(e => e.type === "license")?.map(l =>
    l.jurisdiction === "AR" ? `M.N. ${l.value}` : `M.P. ${l.value}`
  ).join("-");

  const add = diagnosis => {
    const payload = {
      type: "records",
      consumer: { id: store.consumer?.id, label: store.consumer?.label },
      createdAt: Date.now(),
      createdBy: user.state.uid,
      createdByName: store.resource?.label,
      createdByResourceID: store.resource?.id,
      _id: uuid(),

      label: diagnosis.label,
      cie10Code: diagnosis.id?.replace(/^cie10:/, ''),
      dx: diagnosis.label,
      status: 'chronic',
      startsAt: new Date().toLocaleDateString('fr-ca'),
      type: 'diagnostics',
    }

    const current = store.diagnostics || []
    const diagnostics = [payload, ...current]

    store.update({ diagnostics })
  }

  const onChange = record => {
    const diagnostics = [...store.diagnostics]
    const index = diagnostics.findIndex(i => (
      record._id && record._id === i._id
    ) || (
      record.id && record.id === i.id
    ))
    if (index < 0) return
    diagnostics[index] = { ...record }
    store.update({ diagnostics })
  }

  useEffect(() => {
    try {
      const state = store.diagnostics.reduce((hash, record) => {
        if (record._id) {
          return { ...hash, current: [...hash.current, record] }
        } else {
          return { ...hash, all: [...hash.all, record] }
        }
      }, { current: [], all: [] })

      setState(state)
    } catch {}

  }, [store.diagnostics])

  return (
    <div className="bg-white rounded">
      <div className="d-flex gap-2 align-items-center p-2" style={{ height: '56px' }}>
        <div className="flex-fill">
          <Select
            path={`catalogs/cie10`}
            onChange={add}
            placeholder={<div><IconSearch width="15" height="15" className="text-secondary opacity-50 me-1 mt-n1" /> {t('search_diagnoses_placeholder', 'Buscador de diagnosticos')}</div>}
            getOptionValue={item => item.id}
          />
        </div>
        {state.all.length > 0 ? (
          <button
            data-bs-toggle="collapse"
            data-bs-target="#collapseDiagnoses"
            data-tip={t('show_all_diagnoses_tip', `Mostrar los ${state.all.length} diagnosticos cargados anteriormente`, { count: state.all.length })}
            className="btn bg-white link-primary"
          >
            <IconChevronExpand width="16" height="16" /> {state.all.length}
          </button>
        ) : (
          <span className="text-secondary opacity-75 me-3">0</span>
        )}
      </div>

      {state.current.length > 0 && (
        <div className="d-flex flex-column">
          {state.current.map(record => (
            <Diagnosis
              state={record}
              key={record._id}
              onChange={onChange}
            />
          ))}
        </div>
      )}

      <div className="collapse" id="collapseDiagnoses" ref={$collapse}>
        <div className="px-3 text-center bg-light border-top">
          <small className="font-small-caps fw-bold">{t('prev_diagnoses', 'Diagnosticos anteriores')}</small>
        </div>
        <div className="d-flex flex-column">
          {state.all.map(record => (
            <Diagnosis key={record.id} state={record} onChange={onChange} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Diagnoses;
