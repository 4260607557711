import { useTranslation } from "react-i18next";
import { formatDate } from "../../config/i18n";
import { ReactComponent as IconInfoCircle } from 'bootstrap-icons/icons/info-circle.svg'

const Info = ({ event, className }) => {
  const { t } = useTranslation();
  const notifications = Object.values(event.notifications || {}).filter(n => n?.createdAt).sort(
    (a, b) => a.createdAt - b.createdAt
  );
  let html = `
    ${
      event.overbooking
        ? `<div class="text-uppercase fw-bold text-danger">${t(
            "overbooking"
          )}</div>`
        : ""
    }
    <dl>
      ${!event.time && !!event.range ?(`
        <dt>${t("range_time", 'Rango Horario')}</dt>
        <dd>${event.range}</dd>
      `) : ''}
      <dt>${t("create", { context: "events" })}</dt>
      <dd>${event.createdBy}</dd>
      <dd class="text-first-up">${formatDate(
        event.createdAt,
        "EEEE d MMM HH:mm:ss"
      )}</dd>
      <dt>${t("update", { context: "events" })}</dt>
      <dd>${event.updatedBy}</dd>
      <dd class="text-first-up">${formatDate(
        event.updatedAt,
        "EEEE d MMM HH:mm:ss"
      )}</dd>
      ${
        Array.isArray(event.states)
          ? event.states
              .sort((a, b) => a.createdAt - b.createdAt)
              .map(
                (state) => `
        <dt>${t(`status_${state.status}`, { context: "events" })}</dt>
        ${state.by ? `<dd>${state.by}</dd>` : ""}
        <dd class="text-first-up">${formatDate(
          state.createdAt,
          "EEEE d MMM HH:mm:ss"
        )}</dd>
      `
              )
              .join("")
          : ""
      }
      ${
        notifications.length > 0
          ? `<dt>${t("notifications", { context: "events" })}</dt>`
          : ""
      }
      ${notifications
        .map(
          (notification) => `
        <dd class="text-first-up">${t(
          notification.kind || "unkown"
        )} - ${formatDate(
            notification.createdAt,
            "EEEE d MMM HH:mm:ss" || 0
          )}</dd>
      `
        )
        .join("")}
    </dl>
  `;
  return (
    <IconInfoCircle
      width="14"
      height="14"
      data-tip={html}
    />
  );
};

export default Info;
