import { useTranslation } from "react-i18next";

import Cell from './Cell'

function Row(props) {
  const { t } = useTranslation()

  return (
    <tr className="bg-white">
      <td
        style={{ width: '120px', maxWidth: '120px', minWidth: '120px' }}
        className="text-secondary text-xs"
      >{t(`ehr:${props.cells.id}`, props.cells.label || props.cells.id)}</td>
      
      {props.cells.items.map(cell => (
        <Cell
          key={`p2-${cell.id}/${props.setupID || '-'}/${cell.fId || '-'}`}
          cell={cell}
          allNumbers={props.allNumbers}
        />
      ))}

      {props.allNumbers && (
        <td></td>
      )}
    </tr>
  )
}

export default Row
