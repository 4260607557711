import { useState } from 'react'
import { useTranslation } from "react-i18next";
import { AsyncTypeahead, Hint } from "react-bootstrap-typeahead";
import { useQuery } from 'react-query'
import useUser from "hooks/useUser";
import deburr from 'lodash/deburr'
import kebabCase from 'lodash/kebabCase'

import styles from './styles.module.scss'

const {
  REACT_APP_API_URL
} = process.env

const request = async (q, locale = 'es-AR', country = 'ar') => {
  if (!q) return []
  const params = new URLSearchParams({ q, locale, country }).toString()
  const url = `${REACT_APP_API_URL}/catalogs/cie10?${params}`
  const res = await fetch(url)
  if (res.status >= 200 && res.status <= 299) {
    const items = await res.json()
    let key1 = locale.toLowerCase()
    let key2 = key1.split('-').at(0)
    return items.map(item => ({
      ...item,
      code: item.id.replace('cie10:', '').replace(/-/g, '').toUpperCase(),
      key: item.key || item.id,
      label: item.labels?.[key1] || item.labels?.[key2] || item.label || ''
    }))
  } else {
    return []
  }
}

function Diagnoses(props) {
  const { t } = useTranslation()
  const [query, setQuery] = useState()
  const [state, setState] = useState(props.value || [])
  const user = useUser();

  const search = useQuery(['diagnoses', query], () => request(query, user.state.language, user.state.country), {
    refetchOnWindowFocus: false,
    enabled: !!query
  })

  const onChange = items => {
    setState(items)
    if (props.onChange) props.onChange(items.map(i => ({
      code: (i.code || i.id || `custom:${kebabCase(deburr(i.label))}`).replace('cie10:', '').replace(/-/g, '').toUpperCase(),
      label: i.label,
      labels: undefined,
      dx: undefined,
      namespace: undefined,
      public: undefined,
      normalized: undefined,
      key: undefined,
    })))
  }

  return (
    <div>
      <AsyncTypeahead
        minLength={2}
        align="left"
        id="diagnoses"
        allowNew={results => !search.isLoading && results.length === 0}
        isLoading={search.isLoading}
        filterBy={() => true}
        onChange={onChange}
        options={search.data}
        onSearch={setQuery}
        placeholder={props.placeholder ?? t('ehr:diangosis', 'Diagnostico')}
        promptText={t(`type_to_search`, "Escriba para buscar")}
        searchText={t(`searching`)}
        newSelectionPrefix={null}
        className={styles.diagnoses}
        selected={state}
        renderMenuItemChildren={(option, _props, index) => {

          return props.column ? (
            <div className="border-bottom pb-2 px-3 mx-n3">
              {option.code && (<small className="fw-bold">{option.code}: </small>)}{option.label}
            </div>
            ) : (
            <div className="d-flex align-items-center">
              {option.code && (
                <span
                  className="text-truncate"
                  style={{ width: '65px', minWidth: '65px', maxWidth: '65px' }}
                >{option.code}</span>
              )} {option.label}
            </div>
          )
        }}
      />
    </div>
  )
}

export default Diagnoses
