import React, { useRef, useEffect } from "react";

const Form = (props) => {
  const $form = useRef(null);

  useEffect(() => {
    if (props.skip && $form && $form.current && $form.current.classList) {
      $form.current.classList.remove("was-validated");
    }
  }, [props.skip, $form]);

  function onSubmit(e) {
    e.preventDefault();
    $form.current.classList.remove("was-validated");
    if (typeof props.beforeValidate === "function" && !props.skip)
      props.beforeValidate(e);

    if ($form.current.checkValidity() && typeof props.onSubmit === "function") {
      props.onSubmit(e);
    } else {
      $form.current.classList.add("was-validated");
      $form.current.reportValidity();
    }
  }

  return (
    <form
      noValidate
      ref={$form}
      onSubmit={onSubmit}
      className={props.className || ""}
    >
      {props.children}
    </form>
  );
};

export default Form;
