import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../config/i18n";

function Diagnostics(props) {
  const { t } = useTranslation();

  const onClick = () => {
    if (props.type === "chronic") {
      props.setPrintStatus({
        ...props.printStatus,
        diagnosticsChronic: false,
      });
    } else if (props.type === "intercurrence") {
      props.setPrintStatus({
        ...props.printStatus,
        diagnosticsIntercurrence: false,
      });
    } else {
      props.setPrintStatus({
        ...props.printStatus,
        diagnosticsPending: false,
      });
    }
  };

  function renderDiagnostics(array, type) {
    return array.map((element, index) => {
      if (element.display && element.id) {
        return (
          <div
            className={
              index + 1 === array.length
                ? "px-4 py-3"
                : "px-4 py-2 border-bottom"
            }
          >
            <p className="mb-0">
              <span className="">{element.dx || element.label}</span>{" "}
              {element.startsAt && (
                <small className="text-muted ms-4">
                  {formatDate(element.startsAt, "dd-MMM-yyyy")}
                </small>
              )}{" "}
              {element.endsAt && (
                <small className="text-muted">
                  {" "}
                  - {formatDate(element.endsAt, "dd-MMM-yyyy")}
                </small>
              )}
              <small className="text-muted">
                {" "}
                {t("ehr:by")}{" "}
                {element.createdByName &&
                  element.createdByName.replace(",", " ")}
                <small className="ms-1">
                  {element.createdByLicense && element.createdByLicense}
                </small>
              </small>
              {props.showBtns && (
                <button
                  className="btn btn-sm text-danger"
                  onClick={() => {
                    handleDeleteDiagnostics(element.id, type);
                  }}
                >
                  <small>{t("ehr:hide")}</small>
                </button>
              )}
            </p>
            {props.printStatus.diagnosticsNotes && (
              <small>{element.notes}</small>
            )}
          </div>
        );
      }
    });
  }

  function handleDeleteDiagnostics(recordID, diagnosticType) {
    let newArray = props.array.map((element) => {
      if (element.id === recordID) element.display = false;
      return element;
    });

    if (diagnosticType === "chronic") {
      props.setPrintStatus({
        ...props.printStatus,
        individualDiagnosticsChronic: newArray,
      });
    } else if (diagnosticType === "intercurrence") {
      props.setPrintStatus({
        ...props.printStatus,
        individualDiagnosticsIntercurrence: newArray,
      });
    } else {
      props.setPrintStatus({
        ...props.printStatus,
        individualDiagnosticsPending: newArray,
      });
    }
  }

  return (
    <>
      {props.array.filter((element) => element.id).length > 0 && (
        <div className="d-flex align-items-center bg-light px-4 py-3 mb-2">
          <span className="h4 fw-bold mb-0">
            {t(`${props.type}_diagnostics`)}
          </span>
          {props.showBtns && (
            <button
              className="btn btn-link btn-sm text-danger"
              onClick={onClick}
            >
              <small>{t("ehr:hide")}</small>
            </button>
          )}
        </div>
      )}
      <div className="">{renderDiagnostics(props.array, props.type)}</div>
    </>
  );
}

export default Diagnostics;
