import Record from "../Record/Record";
import Diagnoses from './Diagnoses'
import Treatments from './Treatments'
import Files from './Files'

const Home = ({ className }) => {
  return (
    <div className="d-flex flex-column gap-2">
      <Record />
      <Diagnoses />
      <Treatments />
      <Files />
    </div>
  )
}

export default Home;
