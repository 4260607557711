import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import ReactTooltip from "react-tooltip";
import cx from "classnames";
import kebabCase from "lodash/kebabCase";
import { ReactComponent as IconTrash } from "bootstrap-icons/icons/trash.svg";
import { ReactComponent as IconPlus } from "bootstrap-icons/icons/plus.svg";
import { ReactComponent as IconArrowRepeat } from "bootstrap-icons/icons/arrow-repeat.svg";
import { ReactComponent as IconEyeSlash } from "bootstrap-icons/icons/eye-slash.svg";
import { ReactComponent as IconEye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as IconChevronExpand } from "bootstrap-icons/icons/chevron-expand.svg";
import { ReactComponent as IconSortAlphaDown } from "bootstrap-icons/icons/sort-alpha-down.svg";
import { ReactComponent as IconExclamationTriangle } from "bootstrap-icons/icons/exclamation-triangle.svg";
import { ReactComponent as IconRecycle } from "bootstrap-icons/icons/recycle.svg";

import useMutation from "hooks/useMutation";
import styles from "./styles.module.scss";
import Table from "./Table/Table";
import useStore from "../useStore";

let ticker;
const Form = (props) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const store = useStore();
  const [showDeletedFields, setShowDeletedFields] = useState(false)
  const [showDeletedRecords, setShowDeletedRecords] = useState(false)
  const [form, setForm] = useState(
    JSON.clone({
      id: props.id || `forms/${uuid()}`,
      orientation: props.orientation || "horizontal",
      label: props.label || "",
      fields: props.fields || [{ id: uuid(), type: "text" }],
      hide: props.hide,
    })
  );

  const [mutate] = useMutation(window._l("/forms").substr(1), {
    onMutate: (payload) => {},
    onSuccess: (data) => {},
    onError: (error) => {
      window.notify({
        message: error.message,
        type: "danger",
        title: "Error",
      });
    },
  });

  const rotate = () => {
    if (form.orientation === "vertical") {
      form.orientation = "horizontal";
    } else {
      form.orientation = "vertical";
    }
    save(form);
  };

  const addField = () => {
    if (!Array.isArray(form.fields)) {
      form.fields = [];
    }
    form.fields.push({ id: uuid() });
    save(form);
  };

  const toggle = () => {
    if (props.onHide) return props.onHide();
    if (
      form.hide &&
      !window.confirm(
        t(
          "form_remove_confirmation",
          "Al borrar el formulario va a dejar de verse en todos los pacientes. Lo podemos volver a ver filtrando por formularios eliminados"
        )
      )
    )
      return;
    save({ ...form, hide: !form.hide });
  };

  const onChangeSort = (field, sort) => {
    const index = form.fields.findIndex((f) => f.id === field.id);
    if (index < 0) return;
    form.fields[index].sort = sort;
    save(form);
  };

  const save = (payload) => {
    const form = JSON.clone(payload);
    setForm(form);
    clearTimeout(ticker);
    ticker = setTimeout(() => mutate(form), 700);
  };

  const _fields = form?.fields?.filter((field) => !field.hide) || [];

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    setForm({
      ...form,
      fields: props.fields || [{ id: uuid(), type: "text" }],
    });
  }, [props.fields]);

  if (form.hide && !store[form.id]) return null;
  if (store[form.id] === false) return null;

  const collapseID = kebabCase(`collapseForm${form.id}`);

  const deletedRecords = props.records?.filter(record => record.hide) || []

  return (
    <div
      className={`${styles.container} ${
        collapsed ? "d-print-none" : ""
      } bg-white shadow-sm mb-3`}
    >
      <div className="d-flex align-items-center border-bottom">
        <button
          className="btn btn-sm text-secondary"
          onClick={() => setCollapsed(!collapsed)}
          data-bs-toggle="collapse"
          data-bs-target={`#${collapseID}`}
        >
          <IconChevronExpand />
        </button>
        {form.hide && (
          <div className="badge bg-danger mx-2">{t("hidden", "Oculto")}</div>
        )}
        <input
          type="text"
          value={form.label || ""}
          onChange={(e) => save({ ...form, label: e.target.value })}
          className={`${styles.input} form-control p-2 rounded-0 border-0 text-uppercase`}
          placeholder={t("ehr:form_label", `Ingrese un nombre para formulario`)}
        />
        {deletedRecords.length > 0 && (
          <button
            onClick={() => setShowDeletedRecords(!showDeletedRecords)}
            className="btn btn-sm text-danger"
            data-tip={t("show_form_deleted_records", "Hay registros que fueron borrados. Hace click para verlos")}
          >
            <IconRecycle />
          </button>
        )}
        {form.fields.some(f => f.hide) && (
          <button
            onClick={() => setShowDeletedFields(!showDeletedFields)}
            className="btn btn-sm text-warning"
            data-tip={t("show_form_deleted_fields", "Hay campos que fueron borrados. Hace click para ver los campos borrados")}
          >
            <IconExclamationTriangle />
          </button>
        )}
        <button
          onClick={addField}
          className="btn btn-sm text-secondary"
          data-tip={t("add_new_field_tip", "Agregar un campo")}
        >
          <IconPlus />
        </button>
        <button
          onClick={rotate}
          className={cx("btn btn-sm text-secondary", {
            "rotate-90": form.orientation === "vertical",
          })}
          data-tip={t("ehr:form_rotate_tip", "Rotar")}
        >
          <IconArrowRepeat />
        </button>
        <button
          className="btn btn-sm text-secondary"
          onClick={toggle}
          data-tip={t(
            "ehr:form_hide_tip",
            "Ocultar/Restaurar el formulario. Para volver a ver el formulario podes filtrarlo desde el menu filtros y si lo queres volver a siempre haciendo click de nuevo lo mostras siempre"
          )}
        >
          <IconTrash />
        </button>
        <div>
          <button
            className="btn btn-sm text-secondary"
            data-bs-toggle="dropdown"
            data-bs-auto-close="false"
          >
            <IconSortAlphaDown />
          </button>
          <div className="dropdown-menu p-0 shadow-lg">
            {form.fields.map((field) => {
              if (!["number", "date"].includes(field.type)) return null;
              const label = field?.label
                ? field.label
                : field.type === "number"
                ? t("form_field_type_number", "Numerica")
                : field.type === "date" && t("form_field_type_date", "Fecha");
              return (
                <div
                  key={`${form.id}-${field.id}`}
                  className="p-2 d-flex align-items-center border-top"
                >
                  <div className="flex-fill">{label}</div>
                  <div>
                    <select
                      className="form-select form-select-sm border-0"
                      value={field.sort}
                      onChange={(e) => onChangeSort(field, e.target.value)}
                    >
                      <option value="">{t("no_order", "Sin orden")}</option>
                      <option value="asc">
                        {t("order_asc", "Ascendente")}
                      </option>
                      <option value="desc">
                        {t("order_desc", "Descendente")}
                      </option>
                    </select>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="collapse show" id={collapseID}>
        <Table
          records={props.records}
          showDeletedRecords={showDeletedRecords}
          showDeletedFields={showDeletedFields}
          form={form}
          onChange={save}
        />
      </div>
    </div>
  );
};

export default Form;
