import uniq from "lodash/uniq";
import create from "zustand";
import { Modal } from "bootstrap";

import makeid from "../../lib/makeid";

const useStore = create((set) => ({
  ids: [],
  open: (id) => {
    document.querySelectorAll(".modal").forEach((element) => {
      try {
        Modal.getInstance(element).hide();
      } catch {}
    });

    return set((state) => ({
      ids: uniq([...state.ids, id || makeid(8)]),
    }));
  },
  close: (value) =>
    set((state) => ({
      ids: state.ids.filter((id) => id !== value),
    })),
}));

export default useStore;
