import { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Fuse from 'fuse.js'
import { ReactComponent as IconSearch } from 'bootstrap-icons/icons/search.svg'
import { ReactComponent as IconChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg'
import ReactTooltip from 'react-tooltip'

import { formatDate } from 'config/i18n'
import useUser from 'hooks/useUser'
import parseDate from 'lib/parseDate'
import useStore from '../useStore'
import styles from './styles.module.scss'
import sort from '../sort'
import Record from '../Record/Record'

const Consultations = () => {
  const { t } = useTranslation()
  const [consultations, setConsultations] = useState()
  const store = useStore(state => ({
    records: state.records || [],
    diagnostics: state.diagnostics || [],
    treatments: state.treatments || [],
    files: state.files || [],
    sort: state.sort,
  }))
  const [expand, setExpand] = useState({})
  const [search, setSearch] = useState('')
  const [record, setRecord] = useState()
  const user = useUser()
  const $modal = useRef(null)

  const toggle = key => {
    expand[key] = !expand[key]
    setExpand({ ...expand })
  }

  const edit = record => {
    setRecord({ ...record })
  }

  useEffect(() => {
    let consultations = {}
    const array = [...store.records, ...store.diagnostics, ...store.treatments, ...store.files]
    for (const record of array) {
      const createdBy = record.createdBy || record.createdByName || 'unknown'
      const date = parseDate(record.date || record.createdAt || Date.now()).toLocaleDateString('fr-ca')
      const key = `${createdBy}-${date}`
      consultations[key] = consultations[key] || { evolutions: [], diagnoses: [], treatments: [], files: [], key }
      consultations[key].date = date
      consultations[key].createdAt = record.createdAt
      consultations[key].createdBy = createdBy
      consultations[key].label = record.createdByName || record.createByName || record.createdBy || record.createBy
      if (record.type === "records") {
        consultations[key].evolutions.push(record)
      } else if (record.type === "diagnostics") {
        consultations[key].diagnoses.push(record)
      } else if (record.type === "treatments") {
        consultations[key].treatments.push(record)
      } else if (record.type === "files") {
        consultations[key].files.push(record)
      }
    }

    consultations = Object.values(consultations).sort(sort(store.sort))
    setConsultations(consultations)
  }, [JSON.stringify(store.records), JSON.stringify(store.diagnostics), JSON.stringify(store.treatments), JSON.stringify(store.files), store.sort])

  useEffect(() => {
    const hide = () => setRecord()
    if ($modal.current) {
      $modal.current.addEventListener('hide.bs.modal', hide)
    }
    return () => {
      if ($modal.current) {
        $modal.current.removeEventListener('hide.bs.modal', hide)
      }
    }
  }, [$modal.current])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!consultations) return null

  const fuse = new Fuse(consultations, {
    keys: ['evolutions.content', 'diagnoses.label', 'diagnoses.dx', 'treatments.drug', 'files.name'],
    threshold: 0.3,
  })

  let results = consultations

  if (search) {
    results = fuse.search(search).flatMap(i => i.item)
  }

  return (
    <div>
      <div className="modal fade" id="consultationsRecordModal" tabIndex="-1" aria-hidden="true" ref={$modal}>
        <div className="modal-dialog">
          <div className="modal-content">
            <button style={{ zIndex: 1 }} type="button" className="btn-close position-absolute end-0 top-0 m-1" data-bs-dismiss="modal" aria-label="Close"></button>
            {record && (
              <div className="modal-body">
                <Record state={record}/>
              </div>
            )}
          </div>
        </div>
      </div>
      {consultations.length > 0 && (
        <div className="position-relative">
          <input
            placeholder={t('consultations_search_placeholder', 'Buscador de consultas')}
            type="search"
            value={search}
            onChange={e => setSearch(e.target.value)}
            style={{ padding: '10px 15px !important' }}
            className={`${styles.search} form-control py-2 border-transparent mb-2 fw-light`}
          />
          <button
            data-tip={expand.all ? t('collapse_all_consultations_tip', 'Contraer todas las consultas') : t('expand_all_consultations_tip', 'Expandir todas las consultas')}
            className="btn position-absolute end-0 top-0"
            onClick={() => setExpand({ all: !expand.all })}
          >
            <IconChevronExpand height="16" width="16" className="text-primary" />
          </button>
        </div>
      )}

      <div className="d-flex flex-column gap-0">
        {results.map(consultation => (
          <div key={consultation.key} className="border-bottom overflow-hidden">
            <div className="d-flex align-items-center justify-content-end gap-2 bg-light p-2">
              <button
                onClick={() => toggle(consultation.key)}
                className="btn p-0 text-primary text-first-up lh-1"
              ><IconChevronExpand width="16" height="16" /></button>
              <div className="flex-fill d-flex align-items-center gap-2">
                <div className={`${consultation.evolutions.length ? 'text-teal fw-bold' : 'text-secondary'} small`} data-tip={t('evolutions', 'Evoluciones')}>{t('evolutions_abbr', 'E')}<span className="fw-light text-secondary small">:{consultation.evolutions.length}</span></div>
                <div className={`${consultation.diagnoses.length ? 'text-teal fw-bold' : 'text-secondary'} small`} data-tip={t('diagnoses', 'Diagnosticos')}>{t('diagnoses_abbr', 'D')}<span className="fw-light text-secondary small">:{consultation.diagnoses.length}</span></div>
                <div className={`${consultation.treatments.length ? 'text-teal fw-bold' : 'text-secondary'} small`} data-tip={t('treatments', 'Tratamientos')}>{t('treatments_abbr', 'T')}<span className="fw-light text-secondary small">:{consultation.treatments.length}</span></div>
                <div className={`${consultation.files.length ? 'text-teal fw-bold' : 'text-secondary'} small`} data-tip={t('files', 'Archivos')}>{t('files_abbr', 'A')}<span className="fw-light text-secondary small">:{consultation.files.length}</span></div>
              </div>
              <div className="text-secondary text-truncate small">{consultation.label}</div>
              <div className="text-truncate text-uppercase small">{formatDate(parseDate(consultation.date || consultation.createdAt), 'dd MMM/yy')}</div>
            </div>
            {(consultations.length < 5 || expand.all || expand[consultation.key]) && (
              <div className="overflow-hidden position-relative d-flex flex-column gap-2 p-3 bg-white border-top">
                {consultation.evolutions.length > 0 && (
                  <div>
                    <div className="fw-bold font-small-caps text-secondary">{t('evolutions', 'Evoluciones')}</div>
                    <ul className="mb-0 ps-4 overflow-hidden">
                      {consultation.evolutions.map(record => (
                        <li key={record.id || record._id}>
                          <span key={record.id || record._id} className={`${styles.reset} ${record.deleted ? 'text-danger' : ''}`} dangerouslySetInnerHTML={{ __html: record.content }} />
                          {record.createdBy === user.state?.uid && Date.now() - record.createdAt <= 24 * 60 * 60 * 1000 && (
                            <button className="btn btn-sm p-0 link-primary" data-bs-toggle="modal" data-bs-target="#consultationsRecordModal" onClick={() => edit(record)}>{t('edit', 'editar')}</button>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {consultation.diagnoses.length > 0 && (
                  <div>
                    <div className="fw-bold font-small-caps text-secondary">{t('diagnoses', 'Diagnosticos')}</div>
                    <ul className="mb-0 ps-4 overflow-hidden">
                      {consultation.diagnoses.map(record => (
                        <li key={record.id || record._id}><span className="text-truncate">{record.dx || record.label}</span></li>
                      ))}
                    </ul>
                  </div>
                )}

                {consultation.treatments.length > 0 && (
                  <div>
                    <div className="fw-bold font-small-caps text-secondary">{t('treatments', 'Tratamientos')}</div>
                    <ul className="mb-0 ps-4 overflow-hidden">
                      {consultation.treatments.map(record => (
                        <li key={record.id || record._id}><span className="text-truncate">{record.drug || record.label}</span></li>
                      ))}
                    </ul>
                  </div>
                )}

                {consultation.files.length > 0 && (
                  <div>
                    <div className="fw-bold font-small-caps text-secondary">{t('files', 'Archivos')}</div>
                    <ul className="mb-0 ps-4 overflow-hidden">
                      {consultation.files.map(record => (
                        <li key={record.id || record._id}>
                          <a className="text-truncate" href={record.link} rel="noopener noreferrer" target={record.id}>{record.name || 'link'}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Consultations
