import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { formatDistanceStrict } from "date-fns";
import useUpload from "../../../../hooks/useUpload";

import useMutation from "../../../../hooks/useMutation";
import { formatDate, getLocale } from "../../../../config/i18n";

let ticker;

function File(props) {
  const [countdown, setCountdown] = useState(null);
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const $files = useRef(null);
  const [file, setFile] = useState(false);

  const [record, setRecord] = useState({});
  const [mode, setMode] = useState("read");
  const upload = useUpload();
  const [uploading, setUploading] = useState(false);

  const [mutate] = useMutation(
    window._l(`/${record.id || "records"}`).substr(1),
    {
      onMutate: (payload) => {
        update(payload);
      },
      onSuccess: (data) => {
        update(data, "clear");
      },
      onError: (error) => {
        window.notify({
          message: error.message,
          type: "danger",
          title: "Error",
        });
      },
    }
  );

  const update = (payload, clear) => {
    setMode("read");
    props.update(payload, clear);
  };

  const onChange = ({ target: { name, value } }) => {
    record[name] = value;
    setRecord({ ...record });
  };

  const restore = () => {
    mutate({
      ...record,
      deleted: false,
    });
  };

  const remove = (mode) => {
    setCountdown(null);
    if (!record.id) {
      return update({ ...record, deleted: true, content: "", text: "" });
    }

    if (mode === "confirm") {
      return mutate({
        ...record,
        deleted: true,
      });
    }

    if (mode === "discard") return;

    setCountdown(true);
  };

  const save = () => {
    mutate({ ...record });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    setRecord({ ...props.record });
    setFile(props.file);
  }, [props.record, props.file]);

  useEffect(() => {
    setMode(!record.id ? "edit" : "read");
  }, [record.id]);

  let age;
  if (record.startsAt) {
    age = formatDistanceStrict(
      new Date(record.startsAt),
      new Date(record.endsAt || Date.now()),
      {
        addSuffix: record.startsAt && !record.endsAt,
        locale: getLocale(),
      }
    );
  }

  const selectedFileUpload = async (file) => {
    if (window.confirm(t("ehr:upload_confirm"))) {
      await startUpload(file);
    }
  };

  const handleFiles = props.handleFiles;

  const startUpload = async (file) => {
    if (!file) return setUploading(false);
    setUploading(true);

    const url = await upload([file]);

    if (!url) return setUploading(false);
    const links = { url: url[0], name: file.name };

    record.link = links.url;
    record.name = links.name;

    setRecord({
      ...record,
    });

    setUploading(false);

    save();
  };

  function handleClose() {
    if (!record.updatedAt) {
      setMode("discard");
    } else {
      setMode("read");
      save();
    }
  }

  function handleUpload() {
    !file ? alert(t("please_select_file")) : selectedFileUpload(file);
  }

  return mode === "edit" ? (
    <div className="bg-light p-3 my-3 rounded">
      <div className="d-flex align-items-center mb-3">
        {record.link ? (
          <div className="form-floating  mx-3">
            <a className="form-control mb-0 bg-white" href={record.link}>
              {record.name}
            </a>
            <label htmlFor="formFile" className="form-label">
              {t("file")}
            </label>
          </div>
        ) : !file ? (
          <div className="form-floating  mx-3">
            <div className="form-control bg-white">
              <button
                className="btn btn-link text-primary p-0"
                onClick={() => {
                  $files.current.click();
                }}
              >
                {t("select")}
              </button>
            </div>
            <label for="formFile" className="form-label">
              {t("file")}
            </label>
          </div>
        ) : (
          <div className="form-floating mx-3">
            <div className="form-control bg-white">
              <button
                className="btn btn-link text-danger me-1 p-0"
                onClick={() => {
                  setFile(false);
                }}
              >
                x
              </button>
              <a
                className="btn btn-link text-secondary p-0"
                href={URL.createObjectURL(file)}
                target="_blank"
              >
                {file.name}
              </a>
            </div>
            <label for="formFile" className="form-label">
              {t("file")}
            </label>
          </div>
        )}

        {record.file && !record.link && (
          <div className="form-floating  mx-3">
            <a className="form-control mb-0 bg-white" href={record.link}>
              {record.name}
            </a>
            <label for="formFile" className="form-label">
              {t("file")}
            </label>
          </div>
        )}

        <input
          ref={$files}
          type="file"
          className="form-control d-none"
          id="files"
          name="files[]"
          onChange={(e) => {
            handleFiles(e, record, setFile, setRecord);
          }}
        />

        <div className="form-floating flex-fill mx-3">
          <input
            value={record.notes}
            onChange={onChange}
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder={t("notes_placeholder")}
          />
          <label htmlFor="notes">{t("notes")}</label>
        </div>

        <div className="form-floating me-2">
          <input
            value={record.startsAt || ""}
            onChange={onChange}
            type="date"
            className="form-control"
            id="startsAt"
            name="startsAt"
            placeholder={t("startsAt_placeholder")}
          />
          <label htmlFor="startsAt">{t("date")}</label>
        </div>

        <div className="form-floating">
          <select
            name="status"
            id="status"
            className="form-select"
            onChange={onChange}
            value={record.status}
          >
            <option value="unclassified">{t("unclassified")}</option>
            <option value="lab">{t("lab")}</option>
            <option value="tc">{t("tc")}</option>
            <option value="rmn">{t("rmn")}</option>
            <option value="report">{t("report")}</option>
            <option value="rx">{t("rx")}</option>
          </select>
          <label htmlFor="status">{t("classification")}</label>
        </div>
      </div>

      {record.deleted ? (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={restore}
          >
            {t("restore", { label: t("diagnostic") })}
          </button>
          <button
            className="btn btn-sm btn-link link-secondary"
            onClick={() => setMode("read")}
          >
            {t("close")}
          </button>
        </div>
      ) : countdown !== null ? (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-link link-success me-2"
            onClick={() => remove("discard")}
          >
            {t("discard")}
          </button>
          <button
            className="btn btn-sm btn-link link-danger"
            onClick={() => remove("confirm")}
          >
            {t("confirm")}
          </button>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          {!record.link ? (
            <button
              className="btn btn-sm btn-outline-success me-2"
              onClick={handleUpload}
            >
              {t("upload", { label: t("file") })}
              {uploading === true ? (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
            </button>
          ) : (
            <button className="btn btn-sm btn-link link-success" onClick={save}>
              {t("save")}
            </button>
          )}

          <button
            className="btn btn-sm btn-link link-secondary"
            onClick={handleClose}
          >
            {t("close")}
          </button>
          <button className="btn btn-sm btn-link link-danger" onClick={remove}>
            {t("ehr:delete", { label: t("file") })}
          </button>
        </div>
      )}
    </div>
  ) : mode === "discard" ? null : (
    <div>
      <div className="mt-3 pt-3 border-top d-flex align-items-center justify-content-between">
        <div>
          <a href={record.link}>{record.name}</a>
        </div>
        <div>
          {record.status && (
            <small
              className={cx({
                "text-danger": record.status === "chronic",
                "text-success": record.status === "intercurrence",
                "text-warning": record.status === "pending",
                "text-primary": record.status === "active",
              })}
            >
              {t(`pms:${record.status}`)}
            </small>
          )}
          {record.deleted && (
            <small className="badge bg-danger mx-2 fw-normal">
              {t("ehr:deleted")}
            </small>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          {record.notes && <div className="small">{record.notes}</div>}
          {age && <small className="text-dark me-2">{age}</small>}
          {record.startsAt && (
            <small className="text-uppercase me-2">
              {formatDate(record.startsAt, "dd-MMM-yyyy")}
            </small>
          )}
          <small className="text-muted">
            {record.createdByName}{" "}
            <small>
              {record.createdByLicense &&
                record.createdByLicense.replace(/-/g, " ")}
            </small>
          </small>
        </div>

        <button
          className="btn btn-sm btn-link p-0 align-baseline"
          onClick={() => setMode("edit")}
        >
          {t("edit")}
        </button>
      </div>
    </div>
  );
}

export default File;
