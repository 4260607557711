import { useTranslation } from "react-i18next";

import Switches from "./Switches";
import Resources from "./Resources";
import Services from "./Services";
import Financiers from "./Financiers";
import Locations from "./Locations";
import useStore from "../useStore";

const App = (props) => {
  const { t } = useTranslation()
  const clear = useStore(state => state.clear);
  const flag = !!props.resources + !!props.services + !!props.financiers + !!props.locations
  return (
    <div className={`d-grid gap-1 ${props.className || ''}`}>
      <Resources show={props.resources !== false} />
      <Services show={props.services !== false} />
      <Financiers show={props.financiers !== false} />
      <Locations show={props.locations !== false} />
      {flag > 1 && (
        <div className="d-flex flex-row-reverse align-items-center mb-n2 mt-1">
          <button className="btn btn-sm btn-link" onClick={clear}>
            {t('clean_filters', 'Limpiar filtros')}
          </button>
        </div>
      )}
      {props.switches !== false && <Switches />}
    </div>
  );
}

export default App;
