import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { useQuery, useQueryClient } from "react-query";
import useUser from "../../../../hooks/useUser";
import useQueryLocal from "../../../../hooks/useQuery";
import Treatment from "./Treatment";
import "./treatment.css";
import uniq from "lodash/uniq";

const { REACT_APP_INTERACTIONS_API_URL } = process.env;
const { REACT_APP_PRESCRIPTION_URL } = process.env;

const sort = (a, b) => {
  if (!a.id && b.id) return -1;
  if (a.id && !b.id) return 1;
  return b.createdAt - a.createdAt;
};

const fetchInteractions = (drugs) => {
  const sendDrugs = [];
  drugs.forEach((element) => {
    element.forEach((drug) => sendDrugs.push(drug));
  });

  if (sendDrugs.length === 0 || !sendDrugs) {
    return null;
  }

  return fetch(REACT_APP_INTERACTIONS_API_URL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ drugs: sendDrugs }),
  })
    .then((res) => res.json())
    .then((res) => res.data);
};

function Treatments({ consumer, uid, className, resource }) {
  const { t } = useTranslation();

  let [prescriptionDrugArray, setPrescriptionDrugArray] = useState([]);

  const prescriptionAddDrug = (record) => {
    let found = prescriptionDrugArray.findIndex(
      (element) => element.id === record.id
    );
    if (found === -1) {
      setPrescriptionDrugArray([...prescriptionDrugArray, record]);
    } else {
      setPrescriptionDrugArray(
        prescriptionDrugArray.filter((element) => element.id !== record.id)
      );
    }
  };

  const licenses =
    resource &&
    resource.licenses &&
    resource.licenses
      .filter((element) => element.type === "license")
      .map((license) => {
        if (license.jurisdiction === "AR") {
          return `M.N. ${license.value}`;
        } else {
          return `M.P. ${license.value}`;
        }
      })
      .join("-");

  const queryClient = useQueryClient();

  const [status, setStatus] = useState(["active", "pending", "done"]);
  const user = useUser();

  const [records, setRecords] = useState([]);
  const [stats, setStats] = useState({});
  const [interactionModalContent, setInteractionModalContent] = useState([]);
  const [prescribeMode, setPrescribeMode] = useState(false);

  const query = useQueryLocal(
    window._l(`/${consumer.id}/treatments`).substr(1),
    {
      initialData: [],
    }
  );

  const update = (payload, clear) => {
    const id = payload.id || payload.createdAt;
    const keys = queryClient
      .getQueryCache()
      .findAll()
      .filter((query) => query.queryHash.includes("/treatments"))
      .map((query) => query.queryKey);

    for (const key of keys) {
      queryClient.setQueryData(key, (records) => {
        const array = JSON.clone(records);
        const index = array.findIndex(
          (record) =>
            (record.id || record.createdAt) === id &&
            record.consumer?.id === payload.consumer?.id
        );
        if (index < 0)
          return [...array, payload].filter((record) => !clear || record.id);
        array[index] = payload;
        return array;
      });
    }
  };

  let drugs = query.data
    .filter((treatment) => !treatment.deleted)
    .map(
      (treatment) =>
        treatment.drugs && treatment.drugs.map((element) => element && element)
    )
    .filter((element) => element && element.length > 0);

  const interactions = useQuery(
    ["interactions", drugs],
    () => fetchInteractions(drugs),
    { initialData: [] }
  );

  const add = () => {
    const date = new Date();
    const today = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    update({
      consumer: { id: consumer.id },

      createdBy: user.state.uid,
      createdByName: resource?.label,
      createdByLicense: licenses,
      createdAt: Date.now(),
      status: "active",
      startsAt: today,
      endsAt: "",

      type: "treatments",
      content: "",
      text: "",
      product: "",
      presentation: "",
      company: "",
      drug: "",
      drugs: [],
      dose: "",
      frecuency: "",
      notes: "",
    });
  };

  const toggle = (value) => {
    if (status.includes(value)) {
      setStatus(status.filter((s) => s !== value));
    } else {
      setStatus([...status, value]);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleInteractionClick = (interactions) => {
    setInteractionModalContent(interactions);
  };

  const financier =
    consumer.financiers?.find((financier) => financier.identification) ||
    consumer.financiers?.find((financier) => financier.label);

  const specialty =
    resource &&
    uniq(
      resource.services?.map((service) =>
        t(service.id, service.label).split("/")[0]?.trim()
      ) || []
    ).join(", ");

  const newPrescription = () => {
    const payload = JSON.clone({
      patient: {
        name: [consumer.lastName, consumer.firstName]
          .filter(Boolean)
          .join(", "),
        identification: consumer.identification || "",
        email: consumer.emails?.map((email) => email.value)?.join(" / "),
      },
      practitioner: {
        prefix: resource.namePrefix
          ? `${t(`namePrefix_${resource.namePrefix}`)}`
          : "",
        name: resource.label,
        specialty,
        phone: resource.phones?.map((i) => i.value)?.join(" / "),
        email: resource.emails?.map((i) => i.value)?.join(" / "),
        address: resource.locations?.[0]?.address,
      },
      insurance: {
        name: financier?.label,
        plan: "",
        id: financier?.identification || "",
      },
      member: {
        type: resource.licenses?.some((i) =>
          i.jurisdiction.toLowerCase().includes("/caba/")
        )
          ? "M.N."
          : "M.P.",
        number: resource.licenses
          ?.filter((i) => i.type === "license" && i.value)
          ?.map((i) => i.value)
          ?.join(" "),
      },
      database: user.team?.id,
      practitionerID: user.team?.resourceID,
      highlight: true,
      selected: formatPrescriptionDrug(prescriptionDrugArray),
    });

    const hash = `#${btoa(JSON.stringify(payload))}`;

    window.open(`${REACT_APP_PRESCRIPTION_URL}/${hash}`, "_blank");
  };

  const formatPrescriptionDrug = (drug) => {
    let newArray = [];
    for (const index in drug) {
      newArray.push({
        id: drug[index].product_code,
        label: `${drug[index].drug} ${drug[index].product} ${drug[index].presentation}`,
        drugCode: drug[index].drug_code,
        quantity: 1,
        items: [
          drug[index].drug,
          drug[index].product,
          drug[index].presentation,
        ],
      });
    }

    return newArray;
  };

  useEffect(() => {
    const array = [];
    const stats = {};

    for (const record of query.data) {
      if (uid && record.createdBy !== uid) {
        continue;
      }

      if (record.deleted) {
        stats.deleted = stats.deleted || 0;
        stats.deleted += 1;
      }

      if (record.deleted && !status.includes("deleted")) {
        continue;
      }

      if (record.status && !record.deleted) {
        stats[record.status] = stats[record.status] || 0;
        stats[record.status] += 1;
      }

      if (!record.status) {
        record.status = "chronic";
      }

      if (!record.id || (status.includes(record.status) && !record.deleted)) {
        if (!record.deleted) {
          array.push(record);
        }
      } else {
        if (status.includes("deleted") && record.deleted) {
          array.push(record);
        }
      }
    }

    if (query.data.length === 0) {
      add();
    }

    setRecords(array);
    setStats(stats);
  }, [query.data, status, uid]);

  if (!query.isFetched) {
    return (
      <div className={className || ""}>
        <div className="text-center">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (query.isLoadingError || query.isError) {
    return (
      <div className={className || ""}>
        <div className="text-danger">{t("error")}</div>
      </div>
    );
  }

  const filters = Object.keys(stats || {}).sort((a, b) => {
    if (a === "deleted" && b !== "deleted") return 1;
    if (a !== "deleted" && b === "deleted") return -1;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  const interactionModal = () => {
    return (
      <div
        className="modal fade"
        id="interactionModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable overflow-visible">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("interaction_plural")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {interactionModalContent.map((interaction) => {
                return (
                  <div className="border-bottom py-1 my-1">
                    <h5>
                      {interaction.title} -{" "}
                      <span
                        className={
                          interaction.severity === "major"
                            ? "text-danger small"
                            : interaction.severity === "moderate"
                            ? "text-warning small"
                            : "text-success small"
                        }
                      >
                        {t(interaction.severity)}
                      </span>
                    </h5>
                    <p className="text-muted">
                      {interaction.drugs.join(" + ")}
                    </p>
                    <p>{interaction.message}</p>
                  </div>
                );
              })}
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={className || ""}>
      <>
        <div className="d-flex align-items-center">
          <button className="btn btn-sm btn link-success ms-n2" onClick={add}>
            {t("new", { label: t("treatment") })}
          </button>

          {user && user.state.country === "ar" && (
            <button
              data-tip={t("ehr:prescribe_drug_plural")}
              data-event="mouseenter"
              data-event-off="mouseleave"
              data-effect="solid"
              className={
                prescribeMode
                  ? "btn btn-sm btn-link text-primary"
                  : "btn btn-sm btn-link text-secondary"
              }
              onClick={() => {
                if (prescribeMode) {
                  setPrescribeMode(false);
                } else {
                  setPrescribeMode(true);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pen"
                viewBox="0 0 16 16"
              >
                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
              </svg>
            </button>
          )}

          {prescribeMode && user && user.state.country === "ar" && (
            <button
              className="btn btn-sm btn-link text-primary"
              onClick={() => newPrescription()}
            >
              {t("create_prescription")}
            </button>
          )}

          {filters.length > 0 && (
            <>
              <div className="flex-fill"></div>
              {filters.map((key) => (
                <button
                  key={`status-${key}`}
                  onClick={() => toggle(key)}
                  className={cx("btn btn-sm btn-link", {
                    "link-primary": status.includes(key),
                    "link-secondary": !status.includes(key),
                  })}
                  data-tip={t(`${key}_tooltip`)}
                  data-event="mouseenter"
                  data-event-off="mouseleave"
                  data-effect="solid"
                  data-place="top"
                >
                  {t(`ehr:${key}`, { count: stats[key] })}
                  <sup>{stats[key]}</sup>
                </button>
              ))}
            </>
          )}
        </div>
        <div>
          {records.sort(sort).map((record) => (
            <Treatment
              key={record.id || record.createdAt}
              record={record}
              interactions={interactions}
              update={update}
              country={user.state.country}
              handleInteractionClick={handleInteractionClick}
              addDrug={prescriptionAddDrug}
              prescribeMode={prescribeMode}
            />
          ))}
        </div>
      </>
      {interactionModal()}
    </div>
  );
}
export default Treatments;
