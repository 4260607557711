import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const App = (props) => {
  const { t } = useTranslation();

  const onChange = (e) => {
    props.onChange({ number: e.target.value });
  };

  return props.editable ? (
    <input
      type="number"
      inputMode="numeric"
      pattern="[0-9]*"
      value={props.value || ""}
      onChange={onChange}
      disabled={!props.editable}
      className={`${styles.input} form-control rounded-0 border-0`}
    />
  ) : (
    <div className="p-2">{props.value}</div>
  );
};

export default App;
