import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Table from './Table'

function Tables (props) {
  const { t } = useTranslation()

  return (
    <>
      {props.filters.diagnostics && (
        <div className="bg-white shadow-sm mb-3">
          <Table
            data={props.tables.diagnostics}
            type="diagnostics"
            label={t('ehr:diagnostics')}
            onSave={props.onSave}
          />
        </div>
      )}

      {props.filters.treatments && (
        <div className="bg-white shadow-sm mb-3">
          <Table
            data={props.tables.treatments}
            type="treatments"
            label={t('ehr:treatments')}
            onSave={props.onSave}
          />
        </div>
      )}

      {props.filters.vitalSigns && (
        <div className="p-2 bg-white shadow-sm mb-3">
          <Table
            data={props.tables.vitalSigns}
            type="vitalSigns"
            label={t('ehr:vitalSigns')}
            onSave={props.onSave}
          />
        </div>
      )}

      {props.filters.labs && (
        <div>
          <div className="d-flex align-items-center gap-2 border-bottom bg-white p-2">
            <div>
              <h4 className="text-black text-first-up fw-normal m-0">{t('laboratories', 'Laboratorios')}</h4>
            </div>
          </div>
          {props.tables.labs.groups.map(type => (
            <div key={type} className="p-2 bg-white shadow-sm mb-3">
              <Table
                data={{
                  ...props.tables.labs[type],
                  ids: props.tables.labs.ids,
                  headers: props.tables.labs.headers,
                  setup: props.tables.labs.setup
                }}
                label={t(`ehr:${type}`)}
              />
            </div>
          ))}
        </div>
      )}

      {props.filters.forms && Object.entries(props.tables.forms || {}).map(([formID, entries]) => (
        <div key={formID} className="bg-white shadow-sm mb-3">
          <Table
            data={entries}
            label={entries.setup.label}
          />
        </div>
      ))}
    </>
  )
}

export default Tables;
