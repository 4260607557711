import { useTranslation } from "react-i18next";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const Auth0Error = () => {
  const { t } = useTranslation();
  const auth0 = useAuth0();

  const params = new URLSearchParams(window.location.search);
  const message = params.get("error_description") || "error_default";

  const logout = async () => {
    const promises = []

    try {
      promises.push(auth0.logout())
    } catch {}

    try {
      window.localStorage.clear();
    } catch {}

    if (promises.length) await Promise.all(promises)

    try {
      window.location.href = '/'
    } catch {}
  }

  const email = auth0.user?.email

  return (
    <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
      <div>
        <h4>{t(`${message}_title`)}</h4>
        <p>
          {t(`${message}_message`)}
        </p>
        <div>
          {auth0.isAuthenticated ? (
            <button
              className="py-3 px-0 btn btn-link link-danger text-start text-nowrap d-flex"
              onClick={logout}
            >
              {t("logout", 'Cerrar sesion')}
              {!!email && (
                <div className="text-secondary text-truncate ms-2">{email}</div>
              )}
            </button>
          ) : (
            <a href="/">{t("home")}</a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Auth0Error;
