const getDate = date => {
  try {
    return Intl.DateTimeFormat('fr-ca', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date || new Date())
  } catch {
    return new Date().toISOString().substr(0, 10)
  }
}

export default getDate
