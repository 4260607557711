import { useEffect, useState, useMemo, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCheck } from 'bootstrap-icons/icons/check.svg'
import { ReactComponent as IconPencil } from 'bootstrap-icons/icons/pencil.svg'
import { ReactComponent as IconChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg'
import { ReactComponent as IconTrash } from 'bootstrap-icons/icons/trash.svg'
import { ReactComponent as IconArrowClockwise } from 'bootstrap-icons/icons/arrow-clockwise.svg'
import { ReactComponent as IconInfoCircle } from 'bootstrap-icons/icons/info-circle.svg'
import { ReactComponent as IconJournalText } from 'bootstrap-icons/icons/journal-text.svg'
import ReactTooltip from 'react-tooltip'
import debounce from 'lodash.debounce'

import getAccessToken from "hooks/getAccessToken";
import parseDate from 'lib/parseDate'
import useMutation from "hooks/useMutation";
import Calendar from "./Calendar";
import Status from "./Status";

const mode = ''
const setMode = () => null

const Archive = props => {
   const { t } = useTranslation()
   const [state, setState] = useState({ ...props.state })
   const [loading, setLoading] = useState()
   const [mutate, mutation] = useMutation(window._l(`/records`).substr(1));
   const $collapse = useRef(null)
   /* WORKAROUND ya que cuando creas un nuevo registro hace el POST sin el access token */
  const token = getAccessToken()

   const onChange = payload => {
      if (!state) return
     const record = { ...state, ...payload }
     setState(record)
     setLoading(true)
   }

   const save = record => {
    setLoading(false)
    mutate(record, {
      onSuccess: data => props.onChange({ ...data, ...record })
    })
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(save, 2000)
    , [token.value]
  );

   const toggle = () => {
     const deleted = !(state.deleted ?? false)
     if (state.id && deleted && !window.confirm(t('ehr:remove_file_record', 'Confirmas que queres borrar el archivo', { context: state.type }))) {
       return
     }
     onChange({ deleted })
   }

   useEffect(() => {
     setState({ ...props.state })
     if (!props.state?.id && token.value) {
       setLoading(true)
       save(props.state)
     }
   }, [props.state, token.value])

   useEffect(() => {
     return () => {
       debouncedChangeHandler.cancel();
     }
   }, []);

   useEffect(() => {
     setState({ ...props.state })
   }, [props.state])

   useEffect(() => {
     ReactTooltip.rebuild()
   })

   const options = [
    { value: 'unclassified', label: t("unclassified", 'Sin clasificar') },
    { value: 'lab', label: t("lab") },
    { value: 'tc', label: t("tc") },
    { value: 'rmn', label: t("rmn") },
    { value: 'report', label: t("report") },
    { value: 'rx', label: t("rx") },
   ]

   const classification = state.classification || state.status || 'unclassified'

   let tip = `<dt>${t('classification', 'Clasificacion')}</dt><dd>${t(classification, { context: "files" })}</dd>`
   if (state.notes) {
     tip += `<dt>${t('notes', 'Notas')}</dt><dd>${state.notes}</dd>`
   }
   if (state.date || state.createdAt) {
     tip += `<dt>${t('date', 'Cargado')}</dt><dd>${parseDate(state.date || state.createdAt).toLocaleDateString()}</dd>`
   }
   tip = `<dl>${tip}</dl>`

  return (
    <div className="position-relative border-top">
      {loading && <div className="loading loading-top loading-sm" />}
      <div className="d-flex justify-content-between align-items-center gap-2 p-3 bg-white">
        <div className="d-flex align-items-center gap-2">
          <button className={`btn d-flex align-items-center gap-2 p-0 ${state.deleted ? 'link-danger' : 'text-dark'}`} data-bs-target={`#collapse${state.id?.replace('/', '-')}`} data-bs-toggle="collapse">
            {loading && (
              <span className="spinner-border spinner-border-sm text-primary me-1" />
            )}
            <IconChevronExpand width="14" height="14" className="link-primary" />
            {tip && (
              <IconInfoCircle  width="14" height="14" className="text-teal" data-tip={tip} />
            )}
            {state.notes && (
              <IconJournalText width="14" height="14" className="text-teal" data-tip={state.notes}/>
            )}
          </button>
          <a href={state.link} target={state.link} rel="noopener noreferrer" className="text-truncate">{state.name || t('link', 'Link')}</a>
        </div>
        <div className="text-secondary small">
          {t(classification, { context: 'files' })}
        </div>
      </div>
      <div className="collapse" ref={$collapse} id={`collapse${state.id?.replace('/', '-')}`}>
        <div className="p-3 d-flex flex-column border-top gap-3 shadow-sm bg-light rounded-bottom">
          <div className="d-flex flex-column flex-xxl-row gap-2 align-items-xxl-center">
            <div className="flex-fill form-floating">
              <input
                disabled={loading && !state.id}
                value={state.name || ''}
                onChange={e => onChange({ name: e.target.value })}
                type="text"
                className="form-control"
                name="name"
                placeholder={t("filename_placeholder", "Nombre del archivo")}
              />
              <label>{t("filename_placeholder", "Nombre del archivo")}</label>
            </div>

            <div className="form-floating">
              <input
                disabled={loading && !state.id}
                value={state.notes || ''}
                onChange={e => onChange({ notes: e.target.value })}
                type="text"
                className="form-control"
                name="notes"
                placeholder={t("notes_placeholder_short", "Notas")}
              />
              <label>{t("notes_placeholder_short", "Notas")}</label>
            </div>

            <Calendar
              disabled={loading && !state.id}
              label={t('date', 'Fecha')}
              tip={t('file_date_tip', 'Fecha del archivo')}
              value={{
                value: state.date || state.createdAt,
                label: parseDate(state.date || state.createdAt)?.toLocaleDateString() || t('undefined', 'Indefinida', { context: 'female' })
              }}
              onChange={date => onChange({ date: date?.toLocaleDateString('fr-ca') || "" })}
            />

            <Status
              disabled={loading && !state.id}
              label={t('classification', 'Clasificacion')}
              value={{
                value: classification,
                label: t(classification, { context: 'files' })
              }}
              options={options}
              onChange={classification => onChange({ classification })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Archive
