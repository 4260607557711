import capitalize from "lodash/capitalize";

import getModal from "../../lib/getModal";

const onChangeConsumer = (array, modal = true) => {
  const consumer = array[0] || {};
  consumer.financiers = consumer.financiers || [];
  consumer.label = consumer.label || "";
  const [lastName = "", firstName = ""] = consumer.label
    .split(",")
    .map((p) => p.trim())
    .filter(Boolean);
  if (consumer.customOption || !consumer.id || /^new/.test(consumer.id)) {
    delete consumer.id;
    if (/^\d/.test(consumer.label)) {
      consumer.identification = consumer.label
      if (modal) getModal("consumersModal", true, {
        identification: consumer.identification,
      }).show();
    } else {
      consumer.lastName = lastName.split(" ").map(capitalize).join(" ")
      consumer.firstName = firstName.split(" ").map(capitalize).join(" ")
      if (modal) getModal("consumersModal", true, {
        lastName: consumer.lastName,
        firstName: consumer.firstName,
      }).show();
    }
  }

  consumer.lastName = lastName;
  consumer.firstName = firstName;
  consumer.label = [lastName, firstName].filter(Boolean).join(", ");

  return consumer;
};

export default onChangeConsumer;
