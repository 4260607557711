import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { useQueryClient } from "react-query";

import useUser from "../../../../hooks/useUser";
import useQuery from "../../../../hooks/useQuery";
import File from "./File";

const sort = (a, b) => {
  if (!a.id && b.id) return -1;
  if (a.id && !b.id) return 1;
  return b.createdAt - a.createdAt;
};

function Files({ consumer, uid, className, resource }) {
  const { t } = useTranslation();
  const $container = useRef(null);
  const queryClient = useQueryClient();
  const $files = useRef(null);
  const [files, setFiles] = useState([]);
  let fileData = [];

  const [status, setStatus] = useState([
    "unclassified",
    "tc",
    "rmn",
    "rx",
    "lab",
    "report",
  ]);
  const user = useUser();

  const [records, setRecords] = useState([]);
  const [stats, setStats] = useState({});

  const query = useQuery(window._l(`/${consumer.id}/file`).substr(1), {
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const licenses =
    resource &&
    resource.licenses &&
    resource.licenses
      .filter((element) => element.type === "license")
      .map((license) => {
        if (license.jurisdiction === "AR") {
          return `M.N. ${license.value}`;
        } else {
          return `M.P. ${license.value}`;
        }
      })
      .join("-");

  const add = (name, file) => {
    const date = new Date();
    const today = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    update({
      type: "file",
      consumer: { id: consumer.id },
      createdBy: user.state.uid,
      createdByName: resource.label,
      createdByLicense: licenses,
      createdAt: Date.now(),
      status: "",
      startsAt: today,
      content: "",
      filename: name,
    });

    fileData = [...fileData, file];
    setFiles(fileData);
  };

  const update = (payload, clear) => {
    const id = payload.id || payload.createdAt;
    const keys = queryClient
      .getQueryCache()
      .findAll()
      .filter((query) => query.queryHash.includes("/file"))
      .map((query) => query.queryKey);

    for (const key of keys) {
      queryClient.setQueryData(key, (records) => {
        const array = JSON.clone(records);
        const index = array.findIndex(
          (record) =>
            (record.id || record.createdAt) === id &&
            record.consumer?.id === payload.consumer?.id
        );

        if (index < 0) {
          return [...array, payload].filter((record) => !clear || record.id);
        }

        array[index] = payload;
        return array;
      });
    }
  };

  const handleFiles = (e, record, setFile, setRecord) => {
    const files = Array.from(e.target.files);

    if (!record) {
      files.forEach((file) => add(file.name, file));
    } else {
      setFile(files[0]);
      record.filename = files[0].name;
      setRecord({ ...record });
    }
  };

  const toggle = (value) => {
    if (status.includes(value)) {
      setStatus(status.filter((s) => s !== value));
    } else {
      setStatus([...status, value]);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    const array = [];
    const stats = {};

    for (const record of query.data) {
      if (uid && record.createdBy !== uid) {
        continue;
      }

      if (record.deleted) {
        stats.deleted = stats.deleted || 0;
        stats.deleted += 1;
      }

      if (record.deleted && !status.includes("deleted")) {
        continue;
      }

      if (!record.status) {
        record.status = "unclassified";
      }

      if (record.status && !record.deleted) {
        stats[record.status] = stats[record.status] || 0;
        stats[record.status] += 1;
      }
      if (
        !record.id ||
        status.includes(record.status) ||
        status.includes("deleted")
      ) {
        array.push(record);
      }
    }

    setRecords(array);
    setStats(stats);
  }, [query.data, status, uid]);

  const filters = Object.keys(stats || {}).sort((a, b) => {
    if (a === "deleted" && b !== "deleted") return 1;
    if (a !== "deleted" && b === "deleted") return -1;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  return (
    <div className={className || ""}>
      <div className="d-flex align-items-center">
        <button
          className="btn btn-sm btn link-success ms-n2"
          onClick={() => {
            $files.current.click();
          }}
        >
          {t("new", { label: t("file") })}
        </button>
        <input
          ref={$files}
          type="file"
          className="form-control d-none"
          id="files"
          name="files[]"
          onChange={(e) => {
            handleFiles(e);
          }}
        />

        {filters.length > 0 && (
          <>
            <div className="flex-fill"></div>
            {filters.map((key) => (
              <button
                key={`status-${key}`}
                onClick={() => toggle(key)}
                className={cx("btn btn-sm btn-link", {
                  "link-primary": status.includes(key),
                  "link-secondary": !status.includes(key),
                })}
                data-tip={t(`${key}_tooltip`)}
                data-event="mouseenter"
                data-event-off="mouseleave"
                data-effect="solid"
                data-place="top"
              >
                {t(`ehr:${key}`, { count: stats[key] })}
                <sup>{stats[key]}</sup>
              </button>
            ))}
          </>
        )}
      </div>
      <div ref={$container}>
        {records.sort(sort).map((record, index) => (
          <File
            key={record.id || record.createdAt}
            record={record}
            update={update}
            handleFiles={handleFiles}
            file={files[index]}
          />
        ))}
      </div>
    </div>
  );
}

export default Files;
