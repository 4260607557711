const perfillipidicoModel = () => {
  return {
    col: {
      value: "",
      unit: "",
    },
    hdl: {
      value: "",
      unit: "",
    },
    ldl: {
      value: "",
      unit: "",
    },
    trig: {
      value: "",
      unit: "",
    },
  };
};

const um = () => {
  return {
    col: ["mg/dL", "mmol/L"],
    hdl: ["mmol/L", "mg/dL"],
    ldl: ["mmol/L", "mg/dL"],
    trig: ["mmol/L", "mg/dL"],
  };
};

export { perfillipidicoModel, um };
