import { useTranslation } from "react-i18next";

import useStore from "../useStore";

const Item = ({ id, value }) => {
  const { t } = useTranslation();
  const store = useStore((state) => ({
    toggle: state.toggle,
    [id]: id === 'free' ? value : state[id],
  }));

  const toggle = () => {
    store.toggle(id);
  };

  const key = `filters/${id}`;

  return (
    <div
      className="form-check form-switch mb-1"
      data-tip={t(`${key.replace("/", "_")}_tip`)}
    >
      <input
        className="form-check-input"
        type="checkbox"
        id={key}
        checked={!!store[id]}
        onChange={toggle}
      />
      <label className="form-check-label small" htmlFor={key}>
        {t(key.replace(/\//g, "_"), { context: "sidebar" })}
      </label>
    </div>
  );
};

const Filters = () => {
  const store = useStore((state) => ({
    resource: !!state.resource?.id,
    free: !state.resource?.id ? true : !!state.free
  }));

  if (!store.resource) return null

  return (
    <div className="m-3">
      <Item id="free" value={store.free} />
      {!store.free && <Item id="cancelled" />}
      {!store.free && <Item id="noshow" />}
    </div>
  );
};

export default Filters;
