import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import Papa from 'papaparse';

import { formatDate } from 'config/i18n'
import Table from './Table'
import calculateChecksum from './calculateChecksum'
import useQuery from 'hooks/useQuery'

let clone = window.structuredClone || JSON.clone

function Migrate(props) {
  let { t } = useTranslation();
  let $files = useRef(null);
  let [tables, setTables] = useState([])
  let query = useQuery(window._l(`/consumers/migrate`).substr(1))

  let remove = key => {
    setTables(tables => tables.filter(table => table.key !== key))
  }

  let ready = key => {
    remove(key)
    setTimeout(() => query.refetch(), 2000)

  }

  let handleFiles = async e => {
    try {
      let files = Array.from(e.target.files)
      let array = await Promise.all(
        files.map(file => new Promise(async resolve => {
          let table = { name: file.name, createdAt: Date.now() }
          let key = await calculateChecksum(file)
          table.key = key || Date.now().toString()
          if (tables.some(item => item.key === table.key)) return resolve()
          // if (Array.isArray(query.data) && query.data.some(item => item.key === table.key)) return resolve()

          table.file = [file]
          let reader = new FileReader();
          reader.onload = e => {
            let csv = Papa.parse(e.target.result, { skipEmptyLines: 'greedy' })
            table.csv = csv
            resolve(table)
          };

          reader.onerror = error => {
            console.error(error)
            resolve()
          }

          reader.readAsText(file);
        }))
      )

      array = [...tables, ...array].filter(Boolean).sort((a, b) =>
        a.createdAt - b.createdAt
      )

      setTables(array)

      e.target.value = ""
    } catch(e) {
      console.error(e)
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className={props.className || ''}>
      <div className="d-flex justify-content-between gap-3">
        <button
          className="btn bg-white border text-primary text-start"
          onClick={() => $files.current.click()}
        >
          <div>{t('consumers_csv_upload_link', 'Cargar')}</div>
          <div className="text-secondary"><small>{t('consumers_csv_upload_description', 'Seleccione un archivo CSV para importar pacientes')}</small></div>
        </button>
        <a
          href="https://cdn.drapp.la/consumers/ejemplo-importar-pacientes.csv"
          target="_blank"
          rel="noopener noreferrer"
          className="btn bg-white border text-primary text-start"
        >
          <div>
            {t('consumers_csv_example_link', 'Descargar')}
          </div>
          <div className="text-secondary"><small>{t('consumers_csv_example_description', 'Descargar archivo CSV de ejemplo')}</small></div>
        </a>
      </div>
      {tables.length > 0 && tables.map(table => (
        <div className="mt-4" key={table.key}>
          <Table
            remove={() => remove(table.key)}
            ready={() => ready(table.key)}
            table={table}
          />
        </div>
      ))}

      {query.data?.filter(t => t.status)?.length > 0 && (
        <div className="mt-3">
          <h6 className="mb-0 ps-1">{t('files_migrated', 'Archivos procesados')}</h6>
          <div className="d-flex flex-column gap-3">
            {query.data.filter(t => t.status).map(table =>
              <div key={`${table.key}${table.runningAt}`} className="bg-white p-3 mb-1">
                <div>
                  <a href={table.file} rel="noopener noreferrer" target="_blank">{table.name}</a>
                </div>
                <div>
                  <small className="text-secondary text-first-up">{formatDate(table.runningAt, 'eeee d MMMM HH:mm')}</small> - <small>
                    {table.uid}
                  </small>
                </div>
                <div>
                  <small>
                    <span className="text-first-up">{t('status', 'Estado')}</span>: <span
                      className={table.status === 'ready' ? 'text-success' : table.status === 'running' ? 'text-warning' : 'text-secondary'}
                    >{t(`consumers_csv_status_${table.status || 'unknown'}`, table.status || 'unknown')}</span>
                  </small>
                  <small className="text-secondary">{' '}&mdash;{' '}</small>
                  <small>{t(`consumers_csv_identification_duplicate`, 'Duplicados')}: <span className="text-secondary">{t(table.identificationDuplicate || 'ignore', table.identificationDuplicate || 'ignore')}</span></small>
                  <small className="text-secondary">{' '}&mdash;{' '}</small>
                  <small>{t('consumers_csv_first_line_header', 'Cabecera')}: <span className="text-secondary">{!!table.firstLineHeader ? t('yes', 'Si') : t('no', 'No')}</span></small>
                  <small className="text-secondary">{' '}&mdash;{' '}</small>
                  <small>{t('consumers_csv_lines', 'Registros')}: <span className="text-success" title={t('consumers_csv_records_success', 'Importados')}>{table.success || 0}</span> <span className="text-secondary">+</span> <span className="text-warning" title={t('consumers_csv_records_skip', 'Ignorados')}>{table.skip || 0}</span> <span className="text-secondary">=</span> <span className="text-secondary" title={t('consumers_csv_records_records_total', 'Total')}>{table.total || 0}</span></small>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <input
        ref={$files}
        multiple
        type="file"
        className="d-none"
        id="files"
        accept=".csv"
        onChange={handleFiles}
      />


    </div>
  );
}

export default Migrate;
