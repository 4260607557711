import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const App = (props) => {
  const { t } = useTranslation();

  const onChange = (e) => {
    props.onChange({ checked: props.value === true ? false : true });
  };

  return (
    <div className="form-check mx-1 my-0">
      <input
        disabled={!props.editable}
        type="checkbox"
        checked={props.value === true}
        onChange={onChange}
        className="form-check-input"
      />
    </div>
  );
};

export default App;
