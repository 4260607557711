import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { formatDistanceStrict } from "date-fns";

import useMutation from "../../../../hooks/useMutation";
import { formatDate, getLocale } from "../../../../config/i18n";
import { Typeahead } from "react-bootstrap-typeahead";
import useClearanceCalc from "../../../../hooks/useClearanceCalc";

import LabInput from "./Components/LabRow";

import { um as hemogramaUm, hemogramaModel } from "./Values/Hemograma";
import { um as ionogramaUm, ionogramaModel } from "./Values/Ionograma";
import { um as eabrenalUm, eabrenalModel } from "./Values/Eabrenal";
import { um as hepatogramaUm, hepatogramaModel } from "./Values/Hepatograma";
import {
  um as perfiltiroideoUm,
  perfiltiroideoModel,
} from "./Values/PerfilTiroideo";
import {
  um as perfillipidicoUm,
  perfillipidicoModel,
} from "./Values/PerfilLipidico";
import {
  um as perfilferricoUm,
  perfilferricoModel,
} from "./Values/PerfilFerrico";
import { um as diabetesUm, diabetesModel } from "./Values/Diabetes";

function Lab(props) {
  const [countdown, setCountdown] = useState(null);
  const { t } = useTranslation();

  const calcCrCl = useClearanceCalc;

  const [record, setRecord] = useState({});
  const [mode, setMode] = useState("read");

  function _calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const [mutate] = useMutation(
    window._l(`/${record.id || "records"}`).substr(1),
    {
      onMutate: (payload) => {
        update(payload);
      },
      onSuccess: (data) => {
        update(data, "clear");
      },
      onError: (error) => {
        window.notify({
          message: error.message,
          type: "danger",
          title: "Error",
        });
      },
    }
  );

  function createLabArray() {
    let obj = {};
    for (const titleKey in labModel) {
      obj[titleKey] = {};
      for (const key in labModel[titleKey]) {
        obj[titleKey][key] = [];
      }
    }
    return obj;
  }

  const labModel = {
    hemograma: hemogramaModel(),
    ionograma: ionogramaModel(),
    eabrenal: eabrenalModel(),
    hepatograma: hepatogramaModel(),
    perfiltiroideo: perfiltiroideoModel(),
    perfillipidico: perfillipidicoModel(),
    perfilferrico: perfilferricoModel(),
    diabetes: diabetesModel(),
  };

  const labArray = createLabArray();

  const UM = {
    ...hemogramaUm(),
    ...ionogramaUm(),
    ...eabrenalUm(),
    ...hepatogramaUm(),
    ...perfiltiroideoUm(),
    ...perfillipidicoUm(),
    ...perfilferricoUm(),
    ...diabetesUm(),
  };

  const update = (payload, clear) => {
    setMode("read");
    props.update(payload, clear);
  };

  const onChange = ({ target: { name, value, type, id } }) => {
    if (type === "date") {
      record.startsAt = new Date(value).getTime();
    } else {
      record[name][id].value = value;
    }
    setRecord({ ...record });
  };

  const restore = () => {
    mutate({
      ...record,
      status: undefined,
      deleted: false,
    });
  };

  const remove = (mode) => {
    setCountdown(null);
    if (!record.id) {
      return update({ ...record, deleted: true, content: "", text: "" });
    }

    if (mode === "confirm") {
      return mutate({
        ...record,
        deleted: true,
      });
    }

    if (mode === "discard") return;

    setCountdown(true);
  };

  const save = async () => {
    mutate({ ...record });
    setMode("read");
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    setRecord({ ...props.record });
  }, [props.record]);

  useEffect(() => {
    setMode(!record.id ? "edit" : "read");
  }, [record.id]);

  let age;
  if (record.startsAt) {
    age = formatDistanceStrict(new Date(record.startsAt), Date.now(), {
      addSuffix: record.status !== "done",
      locale: getLocale(),
    });
  }

  function renderLabKey(labkey) {
    if (record[labkey]) {
      for (let modelKey in labModel[labkey]) {
        if (record[labkey][modelKey]) {
          if (mode === "edit") {
            labArray[labkey][modelKey].push(
              <div
                key={record.id + modelKey}
                className="form-floating me-2 mt-2"
              >
                <small className="text-muted">{t(modelKey)}</small>
                <div className="input-group">
                  <input
                    className="form-control valueStyle"
                    type="number"
                    name={labkey}
                    id={modelKey}
                    value={record[labkey][modelKey].value}
                    onChange={onChange}
                  ></input>

                  <Typeahead
                    id="unit"
                    className="unitStyle"
                    placeholder={
                      record[labkey][modelKey].unit === ""
                        ? t("ehr:unit")
                        : record[labkey][modelKey].unit
                    }
                    onInputChange={(e) => {
                      record[labkey][modelKey].unit = e;
                    }}
                    onChange={(selected) => {
                      record[labkey][modelKey].unit = selected.join("");
                    }}
                    options={UM[modelKey]}
                  />
                </div>
              </div>
            );
          } else {
            labArray[labkey][modelKey].push(
              <span className="text-center mx-2" key={record.id + modelKey}>
                <sup className="text-muted mx-2">{t(modelKey)}</sup>
                {record[labkey][modelKey].value}{" "}
                <small className="fst-italic me-2 text-nowrap text-muted">
                  {record[labkey][modelKey].value &&
                  record[labkey][modelKey].value != ""
                    ? record[labkey][modelKey].unit
                    : null}
                </small>
              </span>
            );
          }
        }
      }
    }
  }

  renderLabKey("hemograma");
  renderLabKey("ionograma");
  renderLabKey("eabrenal");
  renderLabKey("hepatograma");
  renderLabKey("perfiltiroideo");
  renderLabKey("perfillipidico");
  renderLabKey("perfilferrico");
  renderLabKey("diabetes");

  function checkForValues(objKey) {
    for (const key in objKey) {
      if (objKey[key].value) {
        return true;
      } else {
        return false;
      }
    }
  }

  function renderAccordion(key, array) {
    if (array) {
      return (
        <div className="accordion accordion-flush mt-2" id={`accordion${key}`}>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#flush-collapse${key}`}
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                {t(key)}
              </button>
            </h2>
            <div
              id={`flush-collapse${key}`}
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent={`#accordion${key}`}
            >
              <div className="accordion-body bg-light p-0">
                {renderAccordionRows(array[key])}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function renderAccordionRows(segment) {
    let total = Object.keys(segment).length;
    let i = 0;
    let row = 0;
    let renderArray = [];
    let renderLabarray = [];
    for (const key in segment) {
      i++;
      row++;
      renderArray.push(segment[key]);
      if (row === 4 || i === total) {
        row = 0;
        renderLabarray.push(<LabInput array={renderArray}></LabInput>);
        renderArray = [];
      }
    }

    return renderLabarray;
  }

  return mode === "edit" ? (
    <div className="bg-light p-3 my-3 rounded">
      <div className="d-flex align-items-center mb-3">
        <div className="form-floating me-2">
          <input
            value={
              record.startsAt
                ? new Date(record.startsAt).toISOString().substr(0, 10)
                : new Date().toISOString().substr(0, 10)
            }
            onChange={onChange}
            type="date"
            className="form-control"
            id="startsAt"
            name="startsAt"
            placeholder={t("date")}
          />
          <label htmlFor="date">{t("date")}</label>
        </div>

        <div className="flex-fill"></div>

        {record.deleted ? (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-outline-primary me-2"
              onClick={restore}
            >
              {t("restore", { label: t("diagnostic") })}
            </button>
            <button
              className="btn btn-sm btn-link link-secondary"
              onClick={() => setMode("read")}
            >
              {t("close")}
            </button>
          </div>
        ) : countdown !== null ? (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-link link-success me-2"
              onClick={() => remove("discard")}
            >
              {t("discard")}
            </button>
            <button
              className="btn btn-sm btn-link link-danger"
              onClick={() => remove("confirm")}
            >
              {t("confirm")}
            </button>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-sm btn-outline-success me-2"
              onClick={save}
            >
              {t("save", { label: t("diagnostic") })}
            </button>
            <button
              className="btn btn-sm btn-link link-secondary"
              onClick={() => {
                if (!record.updatedAt) {
                  setMode("discard");
                  mutate({
                    ...record,
                    deleted: true,
                  });
                } else {
                  setMode("read");
                }
              }}
            >
              {t("close")}
            </button>
            <button
              className="btn btn-sm btn-link link-danger"
              onClick={remove}
            >
              {t("ehr:delete", { label: t("diagnostic") })}
            </button>
          </div>
        )}
      </div>

      <div className="align-items-center mb-3">
        {renderAccordion("hemograma", labArray)}
        {renderAccordion("ionograma", labArray)}
        {renderAccordion("eabrenal", labArray)}
        {renderAccordion("hepatograma", labArray)}
        {renderAccordion("perfiltiroideo", labArray)}
        {renderAccordion("perfillipidico", labArray)}
        {renderAccordion("perfilferrico", labArray)}
      </div>
    </div>
  ) : mode === "discard" ? null : (
    <div>
      {record.createdAt ? (
        <div className="accordion-item mt-2">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#id" + record.createdAt}
              aria-expanded="false"
              aria-controls={"id" + record.createdAt}
            >
              {record.deleted && (
                <small className="badge bg-danger fw-normal me-2">
                  {t("ehr:deleted")}
                </small>
              )}
              {record.startsAt
                ? formatDate(record.startsAt, "dd-MMM-yyyy")
                : null}
              {age && <small className="text-muted ms-2">{age}</small>}{" "}
              <small className="mx-2 text-muted">
                {record.createdByName}{" "}
                <small>
                  {record.createdByLicense &&
                    record.createdByLicense.replace(/-/g, " ")}
                </small>
              </small>
              <small className="ms-3">
                <sup
                  className="me-1"
                  data-tip={t("clcr_tooltip")}
                  data-effect="solid"
                >
                  {t("ClCr")}
                </sup>
                <span className="text-muted">
                  {record.eabrenal
                    ? t(
                        calcCrCl(
                          "ckd",
                          props.consumer.gender,
                          false,
                          _calculateAge(new Date(props.consumer.dob)),
                          record.eabrenal.cr.value,
                          record.eabrenal.cr.unit
                        )
                      )
                    : t("error")}
                </span>
              </small>
            </button>
          </h2>

          <div
            id={"id" + record.createdAt}
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-link p-0 align-baseline mb-2 ms-3"
                  onClick={() => setMode("edit")}
                >
                  {t("edit")}
                </button>
              </div>

              {checkForValues(record.hemograma) && (
                <div>
                  <hr></hr>
                  <p className="mt-3">{t("hemogram")}</p>

                  <LabInput
                    array={[
                      labArray.hemograma.hto,
                      labArray.hemograma.hb,
                      labArray.hemograma.gb,
                      labArray.hemograma.neu,
                    ]}
                  ></LabInput>

                  <LabInput
                    array={[
                      labArray.hemograma.lin,
                      labArray.hemograma.bas,
                      labArray.hemograma.eos,
                      labArray.hemograma.mon,
                    ]}
                  ></LabInput>

                  <LabInput
                    array={[
                      labArray.hemograma.plqt,
                      labArray.hemograma.reti,
                      labArray.hemograma.glu,
                    ]}
                  ></LabInput>
                </div>
              )}

              {checkForValues(record.ionograma) && (
                <div>
                  <hr></hr>
                  <p className="mt-3">{t("electrolyte_plural")}</p>

                  <LabInput
                    array={[
                      labArray.ionograma.na,
                      labArray.ionograma.k,
                      labArray.ionograma.cl,
                      labArray.ionograma.mg,
                    ]}
                  ></LabInput>

                  <LabInput
                    array={[
                      labArray.ionograma.ca,
                      labArray.ionograma.cai,
                      labArray.ionograma.p,
                    ]}
                  ></LabInput>
                </div>
              )}

              {checkForValues(record.eabrenal) && (
                <div>
                  <hr></hr>
                  <p className="mt-3">{t("renal_bga")}</p>

                  <LabInput
                    array={[
                      labArray.eabrenal.cr,
                      labArray.eabrenal.ur,
                      labArray.eabrenal.ph,
                      labArray.eabrenal.pco2,
                    ]}
                  ></LabInput>

                  <LabInput
                    array={[
                      labArray.eabrenal.po2,
                      labArray.eabrenal.hco3,
                      labArray.eabrenal.sat,
                    ]}
                  ></LabInput>
                </div>
              )}

              {checkForValues(record.hepatograma) && (
                <div>
                  <hr></hr>
                  <p className="mt-3">{t("hepatogram")}</p>

                  <LabInput
                    array={[
                      labArray.hepatograma.tgo,
                      labArray.hepatograma.tgp,
                      labArray.hepatograma.fal,
                      labArray.hepatograma.bt,
                    ]}
                  ></LabInput>

                  <LabInput
                    array={[
                      labArray.hepatograma.bd,
                      labArray.hepatograma.bi,
                      labArray.hepatograma.ggt,
                      labArray.hepatograma.n5n,
                    ]}
                  ></LabInput>

                  <LabInput
                    array={[
                      labArray.hepatograma.alb,
                      labArray.hepatograma.prottot,
                    ]}
                  ></LabInput>
                </div>
              )}

              {checkForValues(record.perfiltiroideo) && (
                <div>
                  <hr></hr>
                  <p className="mt-3">{t("thyroidProfile")}</p>

                  <LabInput
                    array={[
                      labArray.perfiltiroideo.tsh,
                      labArray.perfiltiroideo.t4,
                      labArray.perfiltiroideo.t4l,
                      labArray.perfiltiroideo.t3,
                    ]}
                  ></LabInput>
                </div>
              )}

              {checkForValues(record.perfillipidico) && (
                <div>
                  <hr></hr>
                  <p className="mt-3">{t("lipidicProfile")}</p>

                  <LabInput
                    array={[
                      labArray.perfillipidico.col,
                      labArray.perfillipidico.hdl,
                      labArray.perfillipidico.ldl,
                      labArray.perfillipidico.trig,
                    ]}
                  ></LabInput>
                </div>
              )}

              {checkForValues(record.perfilferrico) && (
                <div>
                  <hr></hr>
                  <p className="mt-3">{t("ironProfile")}</p>
                  <LabInput
                    array={[
                      labArray.perfilferrico.ferrit,
                      labArray.perfilferrico.transfer,
                      labArray.perfilferrico.satTransfer,
                      labArray.perfilferrico.tibc,
                    ]}
                  ></LabInput>

                  <LabInput array={[labArray.perfilferrico.fe]}></LabInput>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Lab;
