import { ReactComponent as IconCheckCircleFill } from 'bootstrap-icons/icons/check-circle-fill.svg'
import { ReactComponent as IconCircle } from 'bootstrap-icons/icons/circle.svg'

const App = props => {
  const color = props.options?.find(o => o.value === props.value?.value)?.color || ''
  return (
    <div className="dropdown" style={{ ...props.style }}>
      <button disabled={props.disabled} className={`btn ${props.className || ''} text-truncate`} data-bs-toggle="dropdown">
        <div className="text-start text-secondary fw-light text-first-up">{props.label}</div>
        <div className={['text-truncate', color && `text-${color}`].filter(Boolean).join(' ')}>{props.value.label}</div>
      </button>
      <div className="dropdown-menu p-0" style={{ maxWidth: '350px', minWidth: '350px' }}>
        {props.options.map((option, index) => (
          <button
            key={index}
            className={`dropdown-item d-flex gap-2 p-2`}
            onClick={() => props.onChange(option.value)}
          >
            <div>
              {props.value?.value === option.value ? (
                <IconCheckCircleFill width="16" height="16" className="text-success" />
              ) : (
                <IconCircle width="16" height="16" className="text-secondary" />
              )}
            </div>
            <div>
              <div>{option.label}</div>
              {option.description && (
                <div className="text-secondary small">{option.description}</div>
              )}
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}

export default App
