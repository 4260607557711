import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import useMutation from 'hooks/useMutation'
import getDate from 'lib/getDate'

function VitalSigns(props) {
  const [state, setState] = useState({
    consumer: { id: props.consumer?.id, label: props.consumer?.label },
    createdBy: props.user?.uid,
    createdByName: props.user?.name || props.resource?.label,
    createdByResourceID: props.resource?.id,
    date: getDate(),
    startsAt: getDate(),
    ...props.value,
    type: 'vitalSigns'
  })
  const [mutate, mutation] = useMutation(window._l(`/records`).substr(1));
  const { t } = useTranslation()

  const onChange = ({ target: { name, value, type } }) => {
    state[name] = value
    setState({ ...state })
  }

  const onClose = () => {
    if (props.onClose) return props.onClose()
  }

  const save = () => {
    mutate(state, {
      onSuccess: record => {
        setState({ ...state, ...record })
        if (props.onSave) props.onSave(record)
      }
    })
  }

  return (
    <div
      className={[props.className, 'd-flex flex-column justify-content-top gap-2'].filter(Boolean).join(' ')}
      style={{ maxWidth: 300, width: 300, minWidth: 300 }}
    >
      <div className="d-flex gap-2">
        <div>
          <input
            type="number"
            name="tas"
            onChange={onChange}
            value={state.tas}
            placeholder={t("ehr:systolic_blood_pressure_short", 'TAS', { context: 'vital_signs' })}
            className="form-control"
          />
        </div>
        <div>
          <input
            type="number"
            name="tad"
            onChange={onChange}
            value={state.tad}
            placeholder={t("ehr:diastolic_blood_pressure_short", 'TAD', { context: 'vital_signs' })}
            className="form-control"
          />
        </div>
      </div>
      <div className="d-flex gap-2">
        <div>
          <input
            type="number"
            name="fc"
            onChange={onChange}
            value={state.fc}
            placeholder={t("ehr:heart_rate_short", 'FC', { context: 'vital_signs' })}
            className="form-control"
          />
        </div>
        <div>
          <input
            type="number"
            name="fr"
            onChange={onChange}
            value={state.fr}
            placeholder={t("ehr:respiratory_rate_short", 'FR', { context: 'vital_signs' })}
            className="form-control"
          />
        </div>
      </div>

      <div className="d-flex gap-2">
        <div>
          <div>
            <input
              type="number"
              name="temp"
              onChange={onChange}
              value={state.temp}
              placeholder={t("ehr:temp", 'T°', { context: 'vital_signs' })}
              className="form-control"
            />
          </div>
          <div className="my-2">
            <input
              type="number"
              name="sat"
              onChange={onChange}
              value={state.sat}
              placeholder={t("ehr:oxygen_saturation_shor", "SatO2", { context: 'vital_signs' })}
              className="form-control"
            />
          </div>
          <div>
            <input
              type="number"
              name="fio2"
              onChange={onChange}
              value={state.fio2}
              placeholder={t("ehr:fraction_inspired_oxygen", 'FiO2', { context: 'vital_signs' })}
              className="form-control"
            />
          </div>
        </div>
        <div>
          <div>
            <input
              type="number"
              name="weight"
              onChange={onChange}
              value={state.weight}
              placeholder={t("ehr:weight_kg", 'Peso (kg)', { context: 'vital_signs' })}
              className="form-control"
            />
          </div>
          <div className="my-2">
            <input
              type="number"
              name="height"
              onChange={onChange}
              value={state.height}
              placeholder={t("ehr:height_cm", 'Altura (cm)', { context: 'vital_signs' })}
              className="form-control"
            />
          </div>
          <div>
            <input
              type="number"
              name="abdperim"
              onChange={onChange}
              value={state.abdperim}
              placeholder={t("ehr:abdominal_perimeter_cm", 'Perim Abd (cm)', { context: 'vital_signs' })}
              className="form-control"
            />
          </div>
        </div>

      </div>
      <div className="form-floating">
        <input
          type="date"
          placeholder={t("ehr:date", { context: 'vital_signs' })}
          className="form-control"
          onChange={onChange}
          name="startsAt"
          value={state.startsAt}
        />
        <label>{t("ehr:date", { context: 'vital_signs' })}</label>
      </div>
      <div className="d-flex justify-content-between">
        {mutation.isLoading ? (
          <button disabled className="btn btn-link mx-n2">{t('ehr:saving', { context: 'vital_signs' })}</button>
        ) : (
          <button disabled={!state.tas && !state.tad && !state.fc && !state.fr && !state.temp && !state.sat && !state.fio2 && !state.weight && !state.height && !state.abdperim} className="btn btn-link mx-n2" onClick={save}>{t('ehr:save', { context: 'vital_signs' })}</button>
        )}
        <button className="btn link-secondary mx-n2" onClick={onClose}>{t('close')}</button>
      </div>
    </div>
  )
}

export default VitalSigns
