import { useState } from "react";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";
import kebabCase from "lodash/kebabCase";

import useCatalogs from "hooks/useCatalogs";

const App = (props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const catalog = useCatalogs("cie10", query || "");

  const onChange = (value) => {
    let array = [value];
    if (Array.isArray(value)) {
      array = [...value];
    }
    array = array.filter((item) => item?.id);
    props.onChange({ diagnoses: array });
  };

  return props.editable ? (
    <Creatable
      styles={{
        container: (styles) => ({
          ...styles,
          width: "100%",
        }),
        control: (styles) => ({
          ...styles,
          border: "none",
          borderRadius: 0,
          boxShadow: "none",
        }),
      }}
      isMulti={!!props.field.isMulti}
      isClearable
      tabSelectsValue
      isLoading={catalog.isLoading}
      backspaceRemovesValue
      defaultMenuIsOpen={false}
      getNewOptionData={(value, label) => ({ label, id: kebabCase(value) })}
      onInputChange={setQuery}
      loadingMessage={() => t("loading")}
      value={props.value || []}
      options={catalog.data}
      getOptionValue={(item) => item.id}
      getOptionLabel={(item) => t(item.id, item.label)}
      onChange={onChange}
      formatCreateLabel={(inputValue) => inputValue}
      placeholder={t("form_field_type_diagnoses_placeholder", "")}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: null,
        ClearIndicator: null,
      }}
    />
  ) : (
    <div className="p-2">
      {props.value
        ?.map((i) => i.label)
        ?.filter(Boolean)
        ?.join(", ")}
    </div>
  );
};

export default App;
