import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import kebabCase from "lodash/kebabCase";
import { Typeahead } from "react-bootstrap-typeahead";

import useQuery from "../../../hooks/useQuery";
import useUser from "../../../hooks/useUser";

const Financiers = (props) => {
  const $typeahead = useRef();
  const { t } = useTranslation();
  const user = useUser();

  const [query, setQuery] = useState("");

  const { data, isLoading } = useQuery(
    `catalogs/pms_financiers?q=${query}&locale=${user.state.language}&country=${user.state.country}`,
    {
      initialData: [],
    }
  );

  const [state, setState] = useState({});

  const onChange = (array) => {
    if (props.clearOnSelect) {
      $typeahead.current.clear();
    }
    const item = JSON.clone(array[0]);
    if (!item) return;
    if (
      item &&
      item.label &&
      (item.customOption || /^new-id-/.test(item.id || "-----"))
    ) {
      item.id = `pms_financiers:${kebabCase(item.label)}`;
    }
    item.deleted = false;
    item.locale = item.locale || user.state.language || "es-AR";
    item.label = t(item.id, item.label);
    setState({ ...item });
    props.onChange({ ...item });
  };

  const onSearch = (query) => {
    setState({});
    setQuery(query);
  };

  useEffect(() => {
    setState({ ...props.value, label: t(props.value.id, props.value.label) });
  }, [props.value]);

  return (
    <Typeahead
      selected={[state].filter((p) => p.label)}
      isLoading={!!isLoading}
      ref={$typeahead}
      shouldSelect
      inputProps={{
        id: props.inputID,
      }}
      minLength={2}
      filterBy={() => true}
      id={["pms_financiers", state.id].filter(Boolean).join("_")}
      allowNew={results => !isLoading && !results.some(d => d.id === `pms_financiers:${kebabCase(query)}`)}
      onChange={onChange}
      onInputChange={onSearch}
      options={data.filter(
        (item) => props.any !== false || item.id.substr(-1) !== "/"
      )}
      placeholder={t(`financier_query_placeholder`)}
      newSelectionPrefix={`${t("new_financier", "Nueva cobertura")}: ` }
      emptyLabel={isLoading ? t(`searching`) : t(`no_matches`)}
      paginationText={t(`display_more_results`)}
      promptText={t(`type_to_search`)}
      searchText={t(`searching`)}
    />
  );
};

export default Financiers;
