import React, { useState } from "react";
import { useNavigate, useLocation } from "@reach/router";
import { useInfiniteQuery } from "react-query";

import useScroll from "../lib/useScroll";
import getAccessToken from "./getAccessToken";

const { REACT_APP_API_URL } = process.env;

const LIMIT = 25;

const request = (key, token, body, offset) => {
  return fetch(`${REACT_APP_API_URL}${window._l(`/search/${key}`)}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...body, offset }),
  }).then((res) => res.json());
};

const Hook = (options = {}, skipLocation) => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = getAccessToken();
  const $params = new URLSearchParams(location.search || "");
  const [filters, setFilters] = useState(
    Array.from($params).reduce(
      (hash, [key, value]) => ({
        ...hash,
        [key]: ["true", "false"].includes(value) ? !!value : value,
      }),
      { ...options.filters }
    )
  );

  const q = [];
  if (options.q && options.q.trim().length > 1) {
    q.push(options.q);
  }

  if (filters.q && filters.q.trim().length > 1) {
    q.push(filters.q);
  }

  if (options.default && !filters.all) {
    q.push(options.default);
  }

  const body = {
    ...filters,
    q: q.join(" "),
    sort: options.sort,
  };

  const {
    isFetching,
    isFetchingNextPage,
    error,
    data,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [options.key, accessToken.value, body],
    ({ pageParam }) => request(options.key, accessToken.value, body, pageParam),
    {
      enabled: !!accessToken.value,
      refetchOnWindowFocus: false,
      initialData: [],
      initialStale: true,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || lastPage.length < (options.limit || LIMIT)) return;
        return pages.reduce((sum, array) => sum + array.length, 0);
      },
    }
  );

  const scroll = React.useCallback(() => {
    if (!isFetching && !isFetchingNextPage && hasNextPage) fetchNextPage();
  }, [isFetching, isFetchingNextPage, hasNextPage, fetchNextPage]);

  const $bottom = useScroll(scroll);

  const onChange = React.useCallback(
    (name, value) => {
      const payload = { ...filters };
      payload[name] = value;
      setFilters(payload);

      if (!skipLocation) {
        if (value) {
          $params.set(name, value);
        } else {
          $params.delete(name);
        }

        const $question = $params.toString() ? "?" : "";
        const newPath = `${location.pathname}${$question}${$params.toString()}`;
        const oldPath = `${location.pathname}${location.search}`;
        if (newPath === oldPath) return;

        navigate(newPath, { replace: true });
      }
    },
    [filters, $params, location, skipLocation]
  );

  return {
    filters,
    $bottom,
    onChange,
    data: data.pages || [],
    isLoading: isFetching || isFetchingNextPage,
    error,
  };
};

export default Hook;
