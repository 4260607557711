import { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, navigate, Link } from "@reach/router";
import { ReactComponent as IconArrowLeftShort } from 'bootstrap-icons/icons/arrow-left-short.svg'
import { ReactComponent as IconArrowRightShort } from 'bootstrap-icons/icons/arrow-right-short.svg'
import { ReactComponent as IconSquare } from 'bootstrap-icons/icons/square.svg'
import { ReactComponent as IconCheckSquare } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as IconPrinter } from 'bootstrap-icons/icons/printer.svg'
import { ReactComponent as IconPrescription } from 'bootstrap-icons/icons/prescription.svg'
import { ReactComponent as IconPlusCircle } from 'bootstrap-icons/icons/plus-circle.svg'
import deburr from 'lodash/deburr'
import uniqBy from 'lodash/uniqBy'

import parseDate from 'lib/parseDate'
import useQuery from "hooks/useQuery";
import useMutation from "hooks/useMutation";
import useUser from "hooks/useUser";
import Timer from "Components/Timer/Timer"

import styles from './styles.module.scss'
import { formatDate } from 'config/i18n'
import { getBMI, getClCr } from './helpers'
import Consumer from './Consumer'
import Consultations from './Consultations'
import Tables from './Tables'
import Filters from './Filters'
import Form from './Form/Form'

const clone = window.structuredClone || JSON.clone
let V2_DATE = new Date('2023-08-22T09:00').getTime()
let V2_CLOSE = 3 * 60 * 60 * 1000

const LABS_GROUPS = [
  'hemograma',
  'ionograma',
  'eabrenal',
  'hepatograma',
  'perfiltiroideo',
  'perfillipidico',
  'perfilferrico',
  'diabetes'
]
const VITAL_SIGNS_VARIABLES = ['tas', 'tad', 'fc', 'fr', 'temp', 'sat', 'fio2', 'height', 'weight', 'abdperim']
const FALSY = [undefined, "", null]

const sort = (key, order) => (a, b) => {
  let aDate = new Date(a[key] || a.date || a.startsAt || a.createdAt).getTime()
  let bDate = new Date(b[key] || b.date || b.startsAt || b.createdAt).getTime()
  if (order === 'desc') {
    if (aDate > bDate) return -1
    if (aDate < bDate) return 1
  }
  if (aDate < bDate) return -1
  if (aDate > bDate) return 1
  return 0
}

function EHR({ consumerID }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [eventID, setEventID] = useState();
  const [stats, setStats] = useState({})
  const [tables, setTables] = useState()
  const [files, setFiles] = useState()
  const [consultations, setConsultations] = useState()
  const [ready, setReady] = useState()
  const [showForm, setShowForm] = useState(true)
  const [newRecords, addRecords] = useState([])
  const [closedAt, setClosedAt] = useState(null)
  const [prescriptions, setPrescriptions] = useState()

  const event = useQuery(window._l(`/events/${eventID}`).substr(1), {
    enabled: !!eventID,
  });

  const [saveEvent] = useMutation(window._l(`/events/${eventID}`).substr(1), {
    onError: (error) => {
      window.notify({
        message: error.message,
        type: "danger",
        title: "Error",
      });
    },
  });

  let qPrescriptions = useQuery(`prescriptions/consumers/${consumerID}`, {
    onSuccess: data => {
      if (!data) return
      let payload = clone(data)
      payload._form = !!prescriptions?._form
      setPrescriptions(payload)
    },
    retry: false
  })

  const [filters, setFilters] = useState()
  const [timer, setTimer] = useState(null)

  const user = useUser();

  const consumer = useQuery(window._l(`/consumers/${consumerID}`).substr(1));

  const resource = useQuery(
    window._l(`/${user.team?.resourceID}`).substr(1),
    { enabled: !!user.team?.resourceID }
  );

  const [saveResource] = useMutation(window._l(`/${resource.data?.id}`).substr(1), {
    onError: (error) => {
      window.notify({
        message: error.message,
        type: "danger",
        title: "Error",
      });
    },
  });

  const forms = useQuery(window._l(`/forms`).substr(1), {
    enabled: !!consumerID,
  });

  const records = useQuery(
    window._l(`/consumers/${consumerID}/records/_all`).substr(1),
    {
      enabled: !!user.state?.isStaff || !!resource.data?.id
    }
  );

  const endConsultation = () => {
    if (!event.data || !event.data.id) return;
    if (event.data.status !== "active") {
      navigate(window._l("/events/agenda"))
      return
    };
    const payload = clone(event.data);
    payload.states = payload.states || [];
    payload.states.push({ createdAt: Date.now(), status: "fulfilled" });
    payload.status = "fulfilled";
    saveEvent(payload);
    navigate(window._l("/events/agenda"));
  }

  const closeV2Message = () => {
    let resourceID = resource.data?.id
    if (resourceID) {
      let key = `${resourceID}:ehr:v2:closedAt`
      window.localStorage.setItem(key, Date.now())
      setClosedAt(Date.now())
    }
  }

  const setDefault = () => {
    let resourceID = resource.data?.id
    if (resourceID) {
      let payload = clone(resource.data)
      payload.metadata ||= {}
      payload.metadata.ehr ||= {}
      payload.metadata.ehr.version = 'v2:hard'
      saveResource(payload)
      setClosedAt(Date.now() + (30 * 24 * 60 * 60 * 1000))
    }
  }

  useEffect(() => {
    let resourceID = resource.data?.id
    if (resourceID) {
      let key = `${resourceID}:ehr:v2:closedAt`
      let closedAt = Number(window.localStorage.getItem(key)) || 1
      setClosedAt(closedAt)
    }
  }, [resource.data])

  useEffect(() => {
    let eventID = new URLSearchParams(location.search).get("eventID");
    if (eventID) setEventID(eventID);
  }, [location.search])

  useEffect(() => {
    if (Array.isArray(records.data) || Array.isArray(newRecords)) {
      let stats = {}
      let payload = {
        records: true,
        ...filters,
        deleted: { count: 0, flag: false, ...filters?.deleted },
        createdBy: { ...filters?.createdBy }
      }

      delete payload.createdBy._ids

      for (const record of [...(records.data || []), ...newRecords]) {
        const key = record.createdBy

        payload.createdBy[key] ||= { count: 0, flag: true, id: record.createdBy }
        payload.createdBy[key].count += 1
        payload.createdBy[key].label ||= record.createdByName || record.createdBy
        if (payload.createdBy[key].label === record.createdBy && record.createdByName) {
          payload.createdBy[key].label = record.createdByName
        }
        if (record.deleted) {
          payload.deleted.count += 1
        }
        stats[record.type] ||= 0
        stats[record.type] += 1
        if (payload[record.type] === undefined) {
          payload[record.type] = true
        }
      }

      payload.createdBy._ids = Object.keys(payload.createdBy)
      setFilters(payload)
      setStats(stats)
    }
  }, [records.data, newRecords])

  useEffect(() => {
    if (user.state?.uid && filters && Array.isArray(records.data) && Array.isArray(forms.data) && consumer.data?.id) {
      const today = new Date()
      let consultations = {}
      let files = []
      let tables = {
        treatments: {
          values: {}, headers: {}, records: {}, ids: [], setup: {
            orientation: 'horizontal', allNumbers: false, borderless: false
          },
        },
        diagnostics: {
          values: {}, headers: {}, records: {}, ids: [], setup: {
            orientation: 'horizontal', allNumbers: false, borderless: false,
          }
        },
        labs: {
          groups: LABS_GROUPS, ids: [], values: {}, headers: {}, records: {}, setup: {
            orientation: 'vertical', allNumbers: true, borderless: true
          }
        },
        vitalSigns: {
          values: {}, headers: {}, records: {}, setup: {
            orientation: 'vertical', allNumbers: true, borderless: true,
          }, ids: []
        },
        forms: {}
      }

      const div = document.createElement('div')
      let items = clone(records.data || [])

      for (let item of newRecords) {
        let index = items.findIndex(i => i.id === item.id)
        if (index < 0) {
          items.push({ ...item })
        } else if (index >= 0 && item.updatedAt >= items[index].updatedAt) {
          items[index] = { ...item }
        }
      }

      items = items.sort(sort(filters.sortBy || 'date', filters.sort || 'desc'))
      let EHR_EDITABLE_TIME_LIMIT = Date.now() - 48 * 60 * 60 * 1000
      for (const record of items) {
        if (record.type === 'records' && !record.content?.trim()) continue
        const createdBy = record.createdBy || record.createdByName || 'unknown'
        const deleted = !!record.deleted
        if (!filters.createdBy?.[createdBy]?.flag) continue
        if (!filters.deleted.flag && deleted) continue
        let editable = !record.createdAt || record.createdAt >= EHR_EDITABLE_TIME_LIMIT
        editable = editable && (!record.createdBy || record.createdBy === user.state.uid)

        let date = parseDate(record[filters.sortBy] || record.date || record.createdAt || Date.now(), '10:00')
        date ||= parseDate(record.createdAt || Date.now(), '10:00')
        date ||= new Date()
        const group = `${createdBy}:${date.toLocaleDateString('fr-ca')}`
        consultations[group] ||= {
          evolutions: [], diagnostics: [], treatments: [], files: [], vitalSigns: 0, labs: 0, forms: 0,
          date,
          createdBy: record.createdByName || record.createdBy
        }
        if (consultations[group].createdBy && record.createdByName && consultations[group].createdBy !== record.createdByName) {
          consultations[group].createdBy = record.createdByName
        }

        if (record.type === 'records') {
          div.innerHTML = `<div>${record.content ?? ''}</div>`
          const text = deburr(div.textContent?.trim())
          consultations[group].evolutions.push({ ...record, text })
        } else if (record.type === 'files') {
          files.push({ ...record })
          consultations[group].files.push({ ...record })
        } else if (record.type === 'treatments') {
          const id = record.id
          tables.treatments.records[id] = record
          tables.treatments.ids.push(id)
          record.date ||= record.startsAt || record.createdAt
          record.createdAt ||= record.date

          tables.treatments.headers.date ||= { items: [] }
          let date = parseDate(record[filters.sortBy] || record.date, '10:00')

          tables.treatments.headers.date.items.push({ id, value: date, editable })

          let { drug, company, product, dose, presentation, frecuency, notes, startsAt, endsAt, status } = record
          tables.treatments.values.product ||= { items: [], id: 'product' }
          tables.treatments.values.dose ||= { items: [], id: 'dose' }
          tables.treatments.values.frecuency ||= { items: [], id: 'frecuency' }
          tables.treatments.values.notes ||= { items: [], id: 'notes' }
          tables.treatments.values.status ||= { items: [], id: 'status' }

          product ||= drug || record.label
          if (company) {
            product = `<div>${product} <sup class="text-secondary">${company}</sup></div>`
          }
          if (presentation) {
            product += `<div class="text-xs text-secondary">${presentation}</div>`
          }
          tables.treatments.values.product.items.push({ id, value: product, message: drug !== product ? drug : undefined })
          if (dose && dose !== presentation) {
            tables.treatments.values.dose.items.push({ id, value: dose })
          } else {
            tables.treatments.values.dose.items.push({ id, value: '' })
          }

          tables.treatments.values.frecuency.items.push({ id, value: frecuency })
          tables.treatments.values.notes.items.push({ id, value: notes })

          status = {
            value: status,
            label: t(`ehr:${status}`, { context: 'treatments' })
          }

          let dates = []

          try {
            if (startsAt) {
              dates.push(formatDate(startsAt, 'dd-MMM-yyyy').toUpperCase())
            } else {
              dates.push(t('ehr:startsAt_not_defined', '(n/a)', { context: 'treatments' }))
            }

            if (endsAt) {
              dates.push(formatDate(endsAt, 'dd-MMM-yyyy').toUpperCase())
            } else {
              dates.push(t('ehr:endsAt_not_defined', '(n/a)', { context: 'treatments' }))
            }
          } catch {}

          status.content = status.label
          if (status.value === 'active') {
            status.content = `<div class="text-success">${status.label}</div>`
          } else if (status.value === 'done') {
            status.content = `<div class="text-primary">${status.label}</div>`
          } else if (status. value === 'pending') {
            status.content = `<div class="text-warning">${status.label}</div>`
          }

          status.content += `<div class="text-xs text-secondary">${dates.join(' · ')}</div>`


          tables.treatments.values.status.items.push({ id, value: status.content })

          consultations[group].treatments.push({ ...record, product, status: status.content })
        } else if (record.type === 'diagnostics') {
          let { dx, label, notes, startsAt, status, endsAt } = record
          label ||= dx || '(N/A)'

          const id = record.id
          tables.diagnostics.ids.push(id)
          tables.diagnostics.records[id] = record
          record.date ||= record.startsAt || record.createdAt
          record.createdAt ||= record.date

          tables.diagnostics.headers.date ||= { items: [] }
          let date = parseDate(record[filters.sortBy] || record.date, '10:00')

          tables.diagnostics.headers.date.items.push({ id, value: date, editable })

          tables.diagnostics.values.label ||= { items: [], id: 'label' }

          let html = `<div style="max-width: 800px; white-space: break-spaces">${label}</div>`

          if (notes && notes !== label) {
            html += `<div style="max-width: 800px; white-space: break-spaces" class="text-secondary">${notes}</div>`
          }

          status = {
            value: status,
            label: t(`ehr:${status}`, { context: 'diagnoses' })
          }

          let dates = []

          try {
            if (startsAt) {
              dates.push(formatDate(startsAt, 'dd-MMM-yyyy').toUpperCase())
            } else {
              dates.push(t('ehr:startsAt_not_defined', 'N/A', { context: 'diagnoses' }))
            }

            if (endsAt) {
              dates.push(formatDate(endsAt, 'dd-MMM-yyyy').toUpperCase())
            } else {
              dates.push(t('ehr:endsAt_not_defined', 'N/A', { context: 'diagnoses' }))
            }
          } catch {}

          status.content = `<div>${status.label}</div>`
          if (status.value === 'intercurrence') {
            status.content = `<div class="text-success">${status.label}</div>`
          } else if (status.value === 'chronic') {
            status.content = `<div class="text-primary">${status.label}</div>`
          } else if (status. value === 'pending') {
            status.content = `<div class="text-warning">${status.label}</div>`
          }

          status.content += `<div class="text-xs text-secondary">${dates.join(' · ')}</div>`
          html = `<div>${status.content}</div><div class="mx-n2 px-2 mt-1">${html}</div>`

          tables.diagnostics.values.label.items.push({ id, value: html })

          // tables.diagnostics.values.status.items.push({ id, value: status.content })
          consultations[group].diagnostics.push({ ...record })
        } else if (record.type === 'labs') {
          const id = record.id
          tables.labs.ids.push(record.id)
          tables.diagnostics.records[id] = record

          record.date ||= record.startsAt || record.createdAt
          record.createdAt ||= record.date

          tables.labs.headers.date ||= { items: [] }
          let date = parseDate(record[filters.sortBy] || record.date, '10:00')

          tables.labs.headers.date.items.push({ id, value: date, editable })

          for (const type of LABS_GROUPS) {
            const names = Object.keys(record[type])
            tables.labs[type] ||= {}
            tables.labs[type].setup ||={ allNumbers: true, orientation: 'vertical' }
            tables.labs[type].values ||= {}
            for (const name of names) {
              let value = record[type][name].value?.trim()
              if (FALSY.includes(value)) {
                value = '·'
              }

              tables.labs[type].values[name] ||= { items: [], id: name }
              tables.labs[type].values[name].items.push({ id, value })
            }
          }

          if (record.eabrenal?.cr.value) {
            let years
            if (consumer.data.dob) {
              const date = parseDate(record.date || record.startsAt || record.createdAt, '10:00')
              years = Math.round((date.getTime() - new Date(consumer.data.dob).getTime()) / 1000 / 60 / 60 / 24 / 365)
            }
            const clcr = getClCr({
              equation: 'ckd',
              gender: consumer.data.gender,
              weight: false,
              age: years,
              creatinine: record.eabrenal.cr.value,
              unit: record.eabrenal.cr.unit
            })
            let value = clcr?.value
            if (FALSY.includes(value)) {
              value = '·'
            } else {
              record.clcr = value
            }
            tables.labs.eabrenal.values.clcr ||= { items: [], id: 'clcr' }
            tables.labs.eabrenal.values.clcr.items.push({
              id, value, message: clcr?.um, class: clcr?.class,
            })
          }
          consultations[group].labs += 1
        } else if (record.type === 'vitalSigns') {
          const id = record.id
          tables.vitalSigns.records[id] = record
          tables.vitalSigns.ids.push(id)
          record.date ||= record.startsAt || record.createdAt
          record.createdAt ||= record.date

          tables.vitalSigns.headers.date ||= { items: [] }
          let date = parseDate(record[filters.sortBy] || record.date, '10:00')
          tables.vitalSigns.headers.date.items.push({ id, value: date, editable })

          const { weight, height } = record
          tables.vitalSigns.values.bmi ||= { items: [], id: 'bmi' }
          let bmi = {}
          if (weight && height) {
            bmi = getBMI(height, weight) || {}
          } else {

          }

          tables.vitalSigns.values.bmi.items.push({
            id,
            value: bmi.value ? (bmi.value || 0).toFixed(2) : '·',
            message: bmi.warning || '',
            class: bmi.class || '',
          });

          for (const name of VITAL_SIGNS_VARIABLES) {
            let value = record[name]?.trim()
            if (FALSY.includes(value)) {
              value = '·'
            } else {
              value = Number(value)
            }

            tables.vitalSigns.values[name] ||= { items: [], id: name }
            tables.vitalSigns.values[name].items.push({ id, value })
          }
          consultations[group].vitalSigns += 1
        } else if (record.type === 'forms' && record.formID) {
          const formID = record.formID
          record.date ||= record.startsAt || record.createdAt
          record.createdAt ||= record.date
          consultations[group].forms += 1

          tables.forms[record.formID] ||= { values: {}, headers: {}, records: {}, ids: [] }
          tables.forms[record.formID].ids.push(record.id)
          tables.forms[record.formID].records[record.id] = record

          tables.forms[record.formID].headers.date ||= { items: [] }
          let date = parseDate(record[filters.sortBy] || record.date, '10:00')
          tables.forms[record.formID].headers.date.items.push({ id: record.id, value: date, editable })

          let form = tables.forms[record.formID].setup || forms.data.find(f => f.id === formID) || {}
          tables.forms[record.formID].setup ||= { ...form, allNumbers: true }

          if (!Array.isArray(form.fields)) {
            form.fields = []
          }

          const fields = Object.entries(record.fields)
          const formFields = [...form.fields]

          for (const [fieldID, payload] of fields) {
            // number
            // date
            // content
            // checked
            // files
            // diagnoses
            // items
            // treatments

            const fieldConfig = formFields.find(f => f.id === fieldID) || {}
            if (fieldConfig.hide) continue
            tables.forms[record.formID].values[fieldID] ||= {
              items: [],
              id: fieldID,
              label: fieldConfig.label,
              key: `${record.id}${fieldID}`
            }

            let value = payload.number || payload.date || payload.content || '·'
            if (fieldConfig.type  === 'checkbox') {
              value = payload.checked ? t('checked', 'Si') : t('unchecked', 'No')
            } else if (fieldConfig.type  === 'diagnoses') {
              value = payload.diagnoses?.map(d => (
                `<div class="text-sm">${d.label} <sup class="text-secondary">${d.id.replace('cie10:', '')?.toUpperCase()}</sup></div>`
              ))?.join() || '·'
            } else if (fieldConfig.type  === 'files') {
              payload.files ||= []
              value = ''
              for (const link of payload.files) {
                const name = link.replace('https://', '')
                value += `<div class="text-sm"><a href=${link} target="_blank" rel="noopener noreferrer">${name}</a></div>`
                consultations[group].files.push({ link, name, deleted })
                files.push({
                  ...record,
                  link,
                  name,
                  deleted: !!record.deleted
                })
              }

            } else if (fieldConfig.type  === 'items') {
              value = payload.items?.map(i => i.label)?.join(', ')
            } else if (fieldConfig.type  === 'treatments') {
              value = payload.treatments?.map(t => {
                t.product ||= t.drug
                let html = t.product
                if (t.company) {
                  html = `<div>${t.product} <sup class="text-secondary">${t.company}</sup></div>`
                }
                if (t.presentation) {
                  html += `<div class="text-xs text-secondary">${t.presentation}</div>`
                }
                return `<div class="text-xs" data-tip="${t.drug && t.drug !== t.product ? t.drug : ''}">${html}</div>`
              })?.join() || '·'
            }
            let text
            if (payload.content) {
              div.innerHTML = value
              text = div.textContent?.trim()
            }
            if (tables.forms[record.formID].setup.allNumbers && !isNaN(text)) {
              value = +text
            } else {
              tables.forms[record.formID].setup.allNumbers = false
            }

            if (!tables.forms[record.formID].setup.allNumbers && value === '·') {
              value = ''
            }

            tables.forms[record.formID].values[fieldID].items.push({
              id: record.id,
              fieldID,
              value,
            })

          }
        }
      }
      consultations.groups = Object.keys(consultations)
      setConsultations(consultations)
      setFiles(files)
      setTables(tables)
      setReady(true)
    }
  }, [records.data, newRecords, forms.data, consumer.data, filters, user.state?.uid])

  useEffect(() => {
    if (event.data?.id) try {
      let status = event.data.status
      let states = event.data.states || []
      let state = states.filter(s => status && s.status === status).sort((a, b) => b.createdAt - a.createdAt)[0]
      let createdAt = state?.createdAt || Date.now()
      let low = new Date(createdAt).getTime()
      let hi
      if (status === "fulfilled") {
        hi = low
        low = states
            .filter((s) => s.status === "active")
            .sort((a, b) => b.createdAt - a.createdAt)[0].createdAt
        low = new Date(low).getTime()
      }
      if (low) setTimer({ low, hi })
    } catch (e) {}

  }, [event.data])

  if (!ready) {
    return (
      <div className="mvh-100-1 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary">
          <span className="visually-hidden">{t("loading")}</span>
        </div>
      </div>
    );
  }

  const main = 400
  const sidebar = filters.records || filters.files || !!prescriptions?._form

  return (
    <div className={styles.container}>
      <Consumer consumer={consumer.data} />

      <div className="bg-white mt-1 shadow-sm d-flex align-items-center gap-2 px-2">
        {false && (
          <button className="btn btn-sm text-success bg-light fw-bold text-truncate border" onClick={() => setShowForm(!showForm)}>
            <IconPlusCircle className="" /> {t('ehr:create_new_record', 'Registro')}
          </button>
        )}
        {timer && (
          <button onClick={endConsultation} className="btn btn-sm text-pink bg-light fw-bold text-truncate border">
            {event.data.status === 'active' ? (
              t('ehr:end_active_consultation', 'Finalizar')
            ) : (
              t('ehr:end_consultation', 'Finalizar')
            )} - <span style={{ fontFamily: 'monospace' }}><Timer low={timer.low} hi={timer.hi} /></span>
          </button>
        )}

        {['records', 'files', 'diagnostics', 'treatments', 'vitalSigns', 'labs', 'forms'].filter(type => stats[type] > 0 || !['labs', 'forms'].includes(type)).map(type => (
          <button key={type} onClick={() => setFilters({ ...filters, [type]: !filters[type] })} className="btn btn-sm btn-link p-2 d-flex align-items-center gap-1">
            {filters[type] ? (
              <IconCheckSquare width="13" height="13" className="text-dark" />
            ) : (
              <IconSquare style={{ opacity: 0.35 }} width="13" height="13" className="text-dark" />
            )}{' '}{t(`ehr:${type}`, type)}<sup className="text-secondary">{stats[type] || 0}</sup>
          </button>
        ))}
        <button onClick={() => setPrescriptions({ ...prescriptions, _form: !prescriptions._form })} className="btn btn-sm btn-link p-2 d-flex align-items-center gap-1">
          {prescriptions?._form ? (
            <IconCheckSquare width="13" height="13" className="text-dark" />
          ) : (
            <IconSquare style={{ opacity: 0.35 }} width="13" height="13" className="text-dark" />
          )}{' '}{t('ehr:prescriptions', 'Recetas')}<sup className="text-secondary">{prescriptions?.docs?.length || 0}</sup>
        </button>
        <div className="flex-fill" />
        <button onClick={() => window.print()} className="btn btn-sm btn-link px-2 py-1 d-flex align-items-center gap-1">
          <IconPrinter className="text-secondary" /> {t('print', 'Imprimir')}
        </button>
        <Filters filters={filters} onChange={payload => setFilters({ ...filters, ...payload })}/>
      </div>

      {resource.data?.id && resource.data.createdAt <= V2_DATE && closedAt && closedAt + V2_CLOSE <= Date.now() && (resource.data.metadata?.ehr?.version !== 'v2:hard' || location.search.includes('mode=static')) && (
        <div className="px-2 py-1 bg-warning shadow-sm d-flex align-items-center">
          <small className="flex-fill">{t('ehr:v2_back_v1_or_default_message', 'Podes usar esta version de manera predeterminada o volver a la version anterior')}</small>
          <div className="d-flex align-items-center gap-1">
            <button onClick={setDefault} className="btn btn-sm btn-link">{t('ehr:v2_set_to_default', 'Usar')}</button>
            <Link className="btn btn-sm btn-link" to={`${location.pathname.replace('/ehr/v2', '/ehr')}${location.search}`}>{t('ehr:v2_back_v1_btn', 'Volver')}</Link>
            <button onClick={closeV2Message} className="btn btn-sm">{t('ehr:v2_back_v1_close', 'Cerrar')}</button>
          </div>
        </div>
      )}

      <div className="p-3">
        <div className={`d-flex d-print-block gap-3 ${styles.printAutoHW}`} style={{ height: `calc(100vh - 220px)`  }}>
          {sidebar && (
            <div
              style={{ width: main, maxWidth: main, minWidth: main, }}
              className={`h-100 ${styles.printAutoHW} ${main ? '' : 'flex-fill'}`}
            >
              <Consultations
                user={user.state}
                consultations={consultations}
                files={files}
                resource={resource.data}
                filters={filters}
                prescriptions={prescriptions}
              />
            </div>
          )}
          <div
            className={`flex-fill overflow-auto ${styles.printAutoHW}`}
            style={{ width: `calc(100% ${sidebar ? `- ${main}px` : ''} - 1rem)` }}
          >
            {showForm && user.state?.uid && consumer.data?.id && (
              <Form
                tabs
                labs={stats.labs > 0}
                forms={stats.forms > 0}
                consumer={consumer.data}
                resource={resource.data}
                user={user.state}
                onSave={(records, flag) => {
                  if (flag === 'prescriptions:update') qPrescriptions.refetch()
                  if (!records || !Array.isArray(records)) return
                  const array = [...newRecords]
                  for (const record of records) {
                    let index = array.findIndex(r => r.id === record.id)
                    if (index >= 0) {
                      array[index] = { ...record }
                    } else {
                      array.push({ ...record })
                    }
                  }
                  addRecords(array)
                }}
              />
            )}
            <Tables
              filters={filters}
              tables={tables}
              onSave={record => {
                const array = [...newRecords]
                let index = array.findIndex(r => r.id === record.id)
                if (index >= 0) {
                  array[index] = { ...record }
                } else {
                  array.push({ ...record })
                }
                addRecords(array)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EHR;
