import { useEffect, useRef } from 'react'
import Simple from "./Event/Simple";

const Print = ({ events, date }) => {
  const ref = useRef(null)
  useEffect(() =>{
    try {
      const element = ref.current
      if (ref.current) {
        window.document.getElementById('print').innerHTML = ref.current.innerHTML
      }

    } catch {}
  }, [events])

  useEffect(() => {
    return () => {
      try {
        window.document.getElementById('print').innerHTML = ''
      } catch {}
    }
  }, [])
  return (
    <div ref={ref} className="d-none">
      <h4 className="text-capitalize p-3 text-center">{date}</h4>
      <table className="table table-borderless table-striped align-middle bg-white">
        <tbody>
          {events.map(item => (
            <Simple key={item.id} event={item} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Print
