const hemogramaModel = () => {
  return {
    hto: {
      value: "",
      unit: "",
    },
    hb: {
      value: "",
      unit: "",
    },
    gb: {
      value: "",
      unit: "",
    },
    neu: {
      value: "",
      unit: "",
    },
    lin: {
      value: "",
      unit: "",
    },
    bas: {
      value: "",
      unit: "",
    },
    eos: {
      value: "",
      unit: "",
    },
    mon: {
      value: "",
      unit: "",
    },
    plqt: {
      value: "",
      unit: "",
    },
    glu: {
      value: "",
      unit: "",
    },
    reti: {
      value: "",
      unit: "",
    },
  };
};

const um = () => {
  return {
    hto: ["%"],
    hb: ["g/dL", "g/L"],
    gb: ["mg/dL", "mcmol/L"],
    neu: ["x10 3 c/mcL", "x10 9 c/L"],
    lin: ["x10 3 c/mcL", "x10 9 c/L"],
    bas: ["x10 3 c/mcL", "x10 9 c/L"],
    eos: ["x10 3 c/mcL", "x10 9 c/L"],
    mon: ["x10 3 c/mcL", "x10 9 c/L"],
    plqt: ["x10 3/mcl", "x10 9/L"],
    glu: ["mg/dL", "mmol/L"],
    reti: ["%", "x10 9 /L"],
  };
};

export { hemogramaModel, um };
