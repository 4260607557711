import { Router } from "@reach/router";

import Home from "./Home/Home";
import Form from "./Form/Form";
import EHR from "./EHR/EHR";
import EHRV2 from "../EHR/V2/EHR";

const Consumers = () => {
  return (
    <Router>
      <Home path="/" />
      <EHRV2 path=":consumerID/ehr/v2/*" />
      <EHR path=":consumerID/ehr/*" />
      <Form
        path=":consumerID/*"
        className="container p-4 my-4 shadow bg-white rounded"
        source="home"
      />
    </Router>
  );
};

export default Consumers;
