import { useEffect, useState, useRef } from 'react'
import { useTranslation } from "react-i18next";
import helpers from "@drapp/helpers";

import useMutation from 'hooks/useMutation'
let clone = window.structuredClone || JSON.clone

let {
  REACT_APP_INCREASE_CHECKOUT_LITE_URL,
  REACT_APP_INCREASE_ACCOUNT_ID
} = process.env

export function IncreaseCheckoutLite({ subscriptionID, className, onUpdate }) {
  let $iframe = useRef(null)
  let [status, setStatus] = useState({ })

  useEffect(() => {
    let callback = message => {
      let { payload, type } = message?.data || {}
      if (onUpdate) onUpdate(type)
      if (type === 'PAYMENT_SUCCESS') {
        setStatus(status => ({ ...status, payment: 'success' }))
      } else if (type === 'PAYMENT_ERROR') {
        setStatus(status => ({ ...status, payment: 'error' }))
      } else if (type === 'PAYMENT_IN_PROCESS') {
        setStatus(status => ({ ...status, payment: 'process' }))
      } else if (type === 'LOAD_ERROR') {
        setStatus(status => ({ ...status, load: 'error' }))
      } else if (type === 'LOAD_SUCCESS') {
        setStatus(status => ({ ...status, load: 'ready' }))
      }
    }

    window.addEventListener('message', callback);

    return () => window.removeEventListener('message', callback)
  }, [$iframe.current])

  if (!subscriptionID || !REACT_APP_INCREASE_CHECKOUT_LITE_URL) return null
  let options = {
    container: 'overflow-hidden',
    iframe: { width: '685px', height: '410px' }
  }
  if (window.document.body.clientWidth <= 650) {
    options.container = ''
    options.iframe = { width: '100%', height: '100vh' }
  }
  return (
    <div className={[options.container, 'position-relative', className || 'bg-white'].filter(Boolean).join(' ')}>
      <div
        style={status.load === 'ready' ? ({ height: '382px', marginTop: '-104px' }) : ({})}
      >
        {!status.load && (
          <div className="d-flex justify-content-center align-items-center">
            <span className="spinner-border text-primary" role="status"></span>
          </div>
        )}

        <iframe
          ref={$iframe}
          id="checkoutLiteIframe"
          style={status.load !== 'ready' ? ({ display: 'none' }) : options.iframe}
          src={`${REACT_APP_INCREASE_CHECKOUT_LITE_URL}?subscription_id=${subscriptionID}`}
        />
      </div>
      {status.load === 'ready' && REACT_APP_INCREASE_CHECKOUT_LITE_URL.includes('pay-sandbox') && (
        <div className="position-absolute top-0" style={{ left: '690px', zIndex: 10 }}>
          <div className="bg-light p-3 border rounded">
            <pre style={{ whiteSpace: 'pre-line' }} className="m-0">{`5399090000000009
            4850110000000000

            APPROVED 05/25 777
            REJECTED 07/27 666`}</pre>
          </div>
          <a className="lh-1 mt-3" href="https://developers.increase.app/docs/how_to_test_payments#tarjetas-de-prueba" rel="noopener noreferrer" target="_blank">
            <small>Pagos de prueba</small>
          </a>
        </div>
      )}
    </div>
  )
}
