// https://stackoverflow.com/a/1349426/1585929
const makeid = (length = 8) => {
  const parts = [];
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    parts.push(
      characters.charAt(Math.floor(Math.random() * characters.length))
    );
  }
  return parts.join("");
};

export default makeid;
