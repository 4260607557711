import { io } from "socket.io-client";

const {
  REACT_APP_WS_URL
} = process.env

export default token => io(REACT_APP_WS_URL, {
  extraHeaders: {
    'Authorization': `Bearer ${token}`
  }
});
