import { useEffect } from 'react'
import { Router, useLocation, useNavigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconXCircleFill } from "bootstrap-icons/icons/x-circle-fill.svg"

import useStore from "./useStore";
import Calendar from "./Calendar/Calendar";
import Agenda from "./Agenda/Agenda";
import Sidebar from "./Sidebar/Sidebar";

const regexp = /\d{4}-\d{2}-\d{2}/

function Events() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation()

  const store = useStore((state) => ({
    filters: {
      resource: state.resource?.id,
      location: state.location?.id,
      service: state.service?.key,
      financier: state.financier?.key
    },
    date: state.date,
    updatedAt: state.updatedAt,
    reset: state.reset,
    update: state.update,
    event: state.event,
    reschedule: state.reschedule,
    hide: state.hide,
  }));

  const body = { ...store.filters };

  if (location.pathname.includes('/agenda/')) {
    delete body.service
    delete body.financier
  }

  useEffect(() => {
    return () => {
      store.reset(null)
    }
  }, [])

  useEffect(() => {
    let $params = new URLSearchParams(location.search).toString();
    $params = $params ? `?${$params}` : "";

    let date = new Date().toLocaleDateString('fr-ca')
    const URLdate = location.pathname?.match(regexp)?.[0]

    if (store.updatedAt && store.date) {
      date = store.date
    } else if (URLdate) {
      date = URLdate
    }

    const section = location.pathname.includes("agenda") ? "agenda" : "calendar";
    const path = window._l(`/events/${section}/${date}${$params}`)

    if (!location.pathname.includes(date)) {
      navigate(path, { replace: !URLdate });
    }

  }, [store.date, store.updatedAt, location.pathname])

  if (!regexp.test(location.pathname)) return null

  return (
    <>
      {store.event?.id && store.reschedule && (
        <div className="p-2 rounded shadow-sm text-dark small bg-warning position-fixed bottom-0 start-50 translate-middle d-flex align-items-center"
          style={{ zIndex: 2 }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="flex-fill">
              <div className="fw-bold">{t('reschedule_title', 'Reprogramando')}</div>
              <div>{t('reschedule_description', 'Seleccione un horario para confirmar')}</div>
            </div>
            <div>
              <button
                data-tip={t("reschedule_cancel_tip", "Cancelar reprogramacion")}
                className="btn btn-sm text-dark p-0 ms-3"
                onClick={() => store.update({ event: undefined, reschedule: undefined })}
              >
                <IconXCircleFill width="16" height="16" />
              </button>
            </div>
          </div>

        </div>
      )}
      <div className="d-flex vh-100-1 overflow-hidden">
        <Sidebar />
        <Router className="flex-fill" id="main-container">
          <Calendar path="calendar/*date" />
          <Agenda path="agenda/*date" />
        </Router>
      </div>
    </>
  );
}

export default Events;
