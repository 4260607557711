import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useUser from "hooks/useUser";

import Items from "./Items";
import Input from "./Input";
import Numeric from "./Numeric";
import DateInput from "./DateInput";
import Checkbox from "./Checkbox";
import Files from "./Files";
import Diagnoses from "./Diagnoses";
import Treatments from "./Treatments";
import References from "./References";

const App = (props) => {
  const { t } = useTranslation();
  const user = useUser();

  const editable =
    user.state.uid === props.record.createdBy &&
    props.record.createdAt > Date.now() - 24 * 60 * 60 * 1000;

  const type = props.field.type || "text";

  const onChange = (value) => {
    const record = JSON.clone({
      ...props.record,
      updatedAt: Date.now(),
      updatedBy: user.state.uid,
    });
    record.fields = record.fields || {};
    record.fields[props.field.id] = {
      ...value,
      updatedAt: Date.now(),
      updatedBy: user.state.uid,
    };
    props.onChange(record);
  };

  const options = {
    field: props.field,
    form: props.form,
    editable,
    onChange,
  };
  const content = props.record.fields?.[props.field.id]?.content
  return (
    <td
      className={`align-middle p-0`}
      style={{ ...props.style, width: type === "date" ? "163px" : undefined }}
    >
      {type === "text" ? (
        <Input
          {...options}
          value={content}
        />
      ) : type === "number" ? (
        <Numeric
          {...options}
          value={props.record.fields?.[props.field.id]?.number || props.record.fields?.[props.field.id]?.content}
        />
      ) : type === "date" ? (
        <DateInput
          {...options}
          value={props.record.fields?.[props.field.id]?.date || (content ? <div className="p-2" dangerouslySetInnerHTML={{ __html: content }} /> : null)}
        />
      ) : type === "checkbox" ? (
        <Checkbox
          {...options}
          value={!!props.record.fields?.[props.field.id]?.checked}
        />
      ) : type === "items" ? (
        <Items
          {...options}
          value={props.record.fields?.[props.field.id]?.items}
        />
      ) : type === "files" ? (
        <Files
          {...options}
          value={props.record.fields?.[props.field.id]?.files}
        />
      ) : type === "diagnoses" ? (
        <Diagnoses
          {...options}
          value={props.record.fields?.[props.field.id]?.diagnoses}
        />
      ) : type === "treatments" ? (
        <Treatments
          {...options}
          value={props.record.fields?.[props.field.id]?.treatments}
        />
      ) : (
        type === "references" && (
          <References
            {...options}
            value={props.record.fields?.[props.field.id]?.references}
          />
        )
      )}
    </td>
  );
};

export default App;
