export default function Switch({
  loading,
  onChange,
  checked,
  label,
  hint,
  id,
}) {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id={`switch${id}`}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={`switch${id}`}>
        {label}
      </label>
      {hint && (
        <div className="form-text" dangerouslySetInnerHTML={{ __html: hint }} />
      )}
    </div>
  );
}
