import { useState, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { ReactComponent as IconTrash } from 'bootstrap-icons/icons/trash.svg'
import { v4 as uuid } from "uuid";
import chunk from 'lodash/chunk'

import useUpload from "hooks/useUpload";
import useMutation from "hooks/useMutation";

let FACTOR = 1000

function getSize(bytes) {
  let value = bytes / FACTOR
  if (value < FACTOR) {
    return `${value.toFixed(2)}K`
  }
  value = value / FACTOR
  if (value < FACTOR) {
    return `${value.toFixed(2)}M`
  }
  value = value / FACTOR
  if (value < FACTOR) {
    return `${value.toFixed(2)}G`
  }
  value = value / FACTOR
  if (value < FACTOR) {
    return `${value.toFixed(2)}T`
  }
  value = value / FACTOR
  return `${value.toFixed(2)}P`
}

function Archive(props) {
  const { t } = useTranslation();
  const [state, setState] = useState({ notes: props.file.notes || '', _name: props.file._name || props.file.name || '' })
  let size = getSize(props.file.size)

  const onChange = ({ target: { name, value } }) => {
    state[name] = value
    setState({ ...state })
    props.onChange(name, value)
  }

  return (
    <tr>
      <td className="p-0 w-100">
        <div className="d-flex gap-2">
          <div className="form-floating">
            <input
              type="text"
              name="_name"
              value={state._name || ''}
              onChange={onChange}
              className="form-control border-0"
              placeholder={`${t("ehr:filename", 'Nombre', { context: 'files' })} - ${size}`}
            />
            <label className="text-secondary">{t("ehr:filename", 'Nombre', { context: 'files' })} - {size}</label>
          </div>
          <div className="form-floating border-start flex-fill">
            <input
              type="text"
              name="notes"
              value={state.notes || ''}
              onChange={onChange}
              className="form-control border-0"
              placeholder={t("ehr:notes", 'Notas', { context: 'files' })}
            />
            <label className="text-secondary">{t("ehr:notes", 'Notas', { context: 'files' })}</label>
          </div>
        </div>
      </td>
      <td>
        <button className="btn btn-sm p-2 link-danger" onClick={props.onRemove}>
          <IconTrash />
        </button>
      </td>
    </tr>
  )
}

function Files(props) {
  const { t } = useTranslation();
  const [files, setFiles] = useState([])
  const upload = useUpload()
  const [mutate, mutation] = useMutation(window._l(`/records`).substr(1));
  const [isLoading, setIsLoading] = useState(false)

  const $files = useRef(null)

  const save = async () => {
    setIsLoading(true)
    const array = []

    for (const file of files) {
      if (file.createdAt) continue
      file.createdAt = Date.now()
      array.push(file)
    }

    let promises = []

    await Promise.all(
      chunk(array, 10).map(async group => {
        try {
          const links = await upload(group);

          for (let i = 0; i < links.length; i += 1) {
            let file = group[i]

            promises.push(
              mutation.mutateAsync({
                link: links[i],
                consumer: { id: props.consumer.id, label: props.consumer.label },
                createdBy: props.user?.uid,
                createdByName: props.user?.name || props.resource?.label,
                createdByResourceID: props.resource?.id,
                name: file._name || file.name,
                notes: file.notes,
                type: 'files',
                contentType: file.type,
                size: file.size,
              })
            )
          }
        } catch (e) {
          console.error(e)
        }
      })
    )

    const records = await Promise.all(promises)

    if (props.onSave) props.onSave(records)
    setIsLoading(false)
    setFiles([])
  }

  const clear = () => {
    setFiles([])
  }

  const onChange = (e) => {
    const array = Array.from(e.target.files)
    $files.current.value = ""
    for (const item of array) {
      item.id ||= uuid()
    }
    setFiles([...files, ...array])
  }

  return (
    <>
      <button 
        onClick={() => $files.current.click()}
        className="py-2 px-3 btn btn-sm btn-link"
        >{t('ehr:files', '+ Archivos', { context: 'form' })}
      </button>
      {files.length > 0 && (
        <div className="position-fixed top-0 start-0 end-0 bottom-0 w-100 h-100 overflow-auto p-3" style={{ zIndex: 1000, background: 'rgba(0, 0, 0, 0.85)' }}>
          {isLoading ? (
            <div
              className="mx-auto bg-white rounded overflow-hidden"
              style={{ maxWidth: 768, minWidth: 768, width: 768 }}
            >
              <div className="border-top border-end border-start bg-light p-3 border-bottom">
                <div className="text-secondary lh-1">{t('ehr:saving_files', 'Guardando Archivos')}</div>
              </div>
              <div className="my-3 text-center">
                <div className="spinner-border text-primary">
                  <span className="visually-hidden">{t("loading")}</span>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="mx-auto bg-white rounded overflow-hidden"
              style={{ maxWidth: 768, minWidth: 768, width: 768 }}
            >
              <div className="d-flex align-items-center border-top border-end border-start bg-light p-1">
                <div>
                  <button className="btn p-2 link-success me-2" onClick={save}>{t('ehr:upload', 'Subir', { context: 'files' })}</button>
                  <button
                    onClick={() => $files.current.click()}
                    className="btn p-2 btn-link"
                    >{t('ehr:files_select_more', 'Seleccionar mas')}
                  </button>
                </div>
                <div className="flex-fill"></div>
                <div>
                  <button className="btn p-2 link-secondary" onClick={clear}>{t('ehr:cancel', 'Cancelar', { context: 'files' })}</button>
                </div>
              </div>
              <table className="table table-bordered m-0">
                <tbody>
                  {files.map(file => (
                    <Archive
                      key={file.id}
                      file={file}
                      onChange={(key, value) => {
                        const array = files.reduce((array, f) => {
                          if (file.id === f.id) {
                            f[key] = value
                          }
                          return [...array, f]
                        }, [])
                        setFiles([...array])
                      }}
                      onRemove={() => setFiles([...files.filter(f => f.id !== file.id)])}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      <input
        ref={$files}
        type="file"
        className="form-control d-none"
        id="files"
        name="files[]"
        multiple
        onChange={onChange}
      />
    </>
  )
}

export default Files
