import { useState } from "react";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";
import kebabCase from "lodash/kebabCase";

import useCatalogs from "hooks/useCatalogs";
import styles from "./styles.module.scss";

const App = (props) => {
  const { t } = useTranslation();

  const onChange = (value) => {
    let array = [value];
    if (Array.isArray(value)) {
      array = [...value];
    }
    array = array.filter((item) => item?.value);
    props.onChange({ items: array });
  };

  return props.editable ? (
    <Creatable
      styles={{
        container: (styles) => ({
          ...styles,
          width: "100%",
        }),
        control: (styles) => ({
          ...styles,
          border: "none",
          borderRadius: 0,
          boxShadow: "none",
        }),
      }}
      isDisabled={!props.editable}
      isMulti={!!props.field.isMulti}
      isClearable
      tabSelectsValue
      backspaceRemovesValue
      defaultMenuIsOpen={false}
      getNewOptionData={(value, label) => ({ label, value: kebabCase(value) })}
      value={props.value}
      options={props.field.options || []}
      onChange={onChange}
      formatCreateLabel={(inputValue) => inputValue}
      placeholder={t("form_field_type_items_placeholder", "")}
      components={{ IndicatorSeparator: null, DropdownIndicator: null }}
    />
  ) : (
    <div className="p-2">
      {props.value
        ?.map((i) => i.label)
        ?.filter(Boolean)
        ?.join(", ")}
    </div>
  );
};

export default App;
