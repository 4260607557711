import { useState, useRef } from 'react'
import { ReactComponent as IconSearch } from 'bootstrap-icons/icons/search.svg'
import CreatableSelect from 'react-select/creatable';
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import styles from './styles.module.scss'

const { REACT_APP_API_URL } = process.env;

const request = path =>
  fetch(`${REACT_APP_API_URL}/${path}`).then(res => res.json()).catch(() => [])

const App = props => {
  const { t } = useTranslation();
  const [value, setValue] = useState('')
  const $select = useRef(null)
  
  const query = useQuery(
    ['query', value],
    () => request(`${props.path}?q=${value}`),
    { enabled: value?.length >= 3 && (props.enabled ?? true) }
  )

  return (
    <CreatableSelect
      ref={$select}
      className={`d-print-none flex-fill ${styles.select}`}
      formatCreateLabel={label => (
        <div className="text-first-up">{label} <small className="text-secondary"> - {t('uncategorized_option', 'Opción sin categorizar')}</small></div>
      )}
      onFocus={props.onFocus}
      filterOption={(candidate, input) => true}
      isValidNewOption={() => query.data?.length === 0}
      onChange={props.onChange}
      value={null}
      onInputChange={value => setValue(value)}
      isLoading={query.isFetching}
      options={query.data}
      menuIsOpen={value?.length >= 3}
      placeholder={props.placeholder || t('search_placeholder', 'Buscador')}
      noOptionsMessage={() => props.noOptionsMessage || t('no_results', 'No se encontraron resultados')}
      loadingMessage={() => props.loadingMessage || t('searching', 'Buscando')}
      getOptionValue={props.getOptionValue}
      getOptionLabel={props.getOptionLabel}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
      }}
      styles={{
        control: (provided, state) => ({
          padding: '2px',
          boxShadow: 'none !important',
        }),
        singleValue: (provided, state) => ({
          ...provided,
          // fontSize: 14,
          color: "var(--bs-primary)",
        }),
        option: (provided, state) => ({
          ...provided,
          // fontSize: 14,
        }),
        placeholder: (provided, state) => ({
          ...provided,
          // color: "rgba(34,47,62,.7)",
          fontWeight: 300,
          // fontSize: '15px'
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
        }),
        clearIndicator: (provided) => ({
          ...provided,
          marginRight: "-10px",
        }),
      }}
    />
  )
}

export default App
