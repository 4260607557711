import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";

import useStore from "../useStore";

const Option = ({ children, ...props }) => {
  const { capacity, active } = props.data || {}
  const isActive = !!(capacity || active)
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center gap-2">
        <div>{active > 0 ? '✅' : '❗'}</div>
        <div className="text-truncate text-nowrap flex-fill">{children}</div>
      </div>
    </components.Option>
  )
}

const control = highlight => ({ children, ...props }) => {
  const className = props.className?.split(' ') || []
  if (highlight) className.push('is-invalid')
  return (
    <components.Control {...props} className={className.join(' ')}>
      {children}
    </components.Control>
  )
}

const Component = (props) => {
  const { t } = useTranslation()
  const isFetching = useStore(state => !!state.isFetching);

  const key = props.valueKey || "id";

  const onChange = value => {
    props.onChange(value);
  };

  const isLoading = props.isLoading ?? isFetching

  if (!props.show) return null

  return (
    <Select
      isClearable={!isLoading}
      isDisabled={isLoading || props.isDisabled}
      value={props.value}
      onChange={onChange}
      options={props.data}
      isLoading={isLoading}
      placeholder={props.placeholder}
      noOptionsMessage={() => props.noOptionsMessage ?? t('no_options', 'Sin opciones')}
      loadingMessage={() => props.loadingMessage ?? t('loading', 'Cargando')}
      getOptionValue={(item) => item[key]}
      components={{
        IndicatorSeparator: () => null,
        Option: params => <Option {...params} />,
        Control: control(props.highlight)
      }}
      styles={{
        singleValue: (provided, state) => ({
          ...provided,
          fontSize: 14,
          color: "var(--bs-primary)",
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: 14,
        }),
        placeholder: (provided, state) => ({
          ...provided,
          fontSize: 14,
          color: "#aaa",
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
        }),
        clearIndicator: (provided) => ({
          ...provided,
          marginRight: "-10px",
        }),
      }}
    />
  );
};

export default Component;
