import useQuery from "./useQuery";
import useUser from "./useUser";

const Hook = (namespace, q, options) => {
  const user = useUser();

  const key = `catalogs/${namespace}?q=${q}&locale=${
    user.state.language || ""
  }&country=${user.state.country}`;

  return useQuery(key, { ...options, initialData: options?.initialData || [] });
};

export default Hook;
