export const getClCr = (equation, gender, weight, age, creatinine, unit) => {
  let result;

  if (!creatinine) return;

  let _creatinine = +creatinine

  if (unit === "µmol/L") {
    _creatinine = _creatinine / 88.4
  }

  let _gender = gender?.toString()?.trim()?.toLowerCase()?.[0] || 'm'

  if (!Number.isInteger(age)) return

  if (equation === "cogau" && weight) {
    let f1 = 140 - age;
    let f2 = f1 * weight;
    let f3 = 72 * _creatinine;
    result = f2 / f3;
    if (_gender === "f") {
      result *= 0.85;
    }
  }

  if (equation === "mdrd") {
    let f1 = Math.pow(_creatinine, -1.154);
    let f2 = Math.pow(age, -0.203);
    result = 175 * f1 * f2;
    if (_gender === "f") {
      result *= 0.742;
    }
  }

  if (equation === "ckd") {
    let [f1, f2] = _gender === 'f' ? [0.7, 144] : [0.9, 141]
    let f3 = Math.pow(0.993, age);
    let f4 = _creatinine / f1;
    let f5 = Math.pow(f4, -1.209)
    if (_gender === "m" && _creatinine <= f1) {
      f5 = Math.pow(f4, -0.411);
    } else if (_gender === "f" && _creatinine <= f1) {
      f5 = Math.pow(f4, -0.329);
    }

    result = f2 * f5 * f3
  }

  if (!result?.toFixed) return

  return { value: result.toFixed(2), um: 'mil/min' };
}

export const getBMI = (height, weight) => {
  if (!height || !weight) return;

  const h2 = Math.pow(height / 100, 2);
  const value = weight / h2;

  if (value < 18) return { value, warning: "underweight", class: 'text-warning' };
  if (value <= 25) return { value, warning: "normal", class: 'text-success' };
  if (value < 30) return { value, warning: "overweight", class: 'text-warning' };

  return { value, warning: "obese", class: 'text-danger' };
}
