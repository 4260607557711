import { useReducer, useEffect, useState, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { Dropdown } from "bootstrap";
import { useLocation } from '@reach/router'

import Editor from '../../Editor/Editor'
import Treatment from './Treatment'
import Diagnostic from './Diagnostic'
import VitalSigns from './VitalSigns'
import Prescription from '../Prescription/Prescription'
import Files from './Files'

import useMutation from "hooks/useMutation";
import getDate from 'lib/getDate'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_RECORD': {
      return {
        changed: Date.now(),
        saving: Date.now(),
        record: { ...action.record }
      }
    }
    case 'SAVING': {
      return {
        ...state,
        saving: Date.now(),
      }
    }
    case 'SAVED': {
      return {
        ...state,
        record: { ...action.record, ...state.record },
        saving: state.saving > action.saving,
      }
    }
    default: {
      throw Error(`Unknown action: ${action.type}`)
    }
  }
}

function Form(props) {
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, {
    record: {
      consumer: { id: props.consumer?.id, label: props.consumer?.label },
      createdBy: props.user?.uid,
      createdByName: props.user?.name || props.resource?.label,
      createdByResourceID: props.resource?.id,
      type: 'records',
      ...props.record,
      date: props.record?.date || getDate()
    }
  })
  const [show, setShow] = useState({})
  const { t } = useTranslation();
  const [mutate, mutation] = useMutation(window._l(`/records`).substr(1));

  const $files = useRef(null)

  useEffect(() => {
    let ticker
    if (state.changed) {
      ticker = setTimeout(save, 2000)
    }
    return () => clearTimeout(ticker)
  }, [state.changed])

  useEffect(() => {
    let show = e => {
      let key = e.target.dataset.form
      if (!key) return
      setShow(show => ({ ...show, [key]: true }))
    }

    let hide = e => {
      let key = e.target.dataset.form || e.form
      if (!key) return
      setShow(show => ({ ...show, [key]: false }))
    }

    window.document.body.addEventListener('show.bs.dropdown', show)
    window.document.body.addEventListener('hidden.bs.dropdown', hide)

    return () => {
      window.document.body.removeEventListener('show.bs.dropdown', show)
      window.document.body.removeEventListener('hidden.bs.dropdown', hide)
    }
  }, [])

  const toggle = key => {
    setShow({ ...show, [key]: !show[key] })
  }

  const onEditorChange = content => {
    const record = { ...state.record, content }
    dispatch({ type: 'SET_RECORD', record })
  }

  const onChangeDate = ({ target: { value } }) => {
    const record = { ...state.record, date: value }
    dispatch({ type: 'SET_RECORD', record })
  }

  const onClose = key => {
    try {
      let $element = window.document.querySelector(`[data-form="${key}"]`)
      if ($element) Dropdown.getInstance($element).hide();
    } catch (e) {
      console.log(e)
    }
  }

  const onSave = key => record => {
    onClose(key)
    if (props.onSave && record) props.onSave([record])
  }

  const save = async () => {
    try {
      dispatch({ type: 'SAVING' })
      let saving = Date.now()
      mutate(state.record, {
        onSuccess: record => {
          dispatch({ type: 'SAVED', record, saving })
          if (props.onSave) props.onSave([record])
        }
      })
    } catch (error) {
      console.error('Error saving', error)
    }
  }

  return (
    <div className="mb-3 d-print-none" style={{ background: '#ffef5f69' }}>
      <div>
        {!!props.tabs && (
          <div className="d-flex align-items-center">
            <div>
              <Files
                consumer={props.consumer}
                resource={props.resource}
                user={props.user}
                onSave={props.onSave}
              />
            </div>
            <div>
              <button className="py-2 px-3 btn btn-sm btn-link" data-bs-toggle="dropdown" data-form="diagnostic" data-bs-auto-close="outside">{t('ehr:diagnostic', '+ Diagnostico', { context: 'form' })}</button>
              <div className="dropdown-menu mt-1 p-2 bg-white border shadow-sm rounded" style={{ maxWidth: 320, minWidth: 320, width: 320 }}>
                {show.diagnostic && (
                  <Diagnostic
                    consumer={props.consumer}
                    resource={props.resource}
                    user={props.user}
                    onSave={onSave('diagnostic')}
                    onClose={() => onClose('diagnostic')}
                  />
                )}
              </div>
            </div>
            <div>
              <div className="dropdown">
                <button
                  className="py-2 px-3 btn btn-sm btn-link"
                  data-bs-toggle="dropdown"
                  data-form="treatment"
                  data-bs-auto-close="outside"
                >{t('ehr:treatment', '+ Tratamiento', { context: 'form' })}</button>
                <div className="dropdown-menu mt-1 p-2 bg-white border shadow-sm rounded" style={{ maxWidth: 320, minWidth: 320, width: 320 }}>
                  {show.treatment && (
                    <Treatment
                      consumer={props.consumer}
                      resource={props.resource}
                      user={props.user}
                      onSave={onSave('treatment')}
                      onClose={() => onClose('treatment')}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <button className="py-2 px-3 btn btn-sm btn-link" data-bs-toggle="dropdown" data-form="vitalSigns" data-bs-auto-close="outside">{t('ehr:vitalSigns', '+ Signos Vitales', { context: 'form' })}</button>
              <div className="dropdown-menu mt-1 p-2 bg-white border shadow-sm rounded" style={{ maxWidth: 320, minWidth: 320, width: 320 }}>
                {show.vitalSigns && (
                  <VitalSigns
                    consumer={props.consumer}
                    resource={props.resource}
                    user={props.user}
                    onSave={onSave('vitalSigns')}
                    onClose={() => onClose('vitalSigns')}
                  />
                )}
              </div>
            </div>

            <div>
              <button
                className="py-2 px-3 btn btn-sm btn-link"
                onClick={() => setShow({ ...show, prescription: true })}
              >{t('ehr:prescription', 'Receta', { context: 'form' })}</button>
                {show.prescription && (
                  <div className="position-fixed top-0 start-0 end-0 bottom-0 w-100 h-100 overflow-auto" style={{ zIndex: 1020, background: 'rgba(0, 0, 0, 0.8)' }}>
                    <Prescription
                      consumer={props.consumer}
                      resource={props.resource}
                      user={props.user}
                      type="prescription"
                      onSave={prescription => props.onSave([prescription], 'prescriptions:update')}
                      onClose={() => setShow({ ...show, prescription: false })}
                    />
                  </div>
                )}
            </div>

            <div>
              <button
                className="py-2 px-3 btn btn-sm btn-link"
                onClick={() => setShow({ ...show, medicalOrder: true })}
              >{t('ehr:medical_order', 'Orden', { context: 'form' })}</button>
                {show.medicalOrder && (
                  <div className="position-fixed top-0 start-0 end-0 bottom-0 w-100 h-100 overflow-auto" style={{ zIndex: 1020, background: 'rgba(0, 0, 0, 0.8)' }}>
                    <Prescription
                      consumer={props.consumer}
                      resource={props.resource}
                      user={props.user}
                      type="order"
                      onSave={prescription => props.onSave([prescription], 'prescriptions:update')}
                      onClose={() => setShow({ ...show, medicalOrder: false })}
                    />
                  </div>
                )}
            </div>

            {props.labs && (
              <div>
                <button className="py-2 px-3 btn btn-sm btn-link" data-bs-toggle="dropdown" data-form="labs" data-bs-auto-close="outside">{t('ehr:labs', '+ Labs', { context: 'form' })}</button>
                <div className="dropdown-menu mt-1 p-2 bg-white border shadow-sm rounded" style={{ maxWidth: 320, minWidth: 320, width: 320 }}>
                  {show.labs && (
                    <div className="lh-1">
                      <div className="mb-1"><small className="text-secondary">{t('ehr:v2_labs_message', 'Laboratorios se debe usar desde la version anterior. Estamos trabajando para que se pueda usar desde aca', { context: 'form' })}</small></div>
                      <small><a href={`${location.pathname.replace('v2', 'labs')}?mode=static`} rel="noopener noreferrer" target={`labs${props.consumer.id.replace('consumers/', '')}`}>{t('ehr:v2_labs_link', 'Ir a la version anterior (se abre en una nueva pestaña)')}</a></small>
                    </div>
                  )}
                </div>
              </div>
            )}

            {props.forms && (
              <div>
                <button className="py-2 px-3 btn btn-sm btn-link" data-bs-toggle="dropdown" data-form="forms" data-bs-auto-close="outside">{t('ehr:forms', '+ Formularios', { context: 'form' })}</button>
                <div className="dropdown-menu mt-1 p-2 bg-white border shadow-sm rounded" style={{ maxWidth: 320, minWidth: 320, width: 320 }}>
                  {show.forms && (
                    <div className="lh-1">
                      <div className="mb-1"><small className="text-secondary">{t('ehr:v2_forms_message', 'Los formularios se deben usar desde la version anterior. Estamos trabajando para que se pueda usar desde aca', { context: 'form' })}</small></div>
                      <small><a href={`${location.pathname.replace('v2', 'forms')}?mode=static`} rel="noopener noreferrer" target={`forms${props.consumer.id.replace('consumers/', '')}`}>{t('ehr:v2_labs_link', 'Ir a la version anterior (se abre en una nueva pestaña)')}</a></small>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div>
          <Editor
            value={state.record.content}
            placeholder={t("ehr:record_placeholder", "Escriba aqui la evolucion")}
            onEditorChange={onEditorChange}
          />
        </div>

        <div className="d-flex align-items-center">
          <div>
            <input
              style={{ maxWidth: '118px' }}
              type="date" className="form-control form-control-sm p-2 border-0 w-auto bg-transparent" value={state.record.date} onChange={onChangeDate}
            />
          </div>
          {state.saving ? (
            <div className="p-2">
              <small className="text-secondary">{t('ehr:record_is_saving', 'Guardando...')}</small>
            </div>
          ) : state.saving !== undefined && (
            <div className="p-2">
              <small className="text-success">{t('ehr:record_no_changes', 'Guardado')}</small>
            </div>
          )}
          {!props.tiny && (
            <div className="p-2">
              <small className="text-secondary">{t('ehr:editor_hint_1', 'Podes seleccionar el texto y darle formato')}</small>
            </div>
          )}
        </div>

      </div>
    </div>
  )
}

export default Form
