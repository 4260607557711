import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import isEqual from 'lodash/isEqual'

import { formatDate } from "config/i18n";
import Address from "Components/Address/Address";
import useQuery from 'hooks/useQuery'
import useMutation from "hooks/useMutation";

const KEY = 'CheckLicenses'
const HS_24 = 24 * 60 * 60 * 1000
const clone = window.structuredClone || JSON.clone

const validLicenses = license => {
  const value = license.value?.toString()?.replace(/\D/g, '')
  if (value.length <= 3) return false

  const jurisdiction = [
    license.jurisdiction?.country?.short,
    license.jurisdiction?.administrative_area_level_1?.short,
    license.jurisdiction?.locality?.short,
  ].filter(Boolean)
  if (jurisdiction.length <= 0) return false
  return true
}


function Row (props) {
  const item = props.item

  const { t } = useTranslation();
  const [state, setState] = useState({})
  const [flag, setFlag] = useState('save')

  const [mutate, mutation] = useMutation(
    window._l('/resources/check/licenses').substr(1),
    {
      onMutate: (payload) => {},
      onSuccess: (data) => {
        setFlag('saved')
        props.update(JSON.clone(data))
      },
    }
  );

  const onChange = ({ target: { name, value } }) => {
    const payload = clone(state)
    payload[name] = value
    if (name === 'value') {
      payload[name] = payload[name].replace(/\D/g, '')
    }
    setFlag('save')
    setState(payload)
  }

  const save = () => {
    mutate({ ...state, resourceID: item.resourceID })
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const disabled = !(!!state.jurisdiction?.country?.long && state.value?.length >= 4)

  return (
      <tr>
        <td style={{ maxWidth: 350, width: 350, minWidth: 350 }}>
          <div className="text-truncate">{item.label}</div>
          <div className="text-truncate"><small className="text-secondary">{item.specialties.map(s => t(s.id, s.label)).join(' / ') || t('not_defined', 'Sin definir')}</small></div>
        </td>
        <td className="p-0">
          <div className="form-floating">
            <input
              minLength="3"
              maxLength="64"
              type="text"
              id={`${item.resourceID}value`}
              name="value"
              pattern="[0-9]*"
              inputMode="numeric"
              value={state.value || ''}
              onChange={onChange}
              placeholder={t("value_placeholder", { context: "docs" })}
              className="form-control border-0 rounded-0 bg-transparent"
            />
            <label htmlFor={`${item.resourceID}value`}>
              {t("value_placeholder", { context: "docs" })}
            </label>
          </div>
        </td>
        <td className="p-0" style={{ width: 80, maxWidth: 80, minWidth: 80 }}>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              name={`${item.resourceID}kind`}
              id={`${item.resourceID}kindmn`}
              value="mn"
              checked={state.kind === 'mn'}
              onChange={() => onChange({ target: { name: 'kind', value: 'mn' } })}
            />
            <label className="form-check-label" htmlFor={`${item.resourceID}kindmn`}>
              {t('mn_short_license', 'MN')}
            </label>
          </div>

          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              name={`${item.resourceID}kind`}
              id={`${item.resourceID}kindmp`}
              value="mp"
              checked={state.kind === 'mp'}
              onChange={() => onChange({ target: { name: 'kind', value: 'mp' } })}
            />
            <label className="form-check-label" htmlFor={`${item.resourceID}kindmp`}>
              {t('mp_short_license', 'MP')}
            </label>
          </div>
        </td>
        <td className="p-0">
          <Address
            borderless
            transparent
            id="jurisdiction"
            autocomplete={{ types: ["(regions)"] }}
            value={state.jurisdiction}
            placeholder={t(`jurisdiction_placeholder`, { context: "docs" })}
            label={t(`jurisdiction`, { context: "docs" })}
            onChange={value =>
              onChange({ target: { name: "jurisdiction", value } })
            }
          />
        </td>
        <td style={{ maxWidth: 80, width: 80, minWidth: 80 }} className="text-center">
          {mutation.isLoading ? (
            <span className="spinner-border spinner-border-sm text-primary" role="status"></span>
          ) : !disabled ? (
            <button
              className="btn btn-sm btn-success"
              onClick={save}
              disabled={disabled}
            >
              {t(flag)}
            </button>
          ) : (
            <div>
              <button
                className="btn btn-sm btn-secondary"
                style={{ opacity: '0.5' }}
                data-tip={t('check_license_save_disabled_tip', 'Tiene que ingresar la matricula y seleccionar una jurisdiccion de la lista')}
              >
                {t(flag)}
              </button>
            </div>
          )}
        </td>
      </tr>
  )
}

function CheckLicenses(props) {
  const today = new Date().toISOString().substr(0, 10)

  const { t } = useTranslation();

  const [state, setState] = useState(
    JSON.parse(window.localStorage.getItem(KEY)) || {}
  )
  const [items, setItems] = useState()

  const query = useQuery(
    window._l('/resources/check/licenses').substr(1)
  )

  const close = () => {
    setState({ ...state, closedAt: Date.now() })
  }

  const open = () => {
    setState({ ...state, closedAt: undefined })
  }

  const update = data => {
    try {
      const newItems = data.resources.filter(resource =>
        resource.licenses.filter(validLicenses).length <= 0
      )
      if (isEqual(newItems, items)) return
      setItems(newItems)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const payload = {}
    if (!state.updatedAt || Date.now() >= state.updatedAt + HS_24) {
      payload.updatedAt = Date.now()
      setState(payload)
    }
  }, [state.updatedAt])

  useEffect(() => {
    window.localStorage.setItem(KEY, JSON.stringify(state))
  }, [state])

  useEffect(() => {
    if (query.data && Array.isArray(query.data.resources)) {
      const items = query.data.resources.filter(resource =>
        resource.licenses.filter(validLicenses).length <= 0
      )
      setItems(items)
    }
  }, [query.data])

  if (!Array.isArray(items)) return (
    <div className="position-fixed top-0 bottom-0 start-0 end-0 h-100 w-100 bg-white d-flex align-items-center justify-content-center">
      <div className="spinner-border text-primary" role="status"></div>
    </div>
  )

  if (props.uid === 'ricardo@drapp.com.ar') return props.children
  // si no hay items para verificar no hacer nada
  if (items.length <= 0) return props.children

  // si se cerro hace menos de 24 hs mostrar banner
  if (query.data.expiresAt > today && state.closedAt >= Date.now() - HS_24) return (
    <>
      <button className="btn btn-sm btn-danger rounded-0 w-100 d-block text-start" onClick={open}>
        {t('check_license_banner_text', `Para continuar usando DrApp es necesario que carguen los datos de las matriculas antes del ${formatDate(query.data.expiresAt, "EEEE d MMMM")}`, { expires_at: formatDate(query.data.expiresAt, "EEEE d MMMM") })}
      </button>
      {props.children}
    </>
  )

  return (
    <div className="position-fixed top-0 bottom-0 start-0 end-0 h-100 w-100 overflow-auto" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="m-4">
        {query.data.expiresAt > today ? (
          <div>
            <div className="alert alert-info" role="alert">
              <h4 className="alert-heading">{t('check_license_active_title', 'Matriculas')}</h4>
              <p>{t('check_license_active_text_1', `Para continuar usando DrApp es necesario que carguen los datos de las matriculas antes del ${formatDate(query.data.expiresAt, "EEEE d MMMM")}`, { expires_at: formatDate(query.data.expiresAt, "EEEE d MMMM") })}</p>
              <hr />
              <p className="mb-0">{t('check_license_active_text_2', `Haciendo click en omitir podes continuar usando DrApp sin cargar las matriculas hasta el ${formatDate(query.data.expiresAt, "EEEE d MMMM")}`, { expires_at: formatDate(query.data.expiresAt, "EEEE d MMMM") })}</p>
              <button className="btn btn-sm btn-danger mt-3" onClick={close}>{t('skip', 'Omitir')}</button>
            </div>
          </div>
        ) : (
          <div>
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">{t('check_license_expired_title', 'Matriculas')}</h4>
              <p>{t('check_license_expired_text_1', 'Para continuar usando DrApp es necesario que carguen los datos de las matriculas', { expires_at: formatDate(query.data.expiresAt, "EEEE d MMMM") })}</p>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white rounded m-4">
        <ReactTooltip
          html
          globalEventOff="tooltip:hide"
          effect="solid"
        />
        <table className="table table-borderless align-middle table-striped">
          <thead>
            <tr>
              <th style={{ maxWidth: 350, width: 350, minWidth: 350 }}>{t('name')}</th>
              <th>{t('license')}</th>
              <th style={{ width: 100, maxWidth: 100, minWidth: 100 }}>{t('mn_mp_license', 'MN/MP', { context: 'docs' })}</th>
              <th colSpan="2">{t('jurisdiction', 'Jurisdiccion')}</th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <Row
                key={item.resourceID}
                item={item}
                update={update}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CheckLicenses;
