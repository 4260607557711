import { lazy, useEffect, useState } from "react";
import { Router, useLocation, useNavigate } from "@reach/router";
import ReactTooltip from "react-tooltip";

import actions from "./actions";
import Suspense from "./Suspense/Suspense";
import useQuery from "../hooks/useQuery";
import useUser from "../hooks/useUser";
import useSocket from 'hooks/useSocket'
import useStoreRooms from "./Room/useStore";
import Navbar from "./Navbar/Navbar";
import Events from "./Events/Events";
import Consumers from "./Consumers/Consumers";
import CheckLicenses from "./CheckLicenses/CheckLicenses"
import { Form as SubscriptionForm } from "./Setup/Subscription/Form"

const Notify = lazy(() => import("./Notify/Notify"));
const Room = lazy(() => import("./Room/Room"));
const Consumer = lazy(() => import("./Consumers/Modal/Modal"));
const Location = lazy(() => import("./Location/Modal"));
const MessagesForm = lazy(() => import("./Messages/Form/Form"));
const Onboarding = lazy(() => import("./Onboarding/Onboarding"));
const Setup = lazy(() => import("./Setup/Setup"));
const Locked = lazy(() => import("./Teams/Locked"));
const Prescription = lazy(() => import("./Prescription/Prescription"));
const Monitor = lazy(() => import("./Monitor/Monitor"));

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const teams = useQuery("teams", {
    initialData: [],
  });
  const user = useUser();
  const rooms = useStoreRooms();
  const [team, setTeam] = useState();
  const socket = useSocket()

  useEffect(() => {
    actions.onChangeLocation();
  }, [location.pathname]);

  useEffect(() => {
    if (
      teams.isFetched &&
      user.query.isFetched &&
      user.state.email &&
      location.pathname !== "/teams/new"
    ) {
      const team =
        teams.data.find((team) => location.pathname.includes(team.id)) ||
        teams.data.find((team) => team.id === user.state.teamID) ||
        teams.data.find((team) => team.default) ||
        teams.data[0];
      let search = new URLSearchParams(location.search)
      if (search.get('next')) {
        navigate(search.get('next'), { replace: true });
      } else if (!team && teams.data.length === 0) {
        navigate("/teams/new", { replace: true });
      } else if (!location.pathname.includes(team.id) && !user.state.isStaff) {
        navigate(`/${team.id}/events/agenda`, { replace: true });
      } else if (!location.pathname.includes("/teams/") && user.state.isStaff) {
        navigate(`/${team.id}/events/agenda`, { replace: true });
      }

      setTeam(team);
    }
  }, [
    location.pathname,
    location.search,
    teams.data,
    teams.isFetched,
    user.query.isFetched,
    user.state.email,
    user.state.teamID,
    user.state.isStaff,
  ]);

  useEffect(() => {
    let email = user.state?.email

    if (email) {
      let _hsq = window._hsq = window._hsq || [];

      _hsq.push(['identify', { email }]);
    }
  }, [ user.state.email]);

  useEffect(() => {
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', location.pathname]);
    _hsq.push(['trackPageView']);
  }, [location.pathname])

  return !socket ? (
    <div className="vh-100 p-5 d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : location.pathname === "/teams/new" ? (
    <Suspense>
      <Onboarding />
    </Suspense>
  ) : !team ? (
    <div className="vh-100 p-5 d-flex justify-content-center align-items-center">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : location.pathname.includes('/monitor') ? (
    <Suspense>
      <Monitor />
    </Suspense>
  ) : (
    <Suspense>
      <CheckLicenses uid={user.state?.uid}>
        {(!team.locked || user.state.isStaff) && (
          <Navbar />
        )}
        {team.locked && (
          <Locked />
        )}
        <Router>
          <Consumers path="/teams/:teamID/consumers/*" />
          {(!team.locked || user.state.isStaff) && (
            <>
              <Events path="/teams/:teamID/events/*" />
              <Setup path="/teams/:teamID/setup/*" />
            </>
          )}
          <SubscriptionForm path="/teams/:teamID/suscripcion" />
          <SubscriptionForm path="/teams/:teamID/subscription" />
        </Router>
        <Notify />
        <Consumer />
        <MessagesForm />
        <Location />
        <ReactTooltip
          html
          globalEventOff="tooltip:hide"
          effect="solid"
        />
        {rooms.ids.map((id) => (
          <Room key={`room-${id}`} name={id} onClose={() => rooms.close(id)} />
        ))}
        <Prescription />
      </CheckLicenses>
    </Suspense>
  );
}

export default App;
