import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Router, Link, useLocation, navigate } from "@reach/router";
import uniqBy from 'lodash/uniqBy'

import useQuery from "hooks/useQuery";
import useUser from "hooks/useUser";

import Sidebar from "./Sidebar/Sidebar";
import Home from "./Home/Home";
import Diagnostics from "./Diagnostics/Diagnostics";
import Treatments from "./Treatments/Treatments";
import VitalSigns from "./VitalSigns/VitalSigns";
import Labs from "./Labs/Labs";
import Files from "./Files/Files";
import Summary from "./Summary/Summary";
import Forms from "../../EHR/EHR";
import Consumer from "../../EHR/Consumer/Consumer";
import Print from "./Print/Print";
import useStore from './useStore'
import sort from './sort'

let V2_DATE = new Date('2023-08-22T09:00').getTime()
let V2_CLOSE = 3 * 60 * 60 * 1000

function EHR({ consumerID }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [eventID, setEventID] = useState();
  const [closedAt, setClosedAt] = useState(null)

  const store = useStore()

  const event = useQuery(window._l(`/events/${eventID}`).substr(1), {
    enabled: !!eventID,
  });

  const user = useUser();

  const consumer = useQuery(window._l(`/consumers/${consumerID}`).substr(1));

  const resource = useQuery(
    window._l(`/${user.team?.resourceID}`).substr(1),
    { enabled: !!user.team?.resourceID }
  );

  const forms = useQuery(window._l(`/forms`).substr(1), {
    enabled: !!consumerID,
  });

  const query = useQuery(
    window._l(`/consumers/${consumerID}/records/_all`).substr(1)
  );

  const closeV2Message = () => {
    let resourceID = resource.data?.id
    if (resourceID) {
      let key = `${resourceID}:ehr:v1:closedAt`
      window.localStorage.setItem(key, Date.now())
      setClosedAt(Date.now())
    }
  }

  useEffect(() => {
    let resourceID = resource.data?.id
    let params = new URLSearchParams(location.search)
    if (params.get('mode') !== 'static' && resourceID && resource.data.metadata?.ehr?.version === 'v2:hard') {
      let eventID = params.get('eventID')
      navigate(`${location.pathname.replace('/ehr', '/ehr/v2')}${eventID ? `?eventID=${eventID}` : ''}`)
    } else if (resourceID) {
      let key = `${resourceID}:ehr:v1:closedAt`
      let closedAt = Number(window.localStorage.getItem(key)) || 1
      setClosedAt(closedAt)
    }
  }, [resource.data, location.search, location.pathname])

  useEffect(() => {
    let eventID = new URLSearchParams(location.search).get("eventID");
    if (eventID) {
      setEventID(eventID);
    }
  }, []);

  useEffect(() => {
    store.update({ resource: resource.data })
  }, [resource.data])

  useEffect(() => {
    store.update({ event: event.data })
  }, [event.data])

  useEffect(() => {
    store.update({ consumer: consumer.data })
  }, [consumer.data])

  useEffect(() => {
    const payload = { records: [], diagnostics: [], treatments: [], vitalSigns: [], labs: [], files: [] }
    const data = query.data || []
    const dStatus = Object.keys(store.filters.diagnoses.status || {}).filter(key => !!store.filters.diagnoses.status[key])
    const tStatus = Object.keys(store.filters.treatments.status || {}).filter(key => !!store.filters.treatments.status[key])

    const uids = {}
    for (const record of data) {
      if (record.deleted && !store.filters.showDeletedRecords) continue;
      const type = record.type || "records"
      if (type === 'records' && !record.content) continue
      const uid = record.createdBy || t('no_identification', 'Sin identificar')
      uids[uid] = true
      if (store.filters.uids.me && record.createdBy !== store.filters.uids.me) continue;
      if (store.filters.uids.me !== record.createdBy && store.filters.uids[record.createdBy] === false) continue;
      if (type === "diagnostics" && !dStatus.includes(record.status)) continue
      if (type === "treatments" && !tStatus.includes(record.status)) continue

      if (type === "records") {
        payload.records.push(record)
      } else if (type === "diagnostics") {
        payload.diagnostics.push(record)
      } else if (type === "treatments") {
        payload.treatments.push(record)
      } else if (type === "vitalSigns") {
        payload.vitalSigns.push(record)
      } else if (/^file/.test(type)) {
        record.classification ??= record.status || 'unclassified'
        payload.files.push(record)
      } else if (type === "labs") {
        payload.labs.push(record)
      } else if (type) {
        payload[type] ||= []
        payload[type].push(record)
      }
    }

    for (const [type, entry] of Object.entries(payload)) {
      payload[type] = uniqBy(entry.sort(sort(store.sort)), 'id');
    }

    store.update({ ...payload, uids: Object.keys(uids), data: query.data })
  }, [
    query.data,
    store.filters,
    store.sort
  ])

  useEffect(() => {
    store.update({ forms: forms.data })
  }, [JSON.stringify(forms.data)])

  if (!consumer.data?.id || query.isLoading || query.isIdle || user.query.isLoading || (!!user.team?.resourceID && !resource.data) || resource.isFetching || (resource.data?.metadata?.ehr?.version === 'v2:hard' && !location.search.includes('mode=static'))) {
    return (
      <div className="mvh-100-1 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary">
          <span className="visually-hidden">{t("loading")}</span>
        </div>
      </div>
    );
  }

  if (query.isLoadingError || query.isError) {
    return (
      <div className="text-center my-3 rounded shadow-sm p-3">
        <div className="text-danger">{t("error")}</div>
      </div>
    );
  }

  return (
    <div className="mvh-100-1" style={{ background: 'rgb(235, 235, 235)' }}>
      <div className="container-fluid py-3">
        {resource.data?.id && resource.data.createdAt <= V2_DATE && closedAt && closedAt + V2_CLOSE <= Date.now() && resource.data.metadata?.ehr?.version?.includes('v2') && (
          <div className="px-2 py-1 bg-white rounded border border-warning border-2 mb-3 d-flex align-items-center">
            <small className="flex-fill">{t('ehr:v2_message', 'Ya esta disponible la nueva version de HCE. Podes empezar a usarla cuando quieras')}</small>
            <div className="d-flex align-items-center gap-1">
              <Link className="btn btn-sm btn-link" to={!!eventID ? `v2?eventID=${eventID}` : 'v2'}>{t('ehr:try_v2', 'Probar la version')}</Link>
              <button onClick={closeV2Message} className="btn btn-sm">{t('ehr:v2_close_message', 'Cerrar')}</button>
            </div>
          </div>
        )}
        <div className="row">
          <div className="order-1 col-12 col-lg-7 col-xl-8">
            <Home />
            <Router className="mt-3">
              <Diagnostics
                path="diagnostics/*"
                className="bg-white p-3"
                consumer={consumer.data}
                resource={resource.data}
              />
              <Treatments
                path="treatments/*"
                className="bg-white p-3"
                consumer={consumer.data}
                resource={resource.data}
              />
              <VitalSigns
                path="vitalSigns/*"
                className="bg-white p-3"
                consumer={consumer.data}
                resource={resource.data}
              />
              <Labs
                path="labs/*"
                className="bg-white p-3"
                consumer={consumer.data}
                resource={resource.data}
              />
              <Files
                path="files/*"
                className="bg-white p-3"
                consumer={consumer.data}
                resource={resource.data}
              />
              <Forms
                consumer={consumer.data}
                path="forms/*"
                consumer={false}
                fixed={false}
              />
              <Print
                path="print/*"
                className="bg-white p-3 printBody"
                records={store.records}
                diagnostics={store.diagnostics}
                treatments={store.treatments}
                labs={store.labs}
                vitalSigns={store.vitalSigns}
                user={user}
                consumer={consumer}
                resource={resource.data}
              />
            </Router>
          </div>
          <div className="order-0 mb-3 mb-lg-0 col-12 col-lg-5 col-xl-4 mt-3 mt-lg-0">
            <Sidebar />
          </div>
        </div>
      </div>

    </div>
  );
}

export default EHR;
