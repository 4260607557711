import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo, error });
    try {
      console.error(error, errorInfo);
    } catch {}
  }

  render() {
    if (this.state.hasError)
      return (
        <div className="text-center p-5">
          <div>
            <strong>Error</strong>
          </div>
          <div className="mt-3">
            <a href="/">Home</a>
          </div>
        </div>
      );
    return this.props.children;
  }
}

export default ErrorBoundary;
