import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import Diagnoses from '../Diagnoses/Diagnoses'
import useMutation from 'hooks/useMutation'
import getDate from 'lib/getDate'

function Diagnostic(props) {
  const [state, setState] = useState({
    consumer: { id: props.consumer?.id, label: props.consumer?.label },
    createdBy: props.user?.uid,
    createdByName: props.user?.name || props.resource?.label,
    createdByResourceID: props.resource?.id,
    date: getDate(),
    startsAt: getDate(),
    status: 'chronic',
    ...props.value,
    type: 'diagnostics'
  })
  const [mutate, mutation] = useMutation(window._l(`/records`).substr(1));
  const { t } = useTranslation()

  const onChange = ({ target: { name, value, type } }) => {
    state[name] = value
    setState({ ...state })
  }

  const onClose = () => {
    if (props.onClose) return props.onClose()
  }

  const save = () => {
    mutate(state, {
      onSuccess: record => {
        setState({ ...state, ...record })
        if (props.onSave) props.onSave(record)
      }
    })
  }

  return (
    <div
      className={[props.className, 'd-flex flex-column justify-content-top gap-2'].filter(Boolean).join(' ')}
    >
      <div>
        <Diagnoses
          column
          value={[{ label: state.label || state.dx, key: state.code || state.id }].filter(s => !!s.label)}
          onChange={array => setState(JSON.parse(JSON.stringify({
            ...state,
            ...array[0],
          })))}
        />
      </div>
      <div>
        <input
          type="text"
          name="notes"
          onChange={onChange}
          value={state.notes || ''}
          placeholder={t("ehr:notes", "Notas", { context: 'diagnoses' })}
          className="form-control"
        />
      </div>
      <div className="d-flex gap-2">
        <div className="form-floating" style={{ maxWidth: 'calc(50% - 0.25rem)' }}>
          <input
            type="date"
            name="startsAt"
            onChange={onChange}
            value={state.startsAt || ''}
            placeholder={t("ehr:startsAt", { context: 'diagnoses' })}
            className="form-control"
          />
          <label htmlFor="endsAt">{t("ehr:startsAt", { context: 'diagnoses' })}</label>
        </div>
        <div className="form-floating" style={{ maxWidth: 'calc(50% - 0.25rem)' }}>
          <input
            type="date"
            name="endsAt"
            onChange={onChange}
            value={state.endsAt || ''}
            placeholder={t("ehr:endsAt", { context: 'diagnoses' })}
            className="form-control"
          />
          <label htmlFor="endsAt">{t("ehr:endsAt", { context: 'diagnoses' })}</label>
        </div>
      </div>
      <div className="form-floating">
        <select
          name="status"
          id="status"
          name="status"
          onChange={onChange}
          value={state.status || ''}
          className="form-select"
        >
          <option value="chronic">{t("ehr:chronic", { context: 'diagnoses' })}</option>
          <option value="pending">{t("ehr:pending", { context: 'diagnoses' })}</option>
          <option value="intercurrence">{t("ehr:intercurrence", { context: 'diagnoses' })}</option>
        </select>
        <label htmlFor="status">{t("ehr:status", { context: 'diagnoses' })}</label>
      </div>
      <div className="d-flex justify-content-between">
        {mutation.isLoading ? (
          <button disabled className="btn btn-link mx-n2">{t('ehr:saving', { context: 'diagnoses' })}</button>
        ) : (
          <button disabled={!state.label} className="btn btn-link mx-n2" onClick={save}>{t('ehr:save', { context: 'diagnoses' })}</button>
        )}
        <button className="btn link-secondary mx-n2" onClick={onClose}>{t('close')}</button>
      </div>
    </div>
  )
}

export default Diagnostic
