import { useQueryClient, useMutation } from "react-query";
import helpers from "@drapp/helpers";

import getAccessToken from "./getAccessToken";

const { REACT_APP_API_URL } = process.env;

const request = ({ endpoint, token }) => async (body) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const url = `${REACT_APP_API_URL}/${endpoint}`.replace(/\/new/, "");
  const res = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  const data = await res.json().catch(() => null);
  if (res.status >= 200 && res.status <= 299) {
    return data;
  } else if (data && data.error) {
    throw Error(data.error);
  }
  throw Error("error");
};

const Hook = (endpoint, _options) => {
  const accessToken = getAccessToken();
  const queryClient = useQueryClient();

  const options = JSON.clone(_options || {});

  options.onMutate = (payload) => {
    try {
      if (_options?.onMutate) {
        _options.onMutate(payload);
      }
      update(payload);
    } catch (e) {
      console.log(e);
    }
  };

  options.onSuccess = (data) => {
    try {
      if (_options?.onSuccess) {
        _options.onSuccess(data);
      }
      update(data, "clear");
    } catch (e) {
      console.log(e);
    }
  };

  const update = (payload, clear) => {
    let key;
    if (endpoint.includes("consumers")) {
      key = "consumers";
    } else if (endpoint.includes("events")) {
      key = "events";
    } else if (endpoint.includes("records")) {
      // key = "records";
    }

    if (!key) return;

    if (Array.isArray(payload?.phones)) {
      payload.phones = payload.phones.filter((phone) => phone.value);
    }

    if (Array.isArray(payload?.emails)) {
      payload.emails = payload.emails.filter((email) =>
        helpers.isEmail(email.value)
      );
    }

    const keys = queryClient
      .getQueryCache()
      .findAll()
      .filter(
        (query) =>
          key.split(",").some((key) => query.queryHash.includes(key)) ||
          (payload?.id && query.queryHash.includes(payload.id))
      )
      .map((query) => query.queryKey);

    const findIndex = (item) =>
      item.id === (payload.id || payload.createdAt) &&
      (key !== "records" ||
        (item?.consumer?.id === payload?.consumer?.id &&
          item.type === payload.type));

    for (const key of keys) {
      queryClient.setQueryData(key, (_cache) => {
        let cache = JSON.clone(_cache);
        if (cache?.id && cache.id === payload.id) {
          return JSON.clone(payload);
        } else if (Array.isArray(cache?.pages)) {
          for (let i = 0; i < cache.pages.length; i += 1) {
            const index = cache.pages[i].findIndex(findIndex);
            if (index < 0 && (payload.id || payload.createdAt)) {
              cache.pages[i] = [...cache.pages[i], payload].filter(
                (item) => !clear || item.id
              );
            } else {
              cache.pages[i][index] = payload;
            }
          }
        } else if (Array.isArray(cache?.events)) {
          const index = cache.events.findIndex(findIndex);
          if (index < 0) {
            cache.events = [...cache.events, payload].filter(
              (item) => !clear || item.id
            );
          } else {
            cache.events[index] = payload;
          }
        } else if (Array.isArray(cache)) {
          const index = cache.findIndex(findIndex);
          if (index < 0) {
            cache = [...cache, payload].filter((item) => !clear || item.id);
          } else {
            cache[index] = payload;
          }
        }
        return JSON.clone(cache);
      });
    }
  };

  const { mutate, ...rest } = useMutation(
    request({
      endpoint,
      token: accessToken.value,
    }),
    options
  );

  return [mutate, rest];
};

export default Hook;
