const sort = value => (a, b) => {
  const [key, direction] = value?.split(':') || ['date', 'desc']

  if (!a.id && b.id) return -1;
  if (a.id && !b.id) return 1
  const aDate = a[key] || a.startsAt
  const bDate = b[key] || b.startsAt
  if (aDate < bDate && direction === "asc") return -1;
  if (aDate > bDate && direction === "asc") return 1;
  if (aDate < bDate && direction === "desc") return 1;
  if (aDate > bDate && direction === "desc") return -1;

  if (a.createdAt < b.createdAt && direction === "asc") return -1;
  if (a.createdAt > b.createdAt && direction === "asc") return 1;
  if (a.createdAt < b.createdAt && direction === "desc") return 1;
  if (a.createdAt > b.createdAt && direction === "desc") return -1;

  return 0;
};

export default sort
