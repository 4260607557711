import { useState, useEffect } from 'react'
import { useLocation } from "@reach/router";

import styles from "./styles.module.scss";
import Filters from "../Filters/Filters";
import Calendar from "./Calendar";
import useStoreGlobal from '../../useStore'
import useStore from '../useStore'
import useQuery from 'hooks/useQuery'

function Sidebar() {
  const location = useLocation();
  const storeGlobal = useStoreGlobal()
  const [stats, setStats] = useState()
  const facets = useStore(state => state.facets)

  useEffect(() => {
    if (facets) {
      const stats = Object.entries(facets).reduce((hash, [key, values]) => ({
        ...hash, [key]: Object.keys(values).length
      }), { resources: 0, locations: 0, services: 0, financiers: 0 })

      setStats(stats)
    }
  }, [facets])

  return (
    <div className={`${styles.container} ${storeGlobal.sidebar ? '' : styles.hide} bg-white d-flex flex-column mvh-100-1 overflow-auto d-print-none shadow-lg`}>
      <div className={`flex-fill ${styles.wrapper}`}>
        {!stats ? (
          <div className="vh-100 p-5 d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        ) : (
          <>
            <Calendar />
            <Filters
              className="mx-1"
              resources={stats.resources > 1}
              locations={stats.locations > 1}
              services={!/agenda/.test(location.pathname) && stats.services > 1}
              financiers={!/agenda/.test(location.pathname) && stats.financiers > 1}
              switches={!/agenda/.test(location.pathname)}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
