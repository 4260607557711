const CalculateBMI = (height, weight) => {
  if (!height || !weight) return {};
  const h2 = Math.pow(height / 100, 2);
  let value = weight / h2;
  if (value < 18) return { value, warning: "underweight" };
  if (value <= 25) return { value, warning: "normal" };
  if (value < 30) return { value, warning: "overweight" };
  return { value, warning: "obese" };
};

export default CalculateBMI;
