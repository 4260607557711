import { useState } from 'react'
import { useTranslation } from "react-i18next";
import { ReactComponent as IconFilter } from 'bootstrap-icons/icons/filter.svg'
import { ReactComponent as IconSquare } from 'bootstrap-icons/icons/square.svg'
import { ReactComponent as IconCheckSquare } from 'bootstrap-icons/icons/check-square.svg'
import get from 'lodash/get'
import set from 'lodash/set'

const clone = window.structuredClone || JSON.clone

function Filters(props) {
  const { t } = useTranslation()
  const [filters, setFilters] = useState({
    sort: props.filters.sort || 'desc',
    sortBy: props.filters.sortBy || 'date',
    createdBy: { ...props.filters.createdBy },
    deleted: { ...props.filters.deleted }
  })

  const toggle = parts => {
    const payload = clone(filters)
    let current
    for (const part of parts) {
      if (part === 'flag') {
        current.flag = !current.flag
      } else {
        current = current?.[part] || payload[part]
      }
    }
    setFilters(payload)
    props.onChange(payload)
  }

  const update = (key, value) => {
    filters[key] = value
    setFilters({ ...filters })
    props.onChange({ ...filters })
  }

  return (
    <div className="dropdown">
      <button
        className="text-truncate btn btn-sm btn-link px-2 py-1 d-flex align-items-center gap-1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="false"
      >
        <IconFilter className="text-secondary" /> {t('filters', 'Filtros')}
      </button>
      <div className="dropdown-menu p-0 mt-1 border-0 shadow" style={{ maxWidth: "320px" }}>
        <div className="px-3 py-3">
          <h6 className="m-0 text-first-up">{t('ehr:resources', 'recursos', { context: 'filters' })}</h6>
          {filters.createdBy._ids.map(resourceID => (
            <button key={resourceID} onClick={() => toggle(['createdBy', resourceID, 'flag'])} className="text-truncate btn btn-sm btn-link px-2 py-1 d-flex align-items-center gap-1">
              {filters.createdBy[resourceID].flag ? (
                <IconCheckSquare width="13" height="13" className="text-dark" />
              ) : (
                <IconSquare style={{ opacity: 0.35 }} width="13" height="13" className="text-dark" />
              )}{' '}{filters.createdBy[resourceID].label} <sup className="text-secondary">{filters.createdBy[resourceID].count}</sup>
            </button>
          ))}

          <h6 className="mb-0 mt-2 lh-1 text-first-up">{t('ehr:sort', 'orden', { context: 'filters' })}</h6>
          <button onClick={() => update('sort', 'desc')} className="text-truncate btn btn-sm btn-link px-2 py-1 d-flex align-items-center gap-1">
            {filters.sort === 'desc' ? (
              <IconCheckSquare width="13" height="13" className="text-dark" />
            ) : (
              <IconSquare style={{ opacity: 0.35 }} width="13" height="13" className="text-dark" />
            )}{' '}{t('ehr:sort_desc', 'Recientes primero', { context: 'filters' })}
          </button>
          <button onClick={() => update('sort', 'asc')} className="text-truncate btn btn-sm btn-link px-2 py-1 d-flex align-items-center gap-1">
            {filters.sort === 'asc' ? (
              <IconCheckSquare width="13" height="13" className="text-dark" />
            ) : (
              <IconSquare style={{ opacity: 0.35 }} width="13" height="13" className="text-dark" />
            )}{' '}{t('ehr:sort_asc', 'Antiguos primero', { context: 'filters' })}
          </button>
          <h6 className="mb-0 mt-2 lh-1 text-first-up">{t('ehr:sort_by_field', 'ordenar por', { context: 'filters' })}</h6>
          <button onClick={() => update('sortBy', 'date')} className="text-truncate btn btn-sm btn-link px-2 py-1 d-flex align-items-center gap-1">
            {filters.sortBy === 'date' ? (
              <IconCheckSquare width="13" height="13" className="text-dark" />
            ) : (
              <IconSquare style={{ opacity: 0.35 }} width="13" height="13" className="text-dark" />
            )}{' '}{t('ehr:sort_date', 'Fecha de carga', { context: 'filters' })}
          </button>
          <button onClick={() => update('sortBy', 'createdAt')} className="text-truncate btn btn-sm btn-link px-2 py-1 d-flex align-items-center gap-1">
            {filters.sortBy === 'createdAt' ? (
              <IconCheckSquare width="13" height="13" className="text-dark" />
            ) : (
              <IconSquare style={{ opacity: 0.35 }} width="13" height="13" className="text-dark" />
            )}{' '}{t('ehr:sort_created_at', 'Fecha de creación', { context: 'filters' })}
          </button>
          <button onClick={() => update('sortBy', 'updatedAt')} className="text-truncate btn btn-sm btn-link px-2 py-1 d-flex align-items-center gap-1">
            {filters.sortBy === 'updatedAt' ? (
              <IconCheckSquare width="13" height="13" className="text-dark" />
            ) : (
              <IconSquare style={{ opacity: 0.35 }} width="13" height="13" className="text-dark" />
            )}{' '}{t('ehr:sort_updated_at', 'Fecha de actualizacion', { context: 'filters' })}
          </button>

          <h6 className="mb-0 mt-2 lh-1 text-first-up">{t('ehr:others', 'otros', { context: 'filters' })}</h6>
          <button onClick={() => toggle(['deleted', 'flag'])} className="text-truncate btn btn-sm btn-link px-2 py-1 d-flex align-items-center gap-1">
            {filters.deleted.flag ? (
              <IconCheckSquare width="13" height="13" className="text-dark" />
            ) : (
              <IconSquare style={{ opacity: 0.35 }} width="13" height="13" className="text-dark" />
            )}{' '}{t('ehr:show_deleted', 'Mostrar borrados', { context: 'filters' })} <sup className="text-secondary">{filters.deleted.count || 0}</sup>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Filters
