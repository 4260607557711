import Textarea from "react-textarea-autosize";

function Text({
  id,
  label,
  disabled,
  name,
  value,
  onChange,
  placeholder,
  hint,
  rows = 1,
  className,
  floating = true,
}) {
  return (
    <div
      className={`${floating ? "form-floating" : ""} ${className || "mb-3"}`}
    >
      {label && !floating && (
        <label
          className="form-label"
          htmlFor={id}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
      <Textarea
        id={id}
        name={name}
        value={value || ""}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        className="form-control"
      />
      {label && floating && (
        <label
          className="form-label"
          htmlFor={id}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
      {hint && (
        <div className="form-text" dangerouslySetInnerHTML={{ __html: hint }} />
      )}
    </div>
  );
}

export default Text;
