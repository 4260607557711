import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { formatDistanceStrict } from "date-fns";
import kebabCase from "lodash/kebabCase";
import { AsyncTypeahead, Hint } from "react-bootstrap-typeahead";

import useMutation from "../../../../hooks/useMutation";
import useCatalogs from "../../../../hooks/useCatalogs";
import { formatDate, getLocale } from "../../../../config/i18n";

let ticker;

function Diagnostic(props) {
  const [countdown, setCountdown] = useState(null);
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  const [record, setRecord] = useState({});
  const [mode, setMode] = useState("read");

  const [mutate] = useMutation(
    window._l(`/${record.id || "records"}`).substr(1),
    {
      onMutate: (payload) => {
        update(payload);
      },
      onSuccess: (data) => {
        update(data, "clear");
      },
      onError: (error) => {
        window.notify({
          message: error.message,
          type: "danger",
          title: "Error",
        });
      },
    }
  );

  const cie10 = useCatalogs("cie10", query, {
    enabled: mode === "edit" && !!query,
    initialData: [],
  });

  const onChange = ({ target: { name, value } }) => {
    record[name] = value;
    setRecord({ ...record });
  };

  const onChangeDx = (array) => {
    const option = (array || [])[0];
    record.cie10Code = "";
    record.dx = option?.label;
    if (/^cie10:/.test(option?.id)) {
      record.cie10Code = option.id.replace(/^cie10:/, "");
    }
    setRecord({ ...record });
  };

  const update = (payload, clear) => {
    setMode("read");
    props.update(payload, clear);
  };

  const restore = () => {
    mutate({
      ...record,
      deleted: false,
    });
  };

  const remove = (mode) => {
    setCountdown(null);

    if (!record.id) {
      return update({ ...record, deleted: true, content: "", text: "" });
    }

    if (mode === "confirm") {
      return mutate({
        ...record,
        deleted: true,
      });
    }

    if (mode === "discard") return;

    setCountdown(true);
  };

  const save = () => {
    mutate({ ...record });
    setMode("read");
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    setRecord({ ...props.record });
  }, [props.record]);

  useEffect(() => {
    setMode(!record.id ? "edit" : "read");
  }, [record.id]);

  let age;
  if (record.startsAt) {
    age = formatDistanceStrict(
      new Date(record.startsAt),
      new Date(record.endsAt || Date.now()),
      {
        addSuffix: record.startsAt && !record.endsAt,
        locale: getLocale(),
      }
    );
  }

  return mode === "edit" ? (
    <div className="bg-light p-3 my-3 rounded">
      <div className="d-flex align-items-center mb-3">
        <div className="flex-fill me-2">
          <AsyncTypeahead
            minLength={2}
            allowNew={(results) => !cie10.isLoading && results.length === 0}
            isLoading={cie10.isLoading}
            onChange={onChangeDx}
            filterBy={() => true}
            options={cie10.data}
            onSearch={setQuery}
            id={record.id || record.createdAt}
            useCache={false}
            selected={[
              {
                label: record.dx,
                id: record.cie10Code || kebabCase(record.dx),
              },
            ].filter((d) => d.id)}
            newSelectionPrefix={null}
            emptyLabel={t(`no_matches`, "No hay resultados")}
            paginationText={t(`display_more_results`, "Mostrar mas")}
            promptText={t(`type_to_search`, "Escriba para buscar")}
            searchText={t(`searching`)}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
              <Hint
                shouldSelect={(shouldSelect, e) =>
                  ["Enter", "Tab"].includes(e.key) || shouldSelect
                }
              >
                <div className="form-floating w-100">
                  <input
                    {...inputProps}
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder={t("diagnostic_placeholder")}
                    ref={(input) => {
                      inputRef(input);
                      referenceElementRef(input);
                    }}
                  />
                  <label>{t("diagnostic_placeholder")}</label>
                </div>
              </Hint>
            )}
          />
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.notes}
            onChange={onChange}
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder={t("notes_placeholder")}
          />
          <label htmlFor="notes">{t("notes")}</label>
        </div>

        <div className="form-floating me-2">
          <input
            value={record.startsAt || ""}
            onChange={onChange}
            type="date"
            className="form-control"
            id="startsAt"
            name="startsAt"
            placeholder={t("startsAt_placeholder")}
          />
          <label htmlFor="startsAt">{t("startsAt")}</label>
        </div>

        <div className="form-floating me-2">
          <input
            value={record.endsAt || ""}
            onChange={onChange}
            type="date"
            className="form-control"
            id="endsAt"
            name="endsAt"
            placeholder={t("endsAt_placeholder")}
          />
          <label htmlFor="endsAt">{t("endsAt")}</label>
        </div>

        <div className="form-floating">
          <select
            name="status"
            id="status"
            className="form-select"
            onChange={onChange}
            value={record.status}
          >
            <option value="chronic">{t("chronic")}</option>
            <option value="pending">{t("pending")}</option>
            <option value="intercurrence">{t("intercurrence")}</option>
          </select>
          <label htmlFor="status">{t("status")}</label>
        </div>
      </div>

      {record.deleted ? (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={restore}
          >
            {t("restore", { label: t("diagnostic") })}
          </button>
          <button
            className="btn btn-sm btn-link link-secondary"
            onClick={() => setMode("read")}
          >
            {t("close")}
          </button>
        </div>
      ) : countdown !== null ? (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-link link-success me-2"
            onClick={() => remove("discard")}
          >
            {t("discard")}
          </button>
          <button
            className="btn btn-sm btn-link link-danger"
            onClick={() => remove("confirm")}
          >
            {t("confirm")}
          </button>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-success me-2"
            onClick={save}
          >
            {t("save", { label: t("diagnostic") })}
          </button>
          <button
            className="btn btn-sm btn-link link-secondary"
            onClick={() => {
              if (!record.updatedAt) {
                setMode("discard");
                mutate({
                  ...record,
                  deleted: true,
                });
              } else {
                setMode("read");
              }
            }}
          >
            {t("close")}
          </button>
          <button className="btn btn-sm btn-link link-danger" onClick={remove}>
            {t("ehr:delete", { label: t("diagnostic") })}
          </button>
        </div>
      )}
    </div>
  ) : mode === "discard" ? null : (
    <div>
      <div className="mt-3 pt-3 border-top d-flex align-items-center justify-content-between">
        <div>
          <strong>
            {t(`cie10:${record.cie10Code}`, record.dx || record.label || "(N/A)")}
          </strong>
          {record.cie10Code && (
            <span
              className="ms-1 text-muted fst-italic text-uppercase"
              data-tip={t("ehr:cie10_code")}
              data-event="mouseenter"
              data-event-off="mouseleave"
              data-effect="solid"
              data-place="top"
            >
              <sup>{record.cie10Code}</sup>
            </span>
          )}
        </div>
        <div>
          {record.deleted && (
            <small className="badge bg-danger mx-2 fw-normal">
              {t("ehr:deleted")}
            </small>
          )}
          {record.status && (
            <small
              className={cx({
                "text-primary": record.status === "chronic",
                "text-success": record.status === "intercurrence",
                "text-warning": record.status === "pending",
              })}
            >
              {t(`pms:${record.status}`)}
            </small>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          {record.notes && <div className="small">{record.notes}</div>}
          {age && <small className="text-dark me-2">{age}</small>}
          {record.startsAt && (
            <small className="text-uppercase me-2">
              {formatDate(record.startsAt, "dd-MMM-yyyy")}
            </small>
          )}
          {record.endsAt && (
            <small className="text-muted me-2">
              {t("until")} {formatDate(record.endsAt, "dd-MMM-yyyy")}
            </small>
          )}
          <small className="text-muted">
            {record.createdByName}{" "}
            <small>
              {record.createdByLicense &&
                record.createdByLicense.replace(/-/g, " ")}
            </small>
          </small>
        </div>

        <button
          className="btn btn-sm btn-link p-0 align-baseline"
          onClick={() => setMode("edit")}
        >
          {t("edit")}
        </button>
      </div>
    </div>
  );
}

export default Diagnostic;
