function Select({
  id,
  label,
  disabled,
  required,
  name,
  onChange,
  value,
  placeholder,
  options,
  hint,
  className = "mb-3",
  floating = true,
  border = true,
}) {
  return (
    <div
      className={`${floating ? "form-floating" : ""} ${className ?? "mb-3"}`}
    >
      {label && !floating && (
        <label
          className="form-label"
          htmlFor={id}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
      <select
        disabled={disabled}
        required={required}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        className={`form-select ${!border ? "border-0" : ""}`}
      >
        {!value && placeholder && <option value="">{placeholder}</option>}
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {label && floating && (
        <label
          className="form-label"
          htmlFor={id}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
      {hint && (
        <div className="form-text" dangerouslySetInnerHTML={{ __html: hint }} />
      )}
    </div>
  );
}

export default Select;
