import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import Phone from "../../../Components/Phone/Phone";

function Phones(props) {
  const { t } = useTranslation();
  const [state, setState] = useState([]);

  const slice = (index1) => {
    const newState = state.filter((item, index2) => index1 !== index2);
    setState([...newState]);
    props.onChange(JSON.clone([...newState]));
  };

  const add = () => {
    setState([...state, { ...props.options }]);
  };

  const onChange = (index) => (phone) => {
    state[index] = phone;
    const array = JSON.clone([...state]);
    setState(array);
    props.onChange(array);
  };

  useEffect(() => {
    setState([...props.value]);
  }, [props.value]);

  return (
    <React.Fragment>
      <div className="mb-3">
        <div className="d-flex align-items-center form-label">
          <label htmlFor="phones">
            {t("phone_plural", { context: props.context })}
          </label>
          {!props.disabled && (
            <button
              onClick={add}
              type="button"
              className="btn btn-link p-0 ms-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
              </svg>
            </button>
          )}
        </div>
        {state.map((phone, index) => (
          <div
            className={cx("position-relative", {
              "mb-1": index !== state.length - 1,
            })}
            key={`phone${index}`}
          >
            <Phone
              name="phone"
              id={`phone${index}`}
              disabled={!!props.disabled}
              value={phone}
              required={props.required}
              onChange={onChange(index)}
              hint={index + 1 === state.length}
              onAdd={add}
            />

            <div className="form-control-actions d-flex align-items-center">
              {!props.disabled && (
                <button
                  className="btn btn-sm text-danger py-0 px-1"
                  type="button"
                  onClick={() => slice(index)}
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-x"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
export default Phones;
