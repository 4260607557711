import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconInfoCircle } from "bootstrap-icons/icons/info-circle.svg";
import { ReactComponent as IconRadioactive } from "bootstrap-icons/icons/radioactive.svg";
import { ReactComponent as IconRecycle } from "bootstrap-icons/icons/recycle.svg";
import ReactTooltip from "react-tooltip";

import { formatDate } from "config/i18n";

const App = (props) => {
  const { t } = useTranslation();

  const toggle = () => {
    if (!props.record.hide && !window.confirm(
        t(
          "form_remove_record",
          "Confirmas que queres borrar el registro. Podes volver a verlo filtrando registros eliminados"
        )
      )
    ) return;
    props.onChange({ ...props.record, hide: !props.record.hide });
  };

  const isDone = props.record.fields?.status?.items?.some(
    (item) => item.value === "done"
  );

  const fields = Object.values(props.record.fields || {}).filter(
    (field) => Array.isArray(field.treatments) && !isDone
  );

  const drugs = fields.flatMap((field) =>
    field.treatments.flatMap((treatment) => treatment.drugs)
  );
  let interactions = props.interactions.filter((interaction) =>
    interaction.drugs.some((drug) => drugs.includes(drug))
  );

  interactions = interactions.sort((a, b) => {
    if (a.severity === "major" && b.severity !== "major") return -1;
    if (a.severity !== "major" && b.severity === "major") return 1;
    if (a.severity === "moderate" && b.severity !== "moderate") return -1;
    if (a.severity !== "moderate" && b.severity === "moderate") return 1;
    return 0;
  });

  let $html = `
    <dl>
      <dt>${t("create", { context: "ehr" })}</dt>
      <dd>${props.record?.createdBy?.replace("users/", "")}</dd>
      <dd class="text-first-up">${formatDate(
        props.record.createdAt,
        "EEEE d MMM HH:mm:ss"
      )}</dd>
      ${
        props.record?.updatedBy && props.record?.updatedAt
          ? `
        <dt>${t("update", { context: "ehr" })}</dt>
        <dd>${props.record?.updatedBy?.replace("users/", "")}</dd>
        <dd class="text-first-up">${formatDate(
          props.record.updatedAt,
          "EEEE d MMM HH:mm:ss"
        )}</dd>
      `
          : ""
      }
    </dl>
    ${
      interactions.length > 0
        ? `
      <div class="mt-2">
        ${interactions
          .map(
            (interaction) => `
          <div class="bg-white p-2 text-dark mb-1 rounded">
            <h6 class="m-0">${interaction.title}</h6>
            <div class="small mb-2 text-secondary">${interaction.drugs.join(
              " + "
            )}</div>
            ${interaction.message}
          </div>
        `
          )
          .join("")}
      </div>
    `
        : ""
    }

    <div class="text-end mt-2 small lh-1 fst-italic">${props.record?.hide ?
      t("ehr:form_record_click_to_restore","Para restaurarlo hace click")
      :
      t("ehr:form_record_click_to_remove", "Si necesitas borrar el registro hace click")}</div>
  `;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div>
      <button
        data-tip={$html}
        className="btn rounded-0 bg-light"
        onClick={toggle}
      >
        {props.record?.hide ? (
          <IconRecycle className="text-danger" />
        ) : interactions.length === 0 ? (
          <IconInfoCircle className="text-primary" />
        ) : interactions.some((i) => i.severity === "major") ? (
          <IconRadioactive className="text-danger" />
        ) : interactions.some((i) => i.severity === "moderate") ? (
          <IconRadioactive className="text-warning" />
        ) : (
          <IconRadioactive className="text-success" />
        )}
      </button>
    </div>
  );
};

export default App;
