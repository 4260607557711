import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconSearch } from "bootstrap-icons/icons/search.svg";
import groupBy from "lodash/groupBy";
import { v4 as uuid } from "uuid";
import { ReactComponent as IconList } from "bootstrap-icons/icons/list.svg";

import useQuery from "hooks/useQuery";
import useUser from "hooks/useUser";
import useStorePrescription from "App/Prescription/useStore";
import useStoreConsumer from "./Consumer/useStore";

import Form from "./Form/Form";
import useStore from "./useStore";

const Forms = (props) => {
  let consumerID = props.consumer?.id || props.consumerID;
  if (!/^consumers\//.test(consumerID)) {
    consumerID = `consumers/${consumerID}`
  }
  const { t } = useTranslation();
  const [newForms, setNewForms] = useState([]);
  const [show, setShow] = useState(false);
  const store = useStore();
  const prescription = useStorePrescription();
  const storeConsumer = useStoreConsumer();
  const user = useUser();

  const consumer = useQuery(window._l(`/${consumerID}`).substr(1), {
    enabled: !!consumerID && !props.consumer?.id,
    initialData: { ...props.consumer },
  });

  const records = useQuery(
    window._l(`/${consumerID}/records?type=forms`).substr(1),
    { initialData: [], enabled: !!consumer.data.id }
  );

  const forms = useQuery(window._l(`/forms`).substr(1), {
    initialData: [],
    enabled: !!consumer.data.id,
  });

  const addForm = () => {
    const form = {
      id: `forms/${uuid()}`,
      orientation: "horizontal",
      label: "",
      fields: [{ id: uuid(), type: "text" }],
    };

    setNewForms([form, ...newForms]);
  };

  useEffect(() => {
    if (consumer.data.id) {
      storeConsumer.update({ consumer: JSON.clone(consumer.data) });
    }
  }, [consumer.data]);

  useEffect(() => {
    if (props.fixed === false) {
      for (const form of forms.data) {
        if (form.custom) continue;
        store.set(form.id, false);
      }
    }
  }, [props.fixed, forms.data]);

  records._data = groupBy(records.data, "formID");
  forms.fixed = [];
  forms.custom = [];
  for (const form of forms.data) {
    if (form.custom) {
      forms.custom.push(form);
    } else {
      forms.fixed.push(form);
    }
  }

  if (!records.isFetched) {
    return (
      <div className="mvh-100-1 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary">
          <span className="visually-hidden">{t("loading")}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="d-print-none show"
      >
        <div
          className="gap-2 p-2 d-flex align-items-center mb-3"
        >
          <button className="btn btn-sm btn-primary" onClick={addForm}>
            {t("add", { label: t("form", "formulario") })}
          </button>
          <button
            onClick={() => window.print()}
            className="btn btn-sm btn-primary"
          >
            {t("print")}
          </button>
          <div>
            <button
              className="btn btn-sm btn-primary"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
            >
              {t("filters")}
            </button>
            <div className="dropdown-menu p-2 shadow-sm">
              <div className="form-check">
                <input
                  className="form-check-input"
                  onChange={() => store.set("me", !store.me)}
                  type="checkbox"
                  id="filtersCreatedByMe"
                  checked={!!store.me}
                />
                <label
                  className="form-check-label"
                  htmlFor="filtersCreatedByMe"
                >
                  {t("form_show_only_createdBy_me", "Creados por mi")}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  onChange={() => store.set("others", !store.others)}
                  type="checkbox"
                  id="filtersCreatedByOthers"
                  checked={!!store.others}
                />
                <label
                  className="form-check-label"
                  htmlFor="filtersCreatedByOthers"
                >
                  {t(
                    "form_show_only_createdBy_others",
                    "Creados por los demas"
                  )}
                </label>
              </div>
              {forms.data
                .sort((a, b) => {
                  if (!a.custom && !b.custom) return 0;
                  if (!a.custom && b.custom) return -1;
                  if (a.custom && !b.custom) return 1;
                  if (!a.hide && b.hide) return -1;
                  if (a.hide && !b.hide) return 1;
                  return 0;
                })
                .map((form) => (
                  <div className="form-check" key={`filter${form.id}`}>
                    <input
                      className="form-check-input"
                      onChange={() =>
                        store.set(
                          form.id,
                          store[form.id] === undefined
                            ? !!form.hide
                            : !store[form.id]
                        )
                      }
                      type="checkbox"
                      id={`filtersCreatedBy${form.id}`}
                      checked={store[form.id] ?? !form.hide}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`filtersCreatedBy${form.id}`}
                    >
                      {form.label || t("form_empty_label", "Sin nombre")}
                      {form.hide && (
                        <small className="text-secondary ms-1">
                          ({t("hidden", "Oculto")})
                        </small>
                      )}
                    </label>
                  </div>
                ))}
            </div>
          </div>
          {store["forms/treatments"] !== false && (
            <button
              onClick={() =>
                prescription.setConsumerID(`${consumer.data.id}`)
              }
              className="btn btn-sm btn-primary"
            >
              {t("prescription")}
            </button>
          )}
        </div>
      </div>

      {newForms.map((form) => (
        <Form {...form} key={form.id} />
      ))}

      {forms.fixed.map((form) => (
        <Form records={records?._data[form.id] || []} {...form} key={form.id} />
      ))}

      {forms.custom.map((form) => (
        <Form records={records?._data[form.id] || []} {...form} key={form.id} />
      ))}
    </>
  );
};

export default Forms;
