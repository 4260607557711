import { useState } from 'react'
import { useTranslation } from "react-i18next";
import { AsyncTypeahead, Hint } from "react-bootstrap-typeahead";
import { useQuery } from 'react-query'
import deburr from 'lodash/deburr'
import kebabCase from 'lodash/kebabCase'

import styles from './styles.module.scss'

const {
  REACT_APP_API_URL
} = process.env

const request = async q => {
  if (!q) return []
  const params = new URLSearchParams({ q }).toString()
  const url = `${REACT_APP_API_URL}/vademecums/ar?${params}`
  const res = await fetch(url)
  if (res.status >= 200 && res.status <= 299) {
    const items = await res.json()
    return items.map(item => ({
      ...item,
      key: item.key || item.product_code,
      label: item.label || [item.product, item.presentation, item.drug].filter(Boolean).join(' ')
    }))
  } else {
    return []
  }
}

function Vademecum(props) {
  const { t } = useTranslation()
  const [query, setQuery] = useState()
  const [state, setState] = useState(props.value || [])

  const search = useQuery(['vademecums', query], () => request(query), {
    refetchOnWindowFocus: false,
    enabled: !!query
  })

  const onChange = items => {
    if (!props.onChangeReset) setState(items)
    if (props.onChange) props.onChange(items.map(i => ({
      ...i,
      id: undefined,
      customOption: undefined,
      product_code: i.product_code || `custom:${kebabCase(deburr(i.label))}`
    })))
  }

  return (
    <div>
      <AsyncTypeahead
        minLength={2}
        align="left"
        id="vademecum"
        allowNew={results => !search.isLoading && results.length === 0}
        isLoading={search.isLoading}
        filterBy={() => true}
        onChange={onChange}
        options={search.data}
        onSearch={setQuery}
        placeholder={props.placeholder ?? t('ehr:treatment', 'Trataminento')}
        promptText={t(`type_to_search`, "Escriba para buscar")}
        searchText={t(`searching`)}
        newSelectionPrefix={null}
        className={styles.vademecum}
        selected={state}
        renderMenuItemChildren={(option, props, index) => {
          let {
            product,
            label,
            presentation,
            drug,
            company,
          } = option
          return (
            <div className="d-flex justify-content-between">
              <div>
                <div>
                  <strong>{product || label}</strong> {!!presentation && (
                    <small>{presentation}</small>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <small className="text-secondary">{drug || t('ehr:prescriptions_form_treatment_empty_drug', 'N/A')} {!!company && (
                    <sup>{company}</sup>
                  )}</small>
                </div>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default Vademecum
