import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { formatDistanceStrict } from "date-fns";
import { onlineManager, useQuery } from "react-query";
import "./treatment.css";
import substrDrugs from "./substrDrugs";
import useMutation from "../../../../hooks/useMutation";
import { formatDate, getLocale } from "../../../../config/i18n";
import { AsyncTypeahead, Hint } from "react-bootstrap-typeahead";

const searchVademecum = (country, string) => {
  if (string) {
    return fetch(
      `https://api.drapp.la/vademecums/${country}?q=${string}`
    ).then((res) => res.json());
  }
};

function Treatment(props) {
  const [countdown, setCountdown] = useState(null);
  const { t } = useTranslation();

  const [record, setRecord] = useState({});
  const [mode, setMode] = useState("read");
  const [tooltipHTML, setTooltipHTML] = useState("");
  const [searchString, setSearchString] = useState("");
  const [prescripted, setPrescripted] = useState(false);

  const [mutate] = useMutation(
    window._l(`/${record.id || "records"}`).substr(1),
    {
      onMutate: (payload) => {
        update(payload);
      },
      onSuccess: (data) => {
        update(data, "clear");
      },
      onError: (error) => {
        window.notify({
          message: error.message,
          type: "danger",
          title: "Error",
        });
      },
    }
  );

  const update = (payload, clear) => {
    setMode("read");
    props.update(payload, clear);
  };

  const onChange = ({ target: { name, value } }) => {
    record[name] = value;
    setRecord({ ...record });
  };

  const onChangeDrug = (array) => {
    if (array[0].drug) record.drug = array[0].drug;
    if (array[0].product) record.product = array[0].product;
    if (array[0].product) record.presentation = array[0].presentation;
    if (array[0].product) record.company = array[0].company;
    if (array[0].product) record.product_code = array[0].product_code;
    if (array[0].product) record.drug_code = array[0].drug_code;
    if (array[0].drugs)
      array[0].drugs.forEach((drug) =>
        record.drugs.push(searchReadyDrugs(drug))
      );
    if (!array[0].drug && array[0].label) record.drug = array[0].label;

    setRecord({ ...record });
  };

  function searchReadyDrugs(drug) {
    let found;
    for (let i = 0; i < substrDrugs.length; i++) {
      if (drug.search(substrDrugs[i]) >= 0) {
        found = substrDrugs[i];
      } else {
        found = drug;
      }
    }
    return found;
  }

  const restore = () => {
    mutate({
      ...record,
      deleted: false,
    });
  };

  const remove = (mode) => {
    setCountdown(null);
    if (!record.id) {
      return update({ ...record, deleted: true, content: "", text: "" });
    }

    if (mode === "confirm") {
      return mutate({
        ...record,
        deleted: true,
      });
    }

    if (mode === "discard") return;

    setCountdown(true);
  };

  const save = async () => {
    mutate({ ...record });
    setMode("read");
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    setRecord({ ...props.record });
  }, [props.record]);

  useEffect(() => {
    setMode(!record.id ? "edit" : "read");
  }, [record.id]);

  let age;
  if (record.createdAt) {
    age = formatDistanceStrict(
      new Date(record.createdAt),
      new Date(Date.now()),
      {
        addSuffix: record.endsAt === "",
        locale: getLocale(),
      }
    );
  }

  let interactions = [];
  if (props.interactions.data && record.drugs) {
    props.interactions.data.forEach((interaction) => {
      record.drugs.forEach((drug) => {
        if (interaction.drugs.includes(drug)) {
          interactions.push(interaction);
        }
      });
    });
  }

  //elimina duplicados: si el farmaco tiene 2 principios activos que interaccionan entre si, se duplica la interaccion en el data-tip
  let noDuplicates = [];
  if (interactions.length > 0) {
    for (const i in interactions) {
      if (
        noDuplicates.findIndex((j) => j.message === interactions[i].message) ===
        -1
      ) {
        noDuplicates.push(interactions[i]);
      }
    }
  }

  const vademecum = useQuery(
    searchString,
    () => searchVademecum(props.country, searchString),
    { initialData: [] }
  );

  let labelledVademecum;

  if (vademecum && vademecum.data) {
    labelledVademecum = vademecum.data.map((element) => ({
      ...element,
      label: `${element.drug} ${element.product} ${element.presentation}`,
    }));
  }

  const prescribeButton = () => {
    return (
      <button
        onClick={() => {
          props.addDrug(record);
          if (prescripted) {
            setPrescripted(false);
          } else {
            setPrescripted(true);
          }
        }}
        className={
          prescripted
            ? "badge  border-0 text-success bg-white ms-0 me-3 align-middle"
            : "badge  border-0 text-danger bg-white ms-0 me-3 align-middle"
        }
      >
        {prescripted ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-check-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          </svg>
        )}
      </button>
    );
  };

  useEffect(() => {
    setTooltipHTML(`
    <div class="text-start" style="max-width: 380px">
      ${noDuplicates
        .map(
          (i) =>
            `<div>
          <h6>${i.drugs.join(" + ")}: ${i.title} <small class="text-muted">${t(
              i.severity
            )}</small></h6>
        </div>
      `
        )
        .join("")}</div>
  `);
  }, [noDuplicates]);

  return mode === "edit" ? (
    <div className="bg-light p-3 my-3 rounded">
      <div className="d-flex align-items-center mb-3">
        <div className="form-floating flex-fill me-2">
          <AsyncTypeahead
            minLength={2}
            align="left"
            id={record.id || record.createdAt}
            allowNew={(results) => !vademecum.isLoading && results.length === 0}
            isLoading={vademecum.isLoading}
            onChange={onChangeDrug}
            filterBy={() => true}
            options={labelledVademecum}
            onSearch={setSearchString}
            labelKey="label"
            useCache={false}
            emptyLabel={t(`no_matches`, "No hay resultados")}
            paginationText={t(`display_more_results`, "Mostrar mas")}
            promptText={t(`type_to_search`, "Escriba para buscar")}
            searchText={t(`searching`)}
            newSelectionPrefix={null}
            selected={[
              {
                label: record.drug,
                id: record.drug,
              },
            ].filter((d) => d.id)}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
              <Hint
                shouldSelect={(shouldSelect, e) =>
                  ["Enter", "Tab"].includes(e.key) || shouldSelect
                }
              >
                <div className="form-floating w-100">
                  <input
                    {...inputProps}
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder={t("ehr:drug_placeholder")}
                    ref={(input) => {
                      inputRef(input);
                      referenceElementRef(input);
                    }}
                  />
                  <label>{t("ehr:drug_placeholder")}</label>
                </div>
              </Hint>
            )}
          ></AsyncTypeahead>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.dose}
            onChange={onChange}
            type="text"
            className="form-control"
            id="dose"
            name="dose"
            placeholder={t("dose_placeholder")}
          />
          <label htmlFor="dose">{t("dose")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.frecuency}
            onChange={onChange}
            type="text"
            className="form-control"
            id="frecuency"
            name="frecuency"
            placeholder={t("frecuency_placeholder")}
          />
          <label htmlFor="frecuency">{t("frecuency")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.notes}
            onChange={onChange}
            type="text"
            className="form-control"
            id="notes"
            name="notes"
            placeholder={t("notes_placeholder")}
          />
          <label htmlFor="notes">{t("notes")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.startsAt || ""}
            onChange={onChange}
            type="date"
            className="form-control"
            id="startsAt"
            name="startsAt"
            placeholder={t("startsAt_placeholder")}
          />
          <label htmlFor="startsAt">{t("startsAt")}</label>
        </div>

        <div className="form-floating flex-fill me-2">
          <input
            value={record.endsAt || ""}
            onChange={onChange}
            type="date"
            className="form-control"
            id="endsAt"
            name="endsAt"
            placeholder={t("endsAt_placeholder")}
          />
          <label htmlFor="endsAt">{t("endsAt")}</label>
        </div>

        <div className="form-floating">
          <select
            name="status"
            id="status"
            className="form-select"
            onChange={onChange}
            value={record.status}
          >
            <option value="active">{t("active")}</option>
            <option value="pending">{t("pending")}</option>
            <option value="done">{t("done")}</option>
          </select>
          <label htmlFor="status">{t("status")}</label>
        </div>
      </div>

      {record.deleted ? (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-primary me-2"
            onClick={restore}
          >
            {t("restore", { label: t("treatment") })}
          </button>
          <button
            className="btn btn-sm btn-link link-secondary"
            onClick={() => setMode("read")}
          >
            {t("close")}
          </button>
        </div>
      ) : countdown !== null ? (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-link link-success me-2"
            onClick={() => remove("discard")}
          >
            {t("discard")}
          </button>
          <button
            className="btn btn-sm btn-link link-danger"
            onClick={() => remove("confirm")}
          >
            {t("confirm")}
          </button>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-sm btn-outline-success me-2"
            onClick={save}
          >
            {t("save", { label: t("treatment") })}
          </button>
          <button
            className="btn btn-sm btn-link link-secondary"
            onClick={() => {
              if (!record.updatedAt) {
                setMode("discard");
                mutate({
                  ...record,
                  deleted: true,
                });
              } else {
                setMode("read");
              }
            }}
          >
            {t("close")}
          </button>
          <button className="btn btn-sm btn-link link-danger" onClick={remove}>
            {t("ehr:delete", { label: t("treatment") })}
          </button>
        </div>
      )}
    </div>
  ) : mode === "discard" ? null : (
    <div>
      <div className="mt-3 pt-3 border-top d-flex align-items-center">
        {props.prescribeMode && (
          <div className="d-flex">{prescribeButton()}</div>
        )}
        <div className="d-flex flex-column">
          <strong data-tip={record.presentation}>{record.product}</strong>
          <small className="text-muted">{record.drug}</small>
          <div>
            <div>
              {record.label || [record.dose, record.frecuency, record.notes]
                .filter(Boolean)
                .join(", ")}
            </div>
            {noDuplicates.length > 0 && (
              <a
                key={record.id}
                data-background-color="#000"
                data-opactity="1"
                data-tip={""}
                data-bs-toggle="modal"
                data-bs-target="#interactionModal"
                onClick={() => {
                  props.handleInteractionClick(noDuplicates);
                }}
                data-for={record.id}
                className={cx({
                  "text-success btn btn-sm btn-link px-0":
                    noDuplicates[0].severity === "minor",
                  "text-warning btn btn-sm btn-link px-0":
                    noDuplicates[0].severity === "moderate",
                  "text-danger btn btn-sm btn-link px-0":
                    noDuplicates[0].severity === "major",
                })}
              >
                {t("interaction_plural", { count: noDuplicates.length })}
              </a>
            )}
            <ReactTooltip
              id={record.id}
              html={true}
              getContent={() => tooltipHTML}
            />
            <div>
              {age && <small className="text-dark me-2">{age}</small>}
              {record.creat && (
                <small className="text-uppercase me-2">
                  {formatDate(record.startsAt, "dd-MMM-yyyy")}
                </small>
              )}
              {record.endsAt && (
                <small className="text-muted me-2">
                  {t("until")} {formatDate(record.endsAt, "dd-MMM-yyyy")}
                </small>
              )}
              <small className="text-muted">
                {record.createdByName}{" "}
                <small>
                  {record.createdByLicense &&
                    record.createdByLicense.replace(/-/g, " ")}
                </small>
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column ms-auto">
          {record.deleted && (
            <small className="badge bg-danger mx-2 fw-normal">
              {t("ehr:deleted")}
            </small>
          )}
          <small
            className={cx({
              "text-primary mb-3": record.status === "active",
              "text-success mb-3": record.status === "done",
              "text-warning mb-3": record.status === "pending",
            })}
          >
            {t(`pms:${record.status}`)}
          </small>
          <button
            className="btn btn-sm btn-link p-0 align-baseline"
            onClick={() => setMode("edit")}
          >
            {t("edit")}
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div></div>
      </div>
    </div>
  );
}

export default Treatment;
